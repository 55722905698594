import React, { useState } from 'react';
import { Button, Menu, MenuItem, IconButton, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import IndeterminateCheckBoxIcon from "@material-ui/icons/IndeterminateCheckBox";
import BulkEditEmployees from '../employees/bulkEditEmployees';

const useStyles = makeStyles(theme => ({
    iconButton: {
    },
    iconContainer: {
      marginRight: "24px",
    },
}));

const CustomToolbarSelect = (props) => {
    const classes = useStyles();
    const { selectedRows, setSelectedRows } = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const [ openBulkEdit, setOpenBulkEdit ] = useState(false);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };



    const handleClickDeselectAll = event => {
        setSelectedRows([]);
    }

    return (
        <div className={classes.iconContainer}>
            { openBulkEdit ? 
                <BulkEditEmployees rows={selectedRows} data={props.rows} company={props.company} open={openBulkEdit} reloadAfterSuccess={props.listCall}
                handleClose={() => { setOpenBulkEdit(false)} } />
            : undefined }
            <Tooltip title={'Deselect All'}>
                <IconButton className={classes.iconButton} onClick={handleClickDeselectAll}>
                    <IndeterminateCheckBoxIcon className={classes.icon} />
                </IconButton>
            </Tooltip>
            <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                Actions
            </Button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}>
                { props.bulkUpdate && <MenuItem onClick={() => { setOpenBulkEdit(true); }}>Bulk Edit</MenuItem> }
                <MenuItem onClick={handleClose} disabled={true}>Mark selected for testing</MenuItem>
                <MenuItem onClick={handleClose} disabled={true}>Invite Selected</MenuItem>
            </Menu>
        </div>
    )
}

export default CustomToolbarSelect;