import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Divider, Grid, Typography } from '@material-ui/core';
import clsx from 'clsx';
import UserReportList from './userReportList';
import moment from 'moment';
import corporateHealthService from '../services/corporate-health.service';

const useStyles = makeStyles(theme => ({
    root: {
      padding: theme.spacing(3),
      flexGrow: 1,
      minHeight: 415
    },
    officeInfoTitle: {
      padding: 10
    },
    officeInfoSection: {
      padding: 10
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 200,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    fullHeight: {
      height: '100vh',
      width: '100vw'
    },
    header: {
        fontWeight: 500,
        // marginLeft: 10,
        color: '#000'
    },
    barBadge: {
        padding: 2,
        color: '#2f2f2f',
        borderRadius: '5px',
        fontSize: '12px'
    },
    highRisk: {
        backgroundColor: '#FF7262'
    },
    moderateRisk: {
        backgroundColor: '#FFA53A'
    },
    normal: {
        backgroundColor: '#27AE60'
    }
}));
  
const DailyReport = (props) => {
    const classes = useStyles();
    const [quarantinedEmployees, setQuarantinedEmployees ] = useState({ list: [], count: 0});

    useEffect(() => {
        corporateHealthService.listQuarantinedEmployees(props.company).then(
            response => {
                setQuarantinedEmployees(response);
            }, error => {

            }
        );
    }, []);

    return (
        <div className={classes.root}>
            <Grid container spacing={2}
                justify="flex-start" alignItems="flex-start">
                <Typography variant="h5" className={classes.header}>
                    Quarantined Employees
                </Typography>
                <Typography variant="body1">
                    Today, { moment().format('D MMM YYYY')}
                </Typography>
                <Grid item xs={12}>
                    <Grid item>
                        { quarantinedEmployees.list.length !== 0  ? <UserReportList list={quarantinedEmployees.list || []} /> :
                            <Typography variant="caption">
                                No records yet
                            </Typography> }
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )

}

export default DailyReport;