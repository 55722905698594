import React, { useState, useEffect } from 'react';
import {
    Grid,
    Typography,
    Tab,
    Tabs,
    Paper,
    TextField,
    InputAdornment
} from '@material-ui/core'
import {makeStyles, withStyles} from "@material-ui/core/styles";
import AccountLinkingTable from './accountLinkingTable'
import DailyLogs from "../dashboard/DailyLogs";
import EmployeesOnBoardedMetrics from "./EmployeesOnBoardedMetrics";
import AccountLinkingTrendMetrics from "./AccountLinkingTrendMetrics";
import clsx from "clsx";
import corporateHealthService from './../services/corporate-health.service';
import MuiBadge from '@material-ui/core/Badge';
import {Search as SearchIcon} from "@material-ui/icons";
import DebounceTextField from "../components/DebounceTextField";
import HealthRiskMetrics from './../healthRisk/healthRiskMetrics';


const styles = (theme) => ({
    root: {

    }
  })

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4),
        flexGrow: 1
    },
    textLeft: {
        textAlign: 'left'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#2f2f2f',
    },
    trendChart: {
        [theme.breakpoints.up('md')]: {
            paddingLeft: '2%'
        }
    },
    badge: {
        height: '20px',
        display: 'flex',
        padding: '0 6px',
        zIndex: 1,
        position: 'absolute',
        flexWrap: 'wrap',
        fontSize: '0.75rem',
        minWidth: 20,
        boxSizing: 'border-box',
        transition: 'transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        alignItems: 'center',
        fontWeight: 500,
        lineHeight: 1,
        alignContent: 'center',
        borderRadius: 10,
        flexDirection: 'row',
        justifyContent: 'center'
    },
    pendingCount: {
        backgroundColor: '#8F9EC3', 
        color: 'black',
      },
    rejectedCount: {
        backgroundColor: '#ed1822',
        color: 'black',
    },
    approvedCount: {
        backgroundColor: '#85CBBE',
        color: 'black',
    },
    noMatchCount: {
        backgroundColor: '#CCCCCC',
        color: 'black',
    }
}));

const StyledBadge = withStyles(styles)((props) => {
    const { children, count, styling, label, ...other } = props;
    return (
      <MuiBadge badgeContent={
        <Typography component="span" className={clsx(useStyles().badge, styling)} style={{ top: 1}}>
          { count }
        </Typography>
      } showZero>
        <Typography variant="caption" style={{ marginTop: 10, textTransform: 'none'}}>
            { label }
        </Typography>
      </MuiBadge>
    )
})


function AccountLinking (props) {
    const classes = useStyles();
    const [ company, setCompany ] = useState(null);
    const [ locations, setLocations ] = useState([])
    const [ searchTimer, setSearchTimer ] = useState(setTimeout(() => {}))
    const [ locationSelection, setLocationSelection ] = useState()
    const [ isLoadingLocations, setLocationsLoader ] = useState(false)
    const [searchCounter, setSearchCounter] = useState(0);
    const [ counts, setCounts ] = useState([]);
    const [ tab, setTab ] = useState("PENDING_APPROVAL")
    const [ search, setSearch ] = useState("")
    const tabs = [
        {
            label: "Account Linking Request",
            value: "PENDING_APPROVAL"
        },
        {
            label: "Rejected",
            value: "INVALID"
        },
        {
            label: "Approved",
            value: "VALID"
        },
        {
            label: "Unmatched",
            value: "NO_MATCH"
        }
    ]

    useEffect(() => {
        // if(!props.company_details.company) {
            getCompanyDetails();
        // } else {
        //     setCompany(props.company_details.company);
        // }

        var params = {
            page_size: 1,
            page_offset: 0,
            partnerId: props.company
        }

        const linkedCount = corporateHealthService.getLinkingRequests({ ...params, status: 'VALID', linked: true});
        const unlinkedCount = corporateHealthService.getLinkingRequests({...params, status: 'INVALID', linked: false});
        const pendingCount = corporateHealthService.getLinkingRequests({...params, status: 'PENDING_APPROVAL', linked: false});
        const noMatchCount = corporateHealthService.getNoMatchLinkingRequests({ ...params, linked: false });

        Promise.all([linkedCount, unlinkedCount, pendingCount, noMatchCount]).then(responses => {
            const newList = responses.map((response) => {
                return response.count ? response.count : 0;
            });

            setCounts(newList);
        })
    }, [props.company])

    function getCompanyDetails() {
        corporateHealthService.getPartnerDetails(props.company).then(
          response => {
            setCompany(response);
            // if(!props.company_details.company) {
            //   props.company_details.update(response);
            // }
          }, error => {

          }
        )
    }

    const labels = [
        { label: <StyledBadge label={`Account Linking Request`} styling={classes.pendingCount} count={counts[2]} />},
        { label: <StyledBadge label={`Rejected`} styling={classes.rejectedCount} count={counts[1]} />},
        { label: <StyledBadge label={`Approved`} styling={classes.approvedCount} count={counts[0]} />},
        { label: <StyledBadge label={`Unmatched`} styling={classes.noMatchCount} count={counts[3]} /> }
    ];

    function TabPanel(props) {
        const { children, value, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== tab}
                id={`simple-tabpanel-${tab}`}
                aria-labelledby={`simple-tab-${tab}`}
                {...other}
            >
                {value === tab && (
                    <div>{children}</div>
                )}
            </div>
        );
    }

    function handleSearch() {
        clearTimeout(searchTimer)

        setSearchCounter(counter => counter + 1)
    }

    // const handleChangeSearch = event => {

    //     handleSearch();
    // }

    return (
        <div className={classes.root}>
            <Grid container spacing={3}>
                <Grid item container xs={12}>
                    <Typography variant="h5" style={{ fontWeight: 'bold' }}>
                        Account Linking
                    </Typography>
                </Grid>

                {/*<Grid item container xs={12}>*/}
                {/*    <Grid item container xs={12} md={3}>*/}
                {/*        <EmployeesOnBoardedMetrics />*/}
                {/*    </Grid>*/}
                {/*    <Grid className={classes.trendChart} item container xs={12} md={9}>*/}
                {/*        <AccountLinkingTrendMetrics />*/}
                {/*    </Grid>*/}
                {/*</Grid>*/}

                <Grid item container xs={12}>
                    <Grid item container xs={12} md={6}>
                       {/* { counts.length >= 1 && company ? <EmployeesOnBoardedMetrics
                            counts={counts} company={company}/> : undefined } */}
                             {/* [
                                    'Workers with linked accounts',
                                    'Workers with not linked accounts',
                                    'Workers with pending approval status',
                                    'Workers with rejected ID linking'
                                ] */}
                              <HealthRiskMetrics
                                        title={'Workers On-Boarded'}
                                        data={[
                                            { name: 'Requests Pending', value: counts[2]},
                                            { name: 'Rejected', value: counts[1] },
                                            { name: 'Approved', value: counts[0]},
                                            { name: 'Unmatched', value: counts[3] },
                                        ]}
                                        backgroundColors={ ['#8F9EC3', '#EEBE6A', '#85CBBE', '#E8685E']}
                                    />
                    </Grid>
                    <Grid item container xs={12} md={7} />
                </Grid>

                <Grid item container xs={12}>
                    <Paper variant={"outlined"} style={{ width: "100%", padding: "1%" }}>
                        <Grid container spacing={3}>
                            <Grid item container alignItems="center" justify="space-between">
                                <Grid className={classes.textLeft} item xs={12} sm={8}>
                                    <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                                        Account Linking
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        onChange={(ev) => {
                                            setSearch(ev.target.value);
                                            handleSearch();
                                        }}
                                        // onBlur={(ev) => {
                                        //     setSearch(ev.target.value);
                                        //     handleSearch();
                                        // }}
                                        onKeyPress={(ev) => {
                                            if (ev.key === "Enter") {
                                                setSearch(ev.target.value);
                                                handleSearch();
                                            }
                                        }}
                                        value={search}
                                        label="Search name"
                                        id="outlined-start-adornment"
                                        style={{ minWidth: 300 }}
                                        className={clsx(classes.margin, classes.textField)}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="end">
                                                <SearchIcon />
                                            </InputAdornment>,
                                        }}
                                        variant="outlined"
                                    />
                                </Grid>
                            </Grid>
                            <Grid item container>
                                <Grid item xs={12}>
                                    <Tabs
                                        value={tab}
                                        onChange={(event, newValue) => setTab(newValue)}
                                        aria-label="Linking Requests Tabs"
                                    >
                                        {
                                            tabs.map((tab, index) => (
                                                <Tab key={tab.value} label={labels[index].label} value={tab.value} />
                                            ))
                                        }
                                    </Tabs>

                                    <TabPanel value={"PENDING_APPROVAL"}>
                                        <AccountLinkingTable
                                            partnerId={props.company}
                                            columns={[
                                                { id: 'name', name: 'name', label: 'Name' },
                                                { id: 'cardNumber', name: 'cardNumber', label: 'Worker ID Input' },
                                                { id: 'referenceCardNumber', name: 'referenceCardNumber', label: 'Correct Worker ID'},
                                                { id: 'image', name: 'image', label: 'ID Picture', align: 'left', },
                                                { id: 'actions', name: 'actions',  label: 'Approve / Reject', align: 'left' },
                                                { id: 'lastUpdated', name: 'lastUpdated', label: 'Status Last Updated',  align: 'left'}
                                            ]}
                                            status={"PENDING_APPROVAL"}
                                            search={search}
                                        />
                                    </TabPanel>

                                    <TabPanel value={"INVALID"}>
                                        <AccountLinkingTable
                                            partnerId={props.company}
                                            columns={[
                                                { id: 'name', name: 'name', label: 'Name', align: 'left' },
                                                { id: 'cardNumber', name: 'cardNumber', label: 'Worker ID Input', align: 'left' },
                                                { id: 'referenceCardNumber', name: 'referenceCardNumber', label: 'Correct Worker ID' },
                                                { id: 'image', name: 'image', label: 'ID Picture', align: 'left', },
                                                { id: 'reason', name: 'reason', label: 'Reason', align: 'left'},
                                                { id: 'note', name: 'note', label: 'Note', align: 'left'},
                                                { id: 'lastUpdated', name: 'lastUpdated', label: 'Status Last Updated',  align: 'left'}
                                            ]}
                                            status={"INVALID"}
                                            search={search}
                                        />
                                    </TabPanel>

                                    <TabPanel value={"VALID"}>
                                        <AccountLinkingTable
                                            partnerId={props.company}
                                            columns={[
                                                { id: 'name', name: 'name', label: 'Name', align: 'left' },
                                                { id: 'cardNumber', name: 'cardNumber', label: 'ID Number', align: 'left' },
                                                { id: 'image', name: 'image', label: 'ID Picture', align: 'left', },
                                                { id: 'lastUpdated', name: 'lastUpdated', label: 'Status Last Updated',  align: 'left'}
                                            ]}
                                            status={"VALID"}
                                            search={search}
                                        />
                                    </TabPanel>

                                    <TabPanel value={"NO_MATCH"}>
                                        <AccountLinkingTable
                                            partnerId={props.company}
                                            columns={[
                                                { id: 'name', name: 'name', label: 'Name' },
                                                { id: 'cardNumber', name: 'cardNumber', label: 'Worker ID Input' },
                                                { id: 'referenceCardNumber', name: 'referenceCardNumber', label: 'Correct Worker ID' },
                                                { id: 'image', name: 'image', label: 'ID Picture', align: 'left', },
                                                { id: 'actions', name: 'actions', label: 'Reject', align: 'left' },
                                                { id: 'lastUpdated', name: 'lastUpdated', label: 'Status Last Updated', align: 'left' }
                                            ]}
                                            status={"NO_MATCH"}
                                            search={search}
                                        />
                                    </TabPanel>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item container xs={12}>
                    <Paper variant={"outlined"} style={{ width: "100%", padding: "1%" }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <DailyLogs
                                partnerId={props.company}
                                    logType={["ID_LINKING"]}
                                    title={"Account Linking Logs"}
                                    showFilters={{
                                        name: {
                                            md: 5
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    )
}

export default AccountLinking;
