import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, Grid, Typography, Avatar, Tooltip, IconButton } from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import BusinessIcon from '@material-ui/icons/Business';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding } from '@fortawesome/free-solid-svg-icons'


const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: 2.5,
    width: '100%'
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    color: 'white',
    fontWeight: 700
  },
  avatar: {
    backgroundColor: theme.palette.error.main,
  },
  difference: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  differenceIcon: {
    color: theme.palette.error.dark
  },
  differenceValue: {
    color: theme.palette.error.dark,
    marginRight: theme.spacing(1)
  },
  cardContent: {
    padding: 0,
    paddingBottom: 0
  },
  container: {
    height: '80%',
  },
  iconContainer: {
    backgroundColor: '#7380A7',
    borderBottomLeftRadius: 10,
    borderTopLeftRadius: 10,
    textAlign: 'left',
    color: 'white',
    paddingTop: 20
  },
  macroContainer: {
    backgroundColor: '#929EBC',
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    textAlign: 'center',
    color: 'white'
  },
  icon: {
    color: 'white',
    height: 40,
    width: 40
  },
  paddingTop20: {
    paddingTop: 20
  },
  buttonEl: {
    display: 'inline-block',
    // boxShadow: `0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    // 0 6.7px 5.3px rgba(0, 0, 0, 0.048),
    // 0 12.5px 10px rgba(0, 0, 0, 0.06),
    // 0 22.3px 17.9px rgba(0, 0, 0, 0.072),
    // 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    // 0 100px 80px rgba(0, 0, 0, 0.12)`,
    // cursor: 'pointer',
    verticalAlign: 'middle'
  },
  activeBg: {
    // borderBottomLeftRadius: 10,
    // borderTopLeftRadius: 10,
    // borderTopRightRadius: 10,
    // borderBottomRightRadius: 10,
    background: `radial-gradient(circle, rgba(105,171,189,1) 0%, rgba(139,174,246,1) 48%)`
  },
  activeBg2: {
    background: `radial-gradient(circle, rgba(105,171,189,1) 0%, rgba(139,174,246,1) 95%)`
  }
}));

const OfficeCard = props => {
  const { className, count, ...rest } = props;

  const classes = useStyles();

  const [ state, setState ] = useState({
    hovering: false,
  });

  const handleHover = event => {
    setState({
      ...state,
      hovering: true
    })
  }

  const handleHoverLeave = event => {
    setState({
      ...state,
      hovering: false
    })
  }


  return (
    <div className={clsx(classes.root, classes.buttonEl)}  
      // onClick={props.clickEvent} 
      // onMouseOver={handleHover} 
      // onMouseLeave={handleHoverLeave}
      > 
       <Grid direction="row" container className={clsx({
        [classes.container]: true, 
        [classes.buttonEl]: true,
        // [classes.activeBg]: state.hovering
      })} 
      // onClick={props.clickEvent}
      >
         {/* <Paper className={clsx(classes.paper, classes.container)} elevation={3}> */}
           <Grid item container direction="row" className={classes.container} spacing={3}>
             <Grid item container xs={4} className={clsx({
              [classes.iconContainer]: true,
              // [classes.activeBg2]: state.hovering
            })} style={{ paddingLeft: 18, paddingTop: 15}}>
              {/* <Tooltip title="View Details"> */}
                  <IconButton 
                    // onClick={props.clickEvent}
                    >
                    <FontAwesomeIcon icon={faBuilding} size="lg" style={{ color: 'white' }} />
                  </IconButton>
                {/* </Tooltip> */}
             </Grid>
             <Grid item container direction="column" xs={8} className={clsx({
              [classes.macroContainer]: true,
              // [classes.activeBg]: state.hovering
            })}>
               <Typography variant="h4">
                 { count }
               </Typography>
               <Typography
                   className={classes.title}
                   color="textSecondary"
                   variant="body2"
                   >
                   Total Locations
                   </Typography>
             </Grid>
           </Grid>
         {/* </Paper> */}
       </Grid>
     </div>
   );
};

OfficeCard.propTypes = {
  className: PropTypes.string
};

export default OfficeCard;
