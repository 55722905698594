import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Dialog, DialogContentText, Typography, Grid, TextField, useTheme, FormControl, InputLabel, Select, ExpansionPanelSummary } from '@material-ui/core';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import corporateHealthService from './../services/corporate-health.service';
import { ExpansionPanel, ExpansionPanelDetails } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import SuccessMessage from "../components/successMessage";
import ErrorMessage from "../components/errorMessage";
import NewEditOffice from './../addOffice/newEditOffice'

const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    fullLength: {
        width: '100%'
    }
});

const useStyles = makeStyles(theme => ({
    root: {
      padding: theme.spacing(4),
      flexGrow: 1,
      color: '#000'
    },
    officeInfoTitle: {
      padding: 10,
      color: '#000'
    },
    officeInfoSection: {
      padding: 10,
      color: '#000'
    },
    formControl: {
      marginRight: theme.spacing(1),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      minWidth: 300,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    fullHeight: {
      height: '100vh',
      width: '100vw'
    },
    headerPadding: {
      padding: 20,
      fontWeight: 700
    },
    textFieldSpacer: {
      margin: theme.spacing(1)
    },
    marginSpace: {
      margin: theme.spacing(3)
    },
    errorColor: {
      color: theme.palette.error.main,
      backgroundColor: 'white',
      borderColor: theme.palette.error.main
    },
    btnMargin: {
      marginTop: 10,
      marginLeft: 10
    }
}));
  

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
  
const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);
  
  

const EditOffice = (props) => {
    const classes = useStyles();
    const { selectedOffice, company, setLocation } = props;
    const [ loaders, setLoaders ] = useState({
      types: false,
      actions: false
    });

    const [ mapConfig, setMapConfig ] = useState({
      lng: selectedOffice && selectedOffice.zone ? selectedOffice.zone.longitude : 121.024956,
      lat: selectedOffice && selectedOffice.zone ? selectedOffice.zone.latitude : 14.560307,
      zoom: 15
    });

    const [ radius, setRadius ] = useState(selectedOffice && selectedOffice.zone ? selectedOffice.zone.radius_in_meters : 100);

    const [ officeTypes, setOfficeTypes ] = useState([]);
    const [ industries, setIndustries ] = useState([]);
    const { open, handleClose } = props;
    const [ mapMarker, setMapMarker ] = useState({
      type: 'Feature',
      geometry: {
          type: 'Point',
          coordinates: selectedOffice && selectedOffice.zone ? [ selectedOffice.zone.longitude,
              selectedOffice.zone.latitude] : []
      },
      properties: {
          // title: l.location_label,
          description: ''
      }
    });
    const [ value, setValue ] = useState({
      display_address: selectedOffice ? selectedOffice.address: '',
    });
    const [ inputValue, setInputValue ] = useState(selectedOffice.address || '');    
    const [ state, setState ] = useState(
      {
        id: selectedOffice ? selectedOffice.id : '',
        type: selectedOffice && selectedOffice.type ? selectedOffice.type.id : null,
        label: selectedOffice ? selectedOffice.label : '',
        address: selectedOffice ? selectedOffice.address: '',
        phoneNumbers: selectedOffice ? selectedOffice.phoneNumbers : [''],
        departments: selectedOffice ? selectedOffice.departments : [ { name: ''}],
        floors: selectedOffice ? selectedOffice.floors : [
            { name: '', gates: [] } ],
        gates: [],
        latitude: selectedOffice ? selectedOffice.latitude : 0,
        longitude: selectedOffice ? selectedOffice.longitude : 0
      });

    const [ notification, setNotification ] = useState({
        message: '',
        show: false,
        isError: false
    })

    function setShowNotification (value) {
        setNotification({
            ...notification,
            show: value
        })
    }

    function createNotification(message, isError) {
        setNotification({
            message: message,
            show: true,
            isError: isError
        })
    }

    useEffect(() => {
      setLoaders({
        ...state,
        types: true
      })
      corporateHealthService.listLocationTypes().then(
        response => {
          setLoaders({
            ...state,
            types: false
          })
          setOfficeTypes(response.types);
        }, error => {
          setLoaders({
            ...state,
            types: false
          })
              createNotification("Unable to load location types", true);
          }
      );
    }, []);


    const handleChange = name => event => {
        setState({
            ...state,
            [name]: event.target.value
        });
    }

    const handleChangePhoneNumber = (name, index) => event => {
      setState({
          ...state,
          phoneNumbers: state.phoneNumbers.map((phoneNumber, idx) => {
            if(index === idx) {
              return event.target.value;
            } else return phoneNumber;
          })
      });
  }

    // const handleChangeDepartment = (name, index) => event => {
    //   state.departments[index][`${name}`] = event.target.value;
    //
    //   setState({
    //     ...state,
    //     departments: state.departments
    //   })
    // }

    const handleChangeFloor = (name, index) => event => {
      state.floors[index][`${name}`] = event.target.value;

      setState({
        ...state,
        floors: state.floors
      })
    }

    const handleChangeGate = (name, index, index2) => event => {
      state.floors[index].gates[index2][`${name}`] = event.target.value;

      setState({
        ...state,
        floors: state.floors
      });
    }

    const addDepartment = event => {
      setState({
        ...state,
        departments: state.departments === undefined ? [{ name: '' }] :state.departments.concat({ name: ''})
      });
    }

    const addFloor = event => {
      setState({
        ...state,
        floors: state.floors === undefined ? [{name: '' }] : state.floors.concat({ name: '', gates: []})
      });
    }

    const addGate = (index) => event => {
        const currentLength = state.floors[index].gates.length;

        state.floors[index].gates[currentLength] = { address: '', description: ''}

        setState({
            ...state,
            floors: state.floors
        });

    }

    const handleUpdateLocation = event => {
      var location = {
          pid: props.company,
          id: selectedOffice.id,
          label: state.label,
          address: inputValue,
          departments: [],
          type: state.type,
          phoneNumber: state.phoneNumbers[0],
          officeType: Number(state.type),
          floors: state.floors,
          latitude: state.latitude,
          longitude: state.longitude,
          gates: [],
          zone: {
            longitude: mapMarker.geometry.coordinates.length === 2 ? mapMarker.geometry.coordinates[0]: 0,
            latitude: mapMarker.geometry.coordinates.length === 2 ? mapMarker.geometry.coordinates[1]: 0,
            radius_in_meters: radius
          }
      };

      for (var x = 0; x < location.floors.length; x++) {
        location.floors[x].location_id = selectedOffice.id;
        for (var y = 0; y < location.floors[x].gates.length; y++) {
          location.floors[x].gates[y].location_id = selectedOffice.id;
        }
      }

        setLoaders({
            ...state,
            actions: true
        })
      corporateHealthService.updateLocation(location).then(
        response => {
            setLoaders({
                ...state,
                actions: false
            })
            createNotification("Successfully updated location." );

            const timed = setTimeout(() => {
                setLocation({
                  ...selectedOffice,
                  zone: location.zone
                })
            }, 500);
            return () => clearTimeout(timed);
        }, error => {
              setLoaders({
                  ...state,
                  actions: false
              })
            setLoaders({...state, types: false  });
              createNotification("Unable to update location", true);
          }
      );
    }

    const deleteFloor = (index) => event => {
      const newFloors = state.floors.filter((el, idx) => index !== idx);

      setState({
        ...state,
        floors: newFloors,
      });
    }

    const deleteGate = (index, index2) => event => {
        const newFloors = state.floors[index].gates.splice(index2, 1);

      setState({
        ...state,
        gates: newFloors
      });
    }

    const filteredGates = (index) => {
      return state.gates.filter((e, idx) => e.order === index);
    }


    return (
        <div>
            { notification.show && notification.isError && <ErrorMessage open={notification.show} setOpen={setShowNotification} error={{message: notification.message}}/>}
            { notification.show && !notification.isError && <SuccessMessage open={notification.show} setOpen={setShowNotification} message={notification.message}/>}
            <Dialog maxWidth={'sm'} fullWidth={true} open={open} onClose={handleClose} aria-labelledby="edit-company-title">
                <DialogTitle id="edit-company-title" onClose={handleClose}>
                    { state.id ? `${state.label} Location Details` : 'Add an Location' }<br />
                    <Typography variant="caption">
                        Please fill in the required details to add an location.
                    </Typography>
                </DialogTitle>
                <DialogContent dividers>
                    <NewEditOffice
                      state={state} addFloor={addFloor}
                      officeTypes={officeTypes} handleChange={handleChange}
                      handleChangeFloor={handleChangeFloor} deleteFloor={deleteFloor}
                      deleteGate={deleteGate} handleAddOffice={handleUpdateLocation}
                      handleChangePhoneNumber={handleChangePhoneNumber}
                      addGate={addGate} mapMarker={mapMarker} setMapMarker={setMapMarker}
                      value={value} setValue={setValue} inputValue={inputValue} setInputValue={setInputValue}
                      handleChangeGate={handleChangeGate}
                      mapConfig={mapConfig} radius={radius} setRadius={setRadius}
                      /> 
                    {/* <Grid container spacing={4}>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                required
                                variant="outlined"
                                id="officeName"
                                label="Location Name"
                                fullWidth
                                size="small"
                                value={state.label}
                                onChange={handleChange('label')}
                                />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                variant="outlined"
                                id="officeAddress"
                                label="Location Address"
                                fullWidth
                                multiline={true}
                                size="small"
                                rows={3}
                                rowsMax={3}
                                value={state.address}
                                onChange={handleChange('address')}
                                />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <TextField
                                required
                                variant="outlined"
                                id="telephoneNumber"
                                label="Telephone Number"
                                fullWidth
                                size="small"
                                value={state.phoneNumbers[0]}
                                onChange={handleChangePhoneNumber('phoneNumber', 0)}
                                />
                        </Grid>
                        <Grid item container sm={12} xs={12}>
                            <Grid item xs={4} sm={4}>
                                <Typography variant="body1" className={classes.headerPadding}>
                                    Location Type
                                </Typography>
                            </Grid>
                            <Grid item xs={8} sm={8}>
                                <FormControl variant="outlined" color="primary" className={classes.formControl}>
                                    <InputLabel htmlFor="outlined-industries-native-simple"></InputLabel>
                                        <Select
                                            native
                                            disabled={loaders.types || loaders.actions}
                                            value={state.type}
                                            onChange={handleChange('type')}
                                            label=""
                                            inputProps={{
                                            name: 'office',
                                            id: 'outlined-industries-native-simple',
                                            }}
                                        >
                                            <option aria-label="None" value="" />
                                            ({
                                            officeTypes && officeTypes.length >= 1 ? officeTypes.map((option, index) => {
                                                return (
                                                    option ? 
                                                    <option value={option.id} key={index}>
                                                    { option.label }
                                                    </option>
                                                : undefined)
                                            })
                                            : undefined
                                            })
                                        </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item container xs={12} sm={12}>
                            <Grid item xs={6} sm={6} container alignItems="flex-start" justify="flex-start">
                              <Typography variant="h6" gutterBottom>
                                Add Floors
                              </Typography>
                            </Grid>
                            <Grid item xs={6} sm={6} container alignItems="center" justify="flex-end">
                              <Button variant="outlined" size="small" color="primary" disabled={loaders.types || loaders.actions} onClick={addFloor}>
                                Add Floor
                              </Button>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          {
                                state.floors ? state.floors.map((floor, index) => {
                                  return (
                                    floor ?
                                    <Grid item container spacing={2} key={`floor-${index}`}> 
                                        <Grid item xs={12}>
                                          <Typography variant="h6">
                                            Floor {state.floors[index].name || ''}
                                          </Typography>
                                        </Grid>
                                        <Grid item container xs={12} alignItems="flex-start" justify="flex-start">
                                            <Grid item xs={6} sm={6} container alignItems="flex-start" justify="flex-start">
                                            </Grid>
                                            <Grid item xs={6} sm={6} container alignItems="center" justify="flex-end">
                                              <Button variant="outlined" size="small" className={classes.errorColor} onClick={deleteFloor(index)}>
                                                Delete Floor
                                              </Button>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                          <TextField key={index}
                                            required
                                            className={classes.textFieldSpacer}
                                            variant="outlined"
                                            id={`floorName-${index}`}
                                            label={`Floor Name ${index + 1}`}
                                            fullWidth
                                            size="small"
                                            value={state.floors[index].name}
                                            onChange={handleChangeFloor('name', index)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} container spacing={0} className={classes.marginSpace}>
                                          <Grid item container xs={12} sm={12} spacing={0}>
                                              <Grid item xs={6} sm={6} container alignItems="flex-start" justify="flex-start">
                                                <Typography variant="body2" gutterBottom>
                                                  Entrances
                                                </Typography>
                                              </Grid>
                                              <Grid item xs={6} sm={6} container alignItems="center" justify="flex-end">
                                                <Button variant="outlined" size="small" color="primary" disabled={loaders.types || loaders.actions} onClick={addGate(index)}>
                                                  Add
                                                </Button>
                                              </Grid>
                                          </Grid>
                                          <Grid item container xs={12} sm={12} spacing={0}>
                                                {
                                                        floor.gates ? floor.gates.map((gate, index2) => {
                                                          return (
                                                            gate ?
                                                            <Grid container key={index2} spacing={2}>
                                                              <Grid item key={`gate-${index}-${index2}-description`} xs={5}>
                                                                  <TextField
                                                                    required
                                                                    className={classes.textFieldSpacer}
                                                                    variant="outlined"
                                                                    id={`gateDescription-${index}-${index2}`}
                                                                    label={`Gate Description`}
                                                                    fullWidth
                                                                    size="small"
                                                                    value={gate.description || ''}
                                                                    onChange={handleChangeGate('description', index, index2)}
                                                                    />
                                                              </Grid>
                                                              <Grid item key={`action-${index}-${index2}`} xs={2}>
                                                                  <IconButton aria-label="delete" size="small" disabled={loaders.types || loaders.actions} onClick={deleteGate(index, index2)} className={clsx(classes.errorColor, classes.btnMargin)}>
                                                                      <CancelRoundedIcon />
                                                                  </IconButton>
                                                              </Grid>
                                                            </Grid>
                                                            : undefined
                                                            )
                                                        })
                                                      : undefined}
                                                </Grid>
                                        </Grid>
                                    </Grid> 
                                    : undefined
                                  )
                                })
                              : undefined}
                        </Grid>
                        <Grid item xs={12} sm={12}>
                           <Button onClick={handleUpdateLocation} disabled={loaders.actions} color="primary" variant="contained">
                                Save
                            </Button>
                        </Grid>
                    </Grid> */}
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default EditOffice;