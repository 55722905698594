import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Dialog, DialogContentText, Typography, Grid, TextField, useTheme, FormControl, InputLabel, Select, ExpansionPanelSummary } from '@material-ui/core';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import corporateHealthService from './../services/corporate-health.service';
import { ExpansionPanel, ExpansionPanelDetails } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import NewAddOffice from './../addOffice/newAddOffice';
import ErrorMessage from './../components/errorMessage';
import SuccessMessage from './../components/successMessage';

const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    fullLength: {
        width: '100%'
    }
  });

  const useStyles = makeStyles(theme => ({
    root: {
      padding: theme.spacing(4),
      flexGrow: 1,
      color: '#000'
    },
    officeInfoTitle: {
      padding: 10,
      color: '#000'
    },
    officeInfoSection: {
      padding: 10,
      color: '#000'
    },
    formControl: {
      marginRight: theme.spacing(1),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      minWidth: 300,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    fullHeight: {
      height: '100vh',
      width: '100vw'
    },
    headerPadding: {
      padding: 20,
      fontWeight: 700
    },
    textFieldSpacer: {
      margin: theme.spacing(1)
    },
    marginSpace: {
      margin: theme.spacing(3)
    },
    errorColor: {
      color: theme.palette.error.main,
      backgroundColor: 'white',
      borderColor: theme.palette.error.main
    },
    btnMargin: {
      marginTop: 10,
      marginLeft: 10
    }
  }));
  

  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });
  
  const DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiDialogContent);
  
  

const AddOffice = (props) => {
    const classes = useStyles();
    const [ officeTypes, setOfficeTypes ] = useState([]);
    const { selectedOffice, company } = props;
    const [ mapConfig, setMapConfig ] = useState({
      lng: 121.024956,
      lat: 14.560307,
      zoom: 15
    });
    const [ industries, setIndustries ] = useState([]);
    const [ mapMarker, setMapMarker ] = useState({
      type: 'Feature',
      geometry: {
          type: 'Point',
          coordinates: []
      },
      properties: {
          // title: l.location_label,
          description: ''
      }
    });
    const [ searchText, setSearchText ] = useState('');
    const [ radius, setRadius ] = useState(100);
    const [ inputValue, setInputValue ] = useState('');
    const { open, handleClose } = props;    
    const [ state, setState ] = useState(
      {
        id: selectedOffice ? selectedOffice.id : '',
        label: selectedOffice ? selectedOffice.label : '',
        address: selectedOffice ? selectedOffice.address: '',
        phoneNumbers: selectedOffice ? selectedOffice.phoneNumbers : [''],
        departments: selectedOffice ? selectedOffice.departments : [ { name: ''}],
        floors: selectedOffice ? selectedOffice.floors : [
            { name: '' } ],
        gates: []
      });

    const [ notification, setNotification ] = useState({
        message: '',
        show: false,
        isError: false
    });

    function setShowNotification (value) {
      setNotification({
          ...notification,
          show: value
      })
    }

    function createNotification(message, isError) {
      setNotification({
          message: message,
          show: true,
          isError: isError
      })
    }


    useEffect(() => {
      if(selectedOffice) {
        selectedOffice.floors.map((floor, index) => {
          if(floor.gates.length >= 1) {
              setState({
                ...state,
                gates: state.gates.concat([ { idx: index, gates: floor.gates }])
              })
          }
        })
      } else {
        setState({
          ...state,
          gates: state.gates.concat([ { mockId: 1, order: 0, description: '', address: ''}])
        });
      }

      corporateHealthService.listLocationTypes().then(
        response => {
          setOfficeTypes(response.types);
        }, error => {

        }
      );
    }, []);


    const handleChange = name => event => {
        setState({
            ...state,
            [name]: event.target.value
        });
    }

    const handleChangePhoneNumber = (name, index) => event => {
      setState({
          ...state,
          phoneNumbers: state.phoneNumbers.map((phoneNumber, idx) => {
            if(index === idx) {
              return event.target.value;
            } else return phoneNumber;
          })
      });
  }

    const handleChangeDepartment = (name, index) => event => {
      state.departments[index][`${name}`] = event.target.value;

      setState({
        ...state,
        departments: state.departments
      })
    }

    const handleChangeFloor = (name, index) => event => {
      state.floors[index][`${name}`] = event.target.value;

      setState({
        ...state,
        floors: state.floors
      })
    }

    const handleChangeGate = (name, index, mockedId) => event => {
      const findGate = state.gates.find(({mockId}) => mockId === mockedId);
      findGate[`${name}`] = event.target.value;

      setState({
        ...state,
        gates: state.gates
      });
    }

    const addDepartment = event => {
      setState({
        ...state,
        departments: state.departments === undefined ? [{ name: '' }] :state.departments.concat({ name: ''})
      });
    }

    const addFloor = event => {
      setState({
        ...state,
        floors: state.floors === undefined ? [{name: '' }] : state.floors.concat({ name: ''})
      });
    }

    const addGate = (index) => event => {
      setState({
        ...state,
        gates: state.gates.concat([ {mockId: state.gates.length + 1 , order: index, description: '', address: ''}])
      });
    }

    // const addGate = event => {
    //   setState({
    //     ...state,
    //     gates: state.gates === undefined ? [ { name: '' } ] : state.gates.concat({ description: '', address: ''})
    //   });
    // }

    const handleAddOffice = event => {
      var newFloors = state.floors.map((floor, index) => {
        return {
          name: floor.name,
          gates: state.gates.filter(e => e.order === index).map((gate, index) => {
            return {
              address: gate.address,
              description: gate.description
            }
          })
        }
      });


      var location = {
          label: state.label,
          address: inputValue,
          departments: [],
          type: state.officeType,
          phoneNumber: state.phoneNumbers[0],
          officeType: Number(state.officeType),
          floors: newFloors,
          gates: [],
          zone: {
            longitude: mapMarker.geometry.coordinates.length === 2 ? mapMarker.geometry.coordinates[0]: 0,
            latitude: mapMarker.geometry.coordinates.length === 2 ? mapMarker.geometry.coordinates[1]: 0,
            radius_in_meters: radius
          }
      };

      corporateHealthService.addLocation(company.id, location).then(
        response => {
          createNotification("Successfully added location." );

          setTimeout(() => {
            handleClose();
          }, 1000);

        }, error => {
          createNotification("Unable to add location", true);

        }
      );
    }

    const deleteFloor = (index) => event => {
      const newFloors = state.floors.filter((el, idx) => index !== idx);
      const newGates = state.gates.filter((el, idx) => el.order !== index);

      setState({
        ...state,
        floors: newFloors,
        gates: newGates
      });
    }

    const deleteGate = (index) => event => {
      const newGates = state.gates.filter((el, idx) => index !== idx);

      setState({
        ...state,
        gates: newGates
      });
    }

    const filteredGates = (index) => {
      return state.gates.filter((e, idx) => e.order === index);
    }


    const handleUpdateCompany = event => {
      var location = {};

      var params = {
        corporatePartner: company.id,
        label: company.label,
        brandName: company.brandName,
        description: company.description,
        email: company.email,
        mainRepresentative: company.mainRepresentative,
        phoneNumber: company.phoneNumber,
        industry: company.industry.id,
        locations: company.locations.concat([location]) || [location]
      }

      params.locations = company.addresses.map((address, index) => {
        if(state.id === address.id) {
          return state;
        } else return address;
      });

      corporateHealthService.updatePartnerCompany(params, company.id).then(
        response => {
          createNotification("Successfully added location." );

          setTimeout(() => {
            handleClose();
          }, 1000);
          }, error => {
            createNotification("Unable to add location", true);
          }
      );
    }


    return (
        <div>
            { notification.show && notification.isError && <ErrorMessage open={notification.show} setOpen={setShowNotification} error={{message: notification.message}}/>}
            { notification.show && !notification.isError && <SuccessMessage open={notification.show} setOpen={setShowNotification} message={notification.message}/>}
            <Dialog maxWidth={'sm'} fullWidth={true} open={open} onClose={handleClose} aria-labelledby="edit-company-title">
                <DialogTitle id="edit-company-title" onClose={handleClose}>
                    { state.id ? `${state.label} Location Details` : 'Add an Location' }<br />
                    <Typography variant="caption">
                        Please fill in the required details to add a location.
                    </Typography>
                </DialogTitle>
                <DialogContent dividers>
                    <NewAddOffice 
                      state={state}
                      addFloor={addFloor}
                      deleteFloor={deleteFloor}
                      handleChangeFloor={handleChangeFloor}
                      officeTypes={officeTypes}
                      handleChangePhoneNumber={handleChangePhoneNumber} 
                      handleChange={handleChange}
                      filteredGates={filteredGates}
                      handleChangeGate={handleChangeGate}
                      deleteGate={deleteGate}
                      inputValue={inputValue}
                      setInputValue={setInputValue}
                      mapMarker={mapMarker} setMapMarker={setMapMarker}
                      searchText={searchText} setSearchText={setSearchText}
                      radius={radius} setRadius={setRadius}
                      handleAddOffice={handleAddOffice}
                      mapConfig={mapConfig}
                      addGate={addGate} />
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default AddOffice;