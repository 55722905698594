import React, { createContext, useEffect, useState, useContext } from "react"
import { getUserFromToken } from "../services/authentication.service";
import {Redirect} from "react-router-dom";

const UserContext = createContext()

// export default UserContext
function UserProvider(props) {
    const { children } = props

    const [ user, setUser ] = useState(null)
    const [ authError, setAuthError ] = useState(false)

    function updateUser(newUser) {
        setUser(newUser);
    }

    useEffect(() => {
        getUserFromToken()
            .then(data => {
                setUser(data)
            })
            .catch(() => {
                localStorage.removeItem('currentUser')
                setAuthError(true)
            })
    }, [])

    if (authError) {
        return <Redirect exact to={"/login"} />
    }

    if (!user) {
        return <div />
    }

    var userData = { user, updateUser };

    return (
        <UserContext.Provider value={
            {
                user: user,
                updateUser: updateUser
            }
        }>
            { children }
        </UserContext.Provider>
    )
}

function useUserContext() {
    return useContext(useUserContext);
}

export { UserProvider, UserContext, useUserContext }


export const GetLoggedInUser = () => {
    return useContext(UserContext)
}
