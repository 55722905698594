import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4),
        flexGrow: 1,
        color: '#000',
    },
}));

const AvailedPackages = (props) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>

        </div>
    )
}

export default AvailedPackages;
