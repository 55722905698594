import { useSnackbar } from "notistack"

function err (snackbar, errMessage, action) {
    if (!errMessage) {
        errMessage = 'There was an error while trying to execute the requested action. Please retry or contact support.'
    }

    snackbar(errMessage, {
        key: errMessage,
        preventDuplicate: true,
        variant: "error",
        autoHideDuration: 4000,
        action
    })

}

function success (snackbar, message, action) {
    snackbar(message, {
        key: message,
        preventDuplicate: true,
        variant: "success",
        autoHideDuration: 4000,
        action
    })
}

const logger = {
    err,
    success
}

export const snackbar = useSnackbar
export default logger
