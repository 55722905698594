import React, {useState, useEffect, useCallback} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Avatar,
    Grid,
    Typography,
    Divider,
    Link,
    LinearProgress,
    TextField,
    Select,
    MenuItem,
    InputAdornment,
    IconButton,
    FormControl,
    InputLabel
} from '@material-ui/core';
import {
    Search as SearchIcon
} from "@material-ui/icons"
import {
    Autocomplete
} from "@material-ui/lab"
import TableInfiniteScroll from "../components/tableInfiniteScroll";
import corporateAuditService from "../services/corporate-audit.service"
import corporateHealthService from "../services/corporate-health.service";
import moment from "moment";
import {useHistory} from "react-router-dom";

const useStyles = makeStyles(theme => ({
    root: {
      padding: theme.spacing(2),
      flexGrow: 1,
      color: '#000'
    },
    fullWidth: {
        width: '100%'
    },
    textLeft: {
        textAlign: 'left'
    },
    linkColor: {
        color: theme.palette.link.main,
        textDecoration: 'none'
    }
  }));

const DailyLogs = (props) => {
    const classes = useStyles();
    const history = useHistory();

    const { tableHeight = "60vh" } = props

    const columns = props.columns ? props.columns : [
        ...props.prependColumns || [],
        { id: "action", label: "ACTION", align: 'left' },
        { id: "employee", label: "WORKER", align: 'left' },
        { id: "description", label: "DESCRIPTION", align: 'left' },
        { id: "performed_by", label: "ADMIN", align: 'left' },
        { id: "date_created", label: "DATE", align: 'left' },
        ...props.appendColumns || []
    ]

    const logsPollingTimeout = 1000 * 10
    const userTypes = [
        {
            label: "All",
            value: "all"
        },
        {
            label: "Representatives",
            value: "representative"
        },
        {
            label: "Checkers",
            value: "checker"
        },
        {
            label: "Workers",
            value: "employee"
        },
    ]
    const dateRanges = [
        {
            label: "Today",
            value: "td"
        },
        {
            label: "Last Week",
            value: "wtd"
        },
        {
            label: "Last Month",
            value: "mtd"
        },
        {
            label: "Last Year",
            value: "ytd"
        }
    ]
    const allLocations = {
        id: 0,
        label: "All Locations"
    }

    const partnerId = props.partnerId
    const [ logs, setLogs ] = useState({ list: [], count: 0 })
    const [ isLoadingLogs, setLogsLoader ] = useState(false)
    const [ pollingIndex, setPollingIndex ] = useState(0)
    const [ pollTimer, setPollTimer ] = useState(setTimeout(() => {}, 0))

    const [ search, setSearch ] = useState("")
    const [ searchTimer, setSearchTimer ] = useState(setTimeout(() => {}, 0))
    const [ searchCounter, setSearchCounter ] = useState(0)

    const [ currentDateRange, setCurrentDateRange ] = useState("td")
    const [ currentEmployeeType, setCurrentEmployeeType ] = useState("all")
    const [ location, setLocation ] = useState()
    const [ locationOptions, setLocationOptions ] = useState([allLocations])
    const [ isLoadingLocations, setLocationsLoader ] = useState(false)

    const [ logType, setLogType ] = useState(props.logType)
    const [ title, setTitle ] = useState("Logs")
    const [ showFilters, setShowFilters ] = useState({
            name: {
                md: 3
            },
            employeeType: {
                md: 2
            },
            dateRange: {
                md: 2
            },
            location:  {
                md: 3
            }
    })
    const colorSchemes = ['#85CBB3', '#EEBE6A', '#F3948D' ];


    const pollLogs = useCallback(() => {
        setPollingIndex(current => current + 1)
    }, [])

    function handleLocationSelection (event, value) {
        if (value) {
            setLocation(value)
        } else {
            setLocation(allLocations)
        }
    }

    function replace(src, targ, repl) {
        if (src.indexOf(targ) === -1) {
            return src
        }

        const lh = src.substr(0, src.indexOf(targ))
        const rh = src.substr(src.indexOf(targ)+targ.length, src.length)
        return lh + repl + rh
    }

    function handleSearch() {
        setSearchCounter(counter => counter > 0 ? counter - 1 : counter + 1)
    }

    function getActionLog (type, subType) {
        if (subType) {
            return subType.replace(/_/g, " ")
        }

        return type ? type.replace(/_/g, " ") : "-"
    }

    const processLog = (log) => {
        var action_performer = '';
        var action_performer_format_2 = '';
        var action_performed_to = '';
        var action_performed_to_format_2 = '';
        var description = log.description;

        // if(log.performed_by && log.performed_by.employee && log.performed_by.employee.employee_id) {
        //     action_performer = "`" + log.performed_by.employee.employee_id + " | " + log.performed_by.employee.display_name + "`";
        //     action_performer_format_2 = log.performed_by.employee.employee_id + " | " + log.performed_by.employee.display_name;

        //     description = description.replace(action_performer,  `<a style="color: #92B3D5; text-decoration: none;" href="/workers/${log.performed_by.employee.employee_id}">
        //     ${log.performed_by.employee.last_name}, ${log.performed_by.employee.first_name}</a>`);
        //     // description = description.replace(action_performer_format_2,  `<a style="color: #92B3D5; text-decoration: none;" href="/employees/${log.performed_by.employee.employee_id}">
        //     // ${log.performed_by.employee.last_name}, ${log.performed_by.employee.first_name}
        //     // </a>`);
        // }

        if(log.resource && log.resource.employee && log.resource.employee.employee_id) {
            action_performed_to_format_2 = "`" + log.resource.employee.employee_id + " | "  + log.resource.employee.display_name + "`";
            action_performed_to = "` " + `${log.resource.employee.employee_id} | ` + `${log.resource.employee.display_name}` + "`";

            description = description.replace(action_performed_to, `<a style="color: #3474B7; text-decoration: none;" target="_blank" href="/workers/${log.resource.employee.employee_id}">
                ${log.resource.employee.display_name }
            </a>`);

            description = description.replace(action_performed_to_format_2, "");
        }

        // if(log.type === 'GATE_ACCESS') {
            // var action_location = "`" + log.location.id + " | " + log.location.label + "`";

            // description = description.replace(action_location, `<a style="color: #3474B7; text-decoration: none;" target="_blank" href="/locations/${log.location.id}">
            //     ${log.location.label}
            // </a>`)
        // }


        if(log.type === 'COVID_SCREEN') {
            var splitText = description.split('-');
            var lastStringToken = splitText[splitText.length - 1];
            if(lastStringToken) {
                var risk = lastStringToken.split(' ');
                if(risk[1].toLowerCase() === 'low') {
                    description = replace(description, lastStringToken, `<span style="color: ${colorSchemes[0]}; font-weight: 200;">${lastStringToken}</span>`)
                    description = replace(description, splitText[1],`<span style="color: ${colorSchemes[0]}; font-weight: 200;">${splitText[1]}</span>`)
                    description = replace(description, splitText[2],`<span style="color: ${colorSchemes[0]}; font-weight: 200;">${splitText[2]}</span>`)
                }
                if(risk[1].toLowerCase() === 'moderate') {
                    description = replace(description, lastStringToken, `<span style="color: ${colorSchemes[1]}; font-weight: 200;">${lastStringToken}</span>`)
                    description = replace(description, splitText[1],`<span style="color: ${colorSchemes[1]}; font-weight: 200;">${splitText[1]}</span>`)
                    description = replace(description, splitText[2],`<span style="color: ${colorSchemes[1]}; font-weight: 200;">${splitText[2]}</span>`)
                }
                if(risk[1].toLowerCase() === 'high') {
                    description = replace(description, lastStringToken, `<span style="color: ${colorSchemes[2]}; font-weight: 200;">${lastStringToken}</span>`)
                    description = replace(description, splitText[1],`<span style="color: ${colorSchemes[2]}; font-weight: 200;">${splitText[1]}</span>`)
                    description = replace(description, splitText[2],`<span style="color: ${colorSchemes[2]}; font-weight: 200;">${splitText[2]}</span>`)
                }
            }
        }


        return (
           <Typography component="span" variant="body2" dangerouslySetInnerHTML={{ __html: description }} />
        )
    }

    const getAuditLogs = useCallback((params) => {
        setLogsLoader(true)

        corporateAuditService.getAuditLogs(partnerId, params)
            .then(data => {
                let newList = data.list.map(log => {
                    return {
                        location: log.location && (
                            <Typography style={{ textTransform: 'capitalize'}}>
                                { log.location.label }
                            </Typography>
                        ),
                        action: (
                            <Typography style={{ textTransform: 'capitalize'}}>
                                { log.sub_type_label || log.type.replace(/_/g, " ") }
                            </Typography>
                        ),
                        employee: log.resource && log.resource.employee ?
                            (
                                <React.Fragment>
                                    <Link
                                        className={classes.linkColor}
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            textDecoration: 'none',
                                        }}
                                        component={"button"}
                                        onClick={() => history.push(`/workers/${log.resource.employee.employee_id}`)}
                                    >
                                        <Avatar alt={log.resource.employee.last_name} src={log.resource.employee.profile_pic_url || ''} />
                                        <Typography variant="body2" style={{ marginLeft: 5}}>
                                            { log.resource.employee.last_name }, { log.resource.employee.first_name }
                                        </Typography>
                                    </Link>
                                </React.Fragment>
                            ) : (
                                log.performed_by && log.performed_by.employee && (
                                    <React.Fragment>
                                        <Link
                                            className={classes.linkColor}
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                textDecoration: 'none',
                                            }}
                                            component={"button"}
                                            onClick={() => history.push(`/workers/${log.performed_by.employee.employee_id}`)}
                                        >
                                            <Avatar alt={log.performed_by.employee.last_name} src={log.performed_by.employee.profile_pic_url || ''} />
                                            <Typography variant="body2" style={{ marginLeft: 5}}>
                                                { log.performed_by.employee.last_name }, { log.performed_by.employee.first_name }
                                            </Typography>
                                        </Link>
                                </React.Fragment>
                                )
                            ),
                        description: processLog(log),
                        performed_by: log.performed_by && log.performed_by.employee && (
                            <Link
                                className={classes.linkColor}
                                component={"button"}
                                style={{ textDecoration: 'none'}}
                                onClick={() => history.push(`/workers/${log.performed_by.employee.employee_id}`)}
                            >
                                <Typography variant="body2">
                                    { log.performed_by.employee.last_name }, { log.performed_by.employee.first_name }
                                </Typography>
                            </Link>
                        ),
                        date_created: 
                        <Typography variant="body2">
                            { moment(log.date_created).format("DD/MM/YYYY - hh:mm A") }
                        </Typography>
                    }
                })

                setLogs({
                    list: newList,
                    count: data.count || 0
                })
            })
            .finally(() => {
                setLogsLoader(false)

                setPollTimer(setTimeout(() => {
                    pollLogs()
                }, logsPollingTimeout))
            })
    }, [history, partnerId, pollLogs, logsPollingTimeout])

    useEffect(() => {
        if (typeof props.showFilters !== "undefined") {
            setShowFilters(props.showFilters)
        }

        if (typeof props.title !== "undefined") {
            setTitle(props.title)
        }
    }, [props.showFilters, props.title])

    const getPartnerLocations = () => {
        setLocationsLoader(true)

        corporateHealthService.getPartnerLocations(partnerId)
            .then(({ list }) => {
                setLocationOptions((currentOptions) => currentOptions.concat(list))
            })
            .finally(() => {
                setLocationsLoader(false)
            })
    }


    useEffect(() => {
        if (showFilters.location) {
            getPartnerLocations()
        }
    }, [])

    useEffect(() => {
        clearTimeout(pollTimer)

        const params = {
            query: search || null,
            resource_type: currentEmployeeType === "all" ? null : currentEmployeeType,
            date_range: currentDateRange
        }

        if (logType) {
            params.types = logType
        }

        if (props.location) {
            params.locations = [props.location.id]
        } else if (location) {
            params.locations = [location.id]
        }

        getAuditLogs(params)

        return () => {
            clearTimeout(pollTimer)
            clearTimeout(searchTimer)
        }
    }, [pollingIndex, getAuditLogs, logType, searchCounter, currentEmployeeType, currentDateRange, location])

    return(
        <Grid container className={classes.root} spacing={1} style={{ padding: 0 }}>
            <Grid item container alignItems={"center"} justify={"space-between"} spacing={2}>
                {
                    title.length > 0 && (
                        <Grid item container xs={12} sm={6} md={2}>
                            <Typography variant="body1" style={{ fontWeight: "bold" }}>
                                { title }
                            </Typography>
                        </Grid>
                    )
                }
                { showFilters.name && (
                    <Grid item container xs={12} sm={6} md={showFilters.name.md}>
                        <TextField
                            label="Search" variant="outlined"
                            fullWidth
                            onChange={(event) => {
                                setSearch(event.target.value)

                                if (searchTimer) {
                                    clearTimeout(searchTimer)
                                }

                                setSearchTimer(
                                    setTimeout(() => {
                                        handleSearch()
                                    }, 1000)
                                )
                            }}
                            onKeyPress={(ev) => {
                                if (ev.key === "Enter") {
                                    if (searchTimer) {
                                        clearTimeout(searchTimer)
                                    }

                                    handleSearch()
                                }
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                )}
                { showFilters.employeeType && (
                    <Grid item container xs={12} sm={6} md={showFilters.employeeType.md}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel id="employee-type-selection-label">User</InputLabel>
                            <Select
                                className={classes.textLeft}
                                labelId="employee-type-selection-label"
                                label="User"
                                value={currentEmployeeType}
                                onChange={(event) => setCurrentEmployeeType(event.target.value)}
                            >
                                {
                                    userTypes.map(({ label, value }) => {
                                        return (
                                            <MenuItem key={value} value={value}>{ label }</MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                )}
                { showFilters.dateRange && (
                    <Grid item container xs={12} sm={6} md={showFilters.dateRange.md}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel id="date-range-selection-label">Date Range</InputLabel>
                            <Select
                                className={classes.textLeft}
                                labelId="date-range-selection-label"
                                value={currentDateRange}
                                label="Date Range"
                                onChange={(event) => setCurrentDateRange(event.target.value)}
                            >
                                {
                                    dateRanges.map(({ label, value }) => {
                                        return (
                                            <MenuItem key={value} value={value}>{ label }</MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                )}
                { showFilters.location && (
                    <Grid item container xs={12} sm={6} md={showFilters.location.md}>
                        <Autocomplete
                            options={locationOptions}
                            getOptionLabel={(option) => option.label}
                            getOptionSelected={(option, value) => option.id === value.id}
                            onChange={handleLocationSelection}
                            noOptionsText="No locations found"
                            renderInput={(params) => <TextField {...params} fullWidth label="Locations" variant="outlined" />}
                            loading={isLoadingLocations}
                            defaultValue={allLocations}
                            fullWidth
                        />
                    </Grid>
                )}
            </Grid>
            {
                title.length > 0 && (
                    <Grid item container>
                        <Divider className={classes.fullWidth} />
                    </Grid>
                )
            }
            <Grid item container spacing={1} xs={12}>
                <Grid item container xs={12}>
                    { isLoadingLogs && <LinearProgress style={{width: '100%'}}/>}
                    <TableInfiniteScroll
                        rows={logs.list} columns={columns}
                        count={logs.count}
                        endText={"End of Logs"} noDataText={"No Logs Found"}
                        containerHeight={tableHeight}
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default DailyLogs;
