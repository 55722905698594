import React, {useEffect, useRef, useState} from "react"
import {makeStyles} from "@material-ui/core/styles";
import {
    Paper,
    Grid,
    Typography,
    Divider
} from '@material-ui/core'
import DoughnutChart from "../components/DoughnutChart";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
    fullSize: {
        width: "95%",
        height: "95%"
    },
    textLeft: {
        textAlign: "left"
    },
    textBold: {
        fontWeight: 'bold'
    },
    m1: {
        padding: "0.5rem"
    }
}));

export default function OfficePercentMetrics (props) {
    const classes = useStyles()
    const {
        description,
        labels, labelColors,
        counts
    } = props;
    const [ percentages, setPercentages ] = useState([]);
    const graphContainer = useRef(null);


    function numberFormatter(num) {
        return num.toFixed(3) * 100
    }

    useEffect(() => {
        if (counts) {
            const reducer = (accumulator, currentValue) => accumulator + currentValue;
            const total = counts.reduce(reducer);

            if(total === 0) {
                setPercentages([]);
            } else if (total !== 0) {
                const data = counts.map(count => numberFormatter(count / total)) || [];

                if (percentages && data && percentages.length !== data.length) {
                    setPercentages(data);
                } else {
                    for (let i = 0; i < data.length; i++) {
                        if (percentages[i] !== data[i]) {
                            setPercentages(data)
                            break
                        }
                    }
                }
            } else {
                const data = counts.map(count => numberFormatter(0))

                setPercentages(data)
            }
        }
    }, [counts])

    return (
        <Paper className={clsx(classes.fullSize)} variant={"outlined"}>
            <Grid container spacing={2}>
                <Grid className={clsx(classes.fullSize)} item container alignItems={"center"} justify={"center"} xs={12} style={{ paddingTop: 25 }}>
                    {
                        percentages && percentages.length >= 1 ? (
                        <DoughnutChart
                                // style={{ width: 150}}
                                labels={labels}
                                datasets={
                                    [
                                        {
                                            backgroundColor: labelColors,
                                            data: percentages
                                        }
                                    ]
                                }
                                options={
                                    {
                                        tooltips: {
                                            callbacks: {
                                                title: function(tooltipItem, data) {
                                                    return data['labels'][tooltipItem[0]['index']];
                                                },
                                                label: function(tooltipItem, data) {
                                                    return `${data['datasets'][0]['data'][tooltipItem['index']]}%`;
                                                },
                                            }
                                        },
                                        legend: {
                                            position: 'bottom',
                                            lineWidth: 0.1,
                                            labels: {
                                                fontSize: 10,
                                                // lineWidth: 1,
                                                lineCap: 'round'
                                            }
                                        }
                                    }
                                }
                            />
                        ) : ( 
                            <Typography variant="h5">
                                No data available.
                            </Typography>
                        )
                    }
                </Grid>

                <Grid item xs={12} style={{ margin: 20, marginTop: 0 }}>
                    <Typography variant="caption" style={{ color: "#494949" }}>
                        { percentages && percentages.length >= 1 ? description : '' }
                    </Typography>
                </Grid>
            </Grid>
        </Paper>
    )
}
