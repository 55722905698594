import React, { useState, useEffect } from 'react';
import MUIDataTable, { TableFilterList, TableToolbar } from "mui-datatables";
import { Chip } from '@material-ui/core';
import CustomToolbar from './CustomToolbarSelect';
 

// const options = {
//   filterType: 'checkbox',
// };

const CustomChip = ({ label, onDelete }) => {
  return (
      <Chip
          variant="outlined"
          color="secondary"
          label={label}
          onDelete={onDelete}
      />
  );
};


const DataTable = (props) => {
  const { columns, rows, 
    count, canDownload = false,
    canFilter = false, listCall = null, canBulkUpdate = true, selectable = 'multiple', canPaginate = true,
    canPrint = false, handlePage, handleFilterChange, filterType = 'checkbox', handleSort = null, styles = {}
  } = props;
  const [page, setPage] = React.useState(0);
  
  const [options, setOptions ] = useState({
    filterType: filterType,
    rowsPerPage: process.env.REACT_APP_DEFAULT_PAGE_SIZE,
    rowsPerPageOptions: []
  });

  const handleChangePage = (event, newPage) => {
    handlePage(newPage);
    setPage(newPage);
  };

  useEffect(() => {
    setOptions({
      ...options,
      count: count,
      serverSide: true,
      selectableRows: selectable,
      responsive: 'vertical',
      filter: canFilter,
      download: canDownload,
      print: canPrint,
      search: false,
      pagination: canPaginate,
      selectToolbarPlacement: 'replace',
      customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
        <CustomToolbar company={props.company} selectedRows={selectedRows} bulkUpdate={canBulkUpdate} displayData={displayData} rows={props.rows} setSelectedRows={setSelectedRows} listCall={listCall} />
      ),
      onColumnSortChange: (changedColumn, direction) => {
          handleSort({ column: changedColumn, value: direction});
      },
      onTableChange: (action, tableState) => {
        console.log(action);

        switch(action) {
          case 'changePage': 
            handleChangePage(null, tableState.page);
            break;
          case 'filterChange':
            handleFilterChange(tableState.filterList);
            break;
          default:
        }
      }
    });

  },[count, canFilter, canDownload, canPrint, selectable, canPaginate]);
    
    return(
        <MUIDataTable
          // title={"Employee List"}
          data={rows}
          columns={columns}
          download={canDownload}
          filter={canFilter}
          print={canPrint}
          options={options}
          pagination={canPaginate}
          style={styles}
          components={{
            DragAndDropBackEnd:null
          }}
          // components={{
          //   TableToolbarSelect: CustomFilterList
          // }}
        />
     );
}

export default DataTable;
 