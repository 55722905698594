import instance from './helpers/instance';

const API_URL = process.env.REACT_APP_WEB_ADMIN_URL;

const corporateLocationService = {
    getLocationZone: (locationId) => {
        return instance.get(`${API_URL}/api/1/corporate/partners/locations/${locationId}/zone`)
            .then(response => response.data)
            ;
    },
    getEmployeePositions(partnerId, params) {
        return instance.get(`${API_URL}/api/1/corporate/${partnerId}/employees/positions`, { params })
            .then(response => { 
                return response.data;
            })
            ;
    },
    getFieldWorkerPositions(partnerId, params) {
        return instance.get(`${API_URL}/api/1/corporate/${partnerId}/employees/fieldWorkers/positions`).then(response => { 
            return response.data
        });
    },
    deleteLocation(locationId) {
        return instance.delete(`${API_URL}/api/1/corporate/partners/locations/${locationId}`).then(
            response => response.data
        );
    }
};

export default corporateLocationService;
