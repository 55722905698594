import React, {useCallback, useState} from 'react';
import { Link, Button, Grid, Typography } from '@material-ui/core';
import zennya_health_logo from './../assets/images/zennya_health_horizontal.png';
import instance from '../services/helpers/instance';
import axios from 'axios'
const DownloadTemplate = (props) => {
    const [loading, setLoading] = useState(false);
    
    const goDownloadUrl = useCallback(
        async () => {
            setLoading(true)
            const regex = new RegExp('^(?:[a-z]+:)?//', 'i'); //https://stackoverflow.com/a/19709846   test relative url
            let response
            if (regex.test(props.downloadUrl)) {
                response = await axios.get(props.downloadUrl)
            } else {
                response = await instance.get(`${process.env.REACT_APP_WEB_ADMIN_URL}${props.downloadUrl}`, {params:props.downloadParams})
            }
            const file = new Blob([response.data], {
            type: response.headers['content-type'] || 'application/octet-stream',
            });
            const saving = document.createElement('a');
            saving.href = URL.createObjectURL(file);
            saving.download = (props.title);
            saving.click();
            setLoading(false)
        },
        [props.downloadUrl, props.filename],
    );

    return (
        <Grid container direction="row" spacing={3} style={{ backgroundColor: '#EFF7FF'}}>
            <Grid item xs={3}>
                <Typography variant="caption">
                    Template
                </Typography>
            </Grid>
            <Grid item xs={4}>
                <Typography variant="caption">
                    { props.title }
                </Typography>
            </Grid>
            <Grid item xs={5}>
                { props.downloadUrl ? 
                <Link component={Button} 
                disabled={loading}
                variant="outlined" color="primary" style={{ backgroundColor: 'white'}}
                onClick={evt => {
                    evt.preventDefault()
                    goDownloadUrl()
                }}>
                        Download Template
                </Link>
                : 
                     <Typography variant="caption">
                         No available template
                     </Typography> }
            </Grid>
        </Grid>
    )
}
export default DownloadTemplate;