import React, {useState, useEffect, useRef} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {
    LinearProgress,
    Grid,
    Menu,
    MenuItem,
    Paper,
    Typography, Tooltip,
    IconButton, InputLabel, Select, FormControl
} from '@material-ui/core';
import {
    Skeleton
} from "@material-ui/lab"
import corporateHealthService from "../services/corporate-health.service"
import corporateLocationService from "../services/corporate-location.service"
import corporatePartnerService from "../services/corporate-partner.service"
import healthMetricsService from "../services/health-metrics.service";
import clsx from 'clsx';
import BackButton from "./../components/backButton";
import EditOffice from './editOffice';
import CountCard from "../components/countCard";
import StopIcon from '@material-ui/icons/Stop';
import ViewLocationDetails from './ViewLocationDetails';
import EmployeeCard from './../dashboard/Employee';
import HealthCheckerCard from './../dashboard/HealthChecker';
import OfficeCard from './../dashboard/Office';
import HighRiskCard from './../dashboard/HighRisk';
import HealthRiskMetrics from './../healthRisk/healthRiskMetrics';
import HealthRiskDialog from './../healthRisk/healthRiskDialog';
import UserReportList from './../dashboard/userReportList';

import {
    MoreVert as MoreVertIcon
} from "@material-ui/icons"

import {Mapbox} from "../components/mapbox";
import DailyLogs from "../dashboard/DailyLogs";
import OfficePercentMetrics from "./officePercentMetrics";

import {GetLoggedInUser} from "../context/UserContext";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4),
        flexGrow: 1,
        color: '#000'
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        width: '100%'
    },
    fullHeight: {
        height: '100vh',
        width: '100vw'
    },
    gridPadding: {
        padding: 0
    },
    gridSpacer: {
        marginLeft: 10
    },
    fillHeight: {
        height: '100%'
    },
    tableContainer: {
        margin: theme.spacing(2),
        padding: theme.spacing(2)
    },
    textLeft: {
        textAlign: 'left'
    },
    textRight: {
        textAlign: 'right'
    },
    boldText: {
        fontWeight: 700
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    legend: {
        backgroundColor: '#fff',
        borderRadius: '3px',
        bottom: '20px',
        boxShadow: '0 1px 2px rgba(0, 0, 0, 0.1)',
        padding: '5px',
        position: 'absolute',
        right: '10px',
        zIndex: 1,
        opacity: 0.7,
        textAlign: 'left'
    },
    legendTitle: {
        margin: '0 0 0'
    },
    legendEntry: {
        borderRadius: '50%',
        display: 'inline-block',
        height: '10px',
        marginRight: '5px',
        width: '10px',
        textAlign: 'left',
        opacity: 0.7
    }
}));

const colorSchemes = {
    unchecked: '#C4C4C4',
    normal: '#78BDBA',
    moderate: '#EEBE6A',
    high: '#EA857D'
};

const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
      color: theme.palette.common.black,
    },
    tooltip: {
      backgroundColor: theme.palette.common.black,
    },
  }));
  
function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();
  
    return <Tooltip arrow classes={classes} {...props} />;
}


const OfficeView = (props) => {
    const classes = useStyles();
    const [location, setLocation] = useState({});
    const [openAddOffice, setOpenAddOffice] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [loadingDetails, setLoadingDetails] = useState(false);
    const [locationZone, setLocationZone] = useState({})
    const [employeePositions, setEmployeePositions] = useState([])
    const [isPollingEmpPos, setPollingEmpPos] = useState(false)
    const [pollCtr, setPollCtr] = useState(0)
    const [pollTimeout, setPollTimeout] = useState(null)
    const [checkerCount, setCheckerCount] = useState(null)
    const [ openViewOffice, setOpenViewOffice ] = useState(false);
    const [ deleteMode, setDeleteMode ] = useState(false);
    const [ completedLoading, setCompletedLoading ] = useState(false);
    const [ points, setPoints ] = useState(null);
    const [ locationPoint, setLocationPoint ] = useState({});
    const [ countRisks, setCountRisks ] = useState([]);
    const [ hazardCounts, setHazardCounts ] = useState([]);
    const [ openWorkers, setOpenWorkers ] = useState(false);
    const [ openCheckers, setOpenCheckers ] = useState(false);
    const [ openHighRiskWorkers, setOpenHighRiskWorkers ] = useState(false);
    const [ metricList, setMetricList ] = useState({
        workers: [],
        checkers: [],
        highRiskWorkers: []
    })
    const [percentMetrics, setPercentMetrics] = useState({
        cleared: 0,
        scanned: 0,
        scannedCount: 0,
        deniedInPerimeterCount: 0,
        deniedEntries: {
            inQuarantine: {
                label: 'In Quarantine',
                count: 0
            },
            closeContact: {
                label: 'Close Contact with Covid Positive',
                count: 0
            },
            fluSymptoms: {
                label: 'Flu-like Symptoms',
                count: 0
            },
            total: {
                label: 'Total',
                count: 0
            }
        }
    })
    const [riskLevels, setRiskLevels] = useState([
        {
            id: 'LOW',
            label: 'Low Risk',
            count: 0
        },
        {
            id: 'MODERATE',
            label: 'Moderate Risk',
            count: 0
        },
        {
            id: 'HIGH',
            label: 'High Risk',
            count: 0
        },
        { // 4th would be count of all in perimeter, not just count visible
            count: 0
        }
    ])
    const [filters, setFilters] = useState({
        riskLevel: 'ALL',
        workerType: 'ALL',
        sbu: 0
    })

    function updateFilters (key, value) {
        clearTimeout(pollTimeout)

        setFilters(filters => ({
            ...filters,
            [key]: value
        }))
    }

    const [ sbuList, setSbuList ] = useState([
        {
            sbu_id: 0,
            sbu_label: 'All'
        }
    ])
    const riskLevelTypes = [
        {
            label: 'All',
            value: 'ALL'
        },
        {
            label: 'Low',
            value: 'LOW'
        },
        {
            label: 'Moderate',
            value: 'MODERATE'
        },
        {
            label: 'High',
            value: 'HIGH'
        }
    ]
    const workerTypes = [
        {
            label: 'All',
            value: 'ALL'
        },
        {
            label: 'Workers',
            value: 'WORKERS'
        },
        {
            label: 'Checkers',
            value: 'CHECKERS'
        }
    ]

    const pollTimer = 10000
    const userData = GetLoggedInUser()

    const mapRef = useRef();

    useEffect(() => {
        if (props.match.params.locationId) {
            getLocationDetails(props.match.params.locationId);

            corporateLocationService.getLocationZone(props.match.params.locationId)
                .then(({zone}) => {
                    if (zone) {
                        setLocationZone({
                            latitude: zone.latitude,
                            longitude: zone.longitude,
                            radius_in_meters: zone.radius_in_meters
                        })
                    }
                })

            // corporateHealthService.getEmployeesByRoleType({
            //     partner: props.company,
            //     fetch_types: 'CHECKER',
            //     page_size: 1,
            //     page_offset: 0,
            //     locations: props.match.params.locationId
            // }).then(data => {
            //     if (data.checker_online_count_within_proximity) {
            //         setCheckerCount(data.checker_online_count_within_proximity)
            //     }
            // })
        }
    }, [props.company, props.match.params.locationId])

    useEffect(() => {
        corporatePartnerService.getCompanySbuList(props.company)
            .then(data => {
                setSbuList(list => list.concat(data.list))
            })

        healthMetricsService.getLocationDeniedEntriesMetrics(props.company)
            .then(data => {
                var total = data.in_quarantine + data.close_contact + data.flu_like_symptoms;
                setPercentMetrics(metrics => ({
                    ...metrics,
                    deniedEntries: {
                        inQuarantine: {
                            label: 'Quarantined',
                            count: data.in_quarantine
                        },
                        closeContact: {
                            label: 'Close Contact',
                            count: data.close_contact
                        },
                        fluSymptoms: {
                            label: 'Flu-like Symptoms',
                            count: data.flu_like_symptoms
                        },
                        total: {
                            label: 'Total: ' + total,
                            count: total
                        }
                    }
                }))
            })
    }, [props.company])

    useEffect(() => {
        setPollingEmpPos(true)

        const params = {
            locations: [props.match.params.locationId]
        }

        if (filters.sbu && filters.sbu !== 0) {
            params.sbu_id = filters.sbu
        }

        if (filters.riskLevel && filters.riskLevel !== 'ALL') {
            params.risk_factor = filters.riskLevel
        }

        corporateLocationService.getEmployeePositions(props.company, params)
            .then(data => {
                setRiskLevels([
                    {
                        id: 'LOW',
                        label: 'Low Risk',
                        count: data.low_risk_count || 0
                    },
                    {
                        id: 'MODERATE',
                        label: 'Moderate Risk',
                        count: data.moderate_risk_count || 0
                    },
                    {
                        id: 'HIGH',
                        label: 'High Risk',
                        count: data.high_risk_count || 0
                    },
                    { // 4th would be count of all in perimeter, not just count visible
                        count: data.count_within_proximity
                    }
                ])
                var deniedCount = (data.denied_count) ? data.denied_count : 0
                var scannedCount = (data.scanned) ? data.scanned : 0
                setPercentMetrics(metrics => ({
                    ...metrics,
                    count: data.count_within_proximity,
                    deniedInPerimeterCount: deniedCount,
                    scannedCount: scannedCount,
                    quarantinedCount: data.quarantine_count_within_proximity
                }))

                setCheckerCount(data.checker_online_count_within_proximity || 0);

                if (percentMetrics.cleared !== data.low_risk_count || percentMetrics.scanned !== data.percent_scanned_vs_actual) {
                    var deniedCount = (data.denied_count) ? data.denied_count : 0
                    var scannedCount = (data.scanned) ? data.scanned : 0
                    setPercentMetrics(metrics => ({
                        ...metrics,
                        cleared: data.low_risk_count,
                        scanned: data.percent_scanned_vs_actual
                    }))
                }

                const positions = []

                for (let i = 0; i < data.list.length; i++) {
                    var count_risks = {
                        low: 0,
                        moderate: 0,
                        high: 0,
                    };

                    const employee = data.list[i].employee
                    const risk = data.list[i].risk
                    const hazard = data.list[i].hazard;
                    const isStale = data.list[i].is_stale

                    const riskLevel =  risk && risk.risk ? risk.risk.name : "UNKNOWN"
                    const latitude = data.list[i].latitude
                    const longitude = data.list[i].longitude
                    const isChecker = data.list[i].is_checker;

                    // let riskIcon = require('../assets/images/person_marker_grey.png');
                    let riskColor = '#767676';
                    let mapIcon = '';

                    const temperature = data.list[i].temperature
                    const covidScreening = data.list[i].covid_screen

                    let feverStatus = '';

                    if(temperature && temperature <= 37.5) {
                        feverStatus = '( No Fever )';
                    } else if(temperature && temperature >= 37.6 && temperature <= 39.4) {
                        feverStatus = '( Moderate Fever )';
                    } else if (temperature && temperature > 39.4) {
                        feverStatus = '( High Fever )';
                    }

                    var hazardLevel = 0;
                    var message = '';

                    if(hazard && hazard.label) {
                        switch(hazard.label) {
                            case 'LOW': 
                                mapIcon = 'marker_green';
                                count_risks.low++;
                                message = 'Worker is cleared.'
                                break;
                            case 'MODERATE':
                                mapIcon = 'marker_yellow';
                                count_risks.moderate++;
                                message = 'Worker has not yet been cleared by a health checker.'
                                break;
                            case 'HIGH':
                                mapIcon = 'marker_red';
                                count_risks.high++;
                                message = 'Worker may be experiencing one of the following: has symptoms, \nrestricted from entering location, \nor still in quarantine.'
                                break;
                            default:
                                break;
                        }

                        if(isStale) {
                            mapIcon = `${mapIcon}_stale`;
                        }

                        riskColor = hazard.color;
                    }

                    positions.push({
                        id: employee.id,
                        lat: latitude,
                        lng: longitude,
                        hazard: hazardLevel,
                        description: '<div style="width: 100%; margin-right: 150px; text-align: left; color: white">' +
                            '<div style="display: flex; align-items: center">' +
                                '<div style="position: relative; padding-top: 10px; display: inline-block; ">' +
                                    '<img src="' + employee.profile_pic_url + '" alt="user_logo" style="width:25px; height:25px; object-fit:cover; border-radius:50%; border:3px solid ' + riskColor + '" />' + 
                                    // + `<span> <span style="position: absolute; right:-10px; bottom:-10px; background-color: ${isStale ? 'gray' : 'green'}; text-align: center; border-radius: 50%; color:white; padding:10px 10px; font-size:20px;"></span> </span>` +
                                    '<a target="_blank" href="/workers/' + employee.id + `" style="color: #3474B7; text-decoration: none; margin-right: 10px; padding-bottom: 30px;"><span style="font-weight: bold; font-size: 15px; margin-left: 10px;">${employee.name}</span></a>` +
                                '</div>' +
                            '</div>' +
                            `<span style="color: gray; font-size: 11px;">${ isChecker ? `Assigned as a <span style="color: #92B3D5; font-weight: 700;">Health Checker</span> <br/>` : '' }</span>` +
                            `<span style="color: white; font-size: 12px">SBU: ${employee.sbu && employee.sbu.label ? employee.sbu.label : 'N/A'}</span>` +
                            '<br />' +
                            `<span style="color: white; font-size: 12px">Risk Level: ${risk && risk.label ? risk.label : 'Unknown'}</span>` +
                            '<br />' +
                            `<span style="color: white; font-size: 12px">Temperature: ${temperature || 'N/A'} ${feverStatus}</span>` +
                            '<br />' +
                            `<span style="color: white; font-size: 12px">COVID Screening: ${covidScreening ? covidScreening.title : 'N/A'}</span>` +
                            `<span> <span style="height: 10px; width: 10px; background-color: ${covidScreening && covidScreening.color ? covidScreening.color : ''};border-radius: 50%; display:inline-block;"></span> </span>` +
                            `<br/>` +
                            `<span style="color: white; font-size: 12px"><span style="color: ${riskColor}">Reason for hazard level: </span><br/>${message}</span>` +
                            '</div>',
                        layer: 'layer' + riskLevel,
                        source: 'point' + riskLevel,
                        icon: {
                            id: 'image' + riskLevel,
                            file: '',
                            height: 0.75,
                            width: 0.75,
                            img: mapIcon
                        },
                        properties: {
                            count: 1,
                            count_low: count_risks.low,
                            count_moderate: count_risks.moderate,
                            count_high: count_risks.high
                        }
                    })
                }

                // setCountRisks([
                //     count_risks.low,
                //     count_risks.moderate,
                //     count_risks.high
                // ]);

                if (pollCtr === 0) {
                    setCompletedLoading(true);
                    setEmployeePositions(positions) // initial markers. do not set again or map will re-render
                } 
                else if (mapRef.current) {
                    mapRef.current.updateMarkers(positions)
                }

            })
            .finally(() => {
                setPollingEmpPos(false);

                setPollTimeout(setTimeout(() => setPollCtr(ctr => ctr + 1), pollTimer))
            })

        return () => {
            if (pollTimeout) {
                clearTimeout(pollTimeout)
            }
        }
    }, [pollCtr, filters])

    function getLocationDetails(locationId) {
        var params = {
            include_details: true,
            include_floors: true,
            include_gates: true
        }

        setLoadingDetails(true);

        corporateHealthService.getLocation(locationId, params).then(
            response => {
                setLocation(location => ({...location, ...response}));
                setLoadingDetails(false);

                var mapIcon = 'marker-office';

                if(response.type) {
                    switch(response.type) {
                        case 'Office': mapIcon = 'marker_office'; break;
                        case 'Factory': mapIcon = 'marker_factory'; break;
                        case 'Warehouse': mapIcon = 'marker_warehouse'; break;
                        default: break;
                    }

                    setLocationPoint({
                        id: 'location-' + response.id,
                        lat: response.zone.latitude,
                        lng: response.zone.longitude,
                        popup: `<div></div>`,
                        icon: {
                            img: mapIcon
                        },
                        properties: {}
                    })
                }

            }, error => {
                setLoadingDetails(false);
            }
        )
    }

    const handleClickMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleOpenAddOffice = event => {
        setOpenAddOffice(true);
    }

    const handleOpenDeleteOffice = event => {
        setDeleteMode(true);
        setOpenViewOffice(true);
    }

    const handleCloseAddOffice = event => {
        setOpenAddOffice(false);
        getLocationDetails(props.match.params.locationId);
        handleCloseMenu();
        setDeleteMode(false);
    }

    const handleOpenViewOffice = event => {
        setOpenViewOffice(true);
    }

    const handleCloseViewOffice = event => {
        setOpenViewOffice(false);
        handleCloseMenu();
        setDeleteMode(false);
    }

    const handleOpenWorkers = event => {
        const params = {
            locations: [props.match.params.locationId]
        }

        if (filters.sbu && filters.sbu !== 0) {
            params.sbu_id = filters.sbu
        }

        if (filters.riskLevel && filters.riskLevel !== 'ALL') {
            params.risk_factor = filters.riskLevel
        }

        corporateLocationService.getEmployeePositions(props.company, params).then(
          response => {
            setMetricList({...metricList, workers: response.list});
            setOpenWorkers(true);
          }, error => {
    
          }
        )
    }
    
    const handleOpenCheckers = event => {
    const params = {
        locations: [props.match.params.locationId]
    }

    if (filters.sbu && filters.sbu !== 0) {
        params.sbu_id = filters.sbu
    }

    if (filters.riskLevel && filters.riskLevel !== 'ALL') {
        params.risk_factor = filters.riskLevel
    }

    corporateLocationService.getEmployeePositions(props.company, params).then(
            response => {
        setMetricList({...metricList, checkers: response.list.filter(worker => worker.is_checker && worker.is_online)});
        setOpenCheckers(true);
        }, error => {

        }
    )
    }

    const handleOpenHighRiskWorkers = event => {
        const params = {
            locations: [props.match.params.locationId]
        }
    
        if (filters.sbu && filters.sbu !== 0) {
            params.sbu_id = filters.sbu
        }
    
        if (filters.riskLevel && filters.riskLevel !== 'ALL') {
            params.risk_factor = filters.riskLevel
        }

        corporateLocationService.getEmployeePositions(props.company, params).then(
            response => {
                setMetricList({
                    ...metricList,
                    highRiskWorkers: response.list.filter(worker => worker.risk && worker.risk.label && worker.risk.label === 'HIGH Risk')
                })
                setOpenHighRiskWorkers(true);
            }, error => {

            }
        )
    }

    return (
        <div className={classes.root}>
             { openWorkers && <HealthRiskDialog open={openWorkers} handleClose={() => { setOpenWorkers(false)}}
                mw="sm"
                styling={{ color: 'white', backgroundColor: '#211f1f'}}
                title={'Total Workers in the Perimeter'}>
                    <UserReportList list={metricList.workers} />
                </HealthRiskDialog> }
            { openCheckers && <HealthRiskDialog open={openCheckers} handleClose={() => { setOpenCheckers(false)}}
                mw="sm"
                styling={{ color: 'white', backgroundColor: '#211f1f'}}
                title={'Health Checkers in the Perimeter'}>
                <UserReportList list={metricList.checkers} />
            </HealthRiskDialog> }
            { openHighRiskWorkers && <HealthRiskDialog open={openHighRiskWorkers} handleClose={() => { setOpenHighRiskWorkers(false)}}
                mw="sm"
                styling={{ color: 'white', backgroundColor: '#211f1f'}}
                title={'High Risk Workers'}>
                <UserReportList list={metricList.highRiskWorkers} />
            </HealthRiskDialog> }
            <Grid container spacing={0} justify="flex-start" alignItems="flex-end">
                <BackButton/>
            </Grid>
            <Grid container spacing={3}>
                <Grid container item xs={12}>
                    <Paper className={classes.paper}>
                        {
                            openAddOffice && (
                    <EditOffice open={openAddOffice} setLocation={setLocation} handleClose={handleCloseAddOffice} selectedOffice={location} company={props.company} />
                            )
                        }
                        {
                            openViewOffice && (
                                <ViewLocationDetails open={openViewOffice} handleClose={handleCloseViewOffice} location={location} deleteMode={deleteMode}/>
                            )
                        }
                        {
                            loadingDetails
                                ? [...Array(3)].map((item, index) => (
                                    <Skeleton key={index} animation={"wave"}/>
                                ))
                                : (
                                    <Grid item xs={12} container>
                                        <Grid container item xs={12} alignItems="center" justify="space-between">
                                            <Grid item xs={9}>
                                                <Typography variant="h6"
                                                        className={clsx(classes.boldText, classes.textLeft)}>
                                                    {location.label || '-'}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3} className={clsx(classes.textRight)} align="right">
                                                <IconButton aria-controls="simple-menu" aria-haspopup="true"
                                                            onClick={handleClickMenu}>
                                                    <MoreVertIcon/>
                                                </IconButton>
                                                <Menu
                                                    id="simple-menu"
                                                    anchorEl={anchorEl}
                                                    keepMounted
                                                    open={Boolean(anchorEl)}
                                                    onClose={handleCloseMenu}
                                                >
                                                    <MenuItem onClick={handleOpenViewOffice}>View Location Details</MenuItem>
                                                    <MenuItem onClick={handleOpenAddOffice}>Edit Location Details</MenuItem>
                                                    <MenuItem onClick={handleOpenDeleteOffice}>Delete Location</MenuItem>
                                                </Menu>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} container alignItems="flex-start" justify="flex-start">
                                            <Grid item xs={12} align="left" style={{ marginBottom: 20}}>
                                                <Typography variant="caption" style={{ fontWeight: 700}} className={clsx(classes.textLeft)}>
                                                    Address <br />
                                                </Typography>
                                                <Typography variant="caption">
                                                    {location.address || '-'}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} align="left">
                                                <Typography variant="caption" style={{ fontWeight: 700}} className={clsx(classes.textLeft)}>
                                                    Location Type <br />
                                                </Typography>
                                                <Typography variant="caption">
                                                    {location.type ? location.type.label : ''}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )
                            }
                        </Paper>
                </Grid>
                <Grid container spacing={2} style={{marginBottom: 10}}  item xs={12} alignItems="flex-start" justify="flex-start">
                    <Grid item md={4} xs={12}  style={{ height: 400 }}>
                        <HealthRiskMetrics
                            title={'Cleared Workers in the Perimeter'}
                            noDataDisplay={'No workers are cleared.'}
                            backgroundColors={['#85CBBE', '#E8685E']}
                            data={
                                percentMetrics ? [
                                    { name: 'Cleared', value: percentMetrics.cleared },
                                    { name: 'Denied', value: percentMetrics.deniedInPerimeterCount }
                                ] : []
                            }
                        />
                    </Grid>
                    <Grid item md={4} xs={12}  style={{ height: 400 }}>
                        <HealthRiskMetrics
                            title={'Denied Entry Reasons'}
                            noDataDisplay={'No workers are denied.'}
                            backgroundColors={[
                                '#E8685E',
                                '#FE974C',
                                '#EEBE6A'
                            ]}
                            data={
                                percentMetrics ? [
                                    {
                                        name: percentMetrics.deniedEntries.inQuarantine.label,
                                        value: percentMetrics.deniedEntries.inQuarantine.count
                                    },
                                    {
                                        name: percentMetrics.deniedEntries.closeContact.label,
                                        value: percentMetrics.deniedEntries.closeContact.count
                                    },
                                    {
                                        name: percentMetrics.deniedEntries.fluSymptoms.label,
                                        value: percentMetrics.deniedEntries.fluSymptoms.count
                                    }
                                ] : []
                            }
                        />
                    </Grid>
                    <Grid item md={4} xs={12}  style={{ height: 400}}>
                        <HealthRiskMetrics
                            title={'Scanned Workers in the Perimeter'}
                            noDataDisplay={'No workers are scanned.'}
                            // description={"Percentage of scanned workers in perimeter"}
                            // labels={['Scanned Workers: ' + percentMetrics.scannedCount, 'Not Scanned Workers: ' + (percentMetrics.count - percentMetrics.scannedCount)]}
                            backgroundColors={['#8F9EC3', '#92B3D5']}
                            data={
                                percentMetrics ? [
                                    {
                                        name: 'Scanned', value: percentMetrics.scannedCount
                                    },
                                    {
                                        name: 'Not Scanned', value: riskLevels[0].count - percentMetrics.scannedCount
                                    }
                                ] : []
                            }
                            // counts={[percentMetrics.scannedCount, riskLevels[0].count - percentMetrics.scannedCount]}
                        />
                    </Grid>
                </Grid>
                <Grid
                    container item
                    xs={12} spacing={4}
                    style={{ marginLeft: 3, position: 'relative', bottom: 95, paddingLeft: 7}}
                    justify="center" alignItems="flex-start"
                >
                    <Grid
                        item
                        sm={4}
                        xs={12}
                        >
                        <EmployeeCard clickEvent={handleOpenWorkers} label={'Total Workers in Perimeter'} count={riskLevels[3].count || 0} />
                    </Grid>
                    <Grid
                        item
                        sm={4}
                        xs={12}
                        >
                        <HealthCheckerCard clickEvent={handleOpenCheckers} label={'Online Health Checkers'} count={checkerCount || 0}/>
                    </Grid>
                    <Grid
                        item
                        sm={4}
                        xs={12}
                        >
                    <HighRiskCard clickEvent={handleOpenHighRiskWorkers} label={'High Risk Workers'} count={riskLevels[2].count || 0}/>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container direction={"column"} spacing={2} style={{ marginTop: 10}}>
                <Grid container item xs={12} spacing={2} alignItems={"flex-start"} justify={"center"} style={{ position: 'relative', bottom: 65}}>
                    <Grid item container sm={6} xs={12} spacing={2}>
                        <Grid item xs={12}>
                            <DailyLogs
                                partnerId={props.company}
                                logType={["GATE_ACCESS", "TEMP_READING", "PERIMETER_CROSS"]}
                                location={{id: props.match.params.locationId}}
                                showFilters={{
                                    name: {
                                        md: 6
                                    },
                                    dateRange: {
                                        md: 4
                                    }
                                }}
                                columns={[
                                    {id: "action", label: "ACTION", align: 'left'},
                                    {id: "employee", label: "WORKER", align: 'left'},
                                    {id: "description", label: "DESCRIPTION", align: 'left'},
                                    {id: "date_created", label: "DATE", align: 'left'},
                                ]}
                                tableHeight={"100vh"}
                            />
                        </Grid>
                    </Grid>
                    <Grid container item sm={6} xs={12} spacing={2}>
                        <Grid item container xs={12} spacing={2} justify={"center"}>
                            <Grid item container xs={12} spacing={1} justify={"center"}>
                                {
                                    userData.user.isAdmin && (
                                        <Grid item sm={6} xs={12}>
                                            <FormControl variant="outlined" fullWidth>
                                                <InputLabel id="sbu-selection-label">Strategic Business Unit</InputLabel>
                                                <Select
                                                    className={classes.textLeft}
                                                    labelId="sbu-selection-label"
                                                    label="Strategic Business Unit"
                                                    value={filters.sbu}
                                                    onChange={(event) => updateFilters('sbu', event.target.value)}
                                                >
                                                    {
                                                        sbuList.map(({ sbu_id, sbu_label }) => {
                                                            return (
                                                                <MenuItem key={sbu_id} value={sbu_id}>{sbu_label}</MenuItem>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    )
                                }
                                <Grid item sm={6} xs={12}>
                                    <FormControl variant="outlined" fullWidth>
                                        <InputLabel id="risk-level-selection-label">Risk Level</InputLabel>
                                        <Select
                                            className={classes.textLeft}
                                            labelId="risk-level-selection-label"
                                            label="Risk Level"
                                            value={filters.riskLevel}
                                            onChange={(event) => setFilters(filters => ({
                                                ...filters,
                                                riskLevel: event.target.value
                                            }))}
                                        >
                                            {
                                                riskLevelTypes.map(({label, value}) => {
                                                    return (
                                                        <MenuItem key={value} value={value}>{label}</MenuItem>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                {
                                    isPollingEmpPos && (
                                        <LinearProgress color={"primary"} style={{width: "100%"}}/>
                                    )
                                }
                                { completedLoading && <Mapbox
                                    ref={mapRef}
                                    lat={locationZone.latitude}
                                    lng={locationZone.longitude}
                                    zoom={14}
                                    points={
                                        [
                                            {
                                                ...locationPoint,
                                                properties: {
                                                    count: (countRisks[0] + countRisks[1] + countRisks[2]) || 0,
                                                    count_low: countRisks[0],
                                                    count_moderate: countRisks[1],
                                                    count_high: countRisks[2]
                                                }
                                            }
                                        ]
                                    }
                                    counts={countRisks || []}
                                    markers={employeePositions}
                                    displayCenterMarker={false}
                                    centerRadius={locationZone.radius_in_meters || 0}
                                    style={{
                                        width: "100%",
                                        height: "70vh"
                                    }}
                                >
                                    <div className={classes.legend}>
                                        <h4 className={classes.legendTitle}>Marker Legends</h4>
                                        <div><span className={classes.legendEntry} style={{ backgroundColor: '#4BAF8B' }}></span>Low Hazard</div>
                                        <div><span className={classes.legendEntry} style={{ backgroundColor: '#FE974C'}}></span>Moderate Hazard</div>
                                        <div><span className={classes.legendEntry} style={{ backgroundColor: '#DD5353'}}></span>High Hazard</div>
                                    </div>
                                </Mapbox> }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default OfficeView;
