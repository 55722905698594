import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import HealthRiskMetrics from "../../../healthRisk/healthRiskMetrics";
import gPalette from "google-palette";
import chroma from 'chroma-js'

import corporateHealthService from '../../../services/corporate-health.service';
export const Metrics = {
  LINKEDACCOUNTS: "LINKEDACCOUNTS",
  GENDER: "GENDER",
  AGE: "AGE",
  COMORBIDITY:'COMORBIDITY',
  INVITED:'INVITED',
  RAWDATA:'RAWDATA'
};

function generatePalette (count) {
  return chroma.scale([
    '#9bbbdc',
  // '#cce4ea',
  '#74bacd',
  '#85cbc8',
  // '#7f8aac',
  '#eebe6a',
  '#f5a97e',
  '#f59b94',
  ])
  .mode('lab')
  .colors(count)
}

const PieMetric = ({
  title,
  event,
  metric = "",
  consolidatedCohortData = [],
  colors = [],
  renderKey
}) => {
  
  const [palette, setPalette] = useState(generatePalette(12));
  
  const calcLinkedMetrics = (rawData) => {
    setPalette(generatePalette(2))
    const countLinked = rawData.reduce((acc, c) => {
      return acc + (c.status === "LINKED" ? 1 : 0);
    }, 0);
    const countUnlinked = rawData.reduce((acc, c) => {
      return acc + (c.status !== "LINKED" ? 1 : 0);
    }, 0);
    return [
      { name: "Linked", value: countLinked },
      { name: "Unlinked", value: countUnlinked },
    ].sort((a,b)=> b.value - a.value);
  };
  const calcGenderMetrics = (rawData) => {
    setPalette(generatePalette(3))
    const countMale = rawData.reduce((acc, c) => {
      return acc + (c.gender === "Male" ? 1 : 0);
    }, 0);
    const countFemale = rawData.reduce((acc, c) => {
      return acc + (c.gender === "Female" ? 1 : 0);
    }, 0);
    const countOther = rawData.reduce((acc, c) => {
      return acc + (!["Male", "Female"].includes(c.gender) ? 1 : 0);
    }, 0);
    return [
      { name: "Male", value: countMale },
      { name: "Female", value: countFemale },
      { name: "Unspecified", value: countOther },
    ].sort((a,b)=> b.value - a.value);
  };

  const calcAgeMetrics = (rawData) => {
    setPalette(generatePalette(4))
    let age18 = 0;
    let age35 = 0;
    let age65 = 0;
    let ageOldAsBalls = 0;

    rawData.forEach((element) => {
      const age = moment().diff(moment(element.birthdate), "years");
      if (age <= 18) {
        age18 += 1;
      } else if (age <= 35) {
        age35 += 1;
      } else if (age <= 65) {
        age65 += 1;
      } else {
        ageOldAsBalls += 1;
      }
    });

    return [
      { name: "Below 18", value: age18 },
      { name: "18-35", value: age35 },
      { name: "36-65", value: age65 },
      { name: "Above 65", value: ageOldAsBalls },
    ].sort((a,b)=> b.value - a.value);
  };

  const calcInvitedMetrics = (rawData) => {
    setPalette(generatePalette(4))
    let accepted = 0;
    let denied = 0;
    let sent = 0;
    let unsent = 0;

    rawData.forEach((element) => {
      switch (element?.invite?.status) {
        case "ACCEPTED":
          accepted += 1
          break;
        case "DECLINED":
          denied += 1
          break;
        case "SENT":
          sent += 1
          break;
        default:
          unsent += 1
          break;
      }
    });

    return [
      { name: "Denied", value: denied },
      { name: "Accepted", value: accepted },
      { name: "Sent", value: sent },
      { name: "Unsent", value: unsent },
    ].sort((a,b)=> b.value - a.value);
  }

  const calcRaw = (data) => {
    setPalette(generatePalette(data.length))
    return data
  }


  const dataSet = useMemo(() => {
    switch (metric) {
      case Metrics.LINKEDACCOUNTS:
        return calcLinkedMetrics(consolidatedCohortData);
      case Metrics.GENDER:
        return calcGenderMetrics(consolidatedCohortData);
      case Metrics.AGE:
        return calcAgeMetrics(consolidatedCohortData);
      case Metrics.INVITED:
        return calcInvitedMetrics(consolidatedCohortData);
      case Metrics.RAWDATA:
        return calcRaw(consolidatedCohortData)
      default:
        return [];
    }
  }, [consolidatedCohortData, metric]);

  return (
    <HealthRiskMetrics renderKey={renderKey} title={title} data={dataSet} backgroundColors={palette} />
  );
};

export { PieMetric };
