import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
} from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import corporateEventsService from "../../services/corporate-events.service";
import CohortAutoComplete from "../autocomplete/cohort";
import { EventHeaderDetailContent } from "./EventHeaderDetail";
import useStateRef from "react-usestateref";
import { Typography } from "@material-ui/core";
import isEmpty from "lodash-es/isEmpty";
const useStyles = makeStyles((theme) => ({
  count: {
    backgroundColor: `${theme.palette.common.white} !important`,
  },
}));
const AddCohortDialog = ({ event, cohorts, onComplete=()=>{} }) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [processing, setProcessing] = useState(false);
  const [invalid, setInvalid] = useState(false);
  const [open, setOpen] = useState(false);
  const [newServices, setNewServices, newServicesRef] = useStateRef(
    event.services ?? []
  );

  const openCohort = (cohort) => {
    history.push({
      pathname: `/cohorts/${cohort.id}`,
      search: location.search,
    });
  };

  const updateService = useCallback(async () => {
    setProcessing(true);
    const services = newServices.map(service => {
      return {
        type: service.type?.id,
        cohorts: service.cohorts
      }
    })
    try {
      await corporateEventsService.modifyCohorts(event.id, {
        services
      });
      onComplete()
      setOpen(false)
    } catch (error) {
    } finally {
      setProcessing(false);
      setInvalid(false)
    }
  }, [event.id, newServices, onComplete]);

  const onSelectedCohorts = (service, selectCohorts) => {
    setInvalid(false)
    const serviceArray = (newServicesRef.current??[]).map(s=> {
      if(isEmpty(selectCohorts)){
        setInvalid(true)
      }
      if (s?.type?.id === service.type.id) {
        return {
          ...s,
          cohorts: selectCohorts,
        }
      }
      return s
    })
    setNewServices(serviceArray);
  };

  useEffect(() => {
    setInvalid(false)
    newServices.map(s => {
      if(isEmpty(s.cohorts)){
        setInvalid(true)
      }
      return s
    })
  }, [newServices, open]);

  const handleClose = useCallback(
    () => {
      setNewServices(event.services) 
      setOpen(false);
    },
    [event.services, setNewServices],
  );

  return (
    <>
      {["PENDING", "REQUESTED"].includes(event.status) && (
        <Button
          onClick={() => setOpen(true)}
          variant="contained"
          color="primary"
        >
          Add Cohort
        </Button>
      )}
      <Dialog
        maxWidth={"md"}
        fullWidth
        open={open}
        onClose={() => handleClose()}
      >
        <DialogTitle>Add Cohort</DialogTitle>
        <DialogContent dividers>
          <EventHeaderDetailContent event={event} />
        </DialogContent>

        {(event.services ?? []).map((service, idx) => {
          return (
            <DialogContent dividers key={idx}>
              <Typography variant="button">
                Service: {service?.type?.name}
              </Typography>
              <CohortAutoComplete
                label="Add Cohort"
                frozenCohorts={
                  ["PENDING"].includes(event.status) ? service.cohorts : []
                }
                selectedCohorts={
                  (newServices ?? []).find(
                    (s) => service?.type?.id === s.type?.id
                  )?.cohorts ?? []
                }
                onSelectedCohorts={(selectedCohorts) => {
                  onSelectedCohorts(service, selectedCohorts);
                }}
                cohorts={cohorts}
                openCohort={openCohort}
              />
            </DialogContent>
          );
        })}
        <DialogActions>
          <Button
            onClick={() => {
              handleClose()
            }}
            color="default"
          >Cancel</Button>
          <Button
            disabled={processing || invalid}
            onClick={() => {
              updateService();
            }}
            color="primary"
          >Save</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export { AddCohortDialog };
