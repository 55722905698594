import axios from 'axios';
import qs from 'query-string'

const instance = axios.create({
    baseUrl: process.env.REACT_APP_WEB_ADMIN_URL,
    headers: {
        // "Access-Control-Allow-Origin": "*",
        'X-Auth-Token': JSON.parse(localStorage.getItem('currentUser')) ?
            JSON.parse(localStorage.getItem('currentUser')).token : ''
    },
    paramsSerializer: (params) => {
        return qs.stringify(params)
    }
})

export default instance;
