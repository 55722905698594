import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { CircularProgress, TextField, useTheme, FormControl, InputLabel, Select, ExpansionPanelSummary } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {withStyles } from '@material-ui/core/styles';
import MuiDialogContent from '@material-ui/core/DialogContent';


const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    fullLength: {
        width: '100%'
    }
});

const useStyles = makeStyles(theme => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      minHeight: 'fit-content',
      backgroundColor: 'white'
    },
    avatar: {
      backgroundColor: 'white',
      width: '80%',
      margin: theme.spacing(2)
    },
    name: {
      color: 'black',
      marginTop: theme.spacing(1)
    }
  }));

  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <div>{children}</div>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
});
  
const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);
  

const ViewClearanceImage = (props) => {
  const { open, setOpen, handleClickOpen, handleClose, type, url } = props;
  const classes = useStyles();

  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Open alert dialog
      </Button>
      <Dialog
        fullWidth
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" onClose={handleClose}>
            { type === 'doctor' ? 'Doctor' : 'HR'} Clearance
        </DialogTitle>
        <DialogContent>
            <Grid container spacing={1} alignItems="center" justify="center">
                { url ? <img
                    alt="Clearance"
                    className={classes.avatar}
                    src={url}
                /> : <Typography variant="body1">
                    No image url.
                </Typography> }
            </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default ViewClearanceImage;