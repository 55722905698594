import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Dialog, DialogContentText, Typography, Grid, 
    MenuItem, CircularProgress,
    TextField, useTheme, FormControl, InputLabel, Select, ExpansionPanelSummary } from '@material-ui/core';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import corporateHealthService from './../services/corporate-health.service';
import { ExpansionPanel, ExpansionPanelDetails } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import moment from 'moment';
import {
  CloudUpload as CloudUploadIcon,
  Description as DescriptionIcon
} from "@material-ui/icons";
import ErrorMessage from './../components/errorMessage';
import SuccessMessage from './../components/successMessage';
import corporateEmployeeService from './../services/corporate-employee.service';

const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    fullLength: {
        width: '100%'
    }
  });

  const useStyles = makeStyles(theme => ({
    root: {
      padding: theme.spacing(4),
      flexGrow: 1,
      color: '#000'
    },
    officeInfoTitle: {
      padding: 10,
      color: '#000'
    },
    officeInfoSection: {
      padding: 10,
      color: '#000'
    },
    formControl: {
      marginRight: theme.spacing(1),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      minWidth: 300,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    fullHeight: {
      height: '100vh',
      width: '100vw'
    },
    headerPadding: {
      padding: 20,
      fontWeight: 700
    },
    textFieldSpacer: {
      margin: theme.spacing(1)
    },
    marginSpace: {
      margin: theme.spacing(3)
    },
    errorColor: {
      color: theme.palette.error.main,
      backgroundColor: 'white',
      borderColor: theme.palette.error.main
    },
    btnMargin: {
      marginTop: 10,
      marginLeft: 10
    }
  }));

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
});
  
const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);
  

const ClearanceFeedback = (props) => {
    const { open, handleClose, employee } = props;
    console.log(props);
    const classes = useStyles();
    const [ doctorClearanceFile, setDoctorClearanceFile ] = useState(null);
    const [ hrClearanceFile, setHrClearanceFile ] = useState(null);
    const [ openDocUpload, setOpenDocUpload ] = useState(false);
    const [ openHrUpload, setOpenHrUpload ] = useState(false);
    const [ message, setMessage ] = useState('');
    const [ error, setError ] = useState('');
    const [ openSuccess, setOpenSuccess ] = useState(false);
    const [ openError, setOpenError ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const [ reasons, setReasons ] = useState({
        doctor: [],
        hr: []
    });
    const [ state, setState ] = useState({
        note: ''
    });

    // useEffect(() => {
    //     getRejectionReasons('doctor');
    //     getRejectionReasons('hr');
    // }, []);
  
      function getRejectionReasons(clearanceType) {
        var params = {
          type: clearanceType
        }
        setLoading(true);
        corporateHealthService.getRejectionReasons(params).then(
          response => {
            if(clearanceType === 'doctor') {
                setReasons({ ...reasons, doctor: response});
            } else {
                setReasons({ ...reasons, hr: response});
            }
            setLoading(false);
          }, error => {
            setLoading(false);
          }
        );
      }
  

    const handleChange = name => event => {
        setState({
            ...state,
            [name]: event.target.value
        })

        if(name === 'doctor_clearance' && event.target.value === 'REJECT') {
            getRejectionReasons('doctor');
        }
        if(name === 'hr_clearance' && event.target.value === 'REJECT') {
            getRejectionReasons('hr');
        }
    }


    const handleDone = event => {
      var params = {
        clearance_request: employee.clearance_id,
        doctor_clearance: employee.doctor_image.id,
        hr_clearance: employee.hr_image.id,
        doctor_clearance_status: state.doctor_clearance === 'APPROVE',
        hr_clearance_status: state.hr_clearance === 'APPROVE',
        doctor_reject_reason: '',
        hr_reject_reason: '',
        note: state.note
      }

      console.log(params);
      corporateEmployeeService.processQuarantinedEmployeeClearances(params).then(
        response => {
          setMessage('Successfully process clearance.');
          setOpenSuccess(true);

          setTimeout(() => {
            handleClose();
          }, 1000)
        }, error => {
          setError({ message: 'Unable to process clearance.' });
          setOpenError(true);
        }
      );
    }

    return (
        <div>
            <Dialog maxWidth={'sm'} fullWidth={true} open={open} onClose={handleClose}
                aria-labelledby="upload-clearances-title">
                 {error ?  <ErrorMessage error={error} open={openError} setOpen={setOpenError} /> : undefined}
                { message  ? <SuccessMessage open={openSuccess} setOpen={setOpenSuccess} message={message} /> : undefined}
                <DialogTitle id="upload-clearances-title" onClose={handleClose}>
                    <Typography component="span" variant="h6" style={{ fontWeight: 700}}>
                      Clearance Feedback <br />
                    </Typography>
                    <Typography component="span" variant="caption">
                       Approve or reject the Doctor and HR Clearances submitted by employee.
                    </Typography>
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={4}>
                      <Grid item xs={4} sm={4}>
                          <Typography variant="caption">
                            Employee Name
                          </Typography>
                      </Grid>
                      <Grid item xs={8} sm={8}>
                          <Typography variant="subtitle1" style={{ fontWeight: 700}}>
                              { employee.employee.last_name }, { employee.employee.first_name }
                          </Typography>
                      </Grid>
                      <Grid item xs={4} sm={4}>
                          <Typography variant="caption">
                              ID Number:
                          </Typography>
                      </Grid>
                      <Grid item xs={8} sm={8}>
                        <Typography variant="subtitle1" style={{ fontWeight: 700}}>
                              { employee.employee.card_id || '-' }
                          </Typography>
                      </Grid>
                      <Grid item xs={4} sm={4}>
                          <Typography variant="caption">
                            Doctor Clearance
                          </Typography>
                      </Grid>
                      <Grid item xs={8} sm={8}>
                            <FormControl variant="outlined" className={classes.formControl}>
                                <InputLabel id="demo-simple-select-outlined-label">Status</InputLabel>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={state.doctor_clearance || ''}
                                    onChange={handleChange('doctor_clearance')}
                                    label="Status"
                                    >
                                    <MenuItem value={'APPROVE'}>Approve</MenuItem>
                                    <MenuItem value={'REJECT'}>Reject</MenuItem>
                                </Select>
                            </FormControl>
                      </Grid>
                      { state.doctor_clearance === 'REJECT' ? <React.Fragment>
                      <Grid item xs={4} sm={4}>
                          <Typography variant="caption">
                            Doctor Clearance Rejection Reason
                          </Typography>
                      </Grid>
                      <Grid item xs={8} sm={8}>
                            <FormControl variant="outlined" className={classes.formControl}>
                                <InputLabel id="demo-simple-select-outlined-label">Rejection Reason</InputLabel>
                                <Select
                                    disabled={loading}
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={state.doctor_rejection_reason || ''}
                                    onChange={handleChange('doctor_rejection_reason')}
                                    label="Rejection Reason"
                                    >
                                   {
                                       reasons.doctor ? reasons.doctor.map((reason, index) => {
                                           return (
                                               reason ? 
                                                <MenuItem key={index} value={reason.name}>
                                                    { reason.label || ''}
                                                </MenuItem>
                                               : undefined
                                           )
                                       })
                                   : undefined}
                                </Select>
                            </FormControl>
                      </Grid>
                      </React.Fragment> : undefined }
                      <Grid item xs={4} sm={4}>
                          <Typography variant="caption">
                            HR Clearance
                          </Typography>
                      </Grid>
                      <Grid item xs={8} sm={8}>
                        <FormControl variant="outlined" className={classes.formControl}>
                                <InputLabel id="demo-simple-select-outlined-label">Status</InputLabel>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={state.hr_clearance || ''}
                                    onChange={handleChange('hr_clearance')}
                                    label="Status"
                                    >
                                    <MenuItem value={'APPROVE'}>Approve</MenuItem>
                                    <MenuItem value={'REJECT'}>Reject</MenuItem>
                                </Select>
                            </FormControl>
                      </Grid>
                      { state.hr_clearance === 'REJECT' ? 
                      <React.Fragment>
                      <Grid item xs={4} sm={4}>
                          <Typography variant="caption">
                            HR Clearance Rejection Reason
                          </Typography>
                        </Grid>
                        <Grid item xs={8} sm={8}>
                        <FormControl variant="outlined" className={classes.formControl}>
                                <InputLabel id="demo-simple-select-outlined-label">Rejection Reason</InputLabel>
                                <Select
                                    disabled={loading}
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={state.hr_rejection_reason || ''}
                                    onChange={handleChange('hr_rejection_reason')}
                                    label="Rejection Reason"
                                    >
                                    {
                                       reasons.hr ? reasons.hr.map((reason, index) => {
                                           return (
                                               reason ? 
                                                <MenuItem key={index} value={reason.name}>
                                                    { reason.label || ''}
                                                </MenuItem>
                                               : undefined
                                           )
                                       })
                                   : undefined}
                                </Select>
                            </FormControl>
                      </Grid>
                      </React.Fragment> : undefined }
                      <Grid item xs={4} sm={4}>
                          <Typography variant="caption">
                            Short note
                          </Typography>
                      </Grid>
                      <Grid item xs={8} sm={8}>
                        <TextField
                            style={{ width: '100%'}}
                            id="outlined-multiline-static"
                            label="Short note"
                            required
                            multiline
                            rows={4}
                            onChange={handleChange('note')}
                            value={state.note}
                            variant="outlined"
                            />
                      </Grid>
                      <Grid item container>
                        <Button variant="contained" style={{ color: 'white'}} disabled={loading} onClick={handleDone} color="primary">
                          Done { loading && <CircularProgress size="small" color="primary" />}
                        </Button>
                      </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </div>
    )

}

export default ClearanceFeedback;
  
