import React, {useCallback, useEffect, useState} from "react"
import {
    Grid,
    Typography,
    Paper,
    Divider
} from "@material-ui/core"
import {
    Skeleton
} from "@material-ui/lab"
import {
    makeStyles
} from "@material-ui/core/styles";

import Title from "../../components/Title"
import EditCompanyProfile from "./EditCompanyProfileDialog"

import clsx from "clsx";
import CorporatePartnerService from "../../services/corporate-partner.service"

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(2),
        flexGrow: 1,
        maxWidth: '1440px'
    },
    textLeft: {
        textAlign: "left"
    },
    bold: {
        fontWeight: "bold"
    },
    italic: {
        fontStyle: "italic"
    },
    fullWidth: {
        width: "100%"
    },
    editCompanyBtn: {
        [theme.breakpoints.only('xs')]: {
            textAlign: "left"
        },
        [theme.breakpoints.up('sm')]: {
            textAlign: "right"
        }
    },
    companyDataName: {
        color: "#494949",
        fontWeight: "550"
    },
    companyAvatar: {
        // backgroundColor: 'white',
        width: theme.spacing(14),
        height: theme.spacing(14),
        objectFit: 'scale-down'
    }
}))

export default function CompanyProfile(props) {
    const classes = useStyles()
    const partnerId =  props.company

    const [ profile, setProfile ] = useState(null)
    const [ isLoadingProfile, setIsLoadingProfile ] = useState(false)
    const [ isUpdatingProfile, setIsUpdatingProfile ] = useState(false)

    function renderSkeleton() {
        return [...Array(5).keys()].map(index => (
            <React.Fragment key={index}>
                <Grid item xs={12} sm={2}>
                    <Skeleton animation={"wave"} />
                </Grid>
                <Grid item xs={12} sm={10}>
                    <Skeleton animation={"wave"} />
                </Grid>
            </React.Fragment>
        ))
    }

    function renderGridDivider() {
        return (
            <Grid item xs={12}>
                <Divider />
            </Grid>
        )
    }

    const getCompanyDetails = useCallback(() => {
        setIsLoadingProfile(true)

        CorporatePartnerService.getCompanyDetails(partnerId)
            .then(data => {
                setProfile(data)
            })
            .finally(() => setIsLoadingProfile(false))
    }, [partnerId])

    useEffect(() => {
        if (!profile) {
            getCompanyDetails()
        }
    }, [profile, getCompanyDetails])

    return (
        <Grid container alignItems="center" justify="center" className={classes.root} spacing={2}>
            <Grid item container alignItems={"center"} justify={"space-between"} xs={12}>
                <Grid item xs={12} sm={6} className={classes.textLeft}>
                    <Title text={"Company Profile"} />
                </Grid>
                <Grid item xs={12} sm={6} className={classes.editCompanyBtn}>
                    <EditCompanyProfile
                        partnerId={partnerId}
                        company={profile}
                        callback={getCompanyDetails}
                    />
                </Grid>
            </Grid>
            <Grid item container alignItems={"center"} xs={12}>
                <Paper variant={"outlined"} className={classes.root}>
                    <Grid container alignItems={"center"} spacing={2}>
                        <Grid item xs={12} sm={3}>
                            <Typography className={clsx(classes.companyDataName, classes.textLeft)}>
                                Company Name
                            </Typography>
                        </Grid>
                        <Grid item container xs={12} sm={9}>
                            {
                                isLoadingProfile
                                    ? <Skeleton className={classes.fullWidth} animation={"wave"} />
                                    : (
                                        <Typography variant={"h6"} className={clsx(classes.textLeft, classes.bold)}>
                                            { profile ? profile.label : "-" }
                                        </Typography>
                                    )
                            }
                        </Grid>
                        { renderGridDivider() }
                        <Grid item xs={12} sm={3}>
                            <Typography className={clsx(classes.companyDataName, classes.textLeft)}>
                                Company Logo
                            </Typography>
                        </Grid>
                        <Grid item container xs={12} sm={9}>
                            {
                                isLoadingProfile
                                    ? <Skeleton className={classes.companyAvatar} variant={"rect"} animation={"wave"} />
                                    : (
                                        <img
                                            src={profile  ? profile.logo : ""}
                                            className={classes.companyAvatar}
                                            alt={"Logo"}
                                        />
                                    )
                            }
                        </Grid>
                        { renderGridDivider() }
                        <Grid item xs={12} sm={3}>
                            <Typography className={clsx(classes.companyDataName, classes.textLeft)}>
                                Brand Name
                            </Typography>
                        </Grid>
                        <Grid item container xs={12} sm={9}>
                            {
                                isLoadingProfile
                                    ? <Skeleton className={classes.fullWidth} animation={"wave"} />
                                    : (
                                        <Typography variant={"body1"} className={clsx(classes.textLeft, classes.bold)}>
                                            { profile ? profile.brandName : "-" }
                                        </Typography>
                                    )
                            }
                        </Grid>
                        { renderGridDivider() }
                        <Grid item xs={12} sm={3}>
                            <Typography className={clsx(classes.companyDataName, classes.textLeft)}>
                                Company Description
                            </Typography>
                        </Grid>
                        <Grid item container xs={12} sm={9}>
                            {
                                isLoadingProfile
                                    ? <Skeleton className={classes.fullWidth} animation={"wave"} />
                                    : (
                                        <Typography variant={"body2"} className={clsx(classes.textLeft)}>
                                            { profile ? profile.description : "-" }
                                        </Typography>
                                    )
                            }
                        </Grid>
                        { renderGridDivider() }
                        <Grid item xs={12} sm={3}>
                            <Typography className={clsx(classes.companyDataName, classes.textLeft)}>
                                Industry
                            </Typography>
                        </Grid>
                        <Grid item container xs={12} sm={9}>
                            {
                                isLoadingProfile
                                    ? <Skeleton className={classes.fullWidth} animation={"wave"} />
                                    : (
                                        <Typography variant={"body1"} className={clsx(classes.textLeft, classes.bold)}>
                                            { profile && profile.industry ? profile.industry.label : "-" }
                                        </Typography>
                                    )
                            }
                        </Grid>
                        { renderGridDivider() }
                        <Grid item xs={12} sm={3}>
                            <Typography className={clsx(classes.companyDataName, classes.textLeft)}>
                                zennya Worker App Dashboard Color
                            </Typography>
                        </Grid>
                        <Grid item container xs={12} sm={9}>
                            {
                                isLoadingProfile
                                    ? <Skeleton style={{ width: "15px", height: "15px" }} variant={"rect"} animation={"wave"} />
                                    : profile && profile.bg_color
                                        ? <div style={{ backgroundColor: profile.bg_color, width: "15px", height: "15px" }} />
                                        : <span className={classes.italic}>None</span>
                            }
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    )
}
