import React, {useState, useEffect, useCallback, useMemo} from 'react';
import clsx from 'clsx';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import MuiBadge from '@material-ui/core/Badge';
import { TabContext, TabPanel } from '@material-ui/lab';
import {
    AppBar, Tabs, Tab, CircularProgress, Badge, Chip,
    Avatar, Box, Button, Divider, Link, NativeSelect,
    Input, InputAdornment, Menu, MenuItem, TextField, LinearProgress,
    FormControl, Grid, InputLabel, Paper, Select, Typography, IconButton
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import corporateHealthService from './../services/corporate-health.service';
import healthMetricsService from './../services/health-metrics.service';
import UserList from './../employees/userList';
import SearchIcon from '@material-ui/icons/Search';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {useHistory} from 'react-router-dom';
import BootstrapInput from './../components/bootstrapInput';
import moment from 'moment';
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import DailyLogs from "../dashboard/DailyLogs";
import './dropdown.css';
import RemoveFromQuarantine from './../quarantine/uploadClearances';
import ClearanceFeedback from './../quarantine/clearanceFeedback';
// import TabPanel from './../components/tabPanel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import ErrorMessage from './../components/errorMessage';
import SuccessMessage from './../components/successMessage';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faFileImage} from '@fortawesome/free-solid-svg-icons'
import ViewClearanceImage from './../quarantine/viewClearanceImage';
import HealthRiskMetrics from './../healthRisk/healthRiskMetrics';
import { SignalCellularNullOutlined } from '@material-ui/icons';
import {EmployeeCellContextMenu} from './healthCheckerList';

const styles = (theme) => ({
    root: {}
})

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(2),
        flexGrow: 1,
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%'
          },
        [theme.breakpoints.up('md')]: {
            maxWidth: 1440
        },
        [theme.breakpoints.up('xl')]: {
            maxWidth: 1920
        }
    },
    rootTabs: {
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%'
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: 1440
        },
        [theme.breakpoints.up('xl')]: {
            maxWidth: 1920
        }
    },
    officeInfoTitle: {
        padding: 10
    },
    officeInfoSection: {
        padding: 10
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'left',
        color: theme.palette.text.secondary,
    },

    cardSpacing: {
        margin: 5
    },
    boldText: {
        fontWeight: 700,
        color: '#333'
    },
    spacer: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    buttonSpacer: {
        marginRight: 10
    },
    large: {
        width: theme.spacing(14),
        height: theme.spacing(14),
    },
    margin: {
        margin: theme.spacing(1),
    },
    textLeft: {
        textAlign: 'left'
    },
    errorIcon: {
        color: theme.palette.error.main,
        backgroundColor: 'white',
        borderColor: theme.palette.error.main
    },
    linkColor: {
        color: theme.palette.link.main
    },
    badge: {
        height: '20px',
        display: 'flex',
        padding: '0 6px',
        zIndex: 1,
        position: 'absolute',
        flexWrap: 'wrap',
        fontSize: '0.75rem',
        minWidth: 20,
        boxSizing: 'border-box',
        transition: 'transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        alignItems: 'center',
        fontWeight: 500,
        lineHeight: 1,
        alignContent: 'center',
        borderRadius: 10,
        flexDirection: 'row',
        justifyContent: 'center'
    },
    wfhCount: {
        backgroundColor: '#929EBC',
        color: 'white',
    },
    loaCount: {
        backgroundColor: '#F59D6B',
        color: 'white',
    },
    slCount: {
        backgroundColor: '#6A99CA',
        color: 'white',
    },
    forApprovalCount: {
        backgroundColor: '#C4C4C4',
        color: 'white',
    },
    forTestingCount: {
        backgroundColor: '#F38181',
        color: 'white'
    },
    clearedCount: {
        backgroundColor: '#1D6C8A',
        color: 'white'
    },
    legendEntry: {
        borderRadius: '50%',
        display: 'inline-block',
        height: '10px',
        marginRight: '5px',
        width: '10px',
        textAlign: 'left',
        opacity: 0.7
    }, 
    risklabel: {
        display: "flex",
    }
}));

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function QuarantineEmployeeContextMenu({p, index, employee, handleOpenRemoveFromQuarantine, handleMoveCategory, handleOpenClearanceFeedback, loading}) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
  
    const handleContextClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
			<React.Fragment>
      <Button className="dropbtn" onClick={(evt) => handleContextClick(evt)}>
        <MoreVertIcon />
      </Button>
      <Menu anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
        {!p.status_list ||
        (p.status_list &&
          !p.status_list.includes("CLEARED") &&
          !p.status_list.includes("FOR_APPROVAL")) ? (
          <MenuItem
            disabled={loading}
            style={{ fontSize: 11 }}
            onClick={handleOpenRemoveFromQuarantine(employee)}
          >
            Fit to Work Onsite
          </MenuItem>
        ) : undefined}
        {!p.status_list ||
        (p.status_list &&
          p.category_list !== "WORK_FROM_HOME" &&
          !p.status_list.includes("CLEARED") &&
          !p.status_list.includes("FOR_APPROVAL")) ? (
          <MenuItem
            style={{ fontSize: 11 }}
            onClick={handleMoveCategory(employee, index, "WORK_FROM_HOME")}
          >
            Move to Work From Home
          </MenuItem>
        ) : undefined}
        {!p.status_list ||
        (p.status_list &&
          p.category_list !== "LEAVE_OF_ABSENCE" &&
          !p.status_list.includes("CLEARED") &&
          !p.status_list.includes("FOR_APPROVAL")) ? (
          <MenuItem
            disabled={loading}
            style={{ fontSize: 11 }}
            onClick={handleMoveCategory(employee, index, "LEAVE_OF_ABSENCE")}
          >
            Move to On Leave
          </MenuItem>
        ) : undefined}
        {!p.status_list ||
        (p.status_list &&
          p.category_list !== "SICK_LEAVE" &&
          !p.status_list.includes("CLEARED") &&
          !p.status_list.includes("FOR_APPROVAL")) ? (
          <MenuItem
            disabled={loading}
            style={{ fontSize: 11 }}
            onClick={handleMoveCategory(employee, index, "SICK_LEAVE")}
          >
            Move to Sick Leave
          </MenuItem>
        ) : undefined}
        {p.status_list && p.status_list.includes("FOR_APPROVAL") ? (
          <MenuItem
            disabled={loading}
            onClick={handleOpenClearanceFeedback(employee)}
            style={{ fontSize: 11 }}
          >
            Approve / Reject Clearances
          </MenuItem>
        ) : undefined}
      </Menu>
    </React.Fragment>
    )

}
const StyledBadge = withStyles(styles)((props) => {
    const {children, count, styling, label, ...other} = props;
    return (
        <MuiBadge badgeContent={
            <Typography component="span" className={clsx(useStyles().badge, styling)}>
                {count || 0}
            </Typography>
        } showZero>
            <Typography variant="caption" style={{marginTop: 10}}>
                {label}
            </Typography>
        </MuiBadge>
    )
})

const QuarantinedEmployees = (props) => {
    const classes = useStyles();
    const history = useHistory()
    const [locations, setLocations] = useState([]);
    const [notification, setNotification] = useState({
        message: '',
        show: false,
        success: false,
        error: false
    })
    const [state, setState] = useState({
        location: '',
        loc: {
            location_id: '',
            location_label: 'All Locations'
        },
        category_list: 'WORK_FROM_HOME',
        status_list: 'ONGOING',
        include_risk_level: true,
        include_clearances: true
    });
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [employeeForApproval, setEmployeeForApproval] = useState(null);
    const [openRemoveFromQuarantineDialog, setOpenRemoveFromQuarantineDialog] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingLocations, setLoadingLocations] = useState(false);
    const [quarantinedEmployees, setQuarantinedEmployees] = useState({list: [], count: 0});
    const [forApprovalEmployees, setForApprovalEmployees] = useState({list: [], count: 0});
    const [value, setValue] = useState(0);
    const [tabColumns, setTabColumns] = useState([]);
    const [openAutocomplete, setOpenAutocomplete] = useState(false);
    const [openClearanceFeedback, setOpenClearanceFeedback] = useState(false);
    const [options, setOptions] = useState([
        {
            location_id: '',
            location_label: 'All Locations'
        }
    ]);
    const [searchTimer, setSearchTimer] = useState(setTimeout(() => {
    }));
    const [completedQuarantineEmployees, setCompletedQuarantineEmployees] = useState({list: [], count: 0});
    const [savedLists, setSavedLists] = useState(
        [{
            list: [],
            count: 0
        }]
    );
    const [selectedFile, setSelectedFile] = useState(null);
    const [openViewImage, setOpenViewImage] = useState(false);
    const [counts, setCounts] = useState([]);
    const [badgeCounts, setBadgeCounts] = useState([0, 0, 0, 0, 0]);

    const handleChangeValue = (event, val) => {
        setValue(val);
    }

    const handleChangeLocation = (event, value) => {
        setState({
            ...state,
            loc: value,
            location: value.location_id
        })

        getQuarantinedEmployees({...state, location: value.location_id})
    }

    const setShowNotification = (value) => {
        setNotification({
            ...notification,
            show: value
        })
    }


    const handleChange = name => event => {
        setState({
            ...state,
            [name]: event.target.value
        });

        if(name === 'search_name') {
            getQuarantinedEmployees({...state, search_text: event.target.value});
        }
    }

    const handleOpenRemoveFromQuarantine = employee => event => {
        setSelectedEmployee(employee);
        setOpenRemoveFromQuarantineDialog(true);
    }

    const handleOpenClearanceFeedback = employee => event => {
        setEmployeeForApproval(employee);
        setOpenClearanceFeedback(true);
    }

    const handleCloseRemoveFromQuarantine = event => {
        getQuarantinedEmployees({...state, category_list: selectedEmployee.category.name});
        setOpenRemoveFromQuarantineDialog(false);
        setSelectedEmployee(null);
    }

    const handleCloseClearanceFeedback = event => {
        setOpenClearanceFeedback(false);
        setEmployeeForApproval(null);
        getForApprovalEmployees({...state, category_list: 'FOR_APPROVAL'});
    }

    const handleOpenViewImage = (type, url) => event => {
        setSelectedFile({
            type: type,
            url: url
        });
        setOpenViewImage(true);
    }

    const handleCloseViewImage = event => {
        setSelectedFile(null);
        setOpenViewImage(false);
    }

    function handleUpdateBadge(category, count) {
        
    }

    const columns = [
        {id: 'name', label: 'Name', minWidth: 'auto', align: 'left'},
        {id: 'position', label: 'Position', minWidth: 120, align: 'left'},
        {id: 'risk_level', label: 'Risk', minWidth: 100, align: 'left'},
        {id: 'days_quarantined', label: 'Days in Quarantine', minWidth: 100, align: 'left'},
        {id: 'days_quarantined_left', label: 'Days left in Quarantine', minWidth: 'auto', align: 'left'},
        {id: 'action', label: 'Actions', minWidth: 85, align: 'left', noTooltip: true}
    ];

    const completedQuarantineColumns = [
        {id: 'name', label: 'Name', minWidth: 'auto', align: 'left'},
        {id: 'position', label: 'Position', minWidth: 'auto', align: 'left'},
        {id: 'risk_level', label: 'Risk', minWidth: 'auto', align: 'left'},
    ];

    const forApprovalColumns = [
        {id: 'name', label: 'Name', minWidth: 'auto', align: 'left'},
        {id: 'position', label: 'Position', minWidth: 120, align: 'left'},
        {id: 'doctor_clearance', label: 'Doctor Clearance (Tap to View)', minWidth: 170, align: 'left', noTooltip: true},
        {id: 'hr_clearance', label: 'HR Clearance (Tap to View)', minWidth: 170, align: 'left', noTooltip: true},
        {id: 'action', label: 'Actions', minWidth: 85, align: 'left', noTooltip: true}
    ];

    const getPartnerLocations = useCallback((companyId, params) => {
        setLoadingLocations(true);
        corporateHealthService.listLocationCounts(companyId, params).then(
            response => {
                setOptions(options.concat(response.list));
                setLoadingLocations(false);
            }, error => {
                setLoadingLocations(false);
            }
        )
    }, []);


    function getForApprovalEmployees(p) {
        var params = {
            category_list: 'FOR_APPROVAL',
            location: p.location,
            search_text: p.search_text || '',
            status_list: p.status_list,
            include_risk_level: false,
            include_clearances: true
        };
        setLoading(true);
        corporateHealthService.listQuarantinedEmployees(props.company, params).then(
            response => {
                const newList = response.list.map((emp, index) => {
                    return {
                        id: emp.id || '',
                        name: <div
                            style={{
                                display: 'flex',
                                alignItems: 'center'
                            }}
                        >
                            <Avatar src={emp.employee.profile_pic_url} alt={"user_logo"}
                                    style={{width: 40, height: 40}}/>
                            &nbsp;&nbsp;
                            <Link
                                className={classes.linkColor}
                                component="button"
                                variant="body1"
                                onClick={() => {
                                    history.push(`workers/${emp.employee.employee_id}`)
                                }}
                            >
                                {`${emp.employee.last_name}, ${emp.employee.first_name}` || ''}
                            </Link>
                        </div>,
                        position: emp.corporate && emp.corporate.roles && emp.corporate.roles.length >= 1 ? emp.corporate.roles[0].label : '-',
                        location: '-',
                        doctor_clearance:
                            <IconButton aria-label="view-doctor" className={classes.margin} onClick={
                                handleOpenViewImage(
                                    'doctor', emp.doctor_image.url)} color="primary" size="small">
                                <FontAwesomeIcon icon={faFileImage}/>
                            </IconButton>,
                        hr_clearance:
                            <IconButton aria-label="view-hr" className={classes.margin} onClick={
                                handleOpenViewImage('hr', emp.hr_image.url)
                            } color="primary" size="small">
                                <FontAwesomeIcon icon={faFileImage}/>
                            </IconButton>,
                        action: <EmployeeCellContextMenu handleAction={handleOpenClearanceFeedback} employee={emp} label='Approve / Reject Clearances'/>
                        
                    }
                })
                setLoading(false);
                setForApprovalEmployees(
                    {
                        count: response.count,
                        list: newList
                    }
                );
                handleUpdateBadge('FOR_APPROVAL', response.count);
            }, error => {
                setLoading(false);
            }
        )
    }


    function getQuarantinedEmployees(p) {
        var params = {
            category_list: p.category_list,
            location: p.location,
            search_text: p.search_text || '',
            status_list: p.status_list,
            include_risk_level: p.include_risk_level,
            include_clearances: p.include_clearances
        };
        setLoading(true);
        corporateHealthService.listQuarantinedEmployees(props.company, params).then(
            response => {
                const newList = response.list.map((emp, index) => {
                    return {
                        id: emp.id || '',
                        name: <div
                            style={{
                                display: 'flex',
                                alignItems: 'center'
                            }}
                        >
                            <Avatar src={emp.employee.profile_pic_url} alt={emp.employee.first_name}
                                    style={{width: 40, height: 40}}/>
                            &nbsp;&nbsp;
                            <Link
                                className={classes.linkColor}
                                component="button"
                                variant="body1"
                                onClick={() => {
                                    history.push(`workers/${emp.employee.employee_id}`)
                                }}
                            >
                                {`${emp.employee.last_name}, ${emp.employee.first_name}` || ''}
                            </Link>
                        </div>,
                        position: emp.corporate && emp.corporate.roles && emp.corporate.roles.length >= 1 ? emp.corporate.roles[0].label : '-',
                        location: '-',
                        risk_level: <div className={classes.risklabel}>
                            { emp.risk_level === 'LOW' && <div><span className={classes.legendEntry} style={{ backgroundColor: '#4BAF8B' }}></span>Low Risk</div> }
                            { emp.risk_level === 'MODERATE' && <div><span className={classes.legendEntry} style={{ backgroundColor: '#FE974C'}}></span>Moderate Risk</div> }
                            { emp.risk_level === 'HIGH' && <div><span className={classes.legendEntry} style={{ backgroundColor: '#DD5353'}}></span>High Risk</div> }
                        </div>,
                        days_quarantined: emp.start_date && emp.end_date ? moment(emp.end_date).diff(moment(emp.start_date), 'days') : '-',
                        days_quarantined_left:  (emp.start_date && emp.end_date) && (
                            moment(emp.end_date).isAfter(new Date()) ? 
                                <Typography variant="body2">
                                    {moment(emp.end_date).fromNow(true)} to go
                                </Typography>                                     : 
                                 <Chip size="small" style={{ backgroundColor: '#FE974C', color: '#fff', borderRadius: 5}} label={
                                    <Typography variant="caption" style={{ fontWeight: 700}}>
                                       APPROVE FOR FIT TO WORK
                                    </Typography>
                                } />                              
                        ),
                        doctor_clearance:
                            <IconButton aria-label="view-doctor" className={classes.margin} onClick={
                                handleOpenViewImage(
                                    'doctor', emp.doctor_image.url)} color="primary" size="small">
                                <FontAwesomeIcon icon={faFileImage}/>
                            </IconButton>,
                        hr_clearance:
                            <IconButton aria-label="view-hr" className={classes.margin} onClick={
                                handleOpenViewImage('hr', emp.hr_image.url)
                            } color="primary" size="small">
                                <FontAwesomeIcon icon={faFileImage}/>
                            </IconButton>,
                        action: <QuarantineEmployeeContextMenu
                                    loading={loading}
                                    p={p} employee={emp} handleMoveCategory={handleMoveCategory}
                                    index={index}
                                    handleOpenRemoveFromQuarantine={handleOpenRemoveFromQuarantine}
                                    handleOpenClearanceFeedback={handleOpenClearanceFeedback}
                                />
                    }
                })
                setQuarantinedEmployees({
                    list: newList,
                    count: response.count
                });
                handleUpdateBadge(p.category_list, response.count);
                setLoading(false);
            }, error => {
                setLoading(false);
            }
        );
    }

    useEffect(() => {
        var params = {
            page_size: 1,
            page_offset: 0,
            include_risk_level: false,
            include_clearances: false
        }
        const wfhCount = corporateHealthService.listQuarantinedEmployees(props.company, {
            ...params,
            category_list: 'WORK_FROM_HOME',
            status_list: ['ONGOING']
        });
        const loaCount = corporateHealthService.listQuarantinedEmployees(props.company, {
            ...params,
            category_list: 'LEAVE_OF_ABSENCE',
            status_list: ['ONGOING']
        });
        const slCount = corporateHealthService.listQuarantinedEmployees(props.company, {
            ...params,
            category_list: 'SICK_LEAVE',
            status_list: ['ONGOING']
        });

        const forTestingCount = corporateHealthService.listQuarantinedEmployees(props.company, {
            ...params,
            category_list: null,
            status_list: ['FOR_TESTING']
        });

        const forApprovalCount = corporateHealthService.listQuarantinedEmployees(props.company, {
            ...params,
            category_list: null,
            status_list: ['FOR_APPROVAL']
        });

        const clearedCount = corporateHealthService.listQuarantinedEmployees(props.company, {
            ...params,
            category_list: null,
            status_list: ['CLEARED']
        });

        Promise.all([wfhCount, loaCount, slCount, forTestingCount, forApprovalCount, clearedCount]).then(responses => {
            const newList = responses.map((response) => {
                return response.count ? response.count : 0;
            });

            setCounts(newList);
        })

    }, [state.category_list, state.status_list]);

    useEffect(() => {
        var params = {
            location: '',
            category_list: 'WORK_FROM_HOME',
            status_list: 'ONGOING',
            include_risk_level: true,
            include_clearances: true
        };

        getQuarantinedEmployees(params);
    }, [])

    useEffect(() => {
        if (openAutocomplete) {
            getPartnerLocations(props.company, {page_size: process.env.REACT_APP_DEFAULT_PAGE_SIZE, page_offset: 0});
        }
    }, [openAutocomplete, getPartnerLocations, props.company])


    const handleChangeTab = (category, status = null) => event => {
        setQuarantinedEmployees({
            ...quarantinedEmployees, list: []
        });

        setState({
            ...state,
            category_list: category,
            status_list: status
        });

        // handleUpdateBadge(category, status)


        getQuarantinedEmployees({ ...state, category_list: category, status_list: status})

    }

    const handleMoveCategory = (employee, index, category) => event => {
        var p = {
            quarantine_id: employee.id,
            employee_id: employee.employee.employee_id,
            category: category
        }
        moveQuarantineCategory(props.company, p, employee);
    }

    function moveQuarantineCategory(companyId, params, employee) {
        corporateHealthService.moveQuarantineCategory(companyId, params).then(
            response => {
                var message = '';

                switch (params.category) {
                    case 'WORK_FROM_HOME':
                        message = 'Work From Home';
                        break;
                    case 'LEAVE_OF_ABSENCE':
                        message = 'On Leave';
                        break;
                    case 'SICK_LEAVE':
                        message = 'Sick Leave';
                        break;
                    default:
                        break;
                }

                getQuarantinedEmployees({...state, category_list: employee.category.name});

                setNotification({
                    ...notification,
                    message: `Successfully moved worker category to ${message}.`,
                    show: true,
                    success: true
                });
            }, error => {
                return error;
            }
        );
    }

    function getCompletedQuarantineEmployees(p) {
        corporateHealthService.getCompletedQuarantineEmployees(props.company, p).then(
            response => {
                const newList = response.list.map((emp, index) => {
                    return {
                        id: emp.id || '',
                        name: <div
                            style={{
                                display: 'flex',
                                alignItems: 'center'
                            }}
                        >
                            <Avatar src={emp.employee.profile_pic_url} alt={"user_logo"}
                                    style={{width: 40, height: 40}}/>
                            &nbsp;&nbsp;
                            <Link
                                className={classes.linkColor}
                                component="button"
                                variant="body1"
                                onClick={() => {
                                    history.push(`workers/${emp.employee.employee_id}`)
                                }}
                            >
                                {`${emp.employee.last_name}, ${emp.employee.first_name}` || ''}
                            </Link>
                        </div>,
                        position: emp.corporate && emp.corporate.roles && emp.corporate.roles.length >= 1 ? emp.corporate.roles[0].label : '-',
                        risk_level: <div className={classes.risklabel}>
                            { emp.risk_level === 'LOW' && <div><span className={classes.legendEntry} style={{ backgroundColor: '#4BAF8B' }}></span>Low Risk</div> }
                            { emp.risk_level === 'MODERATE' && <div><span className={classes.legendEntry} style={{ backgroundColor: '#FE974C'}}></span>Moderate Risk</div> }
                            { emp.risk_level === 'HIGH' && <div><span className={classes.legendEntry} style={{ backgroundColor: '#DD5353'}}></span>High Risk</div> }
                        </div>,
                        action:
                            <div className="dropdown">
                                <Button className="dropbtn">
                                    <MoreVertIcon/>
                                </Button>
                                <div className="dropdown-content">
                                    <Paper style={{paddingTop: 10, paddingBottom: 10}}>
                                        { !p.status_list.includes('CLEARED') ? <MenuItem disabled={loading} style={{fontSize: 11}}
                                                  onClick={handleOpenRemoveFromQuarantine(emp)}>Fit to Work
                                            Onsite</MenuItem> : undefined }
                                        {p.category_list !== 'WORK_FROM_HOME' && !p.status_list.includes('CLEARED')  ? <MenuItem style={{fontSize: 11}}
                                                                                          onClick={handleMoveCategory(emp, index, 'WORK_FROM_HOME')}>Move
                                                to Work From Home</MenuItem>
                                            : undefined}
                                        {p.category_list !== 'LEAVE_OF_ABSENCE' && !p.status_list.includes('CLEARED')  ?
                                            <MenuItem disabled={loading} style={{fontSize: 11}}
                                                      onClick={handleMoveCategory(emp, index, 'LEAVE_OF_ABSENCE')}>Move
                                                to On Leave</MenuItem>
                                            : undefined}
                                        {p.category_list !== 'SICK_LEAVE' && !p.status_list.includes('CLEARED')  ?
                                            <MenuItem disabled={loading} style={{fontSize: 11}}
                                                      onClick={handleMoveCategory(emp, index, 'SICK_LEAVE')}>Move to
                                                Sick Leave</MenuItem>
                                            : undefined}
                                        {
                                           p.status_list && p.status_list.includes('FOR_APPROVAL') ? 
                                                    <MenuItem disabled={loading} onClick={handleOpenClearanceFeedback(emp)}
                                                    style={{fontSize: 11}}>Approve / Reject Clearances</MenuItem>
                                        : undefined }
                                    </Paper>
                                </div>
                            </div>
                    }
                })
                setCompletedQuarantineEmployees({
                    list: newList,
                    count: response.count
                });
            }
        )
    }

    const handleChangePage = (event) => {

    }

    const handleRemoveFromList = (employee) => event => {
        var params = {
            employee_id: employee
        }

        corporateHealthService.removeFromQuarantine(props.company, {employee_id: employee}).then(
            response => {
                getQuarantinedEmployees();
            }, error => {

            })
    }

    const [ metrics, setMetrics ] = useState({
        quarantined: null,
        covidTested: null
    })

    useEffect(() => {
        const getQuarantinedEmployeeCovidPositive = healthMetricsService.getQuarantinedEmployeeCovidPositive(props.company)
            .then(data => {
                return {
                    quarantined: {
                        covidPositive: data.covid_positive,
                        total: data.in_quarantine
                    }
                }
            })

        const getEmployeeCovidTestedCount = healthMetricsService.getEmployeeCovidTestedCount(props.company)
            .then(data => {
                return {
                    covidTested: {
                        tested: data.valid_tests_count,
                        notTested: data.health_workers_count - data.valid_tests_count
                    }
                }
            })

        Promise.all([getQuarantinedEmployeeCovidPositive, getEmployeeCovidTestedCount])
            .then(data => {
                setMetrics({
                    ...data[0],
                    ...data[1]
                })
            })
    }, [props.company])

    useEffect(() => {
        let cols
        switch (value) {
            case 4:
                cols = forApprovalColumns
                break;
            case 5:
                cols = completedQuarantineColumns
                break;
            default:
                cols = columns
                break;
        }
        setTabColumns(cols)
    }, [value]);
    

    return (
        <div className={classes.root}>
            {notification.show && notification.error ?
                <ErrorMessage open={notification.show} setOpen={setShowNotification}
                              error={notification.message}/> : undefined}
            {notification.success &&
            <SuccessMessage open={notification.show} setOpen={setShowNotification} message={notification.message}/>}
            {openViewImage && selectedFile ?
                <ViewClearanceImage
                    open={openViewImage} setOpen={setOpenViewImage}
                    handleClickOpen={handleOpenViewImage}
                    handleClose={handleCloseViewImage}
                    type={selectedFile.type}
                    url={selectedFile.url}
                /> : undefined
            }
            <Grid container className={classes.spacer} justify="flex-start" alignItems="flex-start">
                <Typography variant="h5" className={classes.boldText}>
                    Quarantined Workers
                </Typography>
            </Grid>
            {selectedEmployee && openRemoveFromQuarantineDialog ?
                <RemoveFromQuarantine employee={selectedEmployee} company={props.company}
                                      open={openRemoveFromQuarantineDialog}
                                      handleClose={handleCloseRemoveFromQuarantine}/>
                : undefined}
            {employeeForApproval && openClearanceFeedback ?
                <ClearanceFeedback employee={employeeForApproval} company={props.company} open={openClearanceFeedback}
                                   handleClose={handleCloseClearanceFeedback}/>
                : undefined}
            <Grid container direction="row" alignItems="center" justify="center" spacing={3}>
                <Grid item xs={6}>
                    {
                        counts.length >= 1 && (
                            <HealthRiskMetrics
                                title={'Quarantined Workers'}
                                data={
                                    [
                                        {
                                            name: 'Work From Home',
                                            value: counts[0]
                                        },
                                        {
                                            name: 'On Leave',
                                            value: counts[1]
                                        },
                                        {
                                            name: 'Sick Leave',
                                            value: counts[2]
                                        },
                                        {
                                            name: 'For Testing',
                                            value: counts[3]
                                        },
                                        {
                                            name: 'For Approval',
                                            value: counts[4]
                                        },
                                        {
                                            name: 'Cleared',
                                            value: counts[5]
                                        }
                                    ]
                                }
                                backgroundColors={[
                                    '#929EBC',
                                    '#F59D6B',
                                    '#6A99CA',
                                    '#F38181',
                                    '#C4C4C4',
                                    '#1D6C8A'
                                ]}
                            />
                        )
                    }
                </Grid>
                <Grid item xs={6}>
                    {
                        metrics.quarantined && (
                            <HealthRiskMetrics
                                title={"Tested Quarantined Workers"}
                                data={[
                                    {
                                        name: 'Negative',
                                        value:  metrics.quarantined.total - metrics.quarantined.covidPositive
                                    },
                                    {
                                        name: 'Positive',
                                        value: metrics.quarantined.covidPositive
                                    }
                                ]}
                                backgroundColors={['#F59D6B', '#E8685E']}
                            />
                        )
                    }
                </Grid>
                {/* <Grid item xs={4}>
                    {
                        metrics.covidTested && (
                            <QuarantinedMetrics
                                description={"Total number of workers tested for COVID-19"}
                                labels={[
                                    'Tested',
                                    'Not Tested'
                                ]}
                                counts={[
                                    metrics.covidTested.tested,
                                    metrics.covidTested.notTested
                                ]}
                                backgroundColors={['#85CBBE', '#EEBE6A']}
                            />
                        )
                    }
                </Grid> */}
            </Grid>
            <Grid container direction="row" alignItems="center" justify="center" spacing={3}>
                <Grid item container justify="flex-start" alignItems="flex-start" xs={6}>
                    <Typography variant="body1" className={classes.textLeft} style={{fontWeight: "bold"}}>
                        All Locations
                    </Typography>
                </Grid>
                <Grid item container justify="flex-end" alignItems="flex-end" xs={6}>
                    <FormControl className={clsx(classes.margin, classes.textField)} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-search-name">Search Name</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-password"
                            type={'text'}
                            label="Search Name"
                            value={state.search_name}
                            onChange={handleChange('search_name')}
                            onKeyPress={(ev) => {
                                if (ev.key === "Enter") {
                                    handleChange('search_name')
                                }
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="search"
                                        edge="end"
                                    >
                                        <SearchIcon/>
                                    </IconButton>
                                </InputAdornment>
                            }
                            labelWidth={70}
                        />
                    </FormControl>
                </Grid>
                {/* <Grid item container justify="flex-end" alignItems="flex-end" xs={3}>
                            <Autocomplete
                                  id="asynchronous-demo"
                                  style={{ width: 300 }}
                                  open={openAutocomplete}
                                  onOpen={() => {
                                    setOpenAutocomplete(true);
                                  }}
                                  onClose={() => {
                                    setOpenAutocomplete(false);
                                  }}
                                  disableClearable
                                  getOptionSelected={(option, value) => option.location_label === value.location_label}
                                  getOptionLabel={(option) => (option.location_label || '')}
                                  options={options}
                                  loading={loading}
                                  value={state.loc}
                                  onChange={handleChangeLocation}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      onChange={({ target }) => {

                                        if(searchTimer) {
                                            clearTimeout(searchTimer);
                                        }
                                        setSearchTimer(
                                            setTimeout(() => getPartnerLocations(props.company, { search_text: target.value, page_offset: 0, page_size: 20}), 1000)
                                        )}
                                      }
                                      label="Search location"
                                      variant="outlined"
                                      InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                          <React.Fragment>
                                            {loadingLocations ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                          </React.Fragment>
                                        ),
                                      }}
                                    />
                                  )}
                                />
                  </Grid> */}
            </Grid>
            <Grid container spacing={5}>
                <Grid item container direction="row" alignItems="center" justify="center">
                    <Grid item xs={12} container>
                        <div className={classes.root}>
                                <TabContext value={value}>
                            <AppBar position="static" elevation={0} className={classes.rootTabs}>
                                <Tabs value={value} onChange={handleChangeValue} elevation={0}
                                      aria-label="simple tabs example"
                                      style={{color: '#5A5A5A', backgroundColor: '#F7F7F7'}}>
                                    <Tab
                                        label={
                                            <StyledBadge label={`Work From Home`} styling={classes.wfhCount}
                                                         count={counts[0]}/>
                                        } onClick={handleChangeTab('WORK_FROM_HOME', ['ONGOING'])} {...a11yProps(0)} />
                                    <Tab label={
                                        <StyledBadge label={`Leave of Absence`} styling={classes.loaCount}
                                                     count={counts[1]}/>
                                    } onClick={handleChangeTab('LEAVE_OF_ABSENCE', ['ONGOING'])} {...a11yProps(1)} />
                                    <Tab label={
                                        <StyledBadge label={`Sick Leave`} styling={classes.slCount} count={counts[2]}/>

                                    } onClick={handleChangeTab('SICK_LEAVE', ['ONGOING'])} {...a11yProps(2)}/>
                                    <Tab label={<StyledBadge label={`For Testing`} styling={classes.forTestingCount}
                                                         count={counts[3]}/>}
                                        onClick={handleChangeTab(null, ['FOR_TESTING'])} {...a11yProps(5)} />
                                    <Tab label={
                                        <StyledBadge label={`For Approval`} styling={classes.forApprovalCount}
                                                     count={counts[4]}/>
                                    } onClick={handleChangeTab(null, ['FOR_APPROVAL'])} {...a11yProps(3)}/>
                                    <Tab label={<StyledBadge label={`Cleared from Quarantine`} styling={classes.clearedCount}
                                                     count={counts[5]}/>}
                                        onClick={handleChangeTab(null, ['CLEARED'])} {...a11yProps(4)} />
                                </Tabs>
                            </AppBar>
                            <TabPanel value={value} index={0}>
                                {loading && <LinearProgress color="primary"/>}
                                <UserList
                                    columns={tabColumns || []}
                                    rows={loading ? [] : quarantinedEmployees.list}
                                    loader={loading}
                                    count={quarantinedEmployees.count || 0}
                                    handlePage={handleChangePage}
                                />
                            </TabPanel>
                            </TabContext>
                            
                        </div>
                    </Grid>
                    {/* <Grid item xs={12}>
                          <UserList columns={columns || []} rows={quarantinedEmployees.list || []} count={quarantinedEmployees.count || 0} handlePage={handleChangePage}/>
                      </Grid> */}
                </Grid>
                <Grid item container alignItems="center" justify="center">
                    <Grid item xs={12}>
                        <DailyLogs
                            partnerId={props.company}
                            logType={["QUARANTINE"]}
                            title={"Quarantine Logs"}
                            showFilters={{
                                name: {
                                    md: 6
                                },
                                dateRange: {
                                    md: 3
                                }
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default QuarantinedEmployees;
