import React, { useEffect, useState } from 'react';
import { Tooltip } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import './stackBarChart.css';


export default function (props) {
    const { data } = props;
    var colors = [ '#73C2A6', '#FE974C', '#D94135' ];
    
    return (
        <React.Fragment>
            <div className="progress progress-stacked">
                <Tooltip title={"Low Risk Workers: " + data.low_count.toFixed(1) + "%" + ` ( ${data.low} )`} placement="top-start">
                    <span style={{ width: `${data.low_count}%`, backgroundColor: '#73C2A6'}}>
                        <span style={{ position: 'relative', top: 8, fontSize: 12, fontWeight: 700}}>{ data.low }</span>
                    </span>
                </Tooltip>
                <Tooltip title={"Moderate Risk Workers: " + data.moderate_count.toFixed(1) + "%" + ` ( ${ data.moderate} )`} placement="top-start">
                    <span style={{ width: `${data.moderate_count}%`, backgroundColor: '#FE974C' }}>
                        <span style={{ position: 'relative', top: 8, fontSize: 12, fontWeight: 700}}>{ data.moderate }</span>
                    </span>
                </Tooltip>
                <Tooltip title={"High Risk Workers: " + data.high_count.toFixed(1) + "%" + ` ( ${ data.high } )`} placement="top-start">
                    <span style={{ width: `${data.high_count}%`, backgroundColor: '#D94135' }}>
                        <span style={{ position: 'relative', top: 8, fontSize: 12, fontWeight: 700}}>{ data.high }</span>
                    </span>
                </Tooltip>
            </div>
        </React.Fragment>
    )
}