import { faThList } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@material-ui/core";
import { Dialog } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { IconButton } from "@material-ui/core";
import { DialogTitle } from "@material-ui/core";
import { DialogContent } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { Agenda } from "@zennya/web-component-library/src/components/Agenda";
import AgendaProvider, {
  actions,
  AgendaContext,
} from "@zennya/web-component-library/src/components/Agenda/AgendaContext";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { views } from "react-big-calendar/lib/utils/constants";
import corporateEventsService from "../../services/corporate-events.service";

const useStyles = makeStyles((theme) => ({
  close: {},
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

const ResourceAgenda = ({ event, company }) => {
  const classes = useStyles();
  const [state, dispatch] = React.useContext(AgendaContext);

  const [slots, setSlots] = useState([]);
  const [cohorts, setCohorts] = useState([]);
  const [services, setServices] = useState([]);

  useEffect(() => {
    async function getServicesAndCohorts() {
      let response = await corporateEventsService.getCohortsList(company);
      setCohorts(response.list ?? []);
      response = await corporateEventsService.getServiceTypes(company);
      // console.log("📢[eventsAgenda.js:70]:", response);
      setServices(
        (response.list ?? []).map((s) => {
          return {
            ...s,
            title: s.label,
          };
        })
      );
    }
    getServicesAndCohorts();
  }, [company]);

  useEffect(() => {
    const mappedSlots = (event.services || [])
      .map((service) =>
        (service.slots ?? []).map((slot, id) => {
          return {
            start: moment(slot.start_date).toDate(),
            end: moment(slot.start_date).add("0", "h").toDate(),
            id: `svc-${service.type?.id}-slot-${id}`,
            title: (slot.cohorts ?? []).map((c) => c.label).join(", "),
            resourceId: service.type?.id,
          };
        })
      )
      .flat();

    setSlots(mappedSlots);
  }, [event?.services]);

  useEffect(() => {
    dispatch({
      payload: { events: slots },
      type: actions.LOAD_DATA,
    });
  }, [dispatch, slots]);

  return (
    <Agenda
      timeslots={1}
      step={60}
      lockSlotStartTime={"07:00"}
      lockSlotEndTime={"23:59"}
      popup={true}
      views={[views.DAY, views.AGENDA]}
      defaultView={views.DAY}
      style={{ flex: "1 1 auto" }}
      resources={services}
      resourceAccessor={"resourceId"}
      resourceIdAccessor={"id"}
      resourceTitleAccessor={"title"}
      date={event.start_date}
    />
  );
};

function WrappedEventAgenda(props) {
  return (
    <AgendaProvider>
      <ResourceAgenda {...props} />
    </AgendaProvider>
  );
}

function ResourceAgendaDialog({ event, company }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        variant="outlined"
        color="primary"
        startIcon={<FontAwesomeIcon icon={faThList} />}
      >
        View Slots
      </Button>
      <Dialog
        maxWidth={"lg"}
        fullWidth
        open={open}
        onClose={() => setOpen(false)}
        disableEnforceFocus
        scroll="paper"
      >
        <DialogTitle
          id="allocations"
          onClose={() => setOpen(false)}
          disableTypography
          className={classes.header}
        >
          <Typography>Event: {event.label}</Typography>
          <IconButton className={classes.close} onClick={() => setOpen(false)}>
            <Close />
          </IconButton>
        </DialogTitle>

        <DialogContent dividers>
          <WrappedEventAgenda event={event} company={company} />
        </DialogContent>
      </Dialog>
    </>
  );
}
export { WrappedEventAgenda as ResourceAgenda, ResourceAgendaDialog };
