import {
    TablePagination,
} from "@material-ui/core";
import React, { useCallback, useState } from "react";

import {
    DataGrid,
} from "@zennya/web-component-library/src/components/DataGrid";
import DataGridProvider, {
    actions as gridActions,
    DataGridContext,
} from "@zennya/web-component-library/src/components/DataGrid/DataGridContext";

import eventsData from "./eventsData";

function EventsList(props) {

    const [state, dispatch] = React.useContext(DataGridContext);
    const [columns, setColumns] = React.useState([]);
    const [rows, setRows] = React.useState([]);
    const [params, setParams] = useState({
        partner: props.company,
        page_size: 10,
        page_offset: 0,
    });
    const [pages, setPages] = useState({ count: 0, pages: 0 });
    const [page, setPage] = useState(0);



    React.useEffect(() => {
        async function loadEvents() {
            const response = await eventsData.getProcessedEventsList(params);

            setColumns(response.columns);
            setRows(response.rows);
            setPages({
                count: response.count,
                pages: Math.ceil(response.count / params.page_size),
            });
        };

        loadEvents();
    }, [props.company, params]);

    React.useEffect(() => {
        dispatch({
            payload: {
                columns,
            },
            type: gridActions.LOAD_COLUMNS,
        });
    }, [columns]);

    React.useEffect(() => {
        dispatch({
            payload: {
                rows,
            },
            type: gridActions.LOAD_ROWS,
        });
    }, [rows]);





    const onChangePage = useCallback((evt, newPage) => {
        setParams({
            ...params,
            page_offset: (newPage + 1) * params.page_size - params.page_size,
        });
        setPage(newPage);
    }, []);

    const onChangeRowsPerPage = useCallback((evt) => {
        setParams({
            ...params,
            page_size: parseInt(evt.target.value, 10),
        });
    }, []);





    return (
        <div style={{ display: "flex", flexDirection: "column", height: "70vh" }}>
            <DataGrid
                style={{ height: '100%' }}
                containerStyle={{
                    flex: '1 1 0',
                }}
                showSelector
                filterable={false}
                columns={columns}
                rows={rows}
                gridProps={{
                    rowHeight: 45,
                }}
            />
            <TablePagination
                component="div"
                count={pages.count}
                page={page}
                onChangePage={onChangePage}
                onChangeRowsPerPage={onChangeRowsPerPage}
                rowsPerPage={params.page_size}
            />
        </div>
    );
}

export default function WrapEventsList(props) {
    return (
        <DataGridProvider>
            <EventsList {...props} />
        </DataGridProvider>
    );
}