import { makeStyles } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';

const useStyles = makeStyles(theme => ({
  version: {
    position: 'fixed',
    bottom:0,
    left:0,
    zIndex: 99999,
    color: "#FFF",
    backgroundColor: "#465A6D",
    fontWeight: 700
  }
}));
const Versioning = ({}) => {
  const classes = useStyles()
  return <div className={classes.version}>
    {process.env.VERSION} {process.env.COMMIT_HASH}
  </div>
}

export {Versioning}