import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { AppBar, Button, Grid, LinearProgress, Paper, Typography, Tabs, Tab } from '@material-ui/core';
import { GetLoggedInUser } from '../context/UserContext';
import DataTable from '../components/DataTable';
import reportService from '../services/report.service';
import TabPanel from '../components/tabPanel';
import { healthRiskReportColumns, quarantinedReportColumns, covidTestReportColumns, covidScreeningColumns, dailyTempReadingColumns, vaccinationReportsColumns } from './reportColumns';
import moment from 'moment';
import ErrorMessage from '../components/errorMessage';
import CustomDialog from './../healthRisk/healthRiskDialog';
import GenerateCovidTestResults from './generateCovidTestResults';
import GenerateCovidScreening from './generateCovidScreening';
import GenerateDailyTemp from './generateDailyTemp';



const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4),
        flexGrow: 1
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        minHeight: '70vh'
    },
    responsiveTable: {
        [theme.breakpoints.down('sm')]: {
            maxWidth: '60vw'
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: '72vw'
        },
        [theme.breakpoints.up('xl')]: {
            maxWidth: '80vw'
        }
    }
}));

function a11yProps(index) {
    return {
      id: `report-tab-${index}`,
      'aria-controls': `report-tabpanel-${index}`,
    };
  }

const ReportList = (props) => {
    const classes = useStyles();
    const userData = GetLoggedInUser()
    const [ isLoadingReports, setIsLoadingReports ] = useState(false)
    const [ initialLoading, setInitialLoading ] = useState(false)
    const [ page, setPage ] = useState(0)
    const [ loading, setLoading ] = useState(false);
    const [ error, setError ] = useState({ message: ''});
    const [ openError, setOpenError ] = useState(false);
    const [ openDialog1, setOpenDialog1 ] = useState(false);
    const [ openDialog2, setOpenDialog2 ] = useState(false);
    const [ openDialog3, setOpenDialog3 ] = useState(false);
    const [ healthRiskReport, setHealthRiskReport ] = useState({
        list: [],
        count: 0
    });
    const [ quarantinedWorkerReport, setQuarantinedWorkerReport ] = useState({
        list: [],
        count: 0
    });
    const [ covidTestReport, setCovidTestReport ] = useState({
        list: [],
        count: 0
    })
    const [ covidScreeningReport, setCovidScreeningReport ] = useState({
        list: [],
        count: 0
    })
    const [dailyTempReadingReport, setDailyTempReadingReport ] = useState({
        list: [],
        count: 0
    })

    const [vaccinationReport, setVaccinationReport ] = useState({
        list:[],
        count:0
    })
    const [ tabValue, setTabValue ] = useState(0);
    const [ sortParams, setSortParams ] = useState(
        {
            changeColumn: '',
            val: ''
        }
    );
    const [ params, setParams ] = useState({
        page_size: 10,
        page_offset: 0
    });
    const [ params2, setParams2 ] = useState({
        page_size: 10,
        page_offset: 0
    })

    const [ state, setState ] = useState({
        search_text: ''
    })

    const [ reports, setReports ] = useState({
        list: [],
        count: 0
    })


    const columns = [
        {
            id: 'name',
            name: 'name',
            label: 'Name'
        },
        {
            id: 'description',
            name: 'description',
            label: 'Description'
        },
        {
            id: 'action',
            name: 'action',
            label: 'Action'
        }
    ];


    const handleChangeTab = (event, newValue) => {
        setTabValue(newValue);
    }

    const handleChange = name => event => {
        setState({
            ...state,
            [name]: event.target.value
        })
    }

    const handleAfterSuccess = event => {
        //getCompanyEvents();
    }

    function handleChangePage(newPage) {
        setParams({
            ...params,
            page_offset: ((newPage + 1) * params.page_size) - params.page_size
        })

    }

    function handleChangePage2(newPage) {
        setParams2({
            ...params2,
            page_offset: ((newPage + 1) * params2.page_size) - params2.page_size
        })

    }

    function handleChangePageVaccinationReport(newPage){
        setParams({
            ...params,
            page_offset:(newPage+1)*params.page_size -params.page_size
        })
        getVaccinationReport({...params,page_offset:(newPage+1)*params.page_size})
    }

    const handleSort = (params) => {
        setSortParams(params);
    }

    const handleFilterChange = (params) => {

    }

    const handleExportHealthRiskReport = event => {
        setLoading(true);
        reportService.downloadHealthRiskReport({ id: props.company}).then(
            response => {

                var downloadFile = `Health-Risk-Report-${moment().format('MM-DD-YYYY-HH:mm:ss')}.csv`;
                setLoading(false);
                  var file = new Blob([response.data], {
                    type: response.headers['content-type'] || 'application/octet-stream',
                  });
                  var saving = document.createElement('a');
                  saving.href = URL.createObjectURL(file);
                  saving.download = (
                    downloadFile
                  );
                  saving.click();
            }
        ).catch(error => {
            setLoading(false);
            if(error.response && error.response.data && error.response.data.message) setError({ message: error.response.data.message.en || ''})
            else setError({ message: 'Unable to export file'})
            setOpenError(true);
        })
    }

    const handleExportVaccinationReport = event => {
        setLoading(true)
        reportService.downloadVaccinationReport({id: props.company}).then(
            response => {
                var downloadFile = `Vaccination-Report-${moment().format('MM-DD-YYYY-HH:mm:ss')}.csv`;
                setLoading(false);
                  var file = new Blob([response.data], {
                    type: response.headers['content-type'] || 'application/octet-stream',
                  });
                  var saving = document.createElement('a');
                  saving.href = URL.createObjectURL(file);
                  saving.download = (
                    downloadFile
                  );
                  saving.click();
            }
        ).catch(error => {
            setLoading(false);
            if(error.response && error.response.data && error.response.data.message) setError({ message: error.response.data.message.en || ''})
            else setError({ message: 'Unable to export file'})
            setOpenError(true);
        })
    }

    const handleExportQuarantinedWorkersReport = event => {
        setLoading(true);
        reportService.downloadQuarantinedWorkerReport({ id: props.company}).then(
            response => {
                  var downloadFile = `Quarantined-Workers-Report-${moment().format('MM-DD-YYYY-HH:mm:ss')}.csv`;
                  setLoading(false);
                  var file = new Blob([response.data], {
                    type: response.headers['content-type'] || 'application/octet-stream',
                  });
                  var saving = document.createElement('a');
                  saving.href = URL.createObjectURL(file);
                  saving.download = (
                    downloadFile
                  );
                  saving.click();
            }
        ).catch(error => {
            setLoading(false);
            if(error.response && error.response.data && error.response.data.message) setError({ message: error.response.data.message.en || ''})
            else setError({ message: 'Unable to export file'})
            setOpenError(true);
        })
    }

    const getHealthRiskReport = p => {
        var prms = {
            id: p.id,
            page_size: params.page_size,
            page_offset: params.page_offset
        }

        reportService.getHealthRiskReport(prms).then(
            response => {
                setHealthRiskReport({
                    list: response.list,
                    count: response.count
                })
            }
        ).catch(error => {

        })
    }

    const getQuarantinedWorkersReport = p => {
        var prms = {
            id: p.id,
            page_size: params2.page_size,
            page_offset: params2.page_offset
        }

        reportService.getQuarantinedWorkerReport(prms).then(
            response => {
                var newList = response.list.map((w, index) => {
                    return {
                        worker_id: w.worker_id,
                        worker_name: w.worker_name,
                        worker_role: w.worker_role,
                        worker_department: w.worker_department,
                        date_quarantined: moment(w.date_entered_quarantine).format('YYYY/MM/DD hh:mm:ss'),
                        quarantine_reason: w.quarantine_reason,
                        quarantine_category: w.quarantine_category
                    }
                })
                setQuarantinedWorkerReport({
                    list: newList,
                    count: response.count
                })
            }
        ).catch(error => {

        })
    }

    const getCovidTestResultsReport = p => {
        var prms = {
            id: p.id,
            page_size: 10,
            page_offset: 0
        }
        reportService.getCovidTestResults(prms).then(
            response => {
                var newList = response.list.map((r, index) => {
                    return {
                        ...r,
                        test_date: moment(r.test_date).format('YYYY/MM/DD hh:mm:ss'),
                        valid_until: moment(r.valid_until).format('YYYY/MM/DD hh:mm:ss')
                    }
                });


                setCovidTestReport({
                    list: newList,
                    count: response.count
                });
            }
        ).catch(error => {

        })
    }

    const getCovidScreeningReport = p => {
        var prms = {
            id: p.id,
            page_size: 10,
            page_offset: 0
        }
        reportService.getCovidScreening(prms).then(
            response => {
                var newList = response.list.map((r, index) => {
                    return {
                        ...r,
                        assessment_date: moment(r.test_date).format('YYYY/MM/DD hh:mm:ss'),
                    }
                });


                setCovidScreeningReport({
                    list: newList,
                    count: response.count
                });
            }
        ).catch(error => {

        })
    }

    const getDailyTempReadingReport = p => {
        var prms = {
            id: p.id,
            page_size: 10,
            page_offset: 0,
            start_date: moment(new Date("2019-10-20")).startOf('day').toISOString(),
            end_date: moment(new Date()).endOf('day').toISOString(),
        }
        reportService.getDailyTempReading(prms).then(
            response =>
            {
                var newList = response.list.map((r, index) =>
                {
                    return {
                        ...r,
                        entrance_date: moment(r.employee_entrance_date).format('YYYY/MM/DD hh:mm:ss'),
                    }
                });


                setDailyTempReadingReport({
                    list: newList,
                    count: response.count
                });
            }
        ).catch(error =>
        {

        })
    }

    const getVaccinationReport = p => {
        reportService.getVaccinationReport(p).then(
            response=>{
                setVaccinationReport({
                    list: response.list,
                    count:response.count
                })
            }
        )
    }

    useEffect(() => {
        var p = {
            id: props.company
        }

        if(tabValue || params || params2) {
            switch(tabValue) {
                case 0: getHealthRiskReport(p); break;
                case 1: getQuarantinedWorkersReport(p); break;
                case 2: getCovidTestResultsReport(p); break;
                case 3: getCovidScreeningReport(p); break;
                case 4: getDailyTempReadingReport(p); break;
                case 5: getVaccinationReport({...params,id:props.company}); break;
                default: break;
            }
        }

        // var newList = [
        //     {
        //         id: 1,
        //         name: 'Health Risk Report',
        //         description: '',
        //         action: <Button variant="contained" color="primary" style={{ color: '#fff'}}>
        //             Generate
        //         </Button> 
        //     },
        //     {
        //         id: 2,
        //         name: 'Quarantined Workers Report',
        //         description: '',
        //         action: <Button variant="contained" color="primary" style={{ color: '#fff'}}>
        //             Generate
        //         </Button> 
        //     }
        // ];

        // setReports({
        //     list: newList,
        //     count: 1
        // });
    }, [tabValue, params, params2]);

    return (
        <div className={classes.root}>
            {/* <Grid container spacing={3}>
                <Grid item xs={12} container justify="flex-start" alignItems="flex-start">
                    <Typography variant="h5" style={{ fontWeight: 700}}>
                        Reports List
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    { isLoadingReports && <LinearProgress color="primary" /> }
                    <DataTable count={reports.count} columns={columns}
                    canFilter={false} company={props.company}
                    canSort={false} handleSort={handleSort}
                    handlePage={handleChangePage} rows={reports.list || []} />
                </Grid>
            </Grid> */}
            { error ?  <ErrorMessage error={error} open={openError} setOpen={setOpenError} /> : ''}
            {
                openDialog1 && <CustomDialog open={openDialog1}
                handleClose={() => { setOpenDialog1(false)}}
                mw="md"
                title={'COVID-19 Test Results'}
                subtitle={'Apply filters or click DOWNLOAD REPORT to export CSV file.'}
                >
                    <GenerateCovidTestResults  company={props.company}/>
                </CustomDialog> 
            }
            {
                openDialog2 && <CustomDialog open={openDialog2}
                handleClose={() => { setOpenDialog2(false)}}
                mw="md"
                title={'COVID-19 Screening Results'}
                subtitle={'Apply filters or click DOWNLOAD REPORT to export CSV file.'}
                >
                    <GenerateCovidScreening company={props.company}/>
                </CustomDialog> 
            }
            {
                openDialog3 && <CustomDialog open={openDialog3}
                    handleClose={() => { setOpenDialog3(false) }}
                    mw="md"
                    title={'Gate Entry Report'}
                    subtitle={'Apply filters or click DOWNLOAD REPORT to export CSV file.'}
                >
                    <GenerateDailyTemp company={props.company} />
                </CustomDialog>
            }
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <Grid item xs={12} container justify="flex-start" alignItems="flex-start">
                            <Grid item xs={12} align="left">
                                <Typography variant="h5" style={{ fontWeight: 700}}>
                                    Reports List
                                </Typography>
                            </Grid>
                            <Grid item xs={12} align="left">
                                <Typography variant="body1">
                                    The following data below only serves as sample data for preview purposes.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} align="left">
                                <AppBar position="static" color="default">
                                    <Tabs value={tabValue} 
                                        onChange={handleChangeTab} 
                                        aria-label="report-tabs"
                                        indicatorColor="primary"
                                        variant="fullWidth">
                                            <Tab label="Health Risk Report" { ...a11yProps(1) } />
                                            <Tab label="Quarantined Workers Report" { ...a11yProps(2) } />
                                            <Tab label="COVID Test Results Report" { ...a11yProps(3) } />
                                            <Tab label="COVID Screening Report" { ...a11yProps(4) } />
                                            <Tab label="Gate Entry Report" {...a11yProps(5)} />
                                            <Tab label="Vaccination Report" {...a11yProps(6)} />
                                    </Tabs>
                                </AppBar>
                                <TabPanel value={tabValue} index={0}>
                                    <Grid item container spacing={3}>
                                        <Grid item xs={12} align="right">
                                            <Button variant="contained" color="primary" style={{ color: 'white'}} onClick={handleExportHealthRiskReport} disabled={loading}>
                                                Export
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="h5">
                                                Health Risk Report
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <DataTable count={healthRiskReport.count} columns={healthRiskReportColumns}
                                                canFilter={false} company={props.company}
                                                selectable={'none'} canPaginate={false}
                                                canSort={false} handleSort={handleSort}
                                                handlePage={handleChangePage} rows={healthRiskReport.list || []} />
                                        </Grid>
                                    </Grid>
                                </TabPanel>
                                <TabPanel value={tabValue} index={1}>
                                    <Grid item container spacing={3}>
                                        <Grid item xs={12} align="right">
                                            <Button variant="contained" color="primary" style={{ color: 'white'}} disabled={loading} onClick={handleExportQuarantinedWorkersReport}>
                                                Export
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="h5">
                                                Quarantined Workers Report
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <DataTable count={quarantinedWorkerReport.count || 0} columns={quarantinedReportColumns}
                                                canFilter={false} company={props.company} selectable={'none'} 
                                                canSort={false} handleSort={handleSort} canPaginate={false}
                                                handlePage={handleChangePage2} rows={quarantinedWorkerReport.list || []} />
                                        </Grid>
                                    </Grid>
                                </TabPanel>
                                <TabPanel value={tabValue} index={2}>
                                    <Grid item container spacing={3}>
                                        <Grid item xs={12} align="right">
                                            <Button variant="contained"
                                                onClick={(ev) => { setOpenDialog1(true); }}
                                                color="primary" style={{ color: 'white'}} disabled={loading}>
                                                Export
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="h5">
                                                COVID Test Results Report
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <DataTable count={covidTestReport.count} columns={covidTestReportColumns}
                                                canFilter={false} company={props.company}
                                                selectable={'none'} canPaginate={false}
                                                canSort={false} handleSort={handleSort}
                                                handlePage={handleChangePage} rows={covidTestReport.list || []} />
                                        </Grid>
                                    </Grid>
                                </TabPanel>
                                <TabPanel value={tabValue} index={3}>
                                    <Grid item container spacing={3}>
                                        <Grid item xs={12} align="right">
                                            <Button variant="contained"
                                                onClick={(ev) => { setOpenDialog2(true); }}
                                                color="primary" style={{ color: 'white'}} disabled={loading}>
                                                Export
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="h5">
                                                COVID Screening Report
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} className={classes.responsiveTable}>
                                            <DataTable count={covidScreeningReport.count} columns={covidScreeningColumns}
                                                canFilter={false} company={props.company}
                                                selectable={'none'} canPaginate={false}
                                                canSort={false} handleSort={handleSort}
                                                styles={{ width: 500}}
                                                handlePage={handleChangePage} rows={covidScreeningReport.list || []} />
                                        </Grid>
                                    </Grid>
                                </TabPanel>
                                <TabPanel value={tabValue} index={4}>
                                    <Grid item container spacing={3}>
                                        <Grid item xs={12} align="right">
                                            <Button variant="contained"
                                                onClick={(ev) => { setOpenDialog3(true); }}
                                                color="primary" style={{ color: 'white' }} disabled={loading}>
                                                Export
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="h5">
                                                Gate Entry Report
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} className={classes.responsiveTable}>
                                            <DataTable count={dailyTempReadingReport.count} columns={dailyTempReadingColumns}
                                                canFilter={false} company={props.company}
                                                selectable={'none'} canPaginate={false}
                                                canSort={false} handleSort={handleSort}
                                                styles={{ width: 500 }}
                                                handlePage={handleChangePage} rows={dailyTempReadingReport.list || []} />
                                        </Grid>
                                    </Grid>
                                </TabPanel>
                                <TabPanel value={tabValue} index={5}>
                                    <Grid item container spacing={3}>
                                        <Grid item xs={12} align="right">
                                            <Button variant="contained" color="primary" style={{ color: 'white'}} onClick={handleExportVaccinationReport} disabled={loading}>
                                                Export
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="h5">
                                                Vaccination Report
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} className={classes.responsiveTable}>
                                            <DataTable 
                                                count={vaccinationReport.count} 
                                                columns={vaccinationReportsColumns}
                                                canFilter={false} company={props.company}
                                                selectable={'none'} 
                                                canPaginate={true}
                                                canSort={false} 
                                                handleSort={handleSort}
                                                styles={{ width: 500 }}
                                                handlePage={handleChangePageVaccinationReport} rows={vaccinationReport.list || []} />
                                        </Grid>
                                    </Grid>
                                </TabPanel>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>        
            </Grid>
        </div>
    )

}

export default ReportList;