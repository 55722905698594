import corporateEventsService from "./../services/corporate-events.service";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";

function formatEventData(event) {
    return {
        ...event,
        id: event.id,
        label: event.label,
        location: event.location.label,
        type: event.type.label,
        date_created: event.date_created,
        start_date: event.start_date,
        end_date: event.end_date,
        client_count: event.client_count,
        status: event.status,
    }
};

function formatCalendarEventData(event) {
    return {
        ...event,
        id: event.id,
        title: event.label,
        location: event.location.label,
        type: event.type.label,
        date_created: event.date_created,
        start: moment(event.start_date).toDate(),
        end: moment(event.end_date).toDate(),
        client_count: event.client_count,
        status: event.status,
        variant: getVariantFromStatus(event.status),
    }
};

function getVariantFromStatus(status) {
    switch (status) {
        case 'PENDING':
            return 'info';
        case 'ACTIVE':
            return 'secondary';
        case 'FINALIZING':
            return 'primary';
        case 'FINISHED':
            return 'success'
        case 'REJECTED':
        case 'CANCELLED':
            return 'error';
        case 'REQUESTED':
            return 'warning';
        default:
            return 'info';
    }
}

export function getCalendarDateFormat() {
    return 'MMMM DD, YYYY hh:mm a';
};


// Ideally, these should be retrieved from the server
const eventStatusList = [
    'PENDING',
    'ACTIVE',
    'FINALIZING',
    'FINISHED',
    'CANCELLED',
    'REQUESTED',
    'REJECTED',
];

const eventsData = {
    getProcessedEventsList(params) {
        return corporateEventsService.getEventsList(params)
            .then(data => {
                var tableData = {
                    draggable: true,
                    count: data.count,
                };
                /* Construct the columns */
                tableData.columns = [
                    {
                        "key": "id",
                        "colId": "col1",
                        "name": "ID",
                        "type": "text",
                        "sortable": true,
                    },
                    {
                        "key": "label",
                        "colId": "col2",
                        "name": "Label",
                        "type": "text",
                        "sortable": true,
                    },
                    {
                        "key": "location",
                        "colId": "col3",
                        "name": "Location",
                        "type": "text",
                        "sortable": true,
                    },
                    {
                        "key": "type",
                        "colId": "col4",
                        "name": "Type",
                        "type": "text",
                        "sortable": true,
                    },
                    {
                        "key": "date_created",
                        "colId": "col5",
                        "name": "Date Created",
                        "type": "date",
                        "sortable": true,
                        cellRenderer(props) {
                            return <span>{moment(props.row["date_created"]).format('lll')}</span>
                        },
                    },
                    {
                        "key": "start_date",
                        "colId": "col6",
                        "name": "Event Date",
                        "type": "date",
                        "sortable": true,
                        cellRenderer(props) {
                            return <span>{moment(props.row["start_date"]).format('LL')}</span>
                        },
                    },
                    {
                        "key": "end_date",
                        "colId": "col7",
                        "name": "Event Time",
                        "type": "date",
                        "sortable": true,
                        cellRenderer(props) {
                            return <span>{moment(props.row["start_date"]).format('LT') + ' - ' + moment(props.row["end_date"]).format('LT')}</span>
                        },
                    },
                    {
                        "key": "client_count",
                        "colId": "col8",
                        "name": "Employee Count",
                        "type": "number",
                        "sortable": true,
                    },
                    {
                        "key": "status",
                        "colId": "col9",
                        "name": "Status",
                        "type": "text",
                        "sortable": true,
                    },
                ]

                /* Construct the rows */
                tableData.rows = data.list.map(function (event) {
                    const row = formatEventData(event);
                    return row;
                });

                return tableData;
            });
    },
    getEventsGroupedByLocation(params) {
        return corporateEventsService.getEventsList(params)
            .then(data => {
                var locations = {};

                for (let event of data.list) {
                    if (!locations[event.location.id]) {
                        locations[event.location.id] = {
                            location: event.location,
                            events: [],
                        };
                    }

                    locations[event.location.id].events.push(formatEventData(event));
                }
            });
    },
    getCalendarEvents(params, locFilter) {
        return corporateEventsService.getEventsList(params)
            .then(data => {
                const filteredEvents = !!locFilter ? data.list.filter(event => event.location.id === locFilter) : data.list
                // console.log("🚀 ~ file: eventsData.js ~ line 154 ~ getCalendarEvents ~ filteredEvents", filteredEvents)
                return filteredEvents.map(function (event) {
                    return formatCalendarEventData(event);
                });
            });
    },
    mockInsert: async (params, eventData) => {
        const data = await corporateEventsService.getEventsList(params)
        data.list.push({
            ...eventData,
            location: {
                label: ""
            },
            type: {
                label: ""
            },
            id: uuidv4()
        })
        return data.list.map(function (event) {
            return formatCalendarEventData(event);
        });
    }


};

export default eventsData;