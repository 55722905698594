import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import { Avatar, Box, Button, Chip, Divider, Link, TextField, IconButton,
    Input, InputAdornment, LinearProgress, Menu, MenuItem,
    FormControl, Grid, InputLabel, Paper, Select, Typography } from '@material-ui/core';
import UserList from './../employees/userList';
import corporatePackagesService from './../services/corporate-packages.service';
import { useHistory } from 'react-router-dom';


const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4),
        flexGrow: 1,
        color: '#000',
    },
    spacer: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    boldText: {
        fontWeight: 700,
        color: '#333'
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        width: '100%'
    },
    margin: {
        margin: theme.spacing(1),
        marginRight: 0
    },
    small: {
        width: theme.spacing(4),
        height: theme.spacing(4),
    },
}));

const CorpPackages = (props) => {
    const classes = useStyles();
    const [ rows, setRows ] = useState([]);
    const [ state, setState ] = useState({
        search_text: '',
        count: 10
    });
    const history = useHistory();

    const columns = [
        {
            id: 'id',
            label: 'ID',
            minWidth: 20,
            align: 'left',
        },
        {
            id: 'name',
            label: 'Service Name',
            minWidth: 'auto',
            align: 'left',
            noTooltip: true,
        },
        {
            id: 'price',
            label: 'Price',
            minWidth: 120,
            align: 'left',
        },
        {
            id: 'discount',
            label: 'Discount',
            minWidth: 120,
            align: 'left',
        },
        {
            id: 'discounted_price',
            label: 'Discounted Price',
            minWidth: 120,
            align: 'left',
        },
        {
            id: 'start_date',
            label: 'Start Date',
            minWidth: 100,
            align: 'left',
        },
        {
            id: 'end_date',
            label: 'End Date',
            minWidth: 100,
            align: 'left',
        },
        {
            id: 'status',
            label: 'Status',
            minWidth: 85,
            align: 'left',
            noTooltip: true
        }
    ];

    const colors = [
        '#E5B6AF',
        '#ABB9E0',
        '#B0CAE6',
        '#E9CBB5',
        '#A7C6B7',
        '#CFD0C0',
        '#E9C5A3',
        '#BECBDC',
        '#E1C4BF',
        '#D8C39E'
    ];

    const handleChange = name => event => {
        setState({
          ...state,
          [name]: event.target.value
        })
    }

    useEffect(() => {
        var pkgs = corporatePackagesService.getPkgs();


        var newList = pkgs.map((pkg, index) => {
            var img = require(`../assets/svg/corp-packages/package-${pkg.id}.svg`);

            return {
                ...pkg,
                name: <Link
                    component="button"
                    variant="body1"
                    style={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        textDecoration: 'none',
                        textAlign: 'left'
                    }}
                    onClick={() => {
                        history.push(`packages/${pkg.id}`)
                    }}>
                        {/* <Avatar alt={pkg.name} style={{ backgroundColor: colors[index]}}>
                            { pkg.name.charAt(0) }
                        </Avatar> */}
                        <Avatar className={classes.small} alt={pkg.name} style={{ backgroundColor: colors[index]}} src={ img || ''} />
                        <Typography variant="body2" style={{ marginLeft: 5}}>
                            { pkg.name }
                        </Typography>
                    </Link>,
                    price: pkg.price.toLocaleString('en-US', { style: 'currency', currency: 'PHP'}),
                    discounted_price: pkg.discounted_price.toLocaleString('en-US', { style: 'currency', currency: 'PHP'}),
                    status: <React.Fragment>
                    {
                        pkg.status === 'ACTIVE' ?
                            <Chip size="small" style={{ backgroundColor: '#C4EDC6', color: '#54A557', border: '1px solid #C4EDC6', borderRadius: 5}} label={
                                <Typography variant="caption" style={{ fontWeight: 700}}>
                                    ACTIVE
                                </Typography>
                            } />
                            : (
                            <Chip size="small" label={
                                <Typography variant="caption" style={{ fontWeight: 700}}>
                                    EXPIRED
                                </Typography>
                            } style={{ backgroundColor: '#FFDBD8', color: '#E8685E', border: '1px solid #FFDBD8', borderRadius: 5}}/>
                        )}
                </React.Fragment>
                
            }
        });

        setRows(newList);
    }, []);

    return (
        <div className={classes.root}>
            <Grid container alignItems="flex-start" justify="flex-start" style={{ width: '100%'}}>
                <Typography variant="h4" className={classes.boldText}>
                    Corporate Services
                </Typography>
                <Grid item container xs={12} style={{ width: '100%'}}>
                    <Paper className={classes.paper}>
                        <Grid item container xs={12} justify="flex-end" alignItems="flex-end">
                            <FormControl>
                                <TextField
                                    onChange={handleChange('search_text')}
                                    value={state.search_text || ''}
                                    label="SEARCH PACKAGE NAME"
                                    id="outlined-start-adornment"
                                    className={clsx(classes.margin, classes.textField)}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">
                                            <SearchIcon />
                                        </InputAdornment>,
                                    }}
                                    variant="outlined"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <UserList columns={columns || []} rows={rows} count={state.count || 0} rowsPerPage={20} />
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    )
}

export default CorpPackages;

