import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import {
    Button,
    IconButton, TextField,
    FormControl,
    InputLabel,
    MenuItem,
    LinearProgress,
    Link,
    Menu,
    NativeSelect,
    Grid,
    Typography,
    Avatar
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import corporateHealthService from './../services/corporate-health.service';
import UserList from './userList';
import AssignAsChecker from './assignAsChecker';
import BootstrapInput from './../components/bootstrapInput';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import CsvImport from './../components/csvImport';
import CsvImportCheckerResponseDialog from "../components/CsvImportCheckerResponseDialog";
import ErrorMessage from './../components/errorMessage';
import SuccessMessage from './../components/successMessage';
import clsx from 'clsx';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import './dropdown.css';
import DailyLogs from "../dashboard/DailyLogs";
import { useHistory } from 'react-router-dom';

export function EmployeeCellContextMenu({handleAction, employee, label}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleContextClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <React.Fragment>
      <Button className="dropbtn" onClick={(evt)=> handleContextClick(evt)}>
        <MoreVertIcon />
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        <MenuItem style={{fontSize: 11}} onClick={handleAction(employee)}>{label}</MenuItem>
      </Menu>
    </React.Fragment>
  )

}
const useStyles = makeStyles(theme => ({
    root: {
      padding: theme.spacing(4),
      flexGrow: 1,
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%'
      },
      [theme.breakpoints.up('md')]: {
        maxWidth: 1440
      },
      [theme.breakpoints.up('xl')]: {
        maxWidth: 1920
      }
    },
    officeInfoTitle: {
      padding: 10
    },
    officeInfoSection: {
      padding: 10
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 200,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    textLeft: {
        textAlign: 'left'
    },
    marginSpacer: {
        marginRight: 10
    },
    margin: {
      margin: theme.spacing(2)
    },
    errorIcon: {
      color: theme.palette.error.main,
      backgroundColor: 'white',
      borderColor: theme.palette.error.main
    },
    linkColor: {
        color: theme.palette.link.main
    }
}));

export default function HealthCheckers(props) {
  const classes = useStyles();
  const history = useHistory()
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [ openMenu, setOpenMenu ] = useState(false);
//   const { columns, rows } = props;
  // const [ page, setPage ] = useState(0);
  const [ company, setCompany ] = useState({
      addresses: []
  });
  const [ employees, setEmployees ] = useState([]);
  const [ openAssignAsChecker, setOpenAssignAsChecker ] = useState(false);
  const [ count, setCount ] = useState(0);
  const [ checkers, setCheckers ] = useState([]);
  const [ importResponseModal, setImportResponseModal] = useState(false);
  const [ importResponse, setImportResponse] = useState({ successes: 0 })
  const [ locations, setLocations ] = useState([  { id: 0, label: "All Locations" } ]);
  const [ selectedChecker, setSelectedChecker ] = useState('');
  const [ state, setState ] = useState({
    assigned_gates: [],
    checkers: [{ gate: ''}]
  });
  const [ loaders, setLoaders ] = useState({
    employees: false,
    checkers: false,
    partner: false,
    locations: false
  });
  const [ notification, setNotification ] = useState({
    message: '',
    show: false,
    success: false,
    error: false
  })
  const [ params, setParams ] = useState({
    pageSize: 20,
    pageOffset: 0
  })
  const [ anchorEl, setAnchorEl ] = useState(null);
  const open = Boolean(anchorEl);
  const [ anchorEls, setAnchorEls ] = useState([]);

const setShowNotification = (value) => {
    setNotification({
        ...notification,
        show: value
    })
}

  const columns = [
    { id: 'name', label: 'Name', minWidth: 'auto', align: 'left' },
    {
      id: 'position',
      label: 'Position',
      minWidth: 'auto',
      align: 'left',
      format: (value) => value.toLocaleString('en-US'),
    },
    {
      id: 'type',
      label: 'Type',
      minWidth: 50,
      align: 'left'
    },
    {
      id: 'location',
      label: 'Location',
      minWidth: 'auto',
      align: 'left',
      format: (value) => value.toLocaleString('en-US'),
    },
    {
      id: 'gate',
      label: 'Floor - Gate',
      minWidth: 85,
      align: 'left'
    },
    {
      id: 'unassign',
      label: 'Actions',
      minWidth: 100,
      noTooltip: true,
      align: 'left'
    }
  ];

  function handleChangePage(newPage) {
    if (newPage > page ) {
        setParams({
            ...params,
            pageOffset: params.pageOffset + params.pageSize
        })
        getCheckers(props.company, { page_size: params.pageSize, page_offset:((newPage + 1) * params.pageSize) + params.pageSize})
    } else if (newPage < page) {
        setParams({
            ...params,
            pageOffset: params.pageOffset - params.pageSize
        })
        getCheckers(props.company, { page_size: params.pageSize, page_offset: ((newPage + 1) * params.pageSize) - params.pageSize})
    }
    setPage(newPage);
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChange = name => event => {
    setState({
      ...state,
      [name]: event.target.value
    });

  }

  const handleLocationSelection = (event, value) => {
    let loc = value && value.id ? value.id : 0;

    setState({
      ...state,
      locations: loc
    });

    getCheckers(props.company, { page_size: 20, page_offset: 0, location: loc})
  }


  const handleClickMenu = (index) =>  (event) => {
    setAnchorEl(event.currentTarget);
    anchorEls[index] = event.currentTarget;

    setAnchorEls(anchorEls);
  };

  const handleCloseMenu = (index) => () => {
    anchorEls[index] = null;

    setAnchorEl(null);
    setAnchorEls(anchorEls);
  };

  useEffect(() => {
    getPartnerDetails(props.company);
    getCheckers(props.company, { page_size: process.env.REACT_APP_DEFAULT_PAGE_SIZE, page_offset: 0});
    getPartnerLocations(props.company);
  }, []);

  function getPartnerDetails(companyId) {
    setLoaders({
      ...loaders,
      partner: true
    });
    corporateHealthService.getPartnerDetails(companyId).then(
      response => {
        setCompany(response);
        // if(!props.company_details.company) {
        //   props.company_details.update(response);
        // }
        setLoaders({
          ...loaders,
          partner: false
        });
      }, error => {
        setLoaders({
          ...loaders,
          partner: true
        });
      }
    )
  }

  function importHealthCheckers (file, setModal, setLoader) {
    setLoader(true)

    let params = new FormData()
    params.append('file', file)
    params.append('partner_id', props.company);

    corporateHealthService.importHealthCheckers(props.company, params)
        .then((result) => {
            setNotification({
                ...notification,
                message: 'Batch Import Successful',
                show: true,
                success: true
            })

            setImportResponse(
              {
                successes: result.successes,
                errorsEmployee: result.errors_employee,
                errorsExisting: result.errors_existing,
                errorsGate: result.errors_gate,
                errorsLocation: result.errors_location,
                errorsMissing: result.errors_missing,
                errorsNotLinked: result.errors_not_linked,
                processedCount: result.processed_count
              }
            )
            setModal(false)
            getCheckers(props.company);

            setImportResponseModal(true)
        })
        .catch((err) => {
            setNotification({
                ...notification,
                message: { message: 'Unable to import CSV' },
                show: true,
                error: true
            })
        })
        .finally(() => {
            setLoader(false)
        })
}

  function getPartnerLocations(companyId) {
    setLoaders({
      ...loaders,
      locations: true
    });
    return corporateHealthService.getPartnerLocations(companyId, {}).then(
      response => {
        setLocations(locations.concat(response.list));
        console.log(response.list);
        setLoaders({
          ...loaders,
          locations: false
        });
        return response;
      }, error => {
        setLoaders({
          ...loaders,
          locations: false
        });
      }
    )
  }

  const getCompanyEmployees = useCallback((locationId) => {
      var params = {
          // page_size: 20,
          // page_offset: 0,
          partner: props.company,
          locations: locationId
      };

      corporateHealthService.getPartnerEmployees(props.company, params)
          .then(response => {
                  var newList = response.list.map((employee, index) => {
                      return {
                          id: employee.employee.id,
                          idNumber: employee.employee.idNumber,
                          name: employee.employee.name,
                          role: employee.employee.roles.length >= 1 ? employee.employee.roles[0].label : '',
                          gender: employee.employee.gender === 'male' ? 'Male' : 'Female'
                      }
                  });

                  setEmployees({
                      list: newList,
                      count: response.count
                  });

                  setCheckers(newList.list);
              }
          )
  }, [props.company])

  function getEmployees(companyId) {
    var params = {
      partner: companyId,
      fetch_types: 'EMPLOYEE',
      page_size: process.env.REACT_APP_DEFAULT_PAGE_SIZE,
      page_offset: 0
    };

    setLoaders({
      ...loaders,
      employees: true
    });

    corporateHealthService.getEmployeesByRoleType(params).then(
      response => {
        setLoaders({
          ...loaders,
          employees: false
        });
        setEmployees(
          {
            list: response.employee_list,
            count: response.employee_count
          }
        );
      }, error => {
        setLoaders({
          ...loaders,
          employees: false
        });
      }
    ).finally(() => {
      setLoaders({
        ...loaders,
        employees: false
      });
    });
  }


  function getCheckers(companyId, p = { page_size: process.env.REACT_APP_DEFAULT_PAGE_SIZE, page_offset: 0}) {
    var params = {
      partner: companyId,
      fetch_types: 'CHECKER',
      page_size: p.page_size,
      page_offset: p.page_offset,
      locations: p.location
    };

    setLoaders({
      ...loaders,
     checkers: true
    });
    return corporateHealthService.getEmployeesByRoleType(params).then(
      response => {
        setLoaders({
          ...loaders,
          checkers: false
        });
        setCheckers(response.checker_list);
        setCount(response.checker_count);
        setState({
          ...state,
          checkers: response.checker_list
        });
        postProcessHealthCheckerData(response.checker_list);

        return response;
      }, error => {
        setLoaders({
          ...loaders,
          checkers: false
        });
      }
    ).finally(() => {
      setNotification({
        ...notification,
        message: 'Unable to retrieve list of health checkers',
        show: true,
        success: false
     })
    });
  }

  const postProcessHealthCheckerData = (response) => {
      var newList = response.map((employee, index) => {
          setAnchorEls(anchorEls.concat([null]));

          return {
            id: employee.employee.id,
            idNumber: employee.employee.idNumber,
            type: employee.gates && employee.gates.length >= 1 ? 'Gate-based' : 'Roving',
            name: (
                // <div
                //     style={{
                //         display: 'flex',
                //         alignItems: 'center'
                //     }}
                // >
                //     <Avatar src={employee.employee.profile_pic_url} alt={"user_logo"} style={{ width: 40, height: 40 }} />
                //     &nbsp;&nbsp;
                //     <Link
                //         className={classes.linkColor}
                //         component="button"
                //         variant="body1"
                //        
                //         { employee.employee.name }
                //     </Link>
                // </div>
                <React.Fragment>
                  <Link className={classes.linkColor}
                    style={{
                      display: 'inline-flex',
                      alignItems: 'center',
                      textDecoration: 'none'
                    }}
                    component="button"
                    onClick={() => {
                       history.push(`checkers/${employee.employee.id}`)
                      }}>
                      <Avatar src={employee.employee.profile_pic_url} alt={"user_logo"} style={{ width: 40, height: 40 }} />
                      <Typography variant="body2" style={{ marginLeft: 5, textAlign: 'left'}}>
                        { employee.employee.name }
                      </Typography>
                    </Link>
                </React.Fragment>
                ),
            role: employee.employee.roles.length >= 1 ? employee.employee.roles[0].label : '',
            location: <React.Fragment>
                {
                 employee.locations && employee.locations.length >= 1 ? employee.locations.map((option, index) => {
                    return (
                      option ?
                      <Link
                          className={classes.linkColor}
                          style={{ textAlign: 'left'}}
                          key="index"
                          component="button"
                          variant="body1"
                          onClick={() => {
                              history.push(`locations/${option.id}`)
                          }}>
                          { option.label || ''}  <br />
                        </Link>
                    : undefined)
                 }) : '-'
               }
            </React.Fragment>,
            gate: <React.Fragment>
               {
                 employee.gates && employee.gates.length >= 1 ? employee.gates.map((option, index) => {
                    return (
                      option ?
                        <Typography key={index} varint="body2">
                          { option.floor } - { option.description }
                        </Typography>
                    : undefined)
                 }) : '-'
               }
            </React.Fragment>,
            unassign: <EmployeeCellContextMenu handleAction={handleUnassign} employee={employee} label='UNASSIGN'/>
          }
      });

      setCheckers(newList);
  }

  const handleOpenAssignHealthChecker = (event) => {
    setSelectedChecker(null);
    setOpenAssignAsChecker(true);
  }

  const handleCloseAssignHealthChecker = (event) => {
    setOpenAssignAsChecker(false);
    getCheckers(props.company, locations);
  }

  const handleReassign = (employee) => event => {
    setSelectedChecker(employee);
    setOpenAssignAsChecker(true);
  }

  const handleUnassign = (employee) => event => {
    var params = {
      employee_id: employee.employee.id
    }

    setLoaders({
      ...loaders,
      checkers: true
    });

    corporateHealthService.unassignHealthChecker(props.company, params).then(
      response => {
        setLoaders({
          ...loaders,
          checkers: false
        });
        setNotification({
          ...notification,
          message: 'Successfully unassigned health checker to location.',
          show: true,
          success: true
       });
       getCheckers(props.company, { page_size: 20, page_offset: 0});
      }, error => {
        setLoaders({
          ...loaders,
          checkers: false
        });
        setNotification({
          ...notification,
          message: { message: 'Unable to unassign health checker' },
          show: true,
          error: true
        });
      }
    );
  }

  return (
    <div className={classes.root}>
        {notification.show && notification.error ? <ErrorMessage open={notification.show} setOpen={setShowNotification} error={notification.message} /> : undefined }
        { notification.success && <SuccessMessage open={notification.show} setOpen={setShowNotification} message={notification.message} /> }
        <CsvImportCheckerResponseDialog
          modal={importResponseModal}
          setModal={setImportResponseModal}
          successes={importResponse.successes}
          errorsEmployee={importResponse.errorsEmployee}
          errorsExisting={importResponse.errorsExisting}
          errorsGate={importResponse.errorsGate}
          errorsLocation={importResponse.errorsLocation}
          errorsMissing={importResponse.errorsMissing}
          errorsNotLinked={importResponse.errorsNotLinked}
          processedCount={importResponse.processedCount}
        />
        <Grid container justify="space-between">
          <Grid item xs={4}>
            <Typography variant="h5" className={classes.textLeft} style={{ fontWeight: 700}}>
              Health Checker List
            </Typography>
          </Grid>
          <Grid item xs={8} align="right">
            <Button variant="outlined" color="primary" size="small" onClick={handleOpenAssignHealthChecker}>
                Assign Health Checker
              </Button>
              { openAssignAsChecker ?
                <AssignAsChecker checker={selectedChecker || null} company={company} open={openAssignAsChecker} handleClose={handleCloseAssignHealthChecker}/> :
                undefined
              }
              <CsvImport
                    downloadUrl={`https://dnjqko642wsuu.cloudfront.net/public/corporate/bulkhealth.csv`}
                    fileName={`Bulk Import Health Checker.csv`}
                    className={clsx(classes.marginSpacer, classes.textLeft)}
                    title={"Bulk Import Health Checkers"}
                    buttonText={"Bulk Import Health Checkers"}
                    subtitle={"Please add your detailed info in."}
                    instructionMessage={"Before uploading the worker list, please make sure that the file has the following:"}
                    requiredFields={["employee id", "location name", "gate name"]}
                    action={importHealthCheckers}
                />
          </Grid>
        </Grid>
        <br />
        <Grid container spacing={5}>
            <Grid item container xs={12}>
                <Grid item xs={3} container justify="flex-end" alignItems="flex-end" style={{ marginBottom: 10}}>
                    <Autocomplete
                        options={locations}
                        getOptionLabel={(option) => option.label}
                        getOptionSelected={(option, value) => option.id === value.id}
                        onChange={handleLocationSelection}
                        disableClearable={true}
                        noOptionsText="No locations found"
                        renderInput={(params) => <TextField {...params} fullWidth label="Locations" variant="outlined" />}
                        loading={loaders.locations}
                        defaultValue={locations}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    { (loaders.partner ||
                        loaders.locations ||
                        loaders.employees ||
                        loaders.checkers) && <LinearProgress color="primary" />}
                    <UserList columns={columns} rows={checkers || []} count={count} handlePage={handleChangePage}/>
                </Grid>
            </Grid>
            <Grid item container xs={12}>
                <Grid item xs={12}>
                    <DailyLogs
                        partnerId={props.company}
                        logType={["TEMP_READING"]}
                        title={"Health Checker Logs"}
                        showFilters={{
                            name: {
                                md: 3
                            },
                            dateRange: {
                                md: 3
                            },
                            location:  {
                                md: 4
                            }
                        }}
                    />
                </Grid>
            </Grid>
        </Grid>
    </div>
  );
}
