import React, {useCallback, useEffect, useState} from 'react';
import {Grid, IconButton, LinearProgress} from "@material-ui/core";
import UserList from "../employees/userList";
import corporateHealthService from "../services/corporate-health.service";
import {Cancel as CancelIcon, CheckCircle as CheckCircleIcon} from "@material-ui/icons";
import ErrorMessage from "../components/errorMessage";
import SuccessMessage from "../components/successMessage";
import ApproveLinkingRequestDialog from "./approveLinkingRequestDialog";
import RejectLinkingRequestDialog from "./rejectLinkingRequestDialog";
import ViewIDImage from './viewIDImage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileImage } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import DataTable from '../components/DataTable';

export default function (props) {

    const { partnerId, columns, status, search } = props
    const [ requests, setRequests ] = useState({ list: [], count:  0})
    const [ isLoadingRequests, setRequestsLoader ] = useState(false)
    const [ isUpdatingRequests,  setUpdateRequestsLoader ] = useState(false)
    const [ page, setPage ] = useState(0)
    const [ paginationParams, setPaginationParams ] = useState({
        page_size: process.env.REACT_APP_DEFAULT_PAGE_SIZE,
        page_offset: 0
    })
    const [ employee, setEmployee ] = useState()
    const [ openViewImage, setOpenViewImage ] = useState(false);
    const [ approvalDialog, setApprovalDialog ] = useState(false)
    const [ rejectionDialog, setRejectionDialog ] = useState(false)
    const [ notification, setNotification ] = useState({
        message: '',
        show: false,
        isError: false
    })
    const [ selectedFile, setSelectedFile] = useState('');
    const [ counts, setCounts ] = useState({
        linked: '',
        unliked: '',
    });

    function createNotification(message, isError) {
        setNotification({
            message: message,
            show: true,
            isError: isError
        })
    }

    function setShowNotification (value) {
        setNotification({
            ...notification,
            show: value
        })
    }

    function handlePaginationParams (name, value) {
        setPaginationParams({
            ...paginationParams,
            [name]: value
        })
    }

    // function handleChangeEmployeePage(newPage) {
    //     if(page < newPage) {
    //         handlePaginationParams("page_offset", paginationParams.page_size + paginationParams.page_offset)
    //     } else {
    //         handlePaginationParams("page_offset", paginationParams.page_offset - paginationParams.page_size)
    //     }

    //     setPage(newPage);
    // }

    function handleChangeEmployeePage(newPage) {
        // setPaginationParams({
        //     ...paginationParams,
        //     page_offset: ((newPage + 1) * paginationParams.page_size) - paginationParams.page_size
        // });

        handlePaginationParams('page_offset', ((newPage + 1) * paginationParams.page_size) - paginationParams.page_size )
    }

    function isPendingStatus() {
        return status === "PENDING_APPROVAL"
    }

    function isNoMatchStatus() {
        return status === "NO_MATCH"
    }

    function showApprovalDialog(employee) {
        setEmployee(employee)
        setApprovalDialog(true)
    }

    function showRejectionDialog(employee) {
        setEmployee(employee)
        setRejectionDialog(true)
    }

    function approveLinkingRequest (requestId) {
        if (isUpdatingRequests)  {
            return
        }

        setEmployee(null)

        updateLinkingRequest(requestId, {
            status: "VALID"
        })
    }

    function rejectLinkingRequest (requestId, reason) {
        if (isUpdatingRequests)  {
            return
        }

        setEmployee(null)

        updateLinkingRequest(requestId, {
            status: "INVALID",
            denied_reason: reason
        })
    }

    const updateLinkingRequest = useCallback((id, params) => {
        setUpdateRequestsLoader(true)

        corporateHealthService.updateLinkingRequest(id, params)
            .then(() => {
                createNotification("Link request updated")
            })
            .catch(err => {
                createNotification("Unable to update link request", true)
            })
            .finally(() => {
                setUpdateRequestsLoader(false)
            })
    }, [])

    const handleOpenViewImage = (url) => event => {
        setSelectedFile({
          url: url
        });
        setOpenViewImage(true);
      }

      const handleCloseViewImage = event => {
        setSelectedFile(null);
        setOpenViewImage(false);
      }


    useEffect(() => {
        var filterParams = {
            ...paginationParams,
            sort_by: 'dateCreated',
            sort_order: 'asc',
            partnerId,
            search
        }

        if(status) filterParams.status = status;

        if(search) filterParams.search = search;

        getLinkingRequests(filterParams);
    }, [partnerId, isUpdatingRequests, paginationParams, status, search])


    const getLinkingRequests = (params) => {
        var params = {
            ...paginationParams,
            sort_by: "dateCreated",
            sort_order: "asc",
            status: status,
            partnerId,
            search
        };

        setRequestsLoader(true)
        var func = corporateHealthService.getLinkingRequests;
        if (status == "NO_MATCH") {
            delete params.status;
            func = corporateHealthService.getNoMatchLinkingRequests;
        }
        
        func(params).then(response => {
                const newList = response.list.map(request => {
                    return {
                        // id: request.id,
                        name: `${request.info.last_name}, ${request.info.first_name}`,
                        cardNumber: request.card_number,
                        referenceCardNumber: (request.ref_card_number) ? request.ref_card_number : "--",
                        reason: request.rejection_reason || '-',
                        lastUpdated: request.status_last_updated ? 
                            `${ moment(request.status_last_updated).format("MMM D, Y hh:mm A") }` : '-',
                        image: request.card_photo_url
                            ? (
                                <IconButton aria-label="view-image" onClick={
                                    handleOpenViewImage(
                                     request.card_photo_url)} color="primary" size="small">
                                   <FontAwesomeIcon icon={faFileImage} />
                                </IconButton>
                            )
                            : <span style={{ fontStyle: 'italic' }}>None</span>
                        ,
                        actions: (isPendingStatus() && (
                            <React.Fragment>
                                <IconButton onClick={() => showApprovalDialog({
                                    id: request.id,
                                    name: `${request.info.last_name}, ${request.info.first_name}`,
                                    cardNumber: request.card_number
                                })} disabled={isUpdatingRequests} size="small">
                                    <CheckCircleIcon color="primary" />
                                </IconButton>
                                <IconButton onClick={() =>
                                    showRejectionDialog({
                                        id: request.id,
                                        name: `${request.info.last_name}, ${request.info.first_name}`,
                                        cardNumber: request.card_number
                                    })
                                } disabled={isUpdatingRequests} size="small">
                                    <CancelIcon color="error" />
                                </IconButton>
                            </React.Fragment>
                        )) || (isNoMatchStatus() && (
                            <React.Fragment>
                                <IconButton onClick={() =>
                                    showRejectionDialog({
                                        id: request.id,
                                        name: `${request.info.last_name}, ${request.info.first_name}`,
                                        cardNumber: request.card_number
                                    })
                                } disabled={isUpdatingRequests} size="small">
                                    <CancelIcon color="error" />
                                </IconButton>
                            </React.Fragment>
                        ))
                    }
                });

                setRequests({
                    list: newList,
                    count: response.count
                });
            })
            .finally(() =>  {
                setRequestsLoader(false)
            })
    }

    const handleAfterSuccess = event => {

    }


    return (
        <React.Fragment>
            { openViewImage && selectedFile ? <ViewIDImage
                  open={openViewImage} setOpen={setOpenViewImage}
                  handleClickOpen={handleOpenViewImage}
                  handleClose={handleCloseViewImage}
                  url={selectedFile.url}
                /> : undefined
            }
            { isPendingStatus() && notification.show && notification.isError && <ErrorMessage open={notification.show} setOpen={setShowNotification} error={{message: notification.message}}/>}
            { isPendingStatus() && notification.show && !notification.isError && <SuccessMessage open={notification.show} setOpen={setShowNotification} message={notification.message}/>}
            { isPendingStatus() && approvalDialog &&
            employee &&
            <ApproveLinkingRequestDialog
                employee={employee}
                open={approvalDialog}
                setOpen={setApprovalDialog}
                actionCallback={approveLinkingRequest}
            />
            }
            { (isPendingStatus() || isNoMatchStatus()) && rejectionDialog &&
            employee &&
            <RejectLinkingRequestDialog
                employee={employee}
                open={rejectionDialog}
                setOpen={setRejectionDialog}
                actionCallback={rejectLinkingRequest}
            />
            }
            { (isLoadingRequests || isUpdatingRequests) && <LinearProgress color="primary" />}
                <DataTable
                    company={props.company}
                    listCall={handleAfterSuccess}
                    loaders={isLoadingRequests}
                    columns={columns}
                    canBulkUpdate={false}
                    rows={requests.list || []}
                    count={requests.count}
                    canFilter={false}
                    filterType={'dropdown'}
                    // handleFilterChange={handleFilterChange}
                    handlePage={handleChangeEmployeePage}
                />
            {/* <UserList
                columns={columns || []}
                rows={requests.list || []}
                count={requests.count || 0}
                rowsPerPage={20}
                loader={isLoadingRequests}
                handlePage={handleChangeEmployeePage}
            /> */}

        </React.Fragment>
    )
}
