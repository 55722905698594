import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  margin: {
    height: theme.spacing(3),
  },
}));

const marks = [
  {
    value: 100,
    label: '100 meters',
  },
];

function valuetext(value) {
  return `${value} meters`;
}

export default function PerimeterSlider(props) {
  const classes = useStyles();
  const { radius, setRadius } = props;

  const handleChange = (event, newValue) => {
    setRadius(newValue);
  };


  return (
    <div className={classes.root}>
      <Slider
        value={radius}
        onChange={handleChange}
        getAriaValueText={valuetext}
        aria-labelledby="discrete-slider-custom"
        step={50}
        valueLabelDisplay="auto"
        marks={marks}
        min={0}
        max={3000}
      />
    </div>
  );
}
