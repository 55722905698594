import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  List,
  ListItem,
  ListItemText,
  Paper,
  Popover,
  Popper,
  Typography,
} from "@material-ui/core";
import { Agenda } from "@zennya/web-component-library/src/components/Agenda";
import AgendaProvider, {
  actions as agendaActions,
  AgendaContext,
} from "@zennya/web-component-library/src/components/Agenda/AgendaContext";
import { views as calendarViews } from "react-big-calendar/lib/utils/constants";
import isEmpty from "lodash.isempty";
import React, { useCallback, useContext, useEffect, useState } from "react";
import corporateEventsService from "../services/corporate-events.service";
import EventCreate from "./eventCreate";
import EventDetail from "./eventDetail";
import eventsData from "./eventsData";
import EventCell from "./eventCell";
import CohortDetails from "./cohortDetails";
import moment from "moment";
import { useHistory, useLocation } from "react-router-dom";

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  agenda: {
    '& .rbc-event': {
      backgroundColor: theme.palette.primary.dark,
      borderColor: theme.palette.grey[700],
    }
  }
}));
function EventAgenda(props) {
  const classes = useStyles()
  const { location } = props;
  const history = useHistory();
  const domLocation = useLocation();

  const [state, dispatch] = useContext(AgendaContext);
  const [events, setEvents] = useState([]);
  const [params, setParams] = useState({
    partner: props.company,
    page_size: 100,
    page_offset: 0,
  });

  const [event, setEvent] = useState();
  const [selectedEvent, setSelectedEvent] = useState();
  const [showEventDetails, setShowEventDetails] = useState(false);
  const [showCreateEvent, setShowCreateEvent] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const [timeSlot, setTimeSlot] = useState();

  const [cohorts, setCohorts] = useState([]);
  const [services, setServices] = useState([]);
  const [cohort, setCohort] = useState();
  const [loading, setLoading] = useState(false);
  const [openCohortDetail, setOpenCohortDetail] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);

  const loadEvents = useCallback(
    async (location) => {
      if (!isEmpty(location)) {
        dispatch({
          type: agendaActions.SET_LOADING,
        });
        const response = await eventsData.getCalendarEvents(
          params,
          location.id
        );
        dispatch({
          type: agendaActions.SET_DONE_LOADING,
        });
        setEvents(response);
      }
    },
    [dispatch, params]
  );

  // useEffect(() => {
  //   // console.log("📢[eventsAgenda.js:78]:", props.location);
  //   setLocation(props.location);
  // }, [props.location]);

  useEffect(() => {
    loadEvents(location);
  }, [loadEvents, location]);

  useEffect(() => {
    setShowEventDetails(!isEmpty(event));
  }, [event]);

  useEffect(() => {
    async function getServicesAndCohorts() {
      let response = await corporateEventsService.getCohortsList(props.company);
      setCohorts(response.list ?? []);
      response = await corporateEventsService.getServiceTypes(props.company);
      // console.log("📢[eventsAgenda.js:70]:", response);
      setServices(response.list ?? []);
    }
    getServicesAndCohorts();
  }, [props.company]);

  useEffect(() => {
    dispatch({
      payload: {
        events: events,
      },
      type: agendaActions.LOAD_DATA,
    });
  }, [dispatch, events]);

  const handleSelectEvent = useCallback(
    (event, element) => {
      setSelectedEvent(event);
      if (moment(event.start).startOf("day").isBefore(moment())) {
        setAnchorEl(null);
        // setShowEventDetails(true);
        // setEvent(event);
        history.push({
          pathname: `/events/${event.id}`,
          search: domLocation.search,
        });
      } else {
        setAnchorEl(anchorEl ? null : element.currentTarget);
      }
    },
    [anchorEl, domLocation.search, history]
  );

  async function submitNewEvent({ event }) {
    setLoading(true);
    const evtObject = {
      ...event,
      location: location.id,
      partner: props.company,
    };
    // console.log("📢[eventsAgenda.js:122]:", evtObject);
    await corporateEventsService.createEventRequest(evtObject);
    loadEvents(location);
    setShowCreateEvent(false);
    setLoading(false);
  }

  const cancelEventRequest = useCallback(
    async (event) => {
      await corporateEventsService.cancelEvent(event.id);
      loadEvents(location);
      setShowEventDetails(false);
    },
    [loadEvents, location]
  );

  const onSelectSlot = ({ start, end }) => {
    if (moment(start).startOf("day").isBefore(moment())) {
      return;
    }
    if (isEmpty(location)) {
      setShowAlert(true);
      return;
    }
    setTimeSlot({ start, end });
    setShowCreateEvent(true);
  };

  const openExistingEventSelector = Boolean(anchorEl);
  return (
    <>
      <Popover
        open={openExistingEventSelector}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Paper>
          <List component="nav" aria-label="secondary mailbox folders">
            <ListItem
              button
              onClick={() => {
                setAnchorEl(null);

                history.push({
                  pathname: `/events/${selectedEvent.id}`,
                  search: domLocation.search,
                });
              }}
            >
              <ListItemText primary={`View Event : ${selectedEvent?.label}`} />
            </ListItem>
            <ListItem
              button
              onClick={() => {
                setAnchorEl(null);
                onSelectSlot({
                  start: selectedEvent.start,
                  end: selectedEvent.end,
                });
              }}
            >
              <ListItemText primary="Create Event in same timeslot" />
            </ListItem>
          </List>
        </Paper>
      </Popover>
      {!!cohort ? (
        <CohortDetails
          company={props.company}
          open={!!cohort}
          handleClose={() => setCohort(null)}
          isAdding={false}
          selectedCohort={cohort}
        />
      ) : null}
      {/* <Dialog
        open={showEventDetails}
        keepMounted
        fullWidth
        onClose={() => {
          setEvent(null);
          setShowEventDetails(false);
        }}
      >
        <EventDetail
          event={event}
          onClose={() => {
            setEvent(null);
            setShowEventDetails(false);
          }}
          onCancelEvent={(event) => {
            cancelEventRequest(event);
          }}
          openCohort={(cohort) => setCohort(cohort)}
        />
      </Dialog> */}
      <Dialog open={showAlert} keepMounted onClose={() => setShowAlert(false)}>
        <DialogContent>
          <DialogContentText>Select a location first</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowAlert(false)} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <EventCreate
        open={showCreateEvent}
        keepMounted
        fullWidth
        cohorts={cohorts}
        services={services}
        start={timeSlot?.start}
        end={timeSlot?.end}
        onClose={() => setShowCreateEvent(false)}
        onSubmit={submitNewEvent}
        loading={loading}
        openCohort={(cohort) => setCohort(cohort)}
      />

      <Agenda
        className={classes.agenda}
        events={events}
        containerStyle={{ height: '100%' }}
        popup
        style={{ flex: "1 1 auto" }}
        timeslots={1}
        step={60}
        views={[
          calendarViews.WEEK,
          calendarViews.WORK_WEEK,
          calendarViews.AGENDA,
        ]}
        defaultView={calendarViews.WEEK}
        selectable={"ignoreEvents"}
        onSelectEvent={(evt, element) => handleSelectEvent(evt, element)}
        onSelectSlot={onSelectSlot}
        lockSlotStartTime="07:00"
        lockSlotEndTime="23:59"
        // tooltipAccessor={(event) => `${event.title}\n${event.title}`}
        tooltipAccessor={null}
        // onSelecting={() => {
        //   return false;
        // }}
        eventComponent={(evt) => <EventCell event={evt} />}
        metaRenderer={(evt) => <EventCell agendaCell event={evt} onEventClick={()=>{
          history.push({
            pathname: `/events/${evt.id}`,
            search: domLocation.search,
          });
        }}/>}

      />
    </>
  );
}

export default function WrappedEventAgenda(props) {
  return (
    <AgendaProvider>
      <EventAgenda {...props} />
    </AgendaProvider>
  );
}
