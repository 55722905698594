import instance from './helpers/instance';

const healthMetricsService = {
    getEmployeeHealthRiskCount,
    getEmployeeHealthRiskUnknownCount,
    getQuarantinedEmployeeCovidPositive,
    getEmployeeCovidTestedCount,
    getComorbidityCounts,
    getLocationDeniedEntriesMetrics,
    getTestSubTypes
}

const API_URL = process.env.REACT_APP_WEB_ADMIN_URL;

function getEmployeeHealthRiskCount(companyId, params) {
    return instance.get(`${API_URL}/api/1/corporate/partners/${companyId}/employees/risks/levels/count`, { params, cache: false}).then(
        response => {
            return response.data
        }
    );
}

function getEmployeeHealthRiskUnknownCount(companyId, params) {
    return instance.get(`${API_URL}/api/1/corporate/partners/${companyId}/employees/risks/unknown/count`, {params: params, cache: false}).then(
        response => {
            return response.data
        }
    );
}
function getQuarantinedEmployeeCovidPositive(partnerId) {
    return instance.get(`${API_URL}/api/1/corporate/${partnerId}/quarantine/countCovidPositive`).then(
        response => {
            return response.data
        }
    );
}
function getEmployeeCovidTestedCount(partnerId) {
    return instance.get(`${API_URL}/api/1/corporate/partners/${partnerId}/employees/risks/countCovidTested`).then(
        response => {
            return response.data
        }
    );
}
function getComorbidityCounts(partnerId, params) {
    return instance.get(`${API_URL}/api/1/corporate/${partnerId}/employeeRiskLevel/getCacheComorbidityCounts`, { params }).then(
        response => {
            return response.data
        }
    );
}
function getLocationDeniedEntriesMetrics(partnerId) {
    return instance.get(`${API_URL}/api/1/corporate/${partnerId}/getGateDenialMetrics`).then(
        response => {
            return response.data
        }
    );
}

function getTestSubTypes(id) {
    return instance.get(`${API_URL}/api/1/medical/covid_medical_lab_result/lab_test/subtypes`).then(
        response => {
            return response.data
        }
    )
}
export default healthMetricsService;
