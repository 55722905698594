import instance from './helpers/instance';

const API_URL = process.env.REACT_APP_WEB_ADMIN_URL;

export default {
    listCohorts: (partnerId, p, sortParams) => {
        var params = p;
        params.sort_by = sortParams.sort_by;
        params.sort_order = sortParams.sort_order;
        var url = `${API_URL}/api/1/corporate/partners/${partnerId}/employee_cohorts`
        console.log(url);
        return instance.get(url, { params: params, cache: false })
        .then(response => {
            return response.data;
        })
    },

    getCohort: (cohortId) => {
        var url = `${API_URL}/api/1/corporate/employee_cohorts/${cohortId}`

        return instance.get(url, { cache: false })
        .then(response => {
            return response.data;
        })
    },

    createCohort: (partnerId, p) => {
        var url = `${API_URL}/api/1/corporate/partners/${partnerId}/employee_cohorts`

        return instance.post(url, p, { cache: false })
        .then(response => {
            return response.data;
        })
    },

    updateCohort: (cohortId, p) => {
        var url = `${API_URL}/api/1/corporate/employee_cohorts/${cohortId}`

        return instance.put(url, p, { cache: false })
        .then(response => {
            return response.data;
        })
    },

    deleteCohort: (cohortId) => {
        var url = `${API_URL}/api/1/corporate/employee_cohorts/${cohortId}`

        return instance.delete(url, { cache: false })
        .then(response => {
            return response.data;
        })
    }

}