import React, {useEffect, useRef, useState} from "react"
import {makeStyles} from "@material-ui/core/styles";
import {
    Paper,
    Grid,
    Typography,
} from '@material-ui/core'
import clsx from "clsx";
import { BarChart, Bar, XAxis, YAxis,
    CartesianGrid, 
    Tooltip, Cell,
    Legend, ComposedChart,
    LabelList } from 'recharts';

const useStyles = makeStyles(theme => ({

}));

const renderCustomizedLabel = (props) => {
    const { x, y, width, height, value } = props;
    const radius = 10;
  
    return (
      <g>
        <circle cx={x + width / 2} cy={y - radius} r={radius} fill="#8884d8" />
        <text x={x + width / 2} y={y - radius} fill="#fff" textAnchor="middle" dominantBaseline="middle">
          {value}
        </text>
      </g>
    );
  };


const HealthRiskBar = (props) => {
    const classes = useStyles();

    const [ totalCount, setTotalCount ] = useState(0);

    const {
        labels = [],
        datasets = [],
        description,
        backgroundColors = [],
        counts = [],
        data = []
    } = props;


    useEffect(() => {
        setTotalCount(data.reduce((acc, currentVal) => acc + currentVal.count, 0));
    }, [data])

    return (
        <Paper className={clsx(classes.fullSize)} variant={"outlined"} style={{ height: 287}}>
            <Grid container 
            // alignItems={"center"} 
            // justify={"center"} 
            style={{ margin: "0.5rem", width: '100%', height: 350 }}
             spacing={1}
             >
                <Grid item container alignItems="flex-start" justify="flex-start">
                    <Grid item xs={12} style={{ padding: 0, margin: 0}} align="left">
                        <Typography variant="caption" style={{ color: '#494949', marginLeft: 5, fontWeight: 700 }}>
                            { props.title }
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item container alignItems="center" justify="center" xs={12} style={{ position: 'relative', bottom: 75}}>
                    {   totalCount > 0 ?
                        (
                            <div style={{ position: 'relative'}}> 
                                <BarChart layout="vertical" width={500} height={200} data={data}>
                                    {/* <CartesianGrid strokeDasharray="3 3" /> */}
                                    <XAxis type="number" tick={{ fontSize: 0 }} hide/>
                                    <YAxis dataKey="name" type="category" tick={{ fontSize: 14}} allowDataOverflow={true} width={100}/>
                                    <Tooltip/>
                                    <Bar dataKey='count' barSize={170} fill="#8884d8" radius={[0, 5, 5, 0]}>
                                        {
                                            data.map((entry, index) => <Cell key={`cell-${index}`} fill={ backgroundColors[index]} />)
                                        }
                                    </Bar>
                                </BarChart>
                            </div>
                        ) : <div style={{ position: 'relative'}}> 
                            <BarChart layout="vertical" width={500} height={200} data={[{ name: 'No Data Available', count: 1},
                            {name: 'No Data Available', count: 2},
                            {name: 'No Data Available', count: 1}]}>
                                {/* <CartesianGrid strokeDasharray="3 3" /> */}
                                <XAxis type="number" tick={{ fontSize: 0 }} label="No Data Available"/>
                                <YAxis dataKey="name" type="category" tick={{ fontSize: 14, width: 25}} allowDataOverflow={true}/>
                                <Bar dataKey='count' barSize={20} fill="#D0CECE" radius={[0, 5, 5, 0]} label={{ position: 'insideStart', fill: '#fff' }}>
                                    {
                                        data.map((entry, index) => <Cell label={{ position: 'insideStart', fill: '#fff' }} key={`cell-${index}`} fill={ backgroundColors[index]} />)
                                    }
                                </Bar>
                            </BarChart>
                    </div>
                    }
                    <br />
                </Grid>
            </Grid>
        </Paper>
    )
}

export default HealthRiskBar;