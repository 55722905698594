import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { Button, Divider, Typography, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Dialog, Grid } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouseUser, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { head } from 'lodash';
import corporateLocationService from './../services/corporate-location.service';
import {
    Link as RouterLink,
    useHistory
} from 'react-router-dom';
import ErrorMessage from './../components/errorMessage';
import SuccessMessage from './../components/successMessage';

const useStyles = makeStyles(theme => ({

}));

const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    fullLength: {
        width: '100%'
    }
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
        </IconButton>
        ) : null}
    </MuiDialogTitle>
    );
});
    
const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

  const ViewLocationDetails = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const { open, handleClose, location, deleteMode } = props;

    const [ notification, setNotification ] = useState({
        message: '',
        show: false,
        isError: false
    });

    function setShowNotification (value) {
        setNotification({
            ...notification,
            show: value
        })
      }
  
    function createNotification(message, isError) {
        setNotification({
            message: message,
            show: true,
            isError: isError
        })
    }

    useEffect(() => {
      console.log(props);
    }, []);

    const handleDelete = event => {
        corporateLocationService.deleteLocation(location.id).then(
            response => {
                createNotification("Successfully deleted location." );

                setTimeout(() => {
                    history.push('/locations');
                }, 1000);
            }
        ).catch(error => {
            createNotification("Unable to delete location", true);
        })
    }

    return (
      <div>
        { notification.show && notification.isError && <ErrorMessage open={notification.show} setOpen={setShowNotification} error={{message: notification.message}}/>}
        { notification.show && !notification.isError && <SuccessMessage open={notification.show} setOpen={setShowNotification} message={notification.message}/>}
        <Dialog maxWidth="md" fullWidth={true} open={open} onClose={handleClose} aria-labelledby='delete-location-prompt-dialog'>
            <DialogTitle id="delete-location-prompt" onClose={handleClose}>
              { !deleteMode ? 'Location Overview' : 'Delete Location' }
              {
                  deleteMode && <Typography variant="body1">
                      <FontAwesomeIcon icon={faExclamationCircle} style={{ color: '#EA857D'}}  /> Deleting a location will remove all the location’s details, logs and and the employees assigned to it will be unassigned. This action is irreversible.
                  </Typography>
              }
            </DialogTitle>
            <DialogContent dividers>
                <Grid container spacing={3}>
                    <Grid item xs={3}>
                        <Typography variant="body1">
                            Location Name:
                        </Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <Typography variant="body1">
                            { location.label }
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="body1">
                            Complete Address:
                        </Typography>
                    </Grid>
                    <Grid item xs={9}>
                        { location.address }
                    </Grid> 
                    <Grid item xs={3}>
                        <Typography variant="body1">
                            Telephone Number:
                        </Typography>
                    </Grid>
                    <Grid item xs={9}>
                        { location.phoneNumbers ? head(location.phoneNumbers) : '-'}
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="body1">
                            Location Type:
                        </Typography>
                    </Grid>
                    <Grid item xs={9}>
                        { location && location.type ? location.type.label : '-'}
                    </Grid>
                    {/* <Grid item xs={3}>
                        <Typography variant="body1">
                            Employee Number:
                        </Typography>
                    </Grid>
                    <Grid item xs={9}>

                    </Grid> */}
                    <Grid item xs={3}>
                        <Typography variant="body1">
                            Floors and Gates
                        </Typography>
                    </Grid>
                    <Grid item xs={9}>
                        {
                            location.floors ? location.floors.map((floor, index) => {
                                return floor && <Typography variant="body1">
                                    { floor.name }
                                    <ul>
                                        {
                                            floor.gates.map((gate, index) => {
                                                return gate && 
                                                    <li>
                                                        <Typography variant="caption">
                                                            { gate.description }
                                                        </Typography> 
                                                    </li>
                                            })
                                        }
                                    </ul>
                                </Typography>
                            })
                        :   undefined }
                    </Grid>
                </Grid>
                { deleteMode && <Grid item xs={12} align="center">
                    <Button variant="contained" color="primary" style={{ color: 'white'}} onClick={handleDelete}>
                        Delete Location
                    </Button>
                </Grid> }
            </DialogContent>
        </Dialog>
      </div>
    )
  }

  export default ViewLocationDetails;