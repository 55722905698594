import instance from './helpers/instance';

const API_URL = process.env.REACT_APP_WEB_ADMIN_URL;

export default {
    getCompanyDetails: (partnerId) => {
        return instance.get(`${API_URL}/api/1/corporate/partners/${partnerId}/detailed`)
            .then(response => response.data)
    },
    getCompanySbuList(partnerId) {
        return instance.get(`${API_URL}/api/1/corporate/partners/${partnerId}/sbus/counts`)
            .then(response => response.data)
    },
    updateCompany: (partnerId, params) => {
        return instance.post(`${API_URL}/api/1/corporate/partners/${partnerId}/settings/profile/update`, params, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(() => {})
    },
    getIndustries: () => {
        return instance.get(`${API_URL}/api/1/corporate/industries`)
            .then(response => response.data)
    },
    getCompanyRiskFactors(partnerId) {
        return instance.get(`${API_URL}/api/1/corporate/partners/${partnerId}/risks/levels`)
            .then(response => response.data)
    }
}
