import React from "react";
import {ArrowLeft as ArrowLeftIcon} from "@material-ui/icons";
import {Button} from "@material-ui/core";
import {useHistory} from "react-router-dom";

export default function BackButton(props) {
    const color = props.color || "primary"
    const disableRipple = props.disableRipple
    const defaultRoute = props.defaultRoute  || "/"
    const icon = props.icon || <ArrowLeftIcon />
    const text = props.text || "Back"
    const history = useHistory();

    return (
        <Button
            color={color} disableRipple={disableRipple}
            disableFocusRipple={disableRipple} startIcon={icon}
            onClick={() => {
                return history.length > 2 ? history.goBack() : history.push(defaultRoute)
            }}
        >
            { text }
        </Button>
    )
}
