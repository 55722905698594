import { Checkbox, Tooltip } from "@material-ui/core";
import { Box } from "@material-ui/core";
import { CardContent } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { Dialog } from "@material-ui/core";
import { DialogTitle } from "@material-ui/core";
import { DialogContent } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { IconButton } from "@material-ui/core";
import { Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { FormBuilder } from "@zennya/web-component-library/src/components/FormBuilder";
import { useFormikContext } from "formik";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { isEmpty } from "voca";
import * as Yup from "yup";
import corporateEventsService from "../../services/corporate-events.service";
import logger, { snackbar } from "../../services/logger.service";
import { EventHeaderDetailContent } from "../eventDetail/EventHeaderDetail";

const CUTOFF_PERIOD = 12

const FormConsumer = ({ onChange = () => {} }) => {
  const formikObject = useFormikContext();
  const { values, initialValues, setValues, setFieldValue, ...formik } =
    formikObject;

  React.useEffect(() => {
    const dirty = formik.dirty;
    onChange(values);
  }, [formik.dirty, values, formik, onChange]);
  return null;
};

const useStyles = makeStyles((theme) => ({
  option: {
    borderBottom: "1px solid #ccc",
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },

}));
const SlotForm = ({
  start,
  end,
  cohorts,
  services,
  triggerReset: tReset = () => {},
  setFormValues = () => {},
  prepareBundle = () => {},
  event,
  loading,
  onClose=()=>{}
}) => {
  const hasEvent = !!event;
  const [triggerReset, setTriggerReset] = useState(tReset);
  const classes = useStyles();
  const [values, setValues] = useState();

  const mappedEventServices = useMemo(() => {
    if (!event) {
      return;
    }
    const mappedServices = (event.services ?? []).map((s) => {
      return {
        service: s.type?.id,
        timeslots: (s.slots ?? []).map((slot) => {
          return {
            callTime: slot.start_date,
            cohorts: slot.cohorts ?? [],
          };
        }),
      };
    });

    return mappedServices;
  }, [event]);

  const formSetup = useMemo(() => {
    const createFormElements = hasEvent
      ? {}
      : {
          start: {
            type: "time",
            label: "Start Time",
            initialValues: start,
            validator: () => Yup.date().required(),
            fieldProps: {
              format: "hh:mm a",
              views: ["hours" ],
            },
          },
          end: {
            type: "time",
            label: "End Time",
            initialValues: end,
            validator: () => Yup.date().required(),
            fieldProps: {
              format: "hh:mm a",
              views: ["hours"],
            },
          },
          label: {
            type: "text",
            label: "Event Name",
            initialValues: "",
            validator: () => Yup.string().nullable().required().label(" "),
          },
        };
    const formElements = {
      ...createFormElements,
      services: {
        type: "fieldarray",
        label: "Service",
        initialValues: mappedEventServices ?? [],
        validator: () =>
          Yup.array()
            .min(1)
            .required()
            .of(
              Yup.object().shape({
                service: Yup.mixed().nullable().required().label(" "),
                timeslots: Yup.array()
                  .min(1)
                  .required()
                  .of(
                    Yup.object().shape({
                      callTime: Yup.date()
                        .min(
                          values?.start ?? start,
                          `No earlier than ${moment(
                            values?.start ?? start
                          ).format("LT")}`
                        )
                        .max(
                          values?.end ?? end,
                          `No later than ${moment(values?.end ?? end).format(
                            "LT"
                          )}`
                        )
                        .nullable()
                        .required()
                        .label(" "),
                      cohorts: Yup.array()
                        .min(1)
                        .nullable()
                        .required()
                        .label(" "),
                    })
                  )
                  .label("Slot"),
              })
            ),
        form: [],
        formLayout: ["service", "timeslots"],
        formValueTemplate: {
          service: "",
          timeslots: [{callTime:start, cohorts:[]}],
        },
        formTemplate: {
          service: {
            type: "select",
            label: "Service",
            initialValues: "",
            forceColumnWidth: 4,
            options: services,
            settings: {
              default: "",
              multiple: false,
              labelField: "label",
              valueField: "id",
            },
          },
          timeslots: {
            form: [],
            type: "fieldarray",
            inline: true,
            label: "Slot",
            formLayout: ["callTime", "cohorts"],
            formValueTemplate: {
              callTime: start,
              cohorts: [],
            },
            formTemplate: {
              callTime: {
                type: "time",
                label: "Call Time",
                initialValues: start,
                forceColumnWidth: 4,
                fieldProps: {
                  format: "hh:mm a",
                  views: ["hours", "minutes"],
                  minTime: values?.start ?? start,
                  maxTime: values?.end ?? end,
                },
              },
              cohorts: {
                type: "autocomplete",
                label: "Cohorts",
                initialValues: [],
                options: cohorts,
                settings: {
                  default: "",
                  multiple: true,
                  labelField: "label",
                  valueField: "id",
                },
                fieldProps: {
                  renderOption: (item, status) => {
                    return (
                      <Tooltip title={item.description}>
                        <Box className={classes.option}>
                          <Box>
                            <Checkbox
                              className={classes.checkbox}
                              checked={status.selected}
                            />
                            {item.label}
                          </Box>
                          <Box>
                            <Typography variant="caption">
                              Entries: {item.entry_count}
                            </Typography>
                          </Box>
                        </Box>
                      </Tooltip>
                    );
                  },
                  ChipProps: {
                    variant: "outlined",
                    color: "primary",
                  },
                },
              },
            },
          },
        },
      },
    };
    return formElements;
  }, [
    classes.checkbox,
    classes.option,
    cohorts,
    end,
    mappedEventServices,
    services,
    start,
    values?.end,
    values?.start,
  ]);

  const formLayout = useMemo(() => {
    return hasEvent ? ["services"] : [["start", "end"], "label", "services"];
  }, [hasEvent]);

  return (
    <FormBuilder
      formLabel=""
      formId="manageSlots"
      columns={2}
      submitLabel={hasEvent?"Save slots":"Create event"}
      formFactor="default"
      variant="outlined"
      formLayout={formLayout}
      form={formSetup}
      triggerReset={triggerReset}
      formReadOnly={loading}
      onTriggerReset={() => {
        setTriggerReset(false)
      }}
      onSubmit={(v) => {
        prepareBundle(v);
      }}
      // formReadOnly
    >
      <FormConsumer
        onChange={(v) => {
          setFormValues(v);
          setValues(v);
        }}
      />
    </FormBuilder>
  );
};

const SlotFormDialog = ({ setNewServices = () => {}, onComplete=()=>{}, event, ...props }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const handleClose = useCallback(() => {
    setOpen(false);
  }, [event.services]);
  
  const {enqueueSnackbar} = snackbar()
  
  const [loading, setLoading] = useState(false);

  const prepareBundle = useCallback(
    async (values) => {
      setLoading(true);
      try {
        const buildServices = {
          services: (values.services ?? []).map((service) => {
            return {
              type: service?.service,
              slots: (service?.timeslots ?? []).map((slot) => {
                return {
                  cohorts: slot?.cohorts?.map((cohort) => cohort.id),
                  start_date: slot?.callTime,
                };
              }),
            };
          }),
        };
  
        // console.log("📢[eventsAgenda.js:122]:", evtObject);
        await corporateEventsService.modifyEventSlots(event.id, buildServices);
        onComplete()
        setOpen(false);
      } catch (error) {
        logger.err(enqueueSnackbar, <div>Error {error?.response?.status} {error?.response?.data?.message?.en}<br/> {error?.response?.data?.details}</div>)
      }finally {
        setLoading(false);
        
      }
    },
    [event.id, handleClose]
  );

  const isEditable = useMemo(() => 
  {
    const isEditableStatus = ["PENDING", "REQUESTED", "ACTIVE"].includes(event.status)
    const isRoleEditable = true
    const isCutoffEditable = moment(event?.start_date).diff(moment(), 'h') > CUTOFF_PERIOD
    // return isEditableStatus && isRoleEditable && isCutoffEditable
    return isEditableStatus && isRoleEditable
  }, [event?.start_date, event.status]);

  useEffect(() => {
    const isEditableStatusError = ["PENDING", "REQUESTED", "ACTIVE"].includes(event.status) ? null : "Event is not able to be edited"
    const isRoleEditableError = true ? null:"No permission to edit event"
    const isCutoffEditableError = (moment(event?.start_date).diff(moment(), 'h') > CUTOFF_PERIOD) ? null : "Event is locked (past cutoff period)"

    const permError = isEditableStatusError || isRoleEditableError || isCutoffEditableError
    if(permError) {
      const logs = [isEditableStatusError, isRoleEditableError, isCutoffEditableError].filter(d=>!!d)
      logger.err(enqueueSnackbar, <div>{logs.join('.  ')}</div>)
    }
    
  }, []);

  return (
    <>
      {isEditable && (
        <Button
          onClick={() => setOpen(true)}
          variant="contained"
          color="primary"
        >
          Manage Slots
        </Button>
      )}
      <Dialog
        maxWidth={"md"}
        fullWidth
        open={open}
        onClose={() => setOpen(false)}
        disableEnforceFocus
      >
        <DialogTitle
          id="allocations"
          onClose={() => setOpen(false)}
          disableTypography
          className={classes.header}
        >
          <Typography variant="h6">Manage Slots</Typography>
          <IconButton className={classes.close} onClick={() => setOpen(false)}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <EventHeaderDetailContent event={event} />
          
          <SlotForm
            {...props}
            start={event.start_date}
            end={event.end_date}
            event={event}
            loading={loading}
            onClose={() => setOpen(false)}
            prepareBundle={(v) => {
              prepareBundle(v);
            }}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export { SlotFormDialog, SlotForm };
