import React from "react";
import {
    Typography,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Table,
    Paper
} from "@material-ui/core";
import {
    makeStyles
} from "@material-ui/core/styles";
import { Waypoint } from 'react-waypoint';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: '75vh',
    },
    darkGreyText: {
        color: '#2f2f2f'
    },
    body2: theme.typography.body2
}))

export default function (props) {
    const classes = useStyles();

    const rows = props.rows
    const columns = props.columns
    const count = props.count
    const loadMore = props.loadMore || function () {}
    const loader = props.loader || false
    const endText = props.endText || "End"
    const noDataText = props.noDataText || "No Results Found"
    const containerHeight = props.containerHeight || '75vh'

    return (
        <Paper className={classes.root}>
            <TableContainer  style={{ maxHeight: containerHeight }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    className={classes.darkGreyText}
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, index) => {
                            return (
                                <TableRow key={index} hover role="checkbox" tabIndex={-1}>
                                    {columns.map((column) => {
                                        return (
                                            <TableCell className={clsx(classes.darkGreyText, classes.body2)} key={column.id} align={column.align}>
                                                { row[column.id] }
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            );
                        })}
                        {
                            rows.length < count &&
                            <TableRow>
                                <TableCell>
                                    <Waypoint
                                        onEnter={() => loadMore()}
                                    />
                                </TableCell>
                            </TableRow>
                        }
                        {
                            rows.length !== 0 && !loader && rows.length >= count &&
                            <TableRow>
                                <TableCell align="center" colSpan={columns.length}>
                                    <Typography variant={"subtitle1"} style={{ fontStyle: "italic" }}>
                                        { endText }
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        }
                        {
                            rows.length === 0 && !loader &&
                            <TableRow>
                                <TableCell align="center" colSpan={columns.length}>
                                    <Typography variant={"subtitle1"} style={{ fontStyle: "italic" }}>
                                        { noDataText }
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    )
}
