import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Dialog, Button, FormControl, Grid, TextField, InputAdornment, LinearProgress,
        Typography} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DataTable from '../components/DataTable';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import ErrorMessage from './../components/errorMessage';
import SuccessMessage from './../components/successMessage';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CreateCohorts from './createCohorts';
import CohortEntriesList from './cohortEntriesList';
import moment from "moment";

import corporateEventsService from './../services/corporate-events.service';



const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
        textAlign: 'start',
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    fullLength: {
        width: '100%'
    }
});

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4),
        flexGrow: 1,
        maxWidth: '1440px'
    },
    button: {
        marginRight: theme.spacing(1)
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    formControl: {
        marginRight: theme.spacing(1),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        minWidth: 300,
    },
    errorColor: {
        color: theme.palette.error.main,
        backgroundColor: 'white',
        borderColor: theme.palette.error.main
    },
    btnMargin: {
        marginTop: 10,
        marginLeft: 10
    },
    prevBtn: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        borderColor: theme.palette.secondary.main
    },
    textFieldSpacer: {
        margin: theme.spacing(1)
    },
    show: {
        display: 'block'
    },
    hidden: {
        display: 'none'
    },
    titleHeader: {
        marginTop: '50',
        fontWeight: 700
    }

}));

const CohortDetails = (props) => {
    const classes = useStyles();

    const [state, setState] = useState({
    });

    const { standalone, peopleType, open, handleClose, isAdding, selectedCohort, company } = props;

    const [cohortEntries, setCohortEntries] = useState([]);

    const handleChange = useCallback(
        (name, event) => {
            setState({
                ...state,
                [name]: event.target.value
            })
        },
        [],
    );

    const [isUpdateMode, setUpdateMode] = useState(false);
    const [cohort, setCohort] = useState({});
 
    const isUpdating = (setting) => {
        setUpdateMode(setting);
    }

    const handleAfterSuccess = () => {
        //todo handle after success
    }

    const [page, setPage] = useState(0);

    const columns = [
        {
            id: 'company_id',
            name: 'company_id',
            label: 'Company ID'
        },
        {
            id: 'name',
            name: 'name',
            label: 'Name'
        },
        {
            id: 'role',
            name: 'role',
            label: 'Role'
            
        },
        {
            id: 'work_type',
            name: 'work_type',
            label: 'Work Type'
        },
        {
            id: 'work_nature',
            name: 'work_nature',
            label: 'Work Nature'
        },
    ]

    const [params, setParams] = useState({
        page_size: process.env.REACT_APP_DEFAULT_PAGE_SIZE,
        page_offset: 0
    });

    const [ sortParams, setSortParams ] = useState(
        { changeColumn: '', 
        val: ''
    });

    const cancelUpdate = () => {
        setUpdateMode(false);
        
    }

    const DialogTitle = withStyles(styles)((props) => {
        const { children, classes, onClose, ...other } = props;
        return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                <CloseIcon />
            </IconButton>
            ) : null}
        </MuiDialogTitle>
        );
    });
  
    const DialogContent = withStyles((theme) => ({
        root: {
        padding: theme.spacing(2),
        },
    }))(MuiDialogContent);

    const [ notification, setNotification ] = useState({
        message: '',
        show: false,
        isError: false
    });

    function handleChangePage(newPage) {
        setParams({
            ...params,
            page_offset: ((newPage + 1) * params.page_size) - params.page_size
        })

        setPage(newPage);
    }

    
    const handleFilterChange = (filters) => {
        
    }

    const handleSort = (params) => {
        setSortParams(params);
    }

    function setShowNotification (value) {
      setNotification({
          ...notification,
          show: value
      })
    }

    function createNotification(message, isError) {
      setNotification({
          message: message,
          show: true,
          isError: isError
      })
    }

    useEffect(() => {
        if (!isAdding && selectedCohort) {
            corporateEventsService.getCohortEntries(selectedCohort.id).then(
                (response) => {
                    var cohortList = {
                        list: response.list.map((entry, index) => {
                            return {
                                company_id: (entry.company_id) ? entry.company_id : "-",
                                name: (entry.full_name) ? entry.full_name : "-",
                                role: (entry.role) ? entry.role : "-",
                                work_type: null, // no discernable data point for this
                                work_nature: (entry.work_nature) ? entry.work_nature : "-"
                            }
                            
                        }),
                        count: selectedCohort.entry_count
                    };
                    setCohortEntries(cohortList);
                }
            )
        }
        
    }, [])

    const cohortContent = useMemo(() => 
    {
        return (
            <>
            <DialogTitle id="dialog-title" onClose={handleClose}>
                    {(selectedCohort) ? `${selectedCohort.label} Details` : `Add a Cohort (${peopleType})`} <br />
                    {
                        (!selectedCohort) ? 
                            <Typography variant="caption">
                                Please fill in the required details to add a cohort.
                            </Typography>
                        : undefined
                    }
                    
                </DialogTitle>
                <DialogContent dividers>
                    {
                        (isAdding) ? 
                            <CreateCohorts peopleType={peopleType} company={props.company} callback={handleClose}></CreateCohorts>
                        : 
                        <div>
                            <Grid container spacing={3}>
                                <Grid item xs={6} textAlign="start" justify="flex-start" alignItems="flex-start">
                                    <Typography variant="h5" style={{ textAlign: 'start', fontWeight: 700, marginBottom: 15 }}>
                                        {isUpdateMode ? "Updating:" : "Viewing:"} {cohort.label}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container spacing={3}>
                                <Grid container spacing={1} alignItems="flex-start" justify="flex-start">
                                    <Grid item xs={3} align="left">
                                        <b>
                                            Label
                                        </b>
                                    </Grid>
                                    {
                                        (!isUpdateMode) ?
                                            <Grid item xs={9} align="left">
                                                <Typography variant="body1">
                                                {selectedCohort.label || '-'}
                                                </Typography>
                                            </Grid>
                                            :
                                            <Grid item xs={9} align="left">
                                                <TextField
                                                    required
                                                    variant="outlined"
                                                    id="cohortLabel"
                                                    label="Cohort Name"
                                                    fullWidth
                                                    size="small"
                                                    value={selectedCohort.label}
                                                    style={{ minWidth: 300, maxWidth: 500 }}
                                                    onChange={event=> handleChange('label', event)}
                                                />
                                            </Grid>
                                    }

                                    <Grid item xs={3} align="left">
                                        <b>
                                            Description
                                        </b>
                                    </Grid>
                                    {
                                        (!isUpdateMode) ?
                                            <Grid item xs={9} align="left">
                                                <Typography variant="body1">
                                                    {selectedCohort.description || '-'}
                                                </Typography>
                                            </Grid>
                                            :
                                            <Grid item xs={9} align="left">
                                                <TextField
                                                    required
                                                    variant="outlined"
                                                    id="cohortDescription"
                                                    label="Cohort Description"
                                                    fullWidth
                                                    size="small"
                                                    value={selectedCohort.description}
                                                    style={{ minWidth: 300, maxWidth: 500 }}
                                                    onChange={event=> handleChange('description', event)}
                                                />
                                            </Grid>
                                    }

                                    <Grid item xs={3} align="left">
                                        <b>
                                            Date Created
                                        </b>
                                    </Grid>
                                    <Grid item xs={9} align="left">
                                        <Typography variant="body1">
                                            {selectedCohort.date_created ? (moment(selectedCohort.date_created).format('lll')) : undefined || '-'}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3} align="left">
                                        <b>
                                            Last Updated
                                        </b>
                                    </Grid>
                                    <Grid item xs={9} align="left">
                                        <Typography variant="body1">
                                            {(selectedCohort.last_updated) ? (moment(selectedCohort.last_updated).format('lll')) : undefined  || '-'}
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} align="left">
                                        <CohortEntriesList {...props} cohortId={selectedCohort.id}></CohortEntriesList>
                                    </Grid>
                                    
                                </Grid>

                                { 
                                    (isUpdateMode) ? 
                                        <Button variant="outlined" size="small" color="primary" onClick={() => { cancelUpdate() }}
                                            inputProps={{
                                                startAdornment: <InputAdornment position="end">
                                                    <AddCircleOutlineIcon />
                                                </InputAdornment>,
                                            }}>
                                            Cancel Edit
                                        </Button>
                                    : null

                                }
                                {
                                    (isUpdateMode) ? 
                                        <Button variant="contained" size="small" color="primary"
                                            InputProps={{
                                                startAdornment: <InputAdornment position="end">
                                                    <AddCircleOutlineIcon />
                                                </InputAdornment>,
                                            }}>
                                            Save Cohort
                                        </Button>
                                    : null
                                }
                                
                            </Grid>

                        </div>
                    }
                </DialogContent>
            </>
        )
    }, [cohort.label, handleChange, handleClose, isAdding, isUpdateMode, peopleType, props, selectedCohort]);

    if(standalone) {
        return <div className={classes.root}>{cohortContent}</div>
    } else {
        return (
            <div className={classes.root}>
                { notification.show && notification.isError && <ErrorMessage open={notification.show} setOpen={setShowNotification} error={{ message: notification.message }} />}
                { notification.show && !notification.isError && <SuccessMessage open={notification.show} setOpen={setShowNotification} message={notification.message} />}
                <Dialog maxWidth={'md'} fullWidth={true} open={open} onClose={handleClose} aria-labelledby="edit-company-title">
                    {cohortContent}
                </Dialog>
            </div>
        )
    }
}

export default CohortDetails;