import axios from 'axios';
import instance from './helpers/instance';


const locationsService = {
    hereGeocode,
    geocoder,
    autocompleteSuggestions,
    fetchRegions
}

const hereAPICode = process.env.REACT_APP_HERE_MAPS_API_CODE;
const hereAPIId = process.env.REACT_APP_HERE_MAPS_API_ID;
const hereAPIKey = process.env.REACT_APP_HERE_MAPS_API_KEY;

const API_URL = process.env.REACT_APP_WEB_ADMIN_URL;

const platform = new window.H.service.Platform({
    apikey: process.env.REACT_APP_HERE_MAPS_API_KEY
});


function hereGeocode(query) {
    return platform.getSearchService().geocode({
        q: query
    }, (result) => {
        console.log(result);
        return result;
    }, onError);
}

function onSuccess(result) {
    return result;
}

function onError(error) {
    console.log(error);
    return null;
}


function geocoder(query) {
    var params = {
        query: query
    };

    return instance.get(`${API_URL}/api/1/geocoding/forward`, { params: params, cache: false })
    .then(response => {
        return response.data;
    })
}

function autocompleteSuggestions(query) {
    var params = {
        app_id: hereAPIId,
        app_code: hereAPICode,
        query: query
    }

    return axios.get(`https://autocomplete.geocoder.api.here.com/6.2/suggest.json`, { params: params}).then(
        response => {
            return response.data;
        }
    )
}

async function fetchRegions() {
    const result = await instance.get(`${API_URL}/api/1/corporate/regions`)
    return result?.data
}

export default locationsService;