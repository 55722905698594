function countServiceEntrants(service) {
  return service?.cohorts.reduce((acc, cohort) => {
    return acc + cohort.entry_count;
  }, 0);
}

function countEventEntrants(event) {
  return event?.services.reduce((acc, service) => {
    return acc + countServiceEntrants(service);
  }, 0);
}

export { countServiceEntrants, countEventEntrants };
