import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Divider, FormControl, Grid, InputLabel, Paper, Select, Typography } from '@material-ui/core';
import EmployeeCard from './Employee';
import HealthCheckerCard from './HealthChecker';
import OfficeCard from './Office';
import HighRiskCard from './HighRisk';
import corporateHealthService from './../services/corporate-health.service';
import corporateLocationService from './../services/corporate-location.service';
import TabbedTable from '../employees/tabbedTable';
import clsx from 'clsx';
import DailyReport from './dailyReport';
import GraphCard from './graphCard';
import DailyLogs from './DailyLogs';
import HealthRiskDialog from './../healthRisk/healthRiskDialog';
import UserReportList from './userReportList';


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
    flexGrow: 1,
    color: '#000',
    paddingLeft: 0,
    paddingRight: 0
  },
  officeInfoTitle: {
    padding: 10,
    color: '#000'
  },
  officeInfoSection: {
    padding: 10,
    color: '#000'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  fullHeight: {
    height: '100vh',
    width: '100vw'
  },
  gridPadding: {
    padding: 0
  },
  gridSpacer: {
    marginLeft: 10
  },
  fillHeight: {
    height: '100%'
  },
  tableContainer: {
    margin: theme.spacing(2),
    padding: theme.spacing(2)
  }
}));

const colorSchemes = {
  unchecked: '#C4C4C4',
  normal: '#78BDBA',
  moderate: '#EEBE6A',
  high: '#EA857D'
};


const tempLabels = [ 'Normal', 'Moderate Fever', 'High Fever', 'Unchecked'];
const assessmentLabels = [ 'Low Risk', 'Moderate Risk', 'High Risk'];
const tempDataSets = [
    {
      label: 'Taguig',
      data: [100,150,190],
      backgroundColor: colorSchemes.normal // green
    },
    {
      label: 'QC',
      data: [5,10,3],
      backgroundColor: colorSchemes.moderate // yellow
    },
    {
      label: 'Jupiter',
      data: [1,1,4],
      backgroundColor: colorSchemes.high // red
    },
];

const assessmentDataSets = [
    {
      label: 'Taguig',
      data: [120,150,190],
      backgroundColor: colorSchemes.normal // green
    },
    {
      label: 'QC',
      data: [15,10,13],
      backgroundColor: colorSchemes.moderate // yellow
    },
    {
      label: 'Jupiter',
      data: [1,21,14],
      backgroundColor: colorSchemes.high // red
    },
];


const Dashboard = (props) => {
  const classes = useStyles();
  const [ partner, setPartner ] = useState({});
  const [ company, setCompany ] = useState({
    addresses: []
  });
  const [ state, setState ] = useState({
    location: ''
  });
  const [employees, setEmployees ] = useState([]);
  const [ checkers, setCheckers ] = useState([]);
  const [officeInfo, setOfficeInfo ] = useState({})
  const [ temperatureMetrics, setTemperatureMetrics ] = useState([]);
  const [ covidMetrics, setCovidMetrics ] = useState([]);

  // dialogs
  const [ openWorkers, setOpenWorkers ] = useState(false);
  const [ openCheckers, setOpenCheckers ] = useState(false);
  const [ openHighRiskWorkers, setOpenHighRiskWorkers ] = useState(false);
  const [ openLocations, setOpenLocations ] = useState(false);

  const [ metricList, setMetricList ] = useState({
    checkers : [],
    highRisks: [],
    workers: []
  })

  const colors = [
    '#86c5da',
    '#ade6d8',
    '#add8e6',
     '#D8BFD8',
     '#DDA0DD',
     '#EE82EE',
     '#DA70D6',
     '#FF00FF',
     '#FF00FF',
     '#BA55D3',
    '#9370DB',
    '#FFC0CB',
     '#FFB6C1',
     '#FF69B4'
  ];

  const columns = [
    { id: 'id', label: 'ID', minWidth: 85, align: 'left' },
    { id: 'idNumber', label: 'ID Number', minWidth: 50, align: 'left' },
    {
      id: 'name',
      label: 'Name',
      minWidth: 85,
      align: 'left',
      format: (value) => value.toLocaleString('en-US'),
    },
    {
      id: 'role',
      label: 'Role',
      minWidth: 85,
      align: 'left',
      format: (value) => value.toLocaleString('en-US'),
    },
    {
      id: 'gender',
      label: 'Gender',
      minWidth: 85,
      align: 'left',
      format: (value) => value.toFixed(2),
    },
  ];

  useEffect(() => {
    console.log(props);
    // if(!props.company_details.company) {
      getCompanyDetails();
    // } else {
    //   setCompany(props.company_details.company);
    // }
  }, []);

  function getCompanyDetails() {
    corporateHealthService.getPartnerDetails(props.company).then(
      response => {
        setCompany(response);
        // if(!props.company_details.company) {
        //   props.company_details.update(response);
        // }
      }, error => {

      }
    )
  }


  const handleOpenWorkers = event => {
    corporateLocationService.getFieldWorkerPositions(props.company).then(
      response => {
        setMetricList({...metricList, workers: response.list});
        setOpenWorkers(true);
      }, error => {

      }
    )
  }

  const handleOpenCheckers = event => {
    corporateLocationService.getFieldWorkerPositions(props.company).then(
      response => {
        setMetricList({...metricList, checkers: response.list.filter(worker => worker.is_checker)});
        setOpenCheckers(true);
      }, error => {

      }
    )
  }

  return (
    <div className={classes.root}>
      { openWorkers && <HealthRiskDialog open={openWorkers} handleClose={() => { setOpenWorkers(false)}} mw="sm"
        styling={{ color: 'white', backgroundColor: '#211f1f'}}
        title={'Field Workers in the Perimeter'}>
          <UserReportList list={metricList.workers} />
      </HealthRiskDialog> }
      { openCheckers && <HealthRiskDialog open={openCheckers} handleClose={() => { setOpenCheckers(false)}} mw="md"
        styling={{ color: 'white', backgroundColor: '#211f1f'}}
        title={'Health Checkers in the Perimeter'}>
          <UserReportList list={metricList.checkers} />
      </HealthRiskDialog> }
      {/* { openHighRiskWorkers && <HealthRiskDialog open={openHighRiskWorkers} handleClose={() => { setOpenHighRiskWorkers(false)}} mw="sm"
        title={'High Risk Workers'}>
        <div>
          High Risk Workers
        </div>
      </HealthRiskDialog> }
      { openLocations && <HealthRiskDialog open={openLocations} handleClose={() => { setOpenLocations(false)}} mw="sm"
        title={'Locations'}>
        <div>
          Locations
        </div>
      </HealthRiskDialog> } */}
      <Grid container spacing={2} justify="center" alignItems="center">
          <Grid
            container item
            xs={12} spacing={4}
            justify="center" alignItems="center"
          >
            <Grid
              item
              sm={3}
              xs={12}
            >
              <EmployeeCard company={props.company} clickEvent={handleOpenWorkers} count={company.employeeCount || 0} />
            </Grid>
            <Grid
              item
              sm={3}
              xs={12}
            >
              <HealthCheckerCard clickEvent={handleOpenCheckers} count={company.checkerCount || 0}/>
            </Grid>
            <Grid
              item
              sm={3}
              xs={12}
            >
              <OfficeCard clickEvent={() => { setOpenLocations(true); }} count={company.addresses || 0}/>
            </Grid>
            <Grid
              item
              sm={3}
              xs={12}
            >
              <HighRiskCard count={company.quarantineCount || 0}/>
            </Grid>
          </Grid>
          {/* <Grid item container xs={12}>
              <DailyLogs
                  partnerId={props.company} logType={"GATE_ACCESS"}
                  showFilters={{
                      name: {
                          md: 4
                      },
                      dateRange: {
                          md: 3
                      },
                      location:  {
                          md: 3
                      }
                  }}
                  prependColumns={[
                      { id: "location", label: "LOCATION" }
                  ]}
              />
          </Grid> */}
      </Grid>
    </div>
  );
};

export default Dashboard;
