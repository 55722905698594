import React, { useState, useEffect } from "react"
import {
    Avatar, FormLabel,
    Badge,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    Typography,
    RadioGroup,
    Radio,
    FormControl,
    FormControlLabel,
    Divider, IconButton,
} from "@material-ui/core";
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider
} from "@material-ui/pickers";
import {
    makeStyles, withStyles
} from "@material-ui/core/styles";
import {
    CameraAlt as CameraAltIcon, Close as CloseIcon
} from "@material-ui/icons";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import clsx from "clsx";
import FixedTags from './../components/fixedTags';
import corporateHealthService from './../services/corporate-health.service';
import corporateEmployeeService from './../services/corporate-employee.service';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import logger, { snackbar } from './../services/logger.service';

const useStyles = makeStyles(theme => ({
    grow: {
        flexGrow: 1,
    },
    bold: {
        fontWeight: "bold"
    },
    textDefaultColor: {
        color: "#494949"
    },
    textSuccessColor: {
        color: "#70BE5C"
    },
    textRight: {
        textAlign: "right"
    },
    subtitle1: {
        fontSize: "1rem"
    },
    fullWidth: {
        width: "100%"
    },
    largeAvatar: {
        width: theme.spacing(12),
        height: theme.spacing(12),
        margin: theme.spacing(1)
    },
}));

const EmployeeAccessRestrictions = (props) => {
    const classes = useStyles();
    const { open, handleClose, employee } = props;
    const [ state, setState ] = useState({
        restrictionType: ''
    })
    // const [ open, setOpen ] = useState(false);
    const [ options, setOptions ] = useState([]);
    const [ loading, setLoading ] = useState(false);
    const [ fixedLocationTypeOptions, setFixedLocationTypeOptions] = useState([]);
    const [ fixedOptions, setFixedOptions] = useState([]);
    const [value, setValue] = useState([]);
    const [value2, setValue2 ] = useState([]);
    const { enqueueSnackbar } = snackbar()
    const [ restrictionTypes, setRestrictionTypes ] = useState([
        {
            label: 'Location Type',
            value: 'LOCATION_TYPE'
        },
        {
            label: 'Location Name',
            value: 'LOCATION_NAME'
        },
        {
            label: 'None',
            value: ''
        }
    ])
    const [ locationTypes, setLocationTypes ] = useState([]);

    // const handleClose = event => {
    //     setOpen(false);
    // }

    const styles = (theme) => ({
        root: {
          margin: 0,
          padding: theme.spacing(2),
        },
        closeButton: {
          position: 'absolute',
          right: theme.spacing(1),
          top: theme.spacing(1),
          color: theme.palette.grey[500],
        },
        fullLength: {
            width: '100%'
        }
      });
    

    useEffect(() => {
        getPartnerLocations({})
        getLocationTypes();
        getEmployeeAccessRestrictions(employee.employee.employee_id);
    }, [])

    function getEmployeeAccessRestrictions(employeeId) {
        Promise.all(
            [
                corporateEmployeeService.getEmployeeLocationTypeRestrictions(employeeId),
                corporateEmployeeService.getEmployeeLocationRestrictions(employeeId)
            ]
        ).then(responses => {
            if(responses[0].list.length >= 1) {
                setState({
                    restrictionType: 'LOCATION_TYPE'
                })
            }

            if(responses[1].list.length >= 1) {
                setState({
                    restrictionType: 'LOCATION_NAME'
                })
            }

            setValue(responses[0].list.map(l => l.location_type));
            setFixedLocationTypeOptions(responses[0].list.map(l => l.location_type));
            setValue2(responses[1].list.map(l => l.location));
            setFixedOptions(responses[1].list.map(l => l.location ));
        })
    }

    const DialogTitle = withStyles(styles)((props) => {
        const { children, classes, onClose, ...other } = props;
        return (
          <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <div>{children}</div>
            {onClose ? (
              <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                <CloseIcon />
              </IconButton>
            ) : null}
          </MuiDialogTitle>
        );
      });

    function getPartnerLocations(p) {
        setLoading(true);
        corporateHealthService.listLocationCounts(props.company, p).then(
            response => {
                var newList = response.list.map((location, index) => {
                    return { "id": location.location_id , "label": location.location_label }
                });
                console.log(newList);

                setOptions(newList);

                setLoading(false);
            }, error => {
                setLoading(false);
            }
        )
    }

    function getLocationTypes() {
        setLoading(true);
        corporateHealthService.listLocationTypes().then(
            response => {
                setLocationTypes(response.types);
                setLoading(false);
            }, error => {
                setLoading(false);
            }
        )
    }

    const handleChangeState = name => event => {
        setState({
            [name]: event.target.value
        })
    }

    const handleChange = (event, newValue) => {
        setValue2([...newValue]);
    }

    const handleChangeTypes = (event, newTypes ) => {
        setValue([...newTypes])
    }

    const handleDone = event => {
        var deleteLocationTypes = fixedLocationTypeOptions.filter(v => !value.includes(v)).map(lt => lt.id);
        var deleteLocations  = fixedOptions.filter(v => !value2.includes(v)).map(l => l.id);
        var addLocationTypes = value.filter(v => !fixedLocationTypeOptions.includes(v)).map(lt => lt.id);
        var addLocations = value2.filter(v => !fixedOptions.includes(v)).map(l => l.id);

        var searchParams = new URLSearchParams();
        deleteLocationTypes.map((dlt, index) => {
            searchParams.append('location_types', dlt);
        })

        var searchParams2 = new URLSearchParams();
        deleteLocations.map((dl, index) => {
            searchParams2.append('locations', dl);
        })

        var params = [
            searchParams,
            searchParams2,
            { location_types: addLocationTypes },
            { locations: addLocations }
        ];

        updateRestrictions(params);
    }

    function updateRestrictions(params) {
        Promise.all(
            [
                corporateEmployeeService.deleteEmployeeLocationTypeRestrictions(employee.employee.employee_id, params[0]),
                corporateEmployeeService.deleteEmployeeLocationRestrictions(employee.employee.employee_id, params[1]),
                corporateEmployeeService.addEmployeeLocationTypeRestrictions(employee.employee.employee_id, params[2]),
                corporateEmployeeService.addEmployeeLocationRestrictions(employee.employee.employee_id, params[3])
            ]
        ).then(responses => {
            logger.success(enqueueSnackbar, `Successfully updated worker's access restrictions`);
        });
        // corporateEmployeeService.addEmployeeLocationTypeRestrictions(employee.employee.employee_id,
        //     params).then(
        //     response => {
        //         logger.success(enqueueSnackbar, `Successfully updated worker's access restrictions`);
        //     }
        // );
    }

    // function updateLocationRestrictions(params) {
    //     corporateEmployeeService.addEmployeeLocationRestrictions(employee.employee.employee_id, 
    //         params).then(
    //         response => {
    //             logger.success(enqueueSnackbar, `Successfully updated worker's access restrictions`);
    //         }
    //     );
    // }

    return(
        <Dialog
            maxWidth="md"
            open={open}
            onClose={handleClose}
            aria-labelledby="employee-access-restrictions"
        >
            <DialogTitle id="employee-access-dialog-title"
                onClose={handleClose}>
                <Typography component="span" variant="h6">
                   Edit Employee Access Restrictions
                </Typography>
            </DialogTitle>
            <DialogContent dividers>
                <Grid item container justify="flex-start" alignItems="flex-start">
                    <Grid item xs={12}>
                        <Typography variant="body1">
                            Location Types
                        </Typography>
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: 10}}>
                        <FixedTags options={locationTypes || []} fixedOptions={[]} value={value} handleChange={handleChangeTypes} valueType={'location_label'} placeholder="Select Location Type" label="Restrict on Location Types" />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1">
                            Locations
                        </Typography>
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: 10 }}>
                        <FixedTags options={options || []} fixedOptions={[]} value={value2} handleChange={handleChange} placeholder="Search Location" valueType={'label'} label="Restriction on Locations" />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                {/* <Button onClick={handleClose} color="primary">
                    Cancel
                </Button> */}
                <Button onClick={handleDone} color="primary" variant="outlined">
                    Update
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default EmployeeAccessRestrictions;