import { Avatar, Chip, Link, makeStyles, Typography } from "@material-ui/core";
import { LocalHospital } from "@material-ui/icons";
import moment from "moment";
import { SelectColumn, SelectCellFormatter } from "react-data-grid";
import { PEOPLE_TYPE } from "./employeeList";
import { Link as RouterLink } from "react-router-dom";
import { ListItem } from "@material-ui/core";
import { SeverityProgress } from "../components/SeverityProgress";

function gridColumns({
  relationOptions = [],
  peopleType,
  history,
  onAllRowsSelectionChange = () => {},
  allRowsSelected = false,
  vaccineData
}) {
  const baseColumns = [
    {
      ...SelectColumn,
      columnHeaderRenderer: (props) => {
        return (
          <SelectCellFormatter
            aria-label="Select All"
            value={allRowsSelected}
            onChange={onAllRowsSelectionChange}
          />
        );
      },
    },
    {
      key: "id",
      colId: "id",
      name: "ID",
      hidden: true,
      width: -1,
    },
    {
      key: "employee_id",
      colId: "employee_id",
      name: "Employee ID",
      hidden: true,
      width: -1,
    },
    {
      key: "company_id",
      colId: "company_id",
      name: "Company ID",
      sortable: true,
      frozen: true,
      resizable:true,
      width: 100,
      // cellRenderer({ row }) {
      //   return row.id ? (
      //     <Link
      //       component={RouterLink}
      //       to={`/workers/${row.employee_id}`}
      //     >
      //       <span>{row.company_id}</span>
      //     </Link>
      //   ) : (
      //     <span>{row.company_id}</span>
      //   );
      // },
    },
    peopleType === PEOPLE_TYPE.DEPENDENT && {
      key: "primary_employee_id",
      colId: "primary_employee_id",
      name: "Primary",
      sortable: true,
      minWidth: 200,
      tooltip({row}) {
        return `${row?.primary_employee?.lastName}, ${row?.primary_employee?.firstName}`
      },
      cellRenderer({ row }) {
        return row?.primary_employee ? (
          <Link
            component={RouterLink}
            to={`/workers/${row?.primary_employee?.id}`}
          >
          
            <Avatar
              src={row?.primary_employee?.profile_picture_url}
              alt={"user_logo"}
              style={{ width: 30, height: 30 }}
            />
            <Typography variant="body2" style={{ marginLeft: 5 }}>
              {row?.primary_employee?.lastName}, {row?.primary_employee?.firstName}
            </Typography>
          
          </Link>
        ) : (
          <span>{row.primary_employee_id}</span>
        );
      },
    },
    {
      key: "name",
      colId: "name",
      name: "Name",
      minWidth: 200,
      resizable: true,
      sortable: true,
      frozen: true,
      filter: {
        type: "text",
        label: "Contains",
        default: "",
      },
      cellRenderer({ row }) {
        return row.id ? (
          <Link
            component={RouterLink}
            to={`/workers/${row.employee_id}`}
          >
          <ListItem dense>
            <Avatar
              src={row.profile_picture_url}
              alt={"user_logo"}
              style={{ width: 30, height: 30 }}
            />
            <Typography variant="body2" style={{ marginLeft: 5 }}>
              {row.name}
            </Typography>
          </ListItem>
          </Link>
        ) : (
          <span>{row.name}</span>
        );
      },
    },
    peopleType === PEOPLE_TYPE.DEPENDENT && {
      key: "relationship",
      colId: "relationship",
      name: "Relation",
      sortable: false,
      resizable: true,
      filter: {
        type: "option",
        label: "Relation",
        options: relationOptions,
      },
    },
    {
      key: "first_dose_date",
      colId: "first_dose_date",
      name: "Covid Vaccine First Dose",
      minWidth: 200,
      resizable: true,
      sortable: true,
      frozen: false,
      cellRenderer({ row }) {
        if(row.vaccine===undefined) return null
        return row.vaccine?.first_dose_date===null? <Typography variant="caption" component="span" color="textSecondary">No vaccination record</Typography>:<span>{`${moment(row.vaccine?.first_dose_date).format("MMM DD, YYYY")} - ${row.vaccine?.brand}`}</span>
      },
    },
    {
      key: 'vaccine_countdown',
      colId: 'vaccine_countdown',
      name: 'Second Dose Eligibility',
      minWidth: 150,
      resizable: true,
      cellRenderer({ row }) {
        if( ( row.vaccine?.first_dose_date ? 1 : 0 ) ^ ( row.vaccine?.second_dose_date ? 1 : 0 ) ) {
          
          console.log("📢[employeeData.js:165]: ", vaccineData);
          const end = moment(row.vaccine?.first_dose_date); // another date
          const days = moment().diff(end, 'days')
          const vacc = vaccineData.find(v => v.name === row.vaccine?.brand)
          const vaccFrequency = vacc?.frequency
          if (vaccFrequency <= 0 ) {
            return <Typography variant="caption" component="span" color="textSecondary">NA</Typography>
          }  
          return (
            <SeverityProgress 
              value={days}
              maxValue={vaccFrequency} 
              unit="days" 
            />
          )
        }
        return <Typography variant="caption" component="span" color="textSecondary">NA</Typography>
      }
    },
    {
      key: "days_since_first_dose",
      colId: "days_since_first_dose",
      name: "Days Since First Dose",
      minWidth: 150,
      resizable: true,
      sortable: true,
      frozen: false,
      cellRenderer({ row }) {
        const end = moment(row.vaccine?.first_dose_date); // another date
        const days = moment().diff(end, 'days')
        if(row.vaccine?.second_dose_date===null && row.vaccine?.first_dose_date===null){
          return <Typography variant="caption" component="span" color="textSecondary">None</Typography>
        }

        if(row.vaccine?.first_dose_date!==null && row.vaccine?.first_dose_date===null){
          return <span>{days}</span>
        }

        if(row.vaccine?.second_dose_date!==null && row.vaccine?.first_dose_date!==null){
          return <span>Done</span>
        }
        return row.vaccine?.second_dose_date===null && row.vaccine?.first_dose_date===null? <span>NA</span>: row.vaccine?.first_dose_date===null? <Typography variant="caption" component="span" color="textSecondary">NA</Typography>:<span>{`${days}`}</span>
      },
    },
    {
      key: "second_dose_date",
      colId: "second_dose_date",
      name: "Covid Vaccine Second Dose",
      minWidth:200,
      resizable: true,
      sortable: true,
      frozen: false,
      cellRenderer({ row }) {
        if(row.vaccine===undefined) return null
        return row.vaccine?.second_dose_date===null? <Typography variant="caption" component="span" color="textSecondary">No vaccination record</Typography>:<span>{`${moment(row.vaccine?.second_dose_date).format("MMM DD, YYYY")} - ${row.vaccine?.brand}`}</span>
      },
    },
    {
      key: "vaccination_status",
      colId: "vaccination_status",
      name: "Vaccination Status",
      minWidth: 150,
      resizable: true,
      sortable: true,
      frozen: false,
      cellRenderer({row}) {
        if(row.vaccine===undefined) return null
        const style =
          row.vaccine?.first_dose_date!==null && row.vaccine?.second_dose_date !== null
            ? {
                backgroundColor: "#C4EDC6",
                color: "#54A557",
                border: "1px solid #C4EDC6",
                borderRadius: 5,
              }
            : row.vaccine?.first_dose_date!==null && row.vaccine?.second_dose_date === null? {
                backgroundColor: "#DBEDFF",
                color: "#6A99CA",
                border: "1px solid #DBEDFF",
                borderRadius: 5,
              }:{
                backgroundColor: "#FFDBD8",
                color: "#E8685E",
                border: "1px solid #FFDBD8",
                borderRadius: 5,
              };
        const label =  row.vaccine?.first_dose_date!==null && row.vaccine?.second_dose_date !== null ? "VACCINATED" :  row.vaccine?.first_dose_date===null && row.vaccine?.second_dose_date === null? "NOT VACCINATED": "FIRST DOSE";
        return (
          <Chip
            size="small"
            style={style}
            label={
              <Typography variant="caption" style={{ fontWeight: 700 }}>
                {label}
              </Typography>
            }
          />
        );
      },
      noTooltip: true,
      // filter: {
      //   type: "option",
      //   label: "Type",
      //   options: [
      //     {
      //       label: "Vaccinated",
      //       value: "vaccinated",
      //     },
      //     {
      //       label: "Not Vaccinated",
      //       value: "notvaccinated",
      //     },
      //     {
      //       label: "First Dose",
      //       value: "firstdose",
      //     },
      //   ],
      // },
    },
    peopleType === PEOPLE_TYPE.THIRDPARTY && {
      key: "third_party_employer",
      colId: "third_party_employer",
      name: "3rd Party Employer",
      sortable: false,
      resizable: true,
      minWidth: 160
    },
    {
      key: "role",
      colId: "role",
      name: "Role",
      sortable: true,
      resizable: true,
      hidden:true,
    },
    {
      key: "business_unit",
      colId: "business_unit",
      name: "Business Unit",
      sortable: true,
      resizable: true,
      hidden:true,
    },
    {
      key: "department",
      colId: "department",
      name: "Department",
      sortable: true,
      resizable: true,
      hidden:true,
    },
    {
      key: "worker_type",
      colId: "worker_type",
      name: "Worker Type",
      sortable: false,
      resizable: true,
      hidden:true,
      filter: {
        type: "option",
        label: "Type",
        options: [
          {
            label: "Internal",
            value: "Internal",
          },
          {
            label: "Third-Party",
            value: "Third-Party",
          },
        ],
      },
    },
    {
      key: "work_nature",
      colId: "work_nature",
      name: "Work Nature",
      sortable: true,
      hidden:true,
      resizable: true,
      // filter: {
      //   type: "option",
      //   label: "Nature",
      //   options: [
      //     {
      //       label: "Office",
      //       value: 0,
      //     },
      //     {
      //       label: "Field",
      //       value: 1,
      //     },
      //   ],
      // },
    },
    {
      key: "status",
      colId: "status",
      name: "Link Status",
      sortable: false,
      resizable: true,
      width: 125,
      cellRenderer(props) {
        const style =
          props?.row.status === "LINKED"
            ? {
                backgroundColor: "#C4EDC6",
                color: "#54A557",
                border: "1px solid #C4EDC6",
                borderRadius: 5,
              }
            : {
                backgroundColor: "#FFDBD8",
                color: "#E8685E",
                border: "1px solid #FFDBD8",
                borderRadius: 5,
              };
        const label = props?.row.status === "LINKED" ? "LINKED" : "NOT LINKED";
        return (
          <Chip
            size="small"
            style={style}
            label={
              <Typography variant="caption" style={{ fontWeight: 700 }}>
                {label}
              </Typography>
            }
          />
        );
      },

      noTooltip: true,
      filter: {
        type: "option",
        label: "Type",
        options: [
          {
            label: "Linked",
            value: "Linked",
          },
          {
            label: "Not Linked",
            value: "Not Linked",
          },
        ],
      },
    },
    {
      key: "location",
      colId: "location",
      name: "Address",
      resizable: true,
      // filter: {
      //   type: "text",
      //   label: "Search",
      // },
      width: 250,
      cellRenderer({ row }) {
        return (
          <div className="doubleRow" style={{alignItems:'start'}} >{row?.location}</div>
        );
      },
    },
    {
      key: "gender",
      colId: "gender",
      name: "Gender",
      sortable: true,
      resizable: true,
    },
    {
      key: "age",
      colId: "age",
      name: "Age",
      sortable: true,
      resizable: true,
    },
    {
      key: "civil_status",
      colId: "civil_status",
      name: "Civil status",
      sortable: true,
      resizable: true,
    },
    // {
    //     key:"vaccine_firstdose",
    //     colId: "vaccine_firstdose",
    //     name: "Vaccine First Dose",
    //     minWidth: 200,
    //     resizable:true,
    //     tooltip({row}) {
    //       return row?.vaccine_firstdose??''
    //     },
    //     sortable:true,
    //     cellRenderer({row}) {
    //       return <Typography variant="body2" color={row?.vaccine_firstdose?'textPrimary':'textSecondary'}>{row?.vaccine_firstdose??'No vaccine record'}</Typography>
    //     }
    // },
    // {
    //     key:"vaccine_days",
    //     colId: "vaccine_days",
    //     name: "Days since first dose",
    //     minWidth: 100,
    //     resizable:true,
    //     noTooltip: true,
    //     sortable:true,
    // },
    // {
    //     key:"vaccine_seconddose",
    //     colId: "vaccine_seconddose",
    //     name: "Vaccine Second Dose",
    //     minWidth: 200,
    //     resizable:true,
    //     tooltip({row}) {
    //       return row?.vaccine_seconddose??''
    //     },
    //     sortable:true,
    //     cellRenderer({row}) {
    //       return <Typography variant="body2" color={row?.vaccine_seconddose?'textPrimary':'textSecondary'}>{row?.vaccine_seconddose??'No vaccine record'}</Typography>
    //     }
    // },
    // {
    //     key:"vaccine",
    //     colId: "vaccine",
    //     name: "Vaccine Status",
    //     minWidth: 140,
    //     resizable:true,
    //     noTooltip: true,
    //     sortable:true,
    //     cellRenderer({row}) {
    //         const vaccinated ={
    //             backgroundColor: "#C4EDC6",
    //             color: "#54A557",
    //             border: "1px solid #C4EDC6",
    //             borderRadius:5,
    //             fontWeight:700,
    //         }
    //         const notvaccinated ={
    //             backgroundColor: "#FFE3E1",
    //             color: "#ED867E",
    //             border: "1px solid #FFE3E1",
    //             borderRadius:5,
    //             fontWeight:700,
    //         }
    //         const firstdose ={
    //             backgroundColor: "#DBEDFF",
    //             color: "#86AED7",
    //             border: "1px solid #DBEDFF",
    //             borderRadius:5,
    //             fontWeight:700,
    //         }
    //         let vaccClass = {}
    //         let vaccStatusLabel = 'NOT VACCINATED'
    //         if (row?.vaccine?.first_dose_date && row?.vaccine?.second_dose_date) {
    //             vaccClass = vaccinated
    //             vaccStatusLabel = 'VACCINATED'
    //         } else if (row?.vaccine?.first_dose_date) {
    //             vaccClass = firstdose
    //             vaccStatusLabel = 'FIRST DOSE'
    //         } else {
    //             vaccClass = notvaccinated
    //             vaccStatusLabel = 'NOT VACCINATED'
    //         }
    //         return (
    //             <Chip
    //             size="small"
    //             style={vaccClass}
    //             label={
    //               <Typography variant="caption" style={{ fontWeight: 700 }}>
    //                 {vaccStatusLabel}
    //               </Typography>
    //             }
    //             />
    //         );
    //     } 
    // },
    {
      key: "allergies",
      colId: "allergies",
      name: "Allergies",
      sortable: true,
      resizable: true,
      minWidth: 200,
      // filter: {
      //   type: "option",
      //   label: "Type",
      //   options: [
      //     { value: "Drug", label: "Drug" },
      //     { value: "Food", label: "Food" },
      //     { value: "Insect", label: "Insect" },
      //     { value: "Latex", label: "Latex" },
      //     { value: "Mold", label: "Mold" },
      //     { value: "Pet", label: "Pet" },
      //     { value: "Pollen", label: "Pollen" },
      //   ],
      // },
      cellRenderer({ row }) {
        return (
          <div className="doubleRow" style={{alignItems:'start'}} >{row?.allergies}</div>
        );
      },
    },
    {
      key: "comorbidities",
      colId: "comorbidities",
      name: "Comorbidities",
      sortable: true,
      resizable: true,
      minWidth: 200,
      // filter: {
      //   type: "option",
      //   label: "Type",
      //   options: [
      //     { value: "Hypertension", label: "Hypertension" },
      //     { value: "Heart Disease", label: "Heart Disease" },
      //     { value: "Kidney Disease", label: "Kidney Disease" },
      //     { value: "Diabetes", label: "Diabetes" },
      //     { value: "Asthma", label: "Asthma" },
      //     { value: "Immunodeficiency", label: "Immunodeficiency" },
      //     { value: "Cancer", label: "Cancer" },
      //     { value: "Others", label: "Others" },
      //   ],
      // },
      cellRenderer({ row }) {
        return (
          <div className="doubleRow" style={{alignItems:'start'}} >{row?.allergies}</div>
        );
      },
    },
  ];

  return [...baseColumns].filter((col) => !!col);
}

function gridRows(rows) {
  return rows.map((employee, index) => {
    let vaccine_days = moment().diff(moment(employee?.vaccine?.first_dose_date), 'days')
    let vaccine_firstdose = employee?.vaccine?.first_dose_date ? `${employee?.vaccine?.brand} - ${moment(employee?.vaccine?.first_dose_date).format('LL')}` :null
    let vaccine_seconddose = employee?.vaccine?.second_dose_date ? `${employee?.vaccine?.brand} - ${moment(employee?.vaccine?.second_dose_date).format('LL')}`:null
    if (employee?.vaccine?.first_dose_date && !employee?.vaccine?.second_dose_date) {
      vaccine_seconddose = 'TBA'
    }
    if (!employee?.vaccine?.first_dose_date && !employee?.vaccine?.second_dose_date) {
      vaccine_days = ''
    }
    if (employee?.vaccine?.first_dose_date && employee?.vaccine?.second_dose_date) {
      vaccine_days = 'N/A'
    }

    const age = employee.birth_date ? moment.utc().diff(moment.utc(employee.birth_date), 'years') : '-'
    return {
      ...employee,
      age,
      employee_id: employee.id,
      id: employee.reference_id || "-",
      name: employee.last_name + ", " + employee.first_name,
      company_id: employee.employee_id || "-",
      dependent_id: employee.employee_id || "-",
      role: employee.role || "-",
      department: employee.department || "-",
      business_unit: employee.business_unit || "-",
      work_nature: employee.work_type || "-",
      worker_type: employee.third_party_employer ? "Third-Party" : "Internal",
      status: employee.status,
      civil_status: employee.civil_status || "",
      gender: employee.gender || 'Unspecified',
      vaccine_days,
      vaccine_firstdose:vaccine_firstdose,
      vaccine_seconddose:vaccine_seconddose,
    };
  });
}
export { gridColumns, gridRows };
