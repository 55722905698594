import {
    Button,
    TablePagination,
} from "@material-ui/core";
import React, { useCallback, useState } from "react";

import {
    DataGrid, GridBox,
} from "@zennya/web-component-library/src/components/DataGrid";
import DataGridProvider, {
    actions as gridActions,
    DataGridContext,
} from "@zennya/web-component-library/src/components/DataGrid/DataGridContext";
import { cohortsData } from "./cohortsData";
import { Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useConfirm } from "material-ui-confirm";
import corporateCohortsService from "../services/corporate-cohorts.service";

const useStyles = makeStyles(theme => ({
    linkColor: {
        color: theme.palette.link.main
    }
}));

function CohortsList(props) {
    const classes = useStyles();
    const [state, dispatch] = React.useContext(DataGridContext);
    const [columns, setColumns] = React.useState([]);
    const [rows, setRows] = React.useState([]);
    const iterator = props.iterator;
    const companyId = props.company;
    const handleFocusedCohort = props.handleFocusedCohort;
    const [params, setParams] = useState({
        page_size: 10,
        page_offset: 0,
    });
    const [pages, setPages] = useState({ count: 0, pages: 0 });
    const [page, setPage] = useState(0);
    const [selectedRows, setSelectedRows] = useState(() => new Set());

    const confirm = useConfirm()

    React.useEffect(()=> {
        dispatch({
            type: gridActions.SET_LOADING
        })
    }, [])

    const loadCohorts = useCallback(
        async () => {
            dispatch({
                type: gridActions.SET_LOADING
            })
            const response = await cohortsData.getProcessedCohortsList(companyId, params, cohortClickCallback, cohortDeleteCallback);
            var repackaged = response.rows.map((cohort, index)=>{
                return {
                    id: cohort.id,
                    label: cohort.label,
                    description: cohort.description,
                    entry_count: cohort.entry_count,
                    date_created: cohort.date_created,
                    last_updated: cohort.last_updated
                }
            })
            setColumns(response.columns);
            setRows(repackaged);
            setPages({
                count: response.count,
                pages: Math.ceil(response.count / params.page_size),
            });
            dispatch({
                type: gridActions.SET_DONE_LOADING
            })
        },
        [companyId, dispatch, params],
    );
    const cohortClickCallback = useCallback((cohort) => {
        handleFocusedCohort(cohort)
    }, [handleFocusedCohort]);

    const cohortDeleteCallback = useCallback((cohort) => {
        confirm({
            description: 'Are you sure you want to delete this cohort?',
            cancellationButtonProps: { autoFocus: true },
        })
        .then(async() => {
            const data = await corporateCohortsService.deleteCohort(cohort.id)
            await loadCohorts()
        })
        .catch(() => {});
    }, [confirm, loadCohorts]);

    const deleteMultipleCohorts = useCallback(
        async() => {
            const requests = await Promise.allSettled(
                Array.from(selectedRows).map(async(cohortId) => {
                    const response = await corporateCohortsService.deleteCohort(cohortId)
                    return response
                })
            )
            setSelectedRows(new Set())
            await loadCohorts()
        },
        [selectedRows],
    );
    React.useEffect(()=>{
        loadCohorts();
    }, [iterator]);

    React.useEffect(() => {
        loadCohorts();
    }, [companyId, params]);

    React.useEffect(() => {
        dispatch({
            payload: {
                columns,
            },
            type: gridActions.LOAD_COLUMNS,
        });
    }, [columns]);

    React.useEffect(() => {
        dispatch({
            payload: {
                rows,
            },
            type: gridActions.LOAD_ROWS,
        });
    }, [rows]);

    const onChangePage = useCallback((evt, newPage) => {
        setParams({
            ...params,
            page_offset: (newPage + 1) * params.page_size - params.page_size,
        });
        setPage(newPage);
    }, []);

    const onChangeRowsPerPage = useCallback((evt) => {
        setParams({
            ...params,
            page_size: parseInt(evt.target.value, 10),
        });
    }, []);

    const renderDeleteMultiple = useCallback(
        () => {
            if(selectedRows.size>0) {
                return <Button variant="contained" color="primary" onClick={()=>{
                    confirm({
                        description: `Are you sure you want to delete selected ${selectedRows.size} cohorts?`,
                    })
                    .then(()=>deleteMultipleCohorts())
                    .catch(()=>{})
                }}>Delete selected ({selectedRows.size})</Button>
            }
        },
        [confirm, selectedRows.size],
    );
    return (
        <div style={{ display: "flex", textAlign: "left", flexDirection: "column" }}>
            <DataGrid
                style={{ minHeight: "70vh" }}
                containerStyle={{
                    minHeight: "70vh",
                    display: "flex",
                    flexDirection: "column",
                }}
                showSelector
                filterable={false}
                columns={columns}
                rows={rows}
                gridProps={{
                    rowHeight: 45,
                    selectedRows: selectedRows,
                    onSelectedRowsChange: (rows) => {
                        setSelectedRows(rows);
                    },
                    rowKeyGetter: (row) => {
                        return row.id;
                    },
                }}
                leftAccessory={()=>{
                    return renderDeleteMultiple()
                }}
            />
            <TablePagination
                component="div"
                count={pages.count}
                page={page}
                onChangePage={onChangePage}
                onChangeRowsPerPage={onChangeRowsPerPage}
                rowsPerPage={params.page_size}
            />
        </div>
    );
}

export default function WrapCohortsList(props) {
    return (
        <DataGridProvider>
            <CohortsList {...props} />
        </DataGridProvider>
    );
}