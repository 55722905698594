import axios from 'axios';
import instance from './helpers/instance';

import qs from 'query-string'

const authenticationService = {
    login,
    providerLogin,
    corporateLogin,
    forgotPassword,
    resetPassword,
    logout
}

const API_URL = process.env.REACT_APP_WEB_ADMIN_URL;

function login(username) {
    var config = {
       headers: {
       }
    };

    return axios.post(`${API_URL}/api/1/employees/login/search`, {
        email: username
    }, config).then(
        response => {
           return response.data;
        }
    );
}

function providerLogin(path, params) {
    const loginUrl = '/api/1/admins/login';

    return axios.post(`${API_URL}${loginUrl}`, params).then(
        user => {
            localStorage.setItem('currentUser', JSON.stringify(user.data));

            const i = axios.create({
                baseUrl: API_URL,
                headers: {
                    'X-Auth-Token': JSON.parse(localStorage.getItem('currentUser')).token
                }
            });

            Object.assign(instance, i);
            return user;
        }
    )
}

function corporateLogin(params) {
    const loginUrl = 'api/1/corporate/admin/login';

    return axios.post(`${API_URL}/${loginUrl}`, params).then(
        user => {
            localStorage.setItem('currentUser', JSON.stringify({ token: user.data ? user.data.token : '' }));

            const i = axios.create({
                baseUrl: API_URL,
                headers: {
                    'X-Auth-Token': JSON.parse(localStorage.getItem('currentUser')).token
                },
                paramsSerializer: (params) => {
                    return qs.stringify(params)
                }
            });

            Object.assign(instance, i);
            return user;
        }
    )
}

function logout() {
    // const reqOptions = {
    //     method: 'POST',
    //     headers: {
    //         'Content-Type': 'application/json',
    //         "Access-Control-Allow-Origin": "*",
    //         'X-Auth-Token': JSON.parse(localStorage.getItem('currentUser')).token
    //     }
    // };

    // return fetch(`${API_URL}/api/logout`, reqOptions)
    //     .then(user => {
            localStorage.removeItem('currentUser');

            const i = axios.create({
                baseUrl: API_URL,
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    'X-Auth-Token': ''
                },
                paramsSerializer: (params) => {
                    return qs.stringify(params)
                }
            });

            Object.assign(instance, i);
    // })
}

function forgotPassword(params) {
    return axios.post(`${API_URL}/api/1/corporate/admin/forgot`, params)
}

function resetPassword(params) {
    return axios.post(`${API_URL}/api/1/corporate/admin/reset`, params)
}

export default authenticationService;

export const getUserFromToken = async () => {
    const user = JSON.parse(localStorage.getItem('currentUser'))

    if (!user) {
        return Promise.reject()
    }

    let userToken = user.token

    return axios.get(`${API_URL}/api/1/corporate/admin/getCurrentUser`, {
        headers: {
            'X-Auth-Token': userToken
        }
    }).then(response => {
        const data = response.data

        data.isAdmin = data.admin && data.admin.roles ? !!data.admin.roles.find(role => role === "ROLE_CORPORATE_ADMIN") : false
        data.isStaff = data.admin && data.admin.roles ? !!data.admin.roles.find(role => role === "ROLE_CORPORATE_STAFF") : false

        return data
    }).catch(err => { throw err })
}

export const getLabelFromRoles = (roles) => {
    if (!Array.isArray(roles) || roles.length === 0) {
        return ""
    }

    let labels = []

    roles.forEach(role => {
        if (role.includes("ROLE_CORPORATE_")) {
            const label = role.replace("ROLE_CORPORATE_", "")

            if (label) {
                labels.push(label)
            }
        }
    })

    if (labels.length === 0) {
        return ""
    }

    if (labels.includes("ADMIN")) {
        return "Admin"
    } else {
        return "Staff"
    }
}
