import React, { useState } from "react"
import {
    makeStyles
} from "@material-ui/core/styles";
import {
    Grid,
    Card,
    CardContent,
    Typography,
    Button,
    TextField,
    CircularProgress,
    InputAdornment,
    IconButton
} from "@material-ui/core"
import {
    useHistory
} from 'react-router-dom';
import {
    Visibility as VisibilityIcon,
    VisibilityOff as VisibilityOffIcon
} from "@material-ui/icons"

import authenticationService from "../services/authentication.service";
import logger, { snackbar } from "../services/logger.service";

const useStyles = makeStyles((theme) => ({
    root: {
    },
    background: {
        backgroundColor: '#87AFD9'
    },
    boldText: {
        fontWeight: 'bold'
    },
    textCenter: {
        textAlign: 'center'
    },
    cardActions: {
        paddingTop: 20,
        paddingBottom: 20
    },
    sendLoginLinkBtn: {

    }
}));

export default function ResetPassword(props) {
    const classes = useStyles()

    const history = useHistory();

    const [ password, setPassword ] = useState('')
    const [ showPassword, setShowPassword ] = useState(false)
    const [ isResettingPassword, setResetPasswordLoader ] = useState(false)

    const { enqueueSnackbar } = snackbar()

    function sendResetPassword() {
        setResetPasswordLoader(true)

        const params = {
            token: new URLSearchParams(props.location.search).get('token'),
            new_password: password
        }

        authenticationService.resetPassword(params)
            .then(() => {
                logger.success(enqueueSnackbar, "Password has been reset")
                setPassword('')

                history.push('/login')
            })
            .catch((err) => {
                if (err && err.response) {
                    const data = err.response.data

                    const message = data.message.en
                    const details = data.details
                    const code = data.code

                    const errMessage = details
                        ? details
                        : message
                            ? message
                            : code

                    logger.err(enqueueSnackbar, errMessage)
                }
            })
            .finally(() => {
                setResetPasswordLoader(false)
            })
    }

    return (
        <Grid
            className={classes.background}
            container
            alignItems="center"
            justify="center"
            style={{ minHeight: '100vh' }}
        >
            <Grid item xs={10} sm={6} md={4}>
                <Card>
                    <CardContent>
                        <Grid container alignItems={"center"} justify={"center"} spacing={2}>
                            <Grid item xs={12}>
                                <img src={require("../assets/images/zennya_health_horizontal.png")} alt={"logo"} />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className={classes.boldText} variant={"h6"}>
                                    Reset Password
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    type={showPassword ? "text" : "password"}
                                    id="password"
                                    autoComplete="current-password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => setShowPassword(show => !show)}
                                                >
                                                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6} style={{ paddingBottom: 30 }}>
                                <Button
                                    variant={"contained"}
                                    color={"primary"}
                                    classes={{
                                        textPrimary: '#fff',
                                        containedPrimary: classes.background
                                    }}
                                    fullWidth
                                    onClick={sendResetPassword}
                                    disabled={isResettingPassword || !password || password.length < 8}
                                >
                                    {
                                        isResettingPassword
                                            ? (
                                                <CircularProgress size={25} color={"primary"} />
                                            )
                                            : 'Reset'
                                    }
                                </Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )
}
