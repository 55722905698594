import React, { useEffect, useState } from "react";
import { SelectColumn } from "react-data-grid";
import {
  DataGrid,
  GridBox,
} from "@zennya/web-component-library/src/components/DataGrid";
import DataGridProvider, {
  actions as gridActions,
  DataGridContext,
} from "@zennya/web-component-library/src/components/DataGrid/DataGridContext";
import { Box, LinearProgress, TablePagination } from "@material-ui/core";
import isEmpty from "lodash.isempty";

function EmployeeGrid({
  company,
  listCall=()=>{},
  handlePage,
  handleFilterChange=()=>{},
  handleSort=()=>{},
  selectedRows,
  onSelectedRows=()=>{},
  disablePagination=false,
  rows,
  rowVer,
  loading,
  compact=false,
  ...props

}) {
  const [state, dispatch] = React.useContext(DataGridContext);

  const [columns, setColumns] = useState([]);
  
  const [selectedRowIds, setSelectedRowIds] = useState(()=>new Set());
  const [hasFilterToolbar, setHasFilterToolbar] = useState(false);
  
  useEffect(()=> {
    dispatch({
      type: loading?gridActions.SET_LOADING:gridActions.SET_DONE_LOADING
  })
  }, [dispatch, loading])

  useEffect(() => {
    setColumns(props.columns);

  }, [props.columns]);
  
  useEffect(() => {
    dispatch({
      payload: { rows },
      type: gridActions.LOAD_ROWS,
    });
  }, [rows, rowVer, dispatch]);

  useEffect(() => {
    dispatch({
      payload: { columns },
      type: gridActions.LOAD_COLUMNS,
    });
  }, [columns, dispatch]);

  useEffect(() => {
    handleSort({ column: state.sortColumn, value: state.sortDirection });
  }, [state.sortColumn, state.sortDirection]);

  useEffect(() => {
    handleFilterChange(state.filterColumn)
    if (props.totalCount) {
      setHasFilterToolbar(!isEmpty(props.totalCount));
    } else {
      setHasFilterToolbar(isEmpty(Object.entries(state.filterColumn).filter(f=>f[1]!==undefined)))
    }
  }, [props.totalCount, state.filterColumn])

  useEffect(() => {
    setSelectedRowIds(selectedRows)
  }, [selectedRows]);
  
  const gridStyle=compact?{ flex: '1 1 auto'}:{ flex: '1 1 auto', height: `calc(87vh - ${!hasFilterToolbar?'150':'100'}px)` }
  return (
    <div style={{ display: "flex", flexDirection: "column", height: compact?"auto":`87vh` }}>
      <DataGrid
        style={gridStyle}
        containerStyle={{
            flex: '1 1 auto',
            display: 'flex',
            flexDirection: 'column'
        }}
        showSelector
        filterable
        gridProps={{
          emptyRowsRenderer: ()=> {
            return <Box textAlign='center' p="1rem" color="grey">{loading?"Loading...":"Sorry, no matching records found"}</Box>
          },
          selectedRows: selectedRowIds,
          onSelectedRowsChange: (rows) => {
            onSelectedRows(rows)
            setSelectedRowIds(rows);
          },
          rowKeyGetter: (row) => {
            return row.id;
          },
          rowHeight: 60,
        }}
        totalCount={props.count}
        {...props}
        
      />
      {disablePagination?<></>:(
        <TablePagination
          component="div"
          count={props.count}
          page={props.page}
          onChangePage={props.onChangePage}
          onChangeRowsPerPage={props.onChangeRowsPerPage}
          rowsPerPage={props.params.page_size}
          rowsPerPageOptions={[10, 20, 50, 100]}
        />
      )}
    </div>
  );
}

function WrapView(props) {
  return (
    <DataGridProvider>
      <EmployeeGrid {...props} />
    </DataGridProvider>
  );
}
export default WrapView;
