import React, { useState, useEffect } from 'react';
import {
    Grid,
    Typography
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import EventsList from './eventsList';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4),
        flexGrow: 1,
        maxWidth: '1440px'
    },
    officeInfoTitle: {
        padding: 10
    },
    officeInfoSection: {
        padding: 10
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    textLeft: {
        textAlign: 'left'
    },
    marginSpacer: {
        marginRight: 10
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    modalContent: {
        backgroundColor: theme.palette.background.paper,
        border: 0,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(3),
        width: '45%',
    },
    linkColor: {
        color: theme.palette.link.main
    },
    btnTextSize: {
        fontSize: '1.25rem'
    },
    rectangularChip: {
        padding: 5,
        borderRadius: 5,
        fontWeight: 700,
        fontSize: '0.75rem'
    }
}));

const Events = (props) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Grid container spacing={3}>
                <Grid item xs={6} container justify="flex-start" alignItems="flex-start">
                    <Typography variant="h5" style={{ fontWeight: 700 }}>
                        Events List
                    </Typography>
                </Grid>
                {/* <Grid item xs={6} container justify="flex-end" alignItems="flex-end">
                    <Button variant="outlined" color="primary">
                        Create a new Event
                    </Button>
                </Grid>
                <Grid item xs={6} container justify="flex-start" alignItems="flex-start">
                    <FormControl>
                        <Autocomplete
                            options={locations}
							getOptionLabel={item=>item.label}
							onChange={(evt, val)=> {
								setLocation(val)
							}}
                            renderInput={(iParams) => (
								<TextField {...iParams} label='Search locations:'
									variant="outlined"
									fullWidth
									style={{ minWidth: 300 }}
								/>
							)}
							// renderInput={(params) => <TextField {...params} label="Combo box" variant="outlined" />}
                        />
                        
                    </FormControl>
                </Grid> */}
                <Grid item xs={12}>
                    <EventsList {...props} />
                </Grid>
            </Grid>
        </div>
    )
}
    
export default Events;