import { faUserShield } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Avatar,
  Box,
  Card,
  CardHeader,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { MoreVert } from "@material-ui/icons";
import React, { useState } from "react";

const useStyles = makeStyles((theme) => ({
  headerCardRoot: {
    height: "100%",
    textAlign: "start",
  },
  avatar: {
    alignSelf: "baseline",
  },
  tooltip: {
    ...theme.typography.body2,
  },
}));

const HeaderCard = ({
  name,
  color = "#000",
  subheader,
  count,
  tooltip,
  role,
  onAssign = () => {},
  onRemove = () => {},
  ...props
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  return (
    <>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            onAssign(role);
          }}
        >
          Assign {name}
        </MenuItem>
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            onRemove(role);
          }}
        >
          Unassign {name}
        </MenuItem>
      </Menu>
      <Tooltip title={tooltip}>
        <Card className={classes.headerCardRoot}>
          <CardHeader
            classes={{
              avatar: classes.avatar,
            }}
            avatar={
              <Avatar style={{ backgroundColor: color }}>
                <FontAwesomeIcon icon={faUserShield} />
              </Avatar>
            }
            action={
              <IconButton
                onClick={(evt) => {
                  setAnchorEl(evt.currentTarget);
                }}
              >
                <MoreVert />
              </IconButton>
            }
            title={
              <Box
                color="textPrimary"
                fontSize="h5.fontSize"
                fontWeight="fontWeightBold"
              >
                {count}
              </Box>
            }
            subheader={
              <Box className={classes.subheader}>
                <Typography color="textPrimary" variant="body1">
                  {name}
                </Typography>
                <Typography color="textSecondary" variant="body2">
                  {subheader}
                </Typography>
              </Box>
            }
          ></CardHeader>
        </Card>
      </Tooltip>
    </>
  );
};

export { HeaderCard };
