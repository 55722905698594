import React, { useState, useEffect } from "react"
import {
    Badge,
    Button,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    Grid, MenuItem,
    Select,
    TextField,
    Typography,
    IconButton
} from "@material-ui/core";
import {
    CameraAlt as CameraAltIcon,
    Close as CloseIcon
} from "@material-ui/icons";
import {
    makeStyles, withStyles
} from "@material-ui/core/styles";
import { SketchPicker } from "react-color"

import Title from "../../components/Title"
import CorporatePartnerService from "../../services/corporate-partner.service"
import clsx from "clsx";

import AdminsOnlyComponent from "../../components/AdminsOnlyComponent";

import { GetLoggedInUser } from "../../context/UserContext";

import logger, { snackbar } from "../../services/logger.service";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4),
        flexGrow: 1
    },
    bold: {
        fontWeight: "bold"
    },
    textDefaultColor: {
        color: "#494949"
    },
    textRight: {
        textAlign: "right"
    },
    subtitle1: {
        fontSize: "1rem"
    },
    companyDataName: {
        color: "#494949",
        fontWeight: "550"
    },
    companyAvatar: {
        width: theme.spacing(14),
        height: theme.spacing(14),
        objectFit: 'scale-down'
    }
}))

export default function EditCompanyProfileDialog(props) {
    const classes = useStyles()
    const { partnerId, company, callback } = props
    const user = GetLoggedInUser()

    const { enqueueSnackbar } = snackbar()

    const [ companyData, setCompanyData ] = useState({
        brandName: "",
        industry: "",
        bg_color: "#FFF"
    })

    const handleCompanyDataChange = name => event => {
        setCompanyData({
            ...companyData,
            [name]: event.target.value
        });
    }

    const [ modal, setModal ] = useState(false)
    const [ isUpdating, setIsUpdating ] = useState(false)
    const [ imageFile, setImageFile ] = useState(null)
    const [ imageUrl, setImageUrl ] = useState()
    const [ industrySelection, setIndustrySelection ] = useState('')
    const [ industryOptions, setIndustryOptions ] = useState([])

    const [ validFields, setValidFields ] = useState({
        brandName: null
    })
    const [ areFieldsValid, setAreFieldsValid ] = useState(false)

    function handleValidFields (name, value) {
        setValidFields(validFields => {
            const fields = {
                ...validFields,
                [name]: value
            }

            allFieldsValid(fields)

            return fields
        })
    }

    function allFieldsValid (validFields) {
        for (let [key, value] of Object.entries(validFields)) {
            if (!value) {
                setAreFieldsValid(false)
                return
            }
        }

        setAreFieldsValid(true)
    }

    const fieldRules = {
        brandName: (e) => {
            const brandName = e.target.value;

            return brandName ? handleValidFields('brandName', true) : handleValidFields('brandName', false)
        }
    }

    function updateCompany() {
        setIsUpdating(true)

        let params = new FormData()

        if (imageFile) {
            params.append('company_logo', imageFile)
        }

        if (company.brandName !== companyData.brandName) {
            params.append('brand_name', companyData.brandName)
        }

        if (company.description !== companyData.description) {
            params.append('description', companyData.description)
        }

        if (company.industry && company.industry.id !== industrySelection.id) {
            params.append('industry', industrySelection.id)
        }

        if (company.bg_color !== companyData.bg_color) {
            params.append('zennya_app_bg_color', companyData.bg_color)
        }

        CorporatePartnerService.updateCompany(partnerId, params)
            .then(() => {
                logger.success(enqueueSnackbar, "Company profile updated")
                setModal(false)
                callback()
            })
            .finally(() => setIsUpdating(false))
    }

    const SmallAvatar = withStyles((theme) => ({
        root: {
            width: 22,
            height: 22,
            border: `${theme.palette.background.paper}`,
        },
    }))(CameraAltIcon);

    function handleImageClick() {
        document.getElementById("selectImage").click()
    }

    function handleFileSelection(event) {
        const file = event.target.files[0]

        setImageFile(file)

        const reader  = new FileReader();

        reader.addEventListener("load", function () {
            setImageUrl(reader.result)
        }, false);

        if (file) {
            reader.readAsDataURL(file);
        }
    }

    function isCompanyEmpty() {
        return !company || company.constructor !== Object || Object.keys(company).length === 0
    }

    useEffect(() => {
        if (industryOptions.length === 0) {
            CorporatePartnerService.getIndustries()
                .then(data => {
                    setIndustryOptions(options => options.concat(data.list))
                })
        }
    }, [industryOptions])

    useEffect(() => {
        if (!isCompanyEmpty()) {
            setCompanyData({
                ...company,
                bg_color: company.bg_color || ""
            })
            setImageUrl(company.logo)

            if (company.industry) {
                setIndustrySelection(company.industry)
            }

            handleValidFields('brandName', company.brandName)
        }
    }, [company])

    return (
        <React.Fragment>
            <AdminsOnlyComponent>
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => setModal(true)}
                    disabled={isCompanyEmpty() || !user.user.isAdmin}
                >
                    Edit Company Profile
                </Button>
            </AdminsOnlyComponent>
            <Dialog
                maxWidth={"md"}
                fullWidth
                open={modal}
                onClose={() => setModal(false)}
                aria-labelledby="edit-company-profile-dialog"
            >
                <DialogTitle
                    id="edit-company-profile-dialog-title"
                    onClose={ () => setModal(false)}
                    disableTypography
                >
                    <Grid container justify={"space-between"}>
                        <Grid item xs={6}>
                            <Typography className={clsx(classes.bold)} variant={"h6"}>
                                Edit Company Profile
                            </Typography>
                            <span className={clsx(classes.textDefaultColor, classes.subtitle1)}>
                                Details here are displayed in the zennya App
                            </span>
                        </Grid>
                        <Grid item xs={6} className={classes.textRight}>
                            <IconButton onClick={ () => setModal(false)}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent className={clsx(classes.root)} dividers>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Title text={company ? company.label : ""} />
                        </Grid>
                        <Grid item container justify={"center"} xs={12}>
                            <Badge
                                overlap="circle"
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                style={{ cursor: 'pointer' }}
                                badgeContent={<SmallAvatar/>}
                                onClick={handleImageClick}
                            >
                                <input id="selectImage" accept="image/*" hidden type="file" onChange={handleFileSelection} />
                                <img
                                    src={imageUrl}
                                    className={classes.companyAvatar}
                                    alt={"Logo"}
                                />
                            </Badge>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                error={validFields.brandName === false}
                                fullWidth
                                value={companyData.brandName}
                                onChange={handleCompanyDataChange('brandName')}
                                label="Brand Name"
                                color="primary" variant="outlined"
                                required onBlur={fieldRules.brandName}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                value={companyData.description}
                                onChange={handleCompanyDataChange('description')}
                                label="Description"
                                color="primary" variant="outlined"
                                multiline
                                rows={5}
                            />
                        </Grid>
                        <Grid item container alignItems={"center"} xs={12}>
                            <Grid item xs={12} sm={2}>
                                <Typography className={clsx(classes.companyDataName, classes.textLeft)}>
                                    Industry
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={10}>
                                <Select
                                    className={classes.selection}
                                    variant="outlined"
                                    onChange={(event) => {
                                        const industry = industryOptions.find(industry => industry.id === event.target.value)

                                        if (industry) {
                                            setIndustrySelection(industry)
                                        }
                                    }}
                                    fullWidth
                                    renderValue={(value) => value.label}
                                    value={industrySelection}
                                    required
                                >
                                    {
                                        industryOptions.map((option, index) => (
                                            <MenuItem key={index} value={option.id}>
                                                { option.label }
                                            </MenuItem>
                                        ))
                                    }
                                </Select>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: "1%", marginBottom: "1%" }}>
                            <Divider />
                        </Grid>
                        <Grid item container alignItems={"center"} xs={12}>
                            <Grid item xs={12}>
                                <Typography variant={"h6"} className={clsx(classes.textLeft, classes.bold)}>
                                    zennya Worker App Dashboard
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item container alignItems={"center"} xs={12}>
                            <Grid item xs={12} sm={2}>
                                <Typography className={clsx(classes.companyDataName, classes.textLeft)}>
                                    Dashboard Color
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={10}>
                                {
                                    companyData.bg_color
                                        ? <div style={{ backgroundColor: companyData.bg_color, width: "15px", height: "15px" }} />
                                        : <span className={classes.italic}>None</span>
                                }
                            </Grid>
                        </Grid>
                        <Grid item container alignItems={"center"} xs={12}>
                            <Grid item xs={12} sm={2}>
                                <SketchPicker
                                    color={companyData.bg_color}
                                    onChange={(color, event) => {
                                        setCompanyData(data => ({
                                            ...data,
                                            bg_color: color.hex
                                        }))
                                    }}
                                    onChangeComplete={(color, event) => {
                                        if (color.hex !== companyData.bg_color) {
                                            setCompanyData(data => ({
                                                ...data,
                                                bg_color: color.hex
                                            }))
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: "1%", marginBottom: "1%" }}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Button
                                variant={"contained"}
                                color="primary"
                                disabled={!areFieldsValid || isUpdating}
                                onClick={() => updateCompany()}
                                fullWidth
                                style={{ color: "white" }}
                            >
                                {
                                    isUpdating
                                        ? <CircularProgress size={25} />
                                        : "Save"
                                }
                            </Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    )
}
