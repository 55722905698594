import { Grid, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { HeaderCard } from "./HeaderCard";

const useStyles = makeStyles((theme) => ({}));

const UserAdminDashboard = ({ ...props }) => {
  const classes = useStyles();
  const [cardHeaderData, setCardHeaderData] = useState([]);

  useEffect(() => {
    const cardHeaders = [
      {
        name: "Administrator",
        subheader: "Access to All Offices",
        count: 3,
        color: "#6F92B6",
        tooltip: (
          <div className={classes.tooltip}>
            Permissions:
            <ul>
              <li>Read (access to all screens)</li>
              <li>Add Employees</li>
              <li>Add Cohorts and Events</li>
              <li>Add users and permissions</li>
              <li>Update</li>
              <li>Account Linking</li>
              <li>Assign Roles to Workers</li>
              <li>Generate Reportibility</li>
            </ul>
          </div>
        ),
        role: "admin",
      },
      {
        name: "Manager",
        subheader: "Access to Specific Offices",
        count: 6,
        color: "#78BDBA",
        tooltip: (
          <div className={classes.tooltip}>
            Permissions:
            <ul>
              <li>Read (access to all screens)</li>
              <li>Add Cohorts and Events</li>
              <li>Update</li>
              <li>Account Linking</li>
              <li>Assign Roles to Workers</li>
              <li>Generate Reportibility</li>
            </ul>
          </div>
        ),
        role: "manager",
      },
      {
        name: "Office Representative",
        subheader: "Access to Assiged Office",
        count: 10,
        color: "#74BACD",
        tooltip: (
          <div className={classes.tooltip}>
            Permissions:
            <ul>
              <li>Read (access to all screens)</li>
              <li>Account Linking</li>
              <li>Assign Roles to Workers</li>
              <li>Generate Reportibility</li>
            </ul>
          </div>
        ),
        role: "officerep",
      },
      {
        name: "Member",
        subheader: "Access to Assiged Office",
        count: 15,
        color: "#78BDBA",
        tooltip: (
          <div className={classes.tooltip}>
            Permissions:
            <ul>
              <li>Account Linking</li>
            </ul>
          </div>
        ),
        role: "member",
      },
    ];
    setCardHeaderData(cardHeaders);
  }, []);
  return (
    <>
      <Grid container spacing={2}>
        {cardHeaderData.length > 0 &&
          cardHeaderData.map((cardHeader, index) => (
            <Grid
              item
              key={index}
              xs={12}
              sm={6}
              md={12 / cardHeaderData.length}
            >
              <HeaderCard
                {...cardHeader}
                onAssign={(role) => alert(`assign ${role}`)}
                onRemove={(role) => alert(`remove ${role}`)}
              />
            </Grid>
          ))}
      </Grid>
    </>
  );
};

export { UserAdminDashboard };
