import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
    Grid, Button, Typography, IconButton, Dialog, FormControl, InputLabel, Select, MenuItem, TextField
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import {Cancel as CancelIcon } from "@material-ui/icons";
import corporateHealthService from './../services/corporate-health.service';
import corporateEmployeeService from './../services/corporate-employee.service';
import ErrorMessage from "../components/errorMessage";
import SuccessMessage from "../components/successMessage";


const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    fullLength: {
        width: '100%'
    }
});


const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4),
        flexGrow: 1,
        color: '#0000'
    }
}))

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });
    
  const DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiDialogContent);
    
    

const BulkEditEmployeesDialog = (props) => {
    const classes = useStyles();
    const { open, handleClose } = props;
    const [ bus, setBus ] = useState([]);
    const [ sbus, setSbus ] = useState([]);
    const [ depts, setDepts ] = useState([]);
    const [ workerTypes, setWorkerTypes ] = useState([]);
    const attributes = [
        { label: 'Strategic Business Unit', value: 'strategic_business_unit' },
        { label: 'Business Unit', value: 'business_unit'},
        { label: 'Department', value:'department'},
        { label: 'Work Nature', value: 'worker_type'},
        { label: 'Test Frequency', value: 'test_frequency'}
    ];
    const [ availableAttrs, setAvailableAttrs ] = useState(attributes);

    const [customAttrs, setCustomAttrs ] = useState([]);
    const [ notification, setNotification ] = useState({
        message: '',
        show: false,
        isError: false
    })

    function setShowNotification (value) {
        setNotification({
            ...notification,
            show: value
        })
    }

    function createNotification(message, isError) {
        setNotification({
            message: message,
            show: true,
            isError: isError
        })
    }


    useEffect(() => {
        console.log(props);

        corporateHealthService.getCompanyMappings(props.company).then(
            response => {
                setSbus(response.sbus);
                setBus(response.bus);
                setDepts(response.depts);
                setWorkerTypes(response.types);
            }, error => {

            }
        )
    }, []);

    const addAttr = event => {
        setCustomAttrs(customAttrs.concat([{ label : '', val: ''}]));
    }

    const removeAttr = index => event => {
        const newAttrs = customAttrs.filter((x, idx) => idx !== index );

        setCustomAttrs(newAttrs);
    }

    const handleChangeAttrLabel = (index) => event => {
        const newAttr = { ...customAttrs[index], label: event.target.value };

        const newAttrs = customAttrs.filter((x, idx) => idx !== index );

        setCustomAttrs([ ...newAttrs, newAttr ]);
    }

    const handleChangeAttrValue = (index) => event => {
        const newAttr = { ...customAttrs[index], value: event.target.value };

        const newAttrs = customAttrs.filter((x, idx) => idx !== index );

        setCustomAttrs([ ...newAttrs, newAttr]);
    }

    const getListByAttributeLabel = (label) => {
        console.log(label);

        switch(label) {
            case attributes[0].value: return sbus;
            case attributes[1].value: return bus;
            case attributes[2].value: return depts;
            case attributes[3].value: return workerTypes;
            case attributes[4].value: return []; //not implemented yet
            default: return [];
        }
    }

    const updateAll = () => {
        var params = {};

        var employees = props.rows.data.map((e, index) => {
            return {
                id: props.data[e.dataIndex].id ? Number(props.data[e.dataIndex].id) : 0
            }
        });

        params.employees = employees;

        if(customAttrs.filter(x => x.label === 'strategic_business_unit').length > 0) {
           var sbu = customAttrs.filter(x => x.label === 'strategic_business_unit');

           params.strategic_business_unit = sbu[0].value
        }

        if(customAttrs.filter(x => x.label === 'business_unit').length > 0) {
            var bu = customAttrs.filter(x => x.label === 'business_unit');
 
            params.business_unit = bu[0].value
        }

        if(customAttrs.filter(x => x.label === 'department').length > 0) {
            var dept = customAttrs.filter(x => x.label === 'department');
 
            params.department = dept[0].value
        }

        if(customAttrs.filter(x => x.label === 'worker_type').length > 0) {
            var wt = customAttrs.filter(x => x.label === 'worker_type');
 
            params.worker_type = wt[0].value
        }

        corporateEmployeeService.bulkUpdateEmployees(params).then(
            response => {
                createNotification("Successfully updated workers." );

                const timed = setTimeout(() => {
                    props.reloadAfterSuccess();
                    handleClose();
                }, 500);
                return () => clearTimeout(timed);
            }, error => {
                createNotification("Unable to update workers.", true);
            }
        )
    }

    const disableItem = (label) => {
        return customAttrs.filter(x => x.label === label).length > 0
    }

    const disableAdding = () => {
      return customAttrs.length === attributes.length
    }

    return (
        <div>
            <Dialog maxWidth={'md'} fullWidth={true} open={open} onClose={handleClose} aria-labelledby="bulk-edit-workers">
                { notification.show && notification.isError && <ErrorMessage open={notification.show} setOpen={setShowNotification} error={{message: notification.message}}/>}
                { notification.show && !notification.isError && <SuccessMessage open={notification.show} setOpen={setShowNotification} message={notification.message}/>}
                <DialogTitle id="edit-company-title" onClose={handleClose}>
                    <Typography component="span" variant="h6">
                        Bulk Edit Worker Details
                    </Typography> <br />
                    <Typography variant="body1">
                        Select the worker attributes and update.
                    </Typography>
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={3} justify="center" alignItems="center">
                        <Grid item xs={6}>
                            <Typography variant="body1">
                                <b> Worker Attribute </b>
                            </Typography>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography variant="body1">
                                <b> Value </b>
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Typography variant="body1">
                                <b>Remove</b>
                            </Typography>
                        </Grid>
                        { customAttrs.length > 0 ? customAttrs.map((attr, index) => {
                                return (  attr ? <React.Fragment key={index}>
                                    <Grid item xs={6} align="center">
                                        <FormControl fullWidth>
                                            <InputLabel id="attrib-name">Attribute Name</InputLabel>
                                                <Select
                                                    className={classes.textLeft}
                                                    labelId="attrib-name"
                                                    value={attr.label}
                                                    label="Attribute Name"
                                                    onChange={handleChangeAttrLabel(index)}
                                                    >
                                                    {
                                                        attributes.map(({ label, value }) => {
                                                            return (
                                                                <MenuItem  disabled={disableItem(value)} key={value} value={value}>{ label }</MenuItem>
                                                            )
                                                        })
                                                    }
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={5} style={{ paddingTop: 10}} align="center">
                                        { attr.label !== 'test_frequency' ? <FormControl fullWidth>
                                            <InputLabel id="attrib-name">Attribute Value</InputLabel>
                                                <Select
                                                    className={classes.textLeft}
                                                    labelId="attrib-value"
                                                    value={attr.value}
                                                    label="Attribute Value"
                                                    onChange={handleChangeAttrValue(index)}
                                                    >
                                                    {
                                                        getListByAttributeLabel(attr.label).map((val, index) => {
                                                            return (
                                                                <MenuItem key={index} value={val.id}>{ val.label }</MenuItem>
                                                            )
                                                        })
                                                    }
                                            </Select>
                                        </FormControl> :   
                                            <TextField 
                                                style={{ width: '100%'}}
                                                value={attr.value} 
                                                min="0"
                                                max="99"
                                                label="Input no. of days"
                                                type="number"
                                                InputLabelProps={{
                                                    shrink: true
                                                }} 
                                            /> }
                                    </Grid>
                                    <Grid item xs={1} align="center">
                                        <IconButton onClick={removeAttr(index)} size="small">
                                            <CancelIcon color="error" />
                                        </IconButton>
                                    </Grid>
                                </React.Fragment> : undefined )
                            }) : undefined 
                        }
                        <Grid item xs={12}>
                            <Button variant="outlined" color="primary" onClick={addAttr} disabled={disableAdding()}>
                                + Add Attribute
                            </Button>
                        </Grid>
                        <Grid item xs={12} container justify="center" alignItems="center">
                            <Button variant="outlined" color="primary" onClick={updateAll}>
                                Update
                            </Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default BulkEditEmployeesDialog;