import React from "react"
import {
    Backdrop,
    Button,
    Fade,
    Grid,
    Modal,
    Typography,
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
    List,
    ListItemText,
    ListItem,
    Divider
} from "@material-ui/core";
import clsx from "clsx";
import {
    CheckCircle as CheckCircleIcon,
    Cancel as CancelIcon,
    Warning as WarningIcon,
    ExpandMore as ExpandMoreIcon,
    Info as InfoIcon
} from "@material-ui/icons";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    textLeft: {
        textAlign: 'left'
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh'
    },
    modalContent: {
        backgroundColor: theme.palette.background.paper,
        border: 0,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            width: '50vw'
        },
        [theme.breakpoints.up('md')]: {
            width: '25vw'
        }
    },
    title: {
        [theme.breakpoints.only('xs')]: {
            ...theme.typography.subtitle1,
            fontWeight: 'bold'
        },
        [theme.breakpoints.up('sm')]: {
            ...theme.typography.h5,
            fontWeight: 'bold'
        },
        textAlign: "center"
    },
    textCenter: {
        textAlign: "center"
    },
    fontBold: {
        fontWeight: "bold"
    },
    block: {
        width: '100%'
    }
}));

export default function (props) {

    const classes = useStyles()

    const { modal, setModal, successes, errorsMissing, errorsEmployee, errorsLocation, errorsGate, errorsExisting, errorsNotLinked, processedCount } = props

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={modal}
            onClose={() =>  {
                setModal(false)
            }}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{timeout: 500}}
        >
            <Fade in={modal}>
                <div className={classes.modalContent}>
                    <Grid container alignItems="center" justify={"flex-start"}>
                        <Grid item xs={12}>
                            <Typography className={classes.title} variant={"body1"}>
                                Your file was uploaded
                            </Typography>
                        </Grid>

                        <Grid item xs={12} style={{ marginTop: "3vh" }}>
                            Results:
                        </Grid>

                        <Grid item container xs={12} style={{ marginTop: "1vh" }} alignItems={"center"} justify={"center"}>
                            <ExpansionPanel square style={{ width: "100%" }} expanded={false}>
                                <ExpansionPanelSummary style={{ cursor: "default" }}>
                                    <Grid item container alignItems={"center"} justify={"center"}>
                                        <Grid item container alignItems={"center"} justify={"center"} sm={6} md={2}>
                                            {
                                                successes.length > 0
                                                    ? (<CheckCircleIcon style={{ color: "#4CAF50" }} fontSize={"large"} />)
                                                    : (<InfoIcon style={{ color: "grey" }} fontSize={"large"} />)
                                            }
                                        </Grid>
                                        <Grid item container alignItems={"center"} justify={"flex-start"} sm={6} md={10}>
                                            <Typography className={clsx(classes.fontBold)} variant={"subtitle1"}>
                                                {
                                                    successes.length > 0
                                                        ? successes.length > 1 ? successes.length + " rows were" : "1 row was"
                                                        : "No rows were"
                                                } imported
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </ExpansionPanelSummary>
                            </ExpansionPanel>
                        </Grid>

                        {
                            Array.isArray(errorsMissing) && errorsMissing.length > 0 && (
                                <Grid item container xs={12} style={{ marginTop: "3vh" }} alignItems={"center"} justify={"center"}>
                                    <ExpansionPanel className={clsx(classes.block)} square>
                                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                            <Grid item container alignItems={"center"} justify={"center"}>
                                                <Grid item container alignItems={"center"} justify={"center"} sm={6} md={2}>
                                                    <CancelIcon color={"error"} fontSize={"large"} />
                                                </Grid>
                                                <Grid item container alignItems={"center"} justify={"flex-start"} sm={6} md={10}>
                                                    <Typography className={clsx(classes.fontBold)} variant={"subtitle1"}>
                                                        {
                                                            errorsMissing.length > 1
                                                                ? errorsMissing.length + " rows were"
                                                                : errorsMissing.length + " row was"
                                                        } skipped because of missing parameters.
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>
                                            <List className={clsx(classes.block)}>
                                                {
                                                    errorsMissing.map((error, index) => (
                                                        <React.Fragment>
                                                            <ListItem key={`item-${index}`}>
                                                                <ListItemText primary={error} />
                                                            </ListItem>
                                                            <Divider key={`divider-${index}`} className={clsx(classes.block)} />
                                                        </React.Fragment>
                                                    ))
                                                }
                                            </List>
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                </Grid>
                            )
                        }

                        {
                            Array.isArray(errorsEmployee) && errorsEmployee.length > 0 && (
                                <Grid item container xs={12} style={{ marginTop: "3vh" }} alignItems={"center"} justify={"center"}>
                                    <ExpansionPanel className={clsx(classes.block)} square>
                                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                            <Grid item container alignItems={"center"} justify={"center"}>
                                                <Grid item container alignItems={"center"} justify={"center"} sm={6} md={2}>
                                                    <CancelIcon color={"error"} fontSize={"large"} />
                                                </Grid>
                                                <Grid item container alignItems={"center"} justify={"flex-start"} sm={6} md={10}>
                                                    <Typography className={clsx(classes.fontBold)} variant={"subtitle1"}>
                                                        {
                                                            errorsEmployee.length > 1
                                                                ? errorsEmployee.length + " rows were"
                                                                : errorsEmployee.length + " row was"
                                                        } skipped because worker was not found.
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>
                                            <List className={clsx(classes.block)}>
                                                {
                                                    errorsEmployee.map((error, index) => (
                                                        <React.Fragment>
                                                            <ListItem key={`item-${index}`}>
                                                                <ListItemText primary={error} />
                                                            </ListItem>
                                                            <Divider key={`divider-${index}`} className={clsx(classes.block)} />
                                                        </React.Fragment>
                                                    ))
                                                }
                                            </List>
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                </Grid>
                            )
                        }

                        {
                            Array.isArray(errorsLocation) && errorsLocation.length > 0 && (
                                <Grid item container xs={12} style={{ marginTop: "3vh" }} alignItems={"center"} justify={"center"}>
                                    <ExpansionPanel className={clsx(classes.block)} square>
                                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                            <Grid item container alignItems={"center"} justify={"center"}>
                                                <Grid item container alignItems={"center"} justify={"center"} sm={6} md={2}>
                                                    <CancelIcon color={"error"} fontSize={"large"} />
                                                </Grid>
                                                <Grid item container alignItems={"center"} justify={"flex-start"} sm={6} md={10}>
                                                    <Typography className={clsx(classes.fontBold)} variant={"subtitle1"}>
                                                        {
                                                            errorsLocation.length > 1
                                                                ? errorsLocation.length + " rows were"
                                                                : errorsLocation.length + " row was"
                                                        } skipped because location does not exist.
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>
                                            <List className={clsx(classes.block)}>
                                                {
                                                    errorsLocation.map((error, index) => (
                                                        <React.Fragment>
                                                            <ListItem key={`item-${index}`}>
                                                                <ListItemText primary={error} />
                                                            </ListItem>
                                                            <Divider key={`divider-${index}`} className={clsx(classes.block)} />
                                                        </React.Fragment>
                                                    ))
                                                }
                                            </List>
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                </Grid>
                            )
                        }

                        {
                            Array.isArray(errorsGate) && errorsGate.length > 0 && (
                                <Grid item container xs={12} style={{ marginTop: "3vh" }} alignItems={"center"} justify={"center"}>
                                    <ExpansionPanel className={clsx(classes.block)} square>
                                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                            <Grid item container alignItems={"center"} justify={"center"}>
                                                <Grid item container alignItems={"center"} justify={"center"} sm={6} md={2}>
                                                    <CancelIcon color={"error"} fontSize={"large"} />
                                                </Grid>
                                                <Grid item container alignItems={"center"} justify={"flex-start"} sm={6} md={10}>
                                                    <Typography className={clsx(classes.fontBold)} variant={"subtitle1"}>
                                                        {
                                                            errorsGate.length > 1
                                                                ? errorsGate.length + " rows were"
                                                                : errorsGate.length + " row was"
                                                        } skipped, because entrance does not exist.
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>
                                            <List className={clsx(classes.block)}>
                                                {
                                                    errorsGate.map((error, index) => (
                                                        <React.Fragment>
                                                            <ListItem key={`item-${index}`}>
                                                                <ListItemText primary={error} />
                                                            </ListItem>
                                                            <Divider key={`divider-${index}`} className={clsx(classes.block)} />
                                                        </React.Fragment>
                                                    ))
                                                }
                                            </List>
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                </Grid>
                            )
                        }

                        {
                            Array.isArray(errorsExisting) && errorsExisting.length > 0 && (
                                <Grid item container xs={12} style={{ marginTop: "3vh" }} alignItems={"center"} justify={"center"}>
                                    <ExpansionPanel className={clsx(classes.block)} square>
                                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                            <Grid item container alignItems={"center"} justify={"center"}>
                                                <Grid item container alignItems={"center"} justify={"center"} sm={6} md={2}>
                                                    <CancelIcon color={"error"} fontSize={"large"} />
                                                </Grid>
                                                <Grid item container alignItems={"center"} justify={"flex-start"} sm={6} md={10}>
                                                    <Typography className={clsx(classes.fontBold)} variant={"subtitle1"}>
                                                        {
                                                            errorsExisting.length > 1
                                                                ? errorsExisting.length + " rows were"
                                                                : errorsExisting.length + " row was"
                                                        } skipped because worker was imported already.
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>
                                            <List className={clsx(classes.block)}>
                                                {
                                                    errorsExisting.map((error, index) => (
                                                        <React.Fragment>
                                                            <ListItem key={`item-${index}`}>
                                                                <ListItemText primary={error} />
                                                            </ListItem>
                                                            <Divider key={`divider-${index}`} className={clsx(classes.block)} />
                                                        </React.Fragment>
                                                    ))
                                                }
                                            </List>
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                </Grid>
                            )
                        }

                        {
                            Array.isArray(errorsNotLinked) && errorsNotLinked.length > 0 && (
                                <Grid item container xs={12} style={{ marginTop: "3vh" }} alignItems={"center"} justify={"center"}>
                                    <ExpansionPanel className={clsx(classes.block)} square>
                                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                            <Grid item container alignItems={"center"} justify={"center"}>
                                                <Grid item container alignItems={"center"} justify={"center"} sm={6} md={2}>
                                                    <CancelIcon color={"error"} fontSize={"large"} />
                                                </Grid>
                                                <Grid item container alignItems={"center"} justify={"flex-start"} sm={6} md={10}>
                                                    <Typography className={clsx(classes.fontBold)} variant={"subtitle1"}>
                                                        {
                                                            errorsNotLinked.length > 1
                                                                ? errorsNotLinked.length + " rows were"
                                                                : errorsNotLinked.length + " row was"
                                                        } skipped because worker has no zennya account linked yet.
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>
                                            <List className={clsx(classes.block)}>
                                                {
                                                    errorsNotLinked.map((error, index) => (
                                                        <React.Fragment>
                                                            <ListItem key={`item-${index}`}>
                                                                <ListItemText primary={error} />
                                                            </ListItem>
                                                            <Divider key={`divider-${index}`} className={clsx(classes.block)} />
                                                        </React.Fragment>
                                                    ))
                                                }
                                            </List>
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                </Grid>
                            )
                        }

                        <Grid item container xs={12} style={{ marginTop: "3vh" }} alignItems={"center"} justify={"center"}>
                            <Button
                                className={clsx(classes.block, classes.fontBold)}
                                variant="contained"
                                onClick={() => setModal(false)}
                            >
                                Close
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            </Fade>
        </Modal>
    )
}
