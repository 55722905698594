import React, { useState, useLayoutEffect, useRef, useEffect, forwardRef, useImperativeHandle } from 'react';
import mapboxgl from 'mapbox-gl';
import marker_icon from '../assets/images/marker.png';
import './maps.css';
import marker_factory from '../assets/images/factory.png';
import marker_office from '../assets/images/office.png';
import marker_warehouse from '../assets/images/warehouse.png';
import marker_green from '../assets/images/field-markers/field-worker-LOW.png';
import marker_yellow from '../assets/images/field-markers/field-worker-MODERATE.png';
import marker_red from '../assets/images/field-markers/field-worker-HIGH.png';
import marker_green_stale from '../assets/images/field-markers/field-worker-LOW-stale.png';
import marker_yellow_stale from '../assets/images/field-markers/field-worker-MODERATE-stale.png';
import marker_red_stale from '../assets/images/field-markers/field-worker-HIGH-stale.png';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

const LocationsMap = forwardRef((props, ref) => {
    const [ state, setState ] = useState(props.mapConfig)
    const {
        handleChangeCoordinates,
        points,
        markers,
        styles = {
            width: "100%",
            height: "53vh",
        }
    } = props;

    const [ map, setMap ] = useState(null);

    const mapContainer = useRef(null);

    var geojson = {
      type: 'FeatureCollection',
      features: points
    };

    useLayoutEffect(() => {
        const initMap = ({ setMap, mapContainer }) => {
            const map = new mapboxgl.Map({
                container: mapContainer.current,
                style: 'mapbox://styles/mapbox/dark-v10?optimize=true',
                center: [state.lng, state.lat],
                zoom: state.zoom
            })

            map.on('load', () => {
                // setMap(map);
                map.resize();

                const images = [ 
                  { src: marker_green, alt: 'marker_green'},
                  { src: marker_yellow, alt: 'marker_yellow'},
                  { src: marker_red, alt: 'marker_red'},
                  { src: marker_green_stale, alt: 'marker_green_stale'},
                  { src: marker_yellow_stale, alt: 'marker_yellow_stale'},
                  { src: marker_red_stale, alt: 'marker_red_stale'},
                  { src: marker_factory, alt: 'marker_factory'},
                  { src: marker_office, alt: 'marker_office'},
                  { src: marker_warehouse, alt: 'marker_warehouse'},
                ];

                images.forEach(image => {
                  var img = new Image();
                  img.src = image.src;
                  img.alt = image.alt;

                  map.loadImage(image.src, function(err, finalImage) {
                      if(err) throw err;
                      map.addImage(image.alt, img);
                  })
                });

                /* Tentative Worker and Location sources */

                var geojson = {
                    type: 'FeatureCollection',
                    features:[]
                };
                map.addSource('map-markers', {
                    type: 'geojson',
                    data: geojson,
                    cluster: true,
                    clusterMaxZoom: 14,
                    clusterRadius: 30,
                    clusterProperties: {
                        count: ['+', ['get', 'count']],
                        count_low:  ['+', ['get', 'count_low']],
                        count_moderate:  ['+', ['get', 'count_moderate']],
                        count_high:  ['+', ['get', 'count_high']],
                    }
                });
                refreshMarkerSource(map);

                /* Worker and Location feature layers */

                map.addLayer({
                    id: 'map-markers',
                    type: 'symbol',
                    source: 'map-markers',
                    filter: ['!=', 'cluster', true],
                    layout: {
                        'icon-image': ['get', 'icon'],
                        'icon-size': 1,
                        'icon-allow-overlap': true,
                        'icon-anchor': 'bottom',
                    }
                });

                map.on('click', 'map-markers', function(e) {
                    var coordinates = e.features[0].geometry.coordinates.slice();
                    var description = e.features[0].properties.description;

                    while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                      coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
                    }

                    var options = {
                        anchor: 'center',
                    };
                    new mapboxgl.Popup(options)
                        .setLngLat(coordinates)
                        .setHTML(description)
                        .addTo(map);
                });

                map.on('mouseenter', 'map-markers', function() {
                  map.getCanvas().style.cursor = 'pointer';
                });
                map.on('mouseleave', 'map-markers', function() {
                  map.getCanvas().style.cursor = '';
                });

                /* Cluster and Location donut indicator marker - hack display */
                map.on('data', function(e) {
                    if (e.sourceId !== 'map-markers' || !e.isSourceLoaded) return;

                    map.on('move', function() { updateClusters(map) });
                    map.on('moveend', function() {
                         setTimeout(() => {
                                 updateClusters(map);
                         }, 100);
                     });
                    updateClusters(map);
                });
            });

            setMap(map);

            map.addControl(new mapboxgl.NavigationControl());
        };

        if(!map) initMap({ setMap, mapContainer});


    }, [map, geojson, state, points]);

    // objects for caching and keeping track of HTML marker objects (for performance)
    var indicatorMarkers = {};
    var markersOnScreen = {};

    function updateClusters(map) {
        var newMarkers = {};
        var features = map.querySourceFeatures('map-markers');

        // for every cluster on the screen, create an HTML marker for it (if we didn't yet),
        // and add it to the map if it's not there already
        for (var i = 0; i < features.length; i++) {
            var coords = features[i].geometry.coordinates;
            var props = features[i].properties;
            if (!props.cluster && props.type !== 'location'){ 
                continue;
            }
            if (!props.cluster && (props.count_low + props.count_moderate + props.count_high === 0)) {
                continue;
            }

            var id = props.cluster_id || props.id;

            /* Re-hash ids so it is replaced based on risk counts */
            id = id + '-' + props.count_low + '-' + props.count_moderate + '-' + props.count_high;

            var marker = indicatorMarkers[id];
            if (!marker) {
                var anchor = 'bottom';
                var el = document.createElement('div');
                var donutChart = createDonutChart(props.count_low,
                    props.count_moderate,
                    props.count_high
                );

                /* Location indicator mode */
                if (!props.cluster) {
                      el.innerHTML = (
                      `<span class="item">
                          <span class="notify-badge">
                              ` + donutChart.innerHTML + `
                          </span>
                       </span>`
                    );
                    anchor = 'bottom';
                } else {
                    el.innerHTML = (
                      `<span class="click-item">
                            ` + donutChart.innerHTML + `
                       </span>`
                    );
                    const clusterId = props.cluster_id;
                    el.addEventListener('click', function() {
                        map.getSource('map-markers').getClusterExpansionZoom(
                            clusterId,
                            function(err, zoom) {
                                if (err || !zoom) {
                                    return;
                                } 

                                map.easeTo({
                                    center: coords,
                                    zoom: zoom
                                });
                            }
                        );
                    });
                }

                marker = indicatorMarkers[id] = new mapboxgl.Marker({
                    element: el,
                    anchor: anchor,
                }).setLngLat(coords);
            }
            newMarkers[id] = marker;

            if (!markersOnScreen[id]) {
                marker.addTo(map);
            }
        }
        // for every marker we've added previously, remove those that are no longer visible
        for (id in markersOnScreen) {
            if (!newMarkers[id]) markersOnScreen[id].remove();
        }
        markersOnScreen = newMarkers;
    }

    function refreshMarkerSource(map) {
        if (!map || !map.getSource('map-markers')) {
            return;
        }
        var source = map.getSource('map-markers');
        var locationFeatures = (points || []).map(l => {
            return {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [l.lng, l.lat]
                },
                properties: {
                    id: l.id,
                    icon: l.icon.img,
                    description: `<div>
                        ${l.popup}
                    </div>`,
                    type: 'location',
                    count: l.properties.count || 0,
                    count_low: l.properties.count_low || 0,
                    count_moderate: l.properties.count_moderate || 0,
                    count_high: l.properties.count_high || 0,
                }
            }
        });

        var workerFeatures = (markers || []).map(l => {
            return {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [l.lng, l.lat]
                },
                properties: {
                    id: l.id,
                    icon: l.icon.img,
                    description: `<div>
                        ${l.popup}
                    </div>`,
                    count: l.properties.count || 0,
                    count_low: l.properties.count_low || 0,
                    count_moderate: l.properties.count_moderate || 0,
                    count_high: l.properties.count_high || 0,
                }
            }
        });


        var geojson = {
            type: 'FeatureCollection',
            features: [...locationFeatures, ...workerFeatures],
        };
        source.setData(geojson);
    }

    function createDonutChart(low, moderate, high) {
        var counts = [low, moderate, high, 0];
        var colors = [ '#73C2A6', '#FE974C', '#D94135', '#465A6D99' ];

        var total = 0;
        var offsets = [];
        for (var i = 0; i < counts.length; i++) {
            offsets.push(total);
            total += counts[i] || 0;
        }

        /* Adds a none color when there is none */
        var displayTotal = total;
        if (total === 0) {
            offsets.push(0)
            total += counts[3] = 1;
        }

        var fontSize = 16;
        var r = 18;
        var r0 = Math.round(r * 0.6);
        var w = r * 2;

        var html =
            `<div><svg width="` +
            w +
            '" height="' +
            w +
            '" viewbox="0 0 ' +
            w +
            ' ' +
            w +
            '" text-anchor="middle" style="font: ' +
            fontSize +
            'px sans-serif; display: block">';

        for (i = 0; i < counts.length; i++) {
            html += donutSegment(
                offsets[i] / (total || 1),
                (offsets[i] + counts[i]) / (total || 1),
                r,
                r0,
                colors[i]
            );
        }

        html +=
            '<circle cx="' +
            r +
            '" cy="' +
            r +
            '" r="' +
            r0 +
            '" fill="white" /><text  dominant-baseline="central" transform="translate(' +
            r +
            ', ' +
            r +
            ')">' +
            displayTotal.toLocaleString() +
            '</text></svg></div>';

        var el = document.createElement('div');
        el.innerHTML = html;

        return el.firstChild;
    }

    function donutSegment(start, end, r, r0, color) {
        if (end - start === 1) end -= 0.00001;
        var a0 = 2 * Math.PI * (start - 0.25);
        var a1 = 2 * Math.PI * (end - 0.25);
        var x0 = Math.cos(a0),
            y0 = Math.sin(a0);
        var x1 = Math.cos(a1),
            y1 = Math.sin(a1);
        var largeArc = end - start > 0.5 ? 1 : 0;

        return [
            '<path d="M',
            r + r0 * x0,
            r + r0 * y0,
            'L',
            r + r * x0,
            r + r * y0,
            'A',
            r,
            r,
            0,
            largeArc,
            1,
            r + r * x1,
            r + r * y1,
            'L',
            r + r0 * x1,
            r + r0 * y1,
            'A',
            r0,
            r0,
            0,
            largeArc,
            0,
            r + r0 * x0,
            r + r0 * y0,
            '" fill="' + color + '" />'
        ].join(' ');
    }

    useImperativeHandle(ref, () => ({
      updateMarkers() {
          refreshMarkerSource(map);
      }
    }));

    return (
        <div ref={el => (mapContainer.current = el)} style={styles}/>
    )
});

export { LocationsMap }
