import React from 'react';
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

export default function FixedTags(props) {
  const { options, placeholder, label, handleChange, value, fixedOptions } = props;

  // (event, newValue) => {
  //   setValue([
  //     ...fixedOptions,
  //     ...newValue.filter((option) => fixedOptions.indexOf(option) === -1),
  //   ]);
  // }

  return (
    <Autocomplete
      multiple
      style={{ width: 300}}
      id="fixed-tags"
      value={value}
      defaultValue={value && value.map(v => v.label) }
      onChange={handleChange}
      options={options}
      getOptionLabel={(option) => ( option.label|| '')}
      getOptionSelected={(option, value) => option.id === value.id}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <Chip
            label={option.label}
            {...getTagProps({ index })}
          />
        ))
      }
      style={{ width: 500 }}
      renderInput={(params) => (
        <TextField {...params} label={label} variant="outlined" placeholder={placeholder} />
      )}
    />
  );
}