import { Checkbox, Grid, TextField, Typography } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import isEmpty from "lodash.isempty";

function ServiceAutoComplete({ selectedServices, services, onSetService, ...props }) {
  
  return (
    <Autocomplete
      // disableCloseOnSelect
      multiple
      options={services}
      getOptionLabel={(option) => option.label}
      renderInput={(iParams) => (
        <TextField
          {...iParams}
          error={isEmpty(selectedServices)}
          label="Services:"
          variant="outlined"
          fullWidth
        />
      )}
      getOptionSelected={(option, value)=>{
        return option.id === value.id
      }}
      value={selectedServices}
      defaultValue={0}
      onChange={(evt, value, reason, detail) => {
        onSetService(value, reason, detail)
      }}
      renderOption={(item, status) => {
        return (
          <Grid alignItems="center" justify="space-between" container>
            <Grid item xs={1}>
              <Checkbox checked={status.selected} />
            </Grid>
            <Grid item xs>
              {item.label}
            </Grid>
          </Grid>
        );
      }}
      size="small"
      ChipProps={{
        variant: "outlined",
        color: "primary",
      }}
    />
  );
}

export default ServiceAutoComplete