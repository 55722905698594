import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { Button, FormControl, Grid, InputLabel, MenuItem, Select } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import reportService from '../services/report.service';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4),
        flexGrow: 1
    }
}));

const GenerateDailyTemp = (props) => {
    const classes = useStyles();
    const [ loading, setLoading ] = useState(false);
    const [ startDate, setStartDate ] = useState(new Date());
    const [ endDate, setEndDate ] = useState(new Date());
    const [ testTypes, setTestTypes ] = useState([]);
    const [ testType, setTestType ] = useState({});
    const [ error, setError ] = useState({ message: ''})
    const [ openError, setOpenError ] = useState(false);

    const handleStartDateChange = date => {
        setStartDate(date);
    }

    const handleEndDateChange = date => {
        setEndDate(date);
    }

    const getTestTypes = (id) => {
        reportService.getTestTypes(id).then(
            response => {
                setTestTypes(response);
            }
        ).catch(error => {

        })
    }

    useEffect(() => {
        getTestTypes(props.company);
    }, [])

    const downloadFile = event => {
        var params = {
            id: props.company,
            start_date: moment(startDate).startOf('day').utc().toISOString(),
            end_date: moment(endDate).endOf('day').utc().toISOString(),
            page_size: 9999
        }

        setLoading(true);
        reportService.downloadDailyTempReadingReport(params).then(
            response => {
                console.log(response);
                  var downloadFile = `Daily-Temperature-Gate-Entry-Report-${moment().format('MM-DD-YYYY-HH:mm:ss')}.csv`;
                  setLoading(false);
                  var file = new Blob([response.data], {
                    type: response.headers['content-type'] || 'application/octet-stream',
                  });
                  var saving = document.createElement('a');
                  saving.href = URL.createObjectURL(file);
                  saving.download = (
                    downloadFile
                  );
                  saving.click();
            }
        ).catch(error => {
            setLoading(false);
            if(error.response && error.response.data && error.response.data.message) setError({ message: error.response.data.message.en || ''})
            else setError({ message: 'Unable to export file'})
            setOpenError(true);
        })
    }


    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <div className={classes.root}>
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <KeyboardDatePicker
                        margin="normal"
                        id="date-picker-dialog"
                        label="Start Date"
                        format="MM/dd/yyyy"
                        value={startDate}
                        onChange={handleStartDateChange}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <KeyboardDatePicker
                        margin="normal"
                        id="date-picker-dialog"
                        label="End Date"
                        format="MM/dd/yyyy"
                        value={endDate}
                        onChange={handleEndDateChange}
                        KeyboardButtonProps={{
                            'aria-label': 'change end date',
                        }}
                    />
                </Grid>
                <Grid item xs={12} align="center">
                    <Button variant="contained" color="primary" style={{ color: 'white'}} onClick={downloadFile}>
                        Download Report
                    </Button>
                </Grid>
            </Grid>
        </div>
        </MuiPickersUtilsProvider>
    )
}

export default GenerateDailyTemp;