import instance from './helpers/instance';
import corporateHealthService from './corporate-health.service';

const API_URL = process.env.REACT_APP_WEB_ADMIN_URL;

const corporateEventsService = {
    getCohortsList(partnerId, params) {
        return instance.get(`${API_URL}/api/1/corporate/partners/${partnerId}/employee_cohorts`, { params }).then(
            response => {
                return response.data;
            }
        );
    },
    createCohort(partnerId, params) {
        return instance.post(`${API_URL}/api/1/corporate/partners/${partnerId}/employee_cohorts/create_from_csv`, params, 
        { 
            headers: { 
                'Content-Type': 'multipart/form-data'
            }
        }).then(
            response => {
                return response.data;
            }
        );
    },
    downloadCohorts(partnerId, params) {
        return instance.get(`${API_URL}/api/1/corporate/partners/${partnerId}/download_employee_cohorts_csv`, {params, cache: false }).then(
            response => {
                return response;
            }
        );
    },
    getCohortDetails(cohortId, params) {
        return instance.get(`${API_URL}/api/1/corporate/employee_cohorts/${cohortId}`, { params }).then(
            response => {
                return response.data;
            }
        );
    },
    updateCohortDetails(cohortId, params) {
        return instance.put(`${API_URL}/api/1/corporate/employee_cohorts/${cohortId}`, params, { cache: false }).then(
            response => {
                return response.data;
            }
        )
    },
    deleteCohort(cohortId) {
        return instance.delete(`${API_URL}/api/1/corporate/employee_cohorts/${cohortId}`, {}).then(
            response => {
                return response.data;
            }
        )
    },

    getCohortEntries(cohortId) {
        return instance.get(`${API_URL}/api/1/corporate/employee_cohorts/${cohortId}/entries`, {}).then(
            response => {
                return response.data;
            }
        )
    },
    getEventCohortEntries(cohortId, event) {
        return instance.get(`${API_URL}/api/1/corporate/employee_cohorts/${cohortId}/entries?event=${event}`).then(
            response => {
                return response.data;
            }
        )
    },
    
    getEventsList(params) {
        return instance.get(`${API_URL}/api/1/corporate/events`, { params }).then(
            response => {
                return response.data;
            }
        );
    },
    createEventRequest(params) {
        return instance.post(`${API_URL}/api/1/corporate/events/requests`, params, { cache: false }).then(
            response => {
                return response.data;
            }
        );
    },
    getEventDetails(eventId, params) {
        return instance.get(`${API_URL}/api/1/corporate/events/${eventId}`, { params }).then(
            response => {
                return response.data;
            }
        );
    },
    cancelEvent(eventId) {
        return instance.post(`${API_URL}/api/1/corporate/events/${eventId}/cancel`, {}, { cache: false }).then(
            response => {
                return response.data;
            }
        );
    },
    modifyCohorts(eventId, params) {
        return instance.post(`${API_URL}/api/1/corporate/events/${eventId}/services`, params, { cache: false }).then(
            response => {
                return response.data;
            }
        );
    },
    modifyEventSlots(eventId, params) {
        return instance.post(`${API_URL}/api/1/corporate/events/${eventId}/services/slots`, params, { cache: false }).then(
            response => {
                return response.data;
            }
        );
    },

    getEventLocations(partnerId, params) {
        return instance.get(`${API_URL}/api/1/corporate/partners/${partnerId}/event_locations`, { params }).then(
            response => {
                return response.data;
            }
        );
    },
    getEventLocationDetails(locationId) {
        return instance.get(`${API_URL}/api/1/corporate/event_locations/${locationId}`, {}).then(
            response => {
                return response.data;
            }
        );
    },
    getEventTypes(partnerId) {
        return instance.get(`${API_URL}/api/1/corporate/partners/${partnerId}/event_types`, {}).then(
            response => {
                return response.data;
            }
        );
    },

    getServiceTypes(partnerId) {
        return instance.get(`${API_URL}/api/1/corporate/partners/${partnerId}/service_types`, {}).then(
            response => {
                return response.data;
            }
        )
    },

    sendEventInvites(eventId, recipients) {
        return instance.post(`${API_URL}/api/1/corporate/events/${eventId}/invites`, {employees:[...recipients]}).then(
            response => {
                return response.data
            }
        )
    },

    fetchMetricsComorbidities: async (eventId) => {
        const comorNamesList = ((await corporateHealthService.getComorbidities())?.data?.list)
        let data = (await instance.get(`${API_URL}/api/1/corporate/events/${eventId}/medical/counts/comorbidities`))?.data?.list
        const mappedData = data
            .filter(el=>el.value>0)
            .sort((a,b)=> b.value - a.value)
            .map(el=>{
            const comorName = comorNamesList.find(e=>e.name === el.name)?.label
            return {...el, name: comorName}
        })
        return {data:mappedData, tag:'comorbidities'}
    },
    fetchMetricsAllergies: async (eventId) => {
        const allerNamesList = ((await corporateHealthService.getAllergies())?.data?.list)
        let data = (await instance.get(`${API_URL}/api/1/corporate/events/${eventId}/medical/counts/allergies`))?.data?.list
        const mappedData = data
            .filter(el=>el.value>0)
            .sort((a,b)=> b.value - a.value)
            .map(el=>{
            const comorName = allerNamesList.find(e=>e.name === el.name)?.label
            return {...el, name: comorName}
        })
        
        return {data:mappedData, tag:'allergies'}
    },
    fetchMetricsLinkages: async (eventId) => {
        let data = (await instance.get(`${API_URL}/api/1/corporate/events/${eventId}/medical/counts/linkages`))?.data?.list
        return {data:data.sort((a,b)=> b.value - a.value), tag:'linkages'}
    },
    fetchMetricsGender: async (eventId) => {
        let data = (await instance.get(`${API_URL}/api/1/corporate/events/${eventId}/medical/counts/gender`))?.data?.list
        return {data:data.sort((a,b)=> b.value - a.value), tag:'gender'}
    },
    fetchMetricsAgeBrackets: async (eventId) => {
        let data = (await instance.get(`${API_URL}/api/1/corporate/events/${eventId}/medical/counts/age_brackets`))?.data?.list
        return {data:data.sort((a,b)=> b.value - a.value), tag:'age_brackets'}
    },
    fetchMetricsVaccinations: async (eventId) => {
        let data = (await instance.get(`${API_URL}/api/1/corporate/events/${eventId}/medical/counts/vaccinations`))?.data?.list
        const mappedData = data
            .sort((a,b)=> b.value - a.value)
            .map(x=>{
                return {...x, name:x.label}
            })
        return {data:mappedData, tag:'vaccinations'}
    },
    downloadEventParticipants: (eventId) => {
        return instance.get(`${API_URL}/api/1/corporate/events/${eventId}/downloadInvitesStatuses`, {}).then(
            response => {
                return response.data
            }
        )
    }
};

export default corporateEventsService;