import { LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import corporateEventsService from '../services/corporate-events.service';
import CohortDetails from './cohortDetails';

const useStyles = makeStyles(theme => ({
}));
const CohortDetailPage = ({company, match}) => {
  const classes = useStyles()
  const [cohortId, setCohortId] = useState(match?.params?.cohortId);
  const [cohort, setCohort] = useState();

  useEffect(() => {
    async function fetchCohort() {
      const result = await corporateEventsService.getCohortDetails(cohortId)
      setCohort(result)
    }
    fetchCohort();
  }, [cohortId]);

  if (!cohort) {
    return <LinearProgress/>
  }
  return <CohortDetails
          company={company}
          handleClose={() => {}}
          isAdding={false}
          selectedCohort={cohort}
          standalone
          />
}

export {CohortDetailPage}