import React, { useState } from "react"
import {
    makeStyles
} from "@material-ui/core/styles";
import {
    Grid,
    Card,
    CardContent,
    CardMedia,
    CardActions,
    Typography,
    Link,
    Button,
    TextField,
    CircularProgress
} from "@material-ui/core"
import {
    Link as RouterLink,
} from 'react-router-dom';

import authenticationService from "../services/authentication.service";
import logger, { snackbar } from "../services/logger.service";

const useStyles = makeStyles((theme) => ({
    root: {
    },
    background: {
        backgroundColor: '#87AFD9'
    },
    boldText: {
        fontWeight: 'bold'
    },
    textCenter: {
        textAlign: 'center'
    },
    cardActions: {
        paddingTop: 20,
        paddingBottom: 20
    },
    sendLoginLinkBtn: {

    }
}));

export default function ForgotPassword(props) {
    const classes = useStyles()

    const [ email, setEmail ] = useState('')
    const [ isSendingResetPassword, setResetPasswordLoader ] = useState(false)

    const { enqueueSnackbar } = snackbar()

    function sendResetPassword() {
        setResetPasswordLoader(true)

        const params = {
            corpDomain: typeof window !== 'undefined' ? window.location.host : 'dev.corphealth.zennya.com',
            email
        }

        authenticationService.forgotPassword(params)
            .then(() => {
                logger.success(enqueueSnackbar, "Login link has been sent!")
                setEmail('')
            })
            .catch((err) => {
                if (err && err.response) {
                    const data = err.response.data

                    const message = data.message.en
                    const details = data.details
                    const code = data.code

                    const errMessage = details
                        ? details
                        : message
                            ? message
                            : code

                    logger.err(enqueueSnackbar, errMessage)
                }
            })
            .finally(() => {
                setResetPasswordLoader(false)
            })
    }

    return (
        <Grid
            className={classes.background}
            container
            alignItems="center"
            justify="center"
            style={{ minHeight: '100vh' }}
        >
            <Grid item xs={10} sm={6} md={4}>
                <Card>
                    <CardContent>
                        <Grid container alignItems={"center"} justify={"center"} spacing={2}>
                            <Grid item xs={12}>
                                <img src={require("../assets/images/zennya_health_horizontal.png")} alt={"logo"} />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className={classes.boldText} variant={"h6"}>
                                    Forgot Password?
                                </Typography>
                            </Grid>
                            <Grid item xs={7}>
                                <Typography className={classes.textCenter} variant={"subtitle1"} style={{ color: "#494949" }}>
                                    Enter your email and we’ll send you a link to get back into your account
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email Address"
                                    name="email"
                                    autoComplete="email"
                                    value={email}
                                    autoFocus
                                    onKeyPress={(e) => {
                                        if (e.keyCode === 13) {
                                            sendResetPassword()
                                        }
                                    }}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={6} style={{ paddingBottom: 30 }}>
                                <Button
                                    variant={"contained"}
                                    color={"primary"}
                                    classes={{
                                        textPrimary: '#fff',
                                        containedPrimary: classes.background
                                    }}
                                    onClick={sendResetPassword}
                                    disabled={isSendingResetPassword || !email}
                                    fullWidth
                                >
                                    {
                                        isSendingResetPassword
                                            ? (
                                                <CircularProgress size={25} color={"primary"} />
                                            )
                                            : 'Send Login Link'
                                    }
                                </Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                    <CardActions className={classes.cardActions} style={{ backgroundColor: '#F8F8F8' }}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Link
                                    className={classes.textCenter}
                                    component={RouterLink}
                                    to={"/login"}
                                    style={{ color: "#767676" }}
                                >
                                    Back to Login
                                </Link>
                            </Grid>
                        </Grid>
                    </CardActions>
                </Card>
            </Grid>
        </Grid>
    )
}
