import React, { useState, createContext, useContext } from 'react';
import corporateHealthService from './../services/corporate-health.service'; 
import authenticationService from './../services/authentication.service';

const CompanyDetailsContext = createContext();

function CompanyProvider(props) {
    const [ company, setCompany ] = useState(null);

    function update(newCompany) {
        setCompany(newCompany);
    }

    const companyData = { company, update };

    return <CompanyDetailsContext.Provider value={companyData} {...props} />;
}

function useCompanyDetailsContext() {
    return useContext(useCompanyDetailsContext);
  }

 
export { CompanyProvider, CompanyDetailsContext, useCompanyDetailsContext };