import instance from './helpers/instance';

const API_URL = process.env.REACT_APP_WEB_ADMIN_URL;

const corporateHealthService = {
    getAuditLogs: (partnerId, params) => {
        return instance.get(`${API_URL}/api/1/corporate/${partnerId}/logs`, { params })
            .then(response => response.data)
            ;
    }
};

export default corporateHealthService;
