import React, { useState, useMemo, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import throttle from 'lodash/throttle';
import locationsService from './../services/locations.service';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
// import parse from 'autosuggest-highlight/parse';
// import throttle from 'lodash/throttle';



const useStyles = makeStyles((theme) => ({
    icon: {
      color: theme.palette.text.secondary,
      marginRight: theme.spacing(2),
    },
  }));

const MapsAutoSuggest = (props) => {
    const classes = useStyles();
    const { value, setValue, inputValue, setInputValue, label, helperText } = props;
    const { mapMarker, setMapMarker } = props;
    // const [ value, setValue ] = useState(null);
    // const [ inputValue, setInputValue ] = useState('');
    const [ options, setOptions ] = useState([]);

    const fetch = useMemo(
        () => 
            throttle((request) => {
                if(request.input !== '' && request.input) {
                    // locationsService.autocompleteSuggestions(request.input).then(
                    //     results => {
                    //     let newOptions = []; 

                    //     if (results) {
                    //         newOptions = [...newOptions, ...results.suggestions];
                    //     }
        
                    //     setOptions(newOptions);
                    //     return results;
                    //     }
                    // );

                    locationsService.geocoder(request.input).then(
                        results => {
                            let newOptions = []; 

                        if (results) {
                            newOptions = [...newOptions, ...results.list];
                        }
        
                        setOptions(newOptions);
                        }
                    )
                }
            }, 2000),
    []);

    useEffect(() => {
        let active = true;

        if(inputValue === '') {
            setOptions(value ? [value] : []);
        }

        fetch({ input: inputValue }, (results) => {
            // console.log(results);
            
            if (active) {
              let newOptions = [];
      
              if (value) {
                newOptions = [value];
              }
      
            //   if (results) {
            //     newOptions = [...newOptions, ...results];
            //   }
      
            //   setOptions(newOptions);
            }
          });

          return () => {
            active = false;
          };
    }, [value, inputValue, fetch]);


    return (
        <Autocomplete
          id="google-map-demo"
          style={{ width: '100%' }}
          getOptionLabel={(option) => (typeof option === 'string' ? option : option.address)}
          filterOptions={(x) => x}
          options={options}
          autoComplete
          includeInputInList
          clearOnBlur={false}
          filterSelectedOptions
          value={value}
          onChange={(event, newValue) => {
            setOptions(newValue ? [newValue, ...options] : options);
            setValue(newValue);
            if(newValue && newValue.latitude && newValue.longitude) {
                setMapMarker({
                  ...mapMarker,
                  geometry: {
                    type: 'Point',
                    coordinates: [ newValue.longitude, newValue.latitude ]
                  },
                  properties: {
                    description: `<p>${newValue.address}</p>`
                  }
                })
            }
          }}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          renderInput={(params) => (
            <TextField {...params} label={label} required variant="outlined" helperText={helperText} fullWidth />
          )}
          renderOption={(option) => {
            // const matches = option.structured_formatting.main_text_matched_substrings;
            // const parts = parse(
            //   option.structured_formatting.main_text,
            //   matches.map((match) => [match.offset, match.offset + match.length]),
            // );
    
            return (
              <Grid container alignItems="center">
                <Grid item>
                  <LocationOnIcon className={classes.icon} />
                </Grid>
                <Grid item xs>
                  {/* {parts.map((part, index) => (
                    <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                      {part.text}
                    </span>
                  ))} */}
    
                  <Typography variant="body2" color="textSecondary">
                      { option.address }
                    {/* {option.structured_formatting.secondary_text} */}
                  </Typography>
                </Grid>
              </Grid>
            );
          }}
        />
      );
}

export default MapsAutoSuggest;