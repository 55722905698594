import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {Avatar, Hidden, Typography} from '@material-ui/core';

import { UserContext } from "../context/UserContext";

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content',
    backgroundColor: 'white',
    width: '100%',
  },
  avatar: {
    backgroundColor: 'white',
    width: theme.spacing(16),
    height: theme.spacing(14),
    objectFit: 'scale-down',
    margin: theme.spacing(2),
  },
  name: {
    color: 'black',
    marginTop: theme.spacing(1)
  },
  avatarThumbnail: {
    backgroundColor: 'white',
    width: theme.spacing(5),
    height: theme.spacing(4),
    objectFit: 'scale-down',
    margin: theme.spacing(2),
  },
}));

const Profile = props => {
  const { className, openDrawer, ...rest } = props;

  const classes = useStyles();

  return (
    <UserContext.Consumer>
      {
        value => (
          <div
          {...rest}
          className={clsx(classes.root, className)}
          >
          <img
            alt="Company Logo"
            className={clsx({
              [classes.avatarThumbnail]: !openDrawer,
              [classes.avatar]: openDrawer
            })}
            component={RouterLink}
            src={value.user.settings.corporate_logo_url}
            to="/dashboard"
          />
          {/* <Hidden lgUp>
            <Typography
                className={classes.name}
                variant="h6"
            >
              {value.user.admin.first_name} {value.user.admin.last_name}
            </Typography>
            <Typography variant="body2" style={{ color: 'black'}}>ID: { value.user.admin.id }</Typography>
          </Hidden> */}
        </div>
        )
      }
    </UserContext.Consumer>
  );
};

Profile.propTypes = {
  className: PropTypes.string
};

export default Profile;
