/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, {forwardRef, useState, useEffect} from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Divider,
  List,
  ListItem,
  Button,
  Typography,
  Hidden,
  colors,
  Collapse
} from '@material-ui/core';
import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
  ArrowRight as ArrowRightIcon
} from "@material-ui/icons"
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import authenticationService from './../services/authentication.service';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    // backgroundColor: '#465A6D',
    // height: '100%'
  },
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: 'white',
    padding: '11px 12px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    borderRadius: 0,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.body2.fontSize
  },
  icon: {
    color: theme.palette.icon,
    width: 35,
    height: 35,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  active: {
    backgroundColor: '#97D2D3',
    color: '#4B5F75',
    '& $icon': {
      color: '#4B5F75'
    },
    '&:hover': {
      background: "#97D2D3",
    }
  },
  secondaryActive: {
    backgroundColor: '#97D2D3',
    color: '#4B5F75',
    '& $icon': {
      color: '#4B5F75'
    },
    '&:hover': {
      background: "#97D2D3",
    }
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  textLeft: {
    textAlign: 'left'
  },
}));

const CustomRouterLink = forwardRef((props, ref) => (
  <div
    ref={ref}
    style={{ flexGrow: 1 }}
  >
    <RouterLink {...props} />
  </div>
));

const SidebarNav = props => {
  const { pages, pages2, className, openDrawer, ...rest } = props;
  const history = useHistory();

  const classes = useStyles();
  const [ nestedListStates, setNestedListStates ] = useState([])

  const handleLogout = (event) => {
    authenticationService.logout();
    history.push('/login');
  }

  const handleNestedListStateClick = title => event => {
    const currentIndex = nestedListStates.indexOf(title);
    const newNestedListStates = [...nestedListStates];

    if (currentIndex === -1) {
      newNestedListStates.push(title);
    } else {
      newNestedListStates.splice(currentIndex, 1);
    }

    setNestedListStates(newNestedListStates);
  }

  const handleClosedDrawer = link => event => {
    history.push(link);
  }

  function renderPageLink(page) {
    if (!page.subPages) {
      return (
          <ListItem
              className={classes.item}
              disableGutters
              key={page.title}
          >
            <Button
                activeClassName={classes.active}
                className={classes.button}
                component={CustomRouterLink}
                to={page.href}
            >
              <div className={classes.icon}>{page.icon}</div>
              {openDrawer ? page.title : ''}
            </Button>
          </ListItem>
      )
    }

    if (Array.isArray(page.subPages) && page.subPages.length > 0) {
      return (
          <React.Fragment key={page.title}>
            <ListItem
                className={classes.item}
                disableGutters
                button
                onClick={openDrawer ? handleNestedListStateClick(page.title) : handleClosedDrawer(page.href)}
            >
              <Button
                  className={classes.button}
                  style={{ backgroundColor: 'transparent' }}
                  disableRipple={true}
              >
                <div className={classes.icon}>{page.icon}</div>
                { openDrawer ? page.title : ''}
              </Button>
              {  openDrawer && nestedListStates.indexOf(page.title) !== -1 ? <ExpandLessIcon style={{ color: 'white'}} /> :
              <ExpandMoreIcon style={{ color: 'white' }} />}
            </ListItem>
              <Collapse
                  in={nestedListStates.indexOf(page.title) !== -1}
                  timeout="auto"
                  unmountOnExit
              >
                <List component="div" disablePadding>
                  {
                    page.subPages.map((subPage, index) => (
                        <ListItem button disableGutters key={index}>
                          <Button
                              activeClassName={classes.secondaryActive}
                              className={classes.button}
                              component={CustomRouterLink}
                              disableRipple={true}
                              to={subPage.href}
                          >
                            <div className={classes.icon}>
                              <ArrowRightIcon />
                            </div>
                            {subPage.title}
                          </Button>
                        </ListItem>
                    ))
                  }
                </List>
              </Collapse>
          </React.Fragment>
      )
    }
  }

  return (
    <List
      {...rest}
      style={{ width: '100%', backgroundColor: 'rgb(70, 90, 109)' }}
      className={clsx(classes.root, className)}
    >
      {pages.map(page => (renderPageLink(page)))}
      <Divider className={classes.divider} />
      { openDrawer && <ListItem className={classes.item} style={{ marginLeft: "-5px" }}>
        <Typography className={classes.textLeft} variant={"body2"} style={{ color: 'white'}}>
          Settings
        </Typography>
      </ListItem> }

      {
        pages2.map(page => renderPageLink(page))
      }

       { !openDrawer &&
          <ListItem className={classes.item} disableGutters>
            <Button
                className={classes.button}
                onClick={handleLogout}
              >
                <div className={classes.icon}>
                  <ExitToAppIcon style={{ width: 22, height: 22 }}/>
                </div>
                { openDrawer ? 'Logout' : ''}
            </Button>
        </ListItem> }
    </List>
  );
};

SidebarNav.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.array.isRequired
};

export default SidebarNav;
