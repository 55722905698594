import React, { useState, useEffect, useRef, useMemo } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Box, Button, Divider, Link, TextField, IconButton,
    Input, InputAdornment, LinearProgress, Menu, MenuItem,
    FormControl, Grid, InputLabel, Paper, Select, Typography } from '@material-ui/core';
import corporateHealthService from './../services/corporate-health.service';
import OfficeCard from './officeCard';
import EditCompany from './editCompany';
import AddOffice from './addOffice';
import UserList from './../employees/userList';
import SearchIcon from '@material-ui/icons/Search';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useHistory } from 'react-router-dom';
import CsvImport from './../components/csvImport';
import ErrorMessage from './../components/errorMessage';
import SuccessMessage from './../components/successMessage';
import { LocationsMap } from './../maps/locationsMap';
import DeleteIcon from '@material-ui/icons/Delete';
import AdminsOnlyComponent from "../components/AdminsOnlyComponent";
import DeleteLocationPromptDialog from './deleteLocationPromptDialog';
import marker_factory from '../assets/images/factory.png';
import marker_office from '../assets/images/office.png';
import marker_warehouse from '../assets/images/warehouse.png';
import Dashboard from './../dashboard/dashboard';
import corporateLocationService from "../services/corporate-location.service"
import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';
import FavoriteIcon from '@material-ui/icons/Favorite';
import StackedBarChart from './../components/StackedBarChart';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import HealthRiskMetrics from './../healthRisk/healthRiskMetrics';
import { isEqual, difference } from  'lodash';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
    flexGrow: 1,
  },
  officeInfoTitle: {
    padding: 10
  },
  officeInfoSection: {
    padding: 10
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
  cardSpacing: {
    margin: 5
  },
  boldText: {
    fontWeight: 700,
    color: '#333'
  },
  spacer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  buttonSpacer: {
      marginRight: 10
  },
  large: {
    width: theme.spacing(14),
    height: theme.spacing(12),
    objectFit: 'scale-down !important'
  },
  margin: {
    margin: theme.spacing(1),
  },
  textLeft: {
      textAlign: 'left'
  },
  linkColor: {
    color: theme.palette.link.main
  }
}));


const Offices = (props) => {
  const classes = useStyles();
  const [ state, setState ] = useState('');
  const [ company, setCompany ] = useState({
      addresses: []
  });
  const [ mapConfig, setMapConfig ] = useState({
    lng: 121.024956,
    lat: 14.560307,
    zoom: 15
  });
  const [ openConfirmDeletion, setOpenConfirmDeletion ] = useState(false);
  const [ locationForDeletion, setLocationForDeletion ] = useState(null);
  const [ openEditCompany, setOpenEditCompany ] = useState(false);
  const [ openAddOffice, setOpenAddOffice ] = useState(false);
  const [ openUpdateOffice, setOpenUpdateOffice ] = useState(false);
  const [ selectedOffice, setSelectedOffice ] = useState('');
  const { logo, isAdmin } = props;
  const [ locations, setLocations ] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [ loading, setLoading ] = useState(false);
  const [ loadingLocations, setLoadingLocations ] = useState(false);
  const [ metricData, setMetricData ] = useState([]);
  const [ metricList, setMetricList ] = useState({
      checkers : [],
      highRisks: [],
      workers: []
  })

  const [ fieldWorkerMetrics, setFieldWorkerMetrics ] = useState({
      counts: []
  });
  const history = useHistory();
  const [ page, setPage] = useState(0);
  const [ searchTimer, setSearchTimer ] = useState(setTimeout(() => {}))
  const [ params, setParams ] = useState({
      pageSize: 20,
      pageOffset: 0,
      count: 0
  });
  const [ completedLoading, setCompletedLoading ] = useState(false);
  const [ notification, setNotification ] = useState({
    message: '',
    show: false,
    success: false,
    error: false
  });
  const [ locationMarkers, setLocationMarkers ] = useState([]);
  const [ pollingWorkers, setPollingWorkers] = useState(false);
  const [ workerPositions, setWorkerPositions ] = useState([]);
  const [pollCtr, setPollCtr] = useState(0);
  const [pollTimeout, setPollTimeout] = useState(null);
  const pollTimer = 10000
  const mapRef = useRef();

  const [ points, setPoints ] = useState(null);

  const setShowNotification = (value) => {
    setNotification({
        ...notification,
        show: value
    })
  }

    const columns = useMemo(() => {
			return [
        {
            id: 'id',
            label: 'Name',
            minWidth: 160,
            align: 'left',
            format: (value) => value.toLocaleString('en-US'),
        },
        {id: 'address', label: 'Address', minWidth: 'auto', align: 'left'},
        {
            id: 'checkerCount',
						noTooltip:true,
            label: <Typography style={{ display: 'flex', alignItems: 'center'}}>
                <LocalHospitalIcon style={{ color: '#6A99CA' }} /> <span style={{ marginLeft: 5, fontSize: '0.75rem' }}>
                    Checkers
                </span>
            </Typography>,
            
            align: 'left',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'lowRisk',
						noTooltip:true,
            label:  <Typography style={{ display: 'flex', alignItems: 'center'}}>
                <FavoriteIcon style={{ color: '#78BDBA' }} /> <span style={{ marginLeft: 5, fontSize: '0.75rem' }}>
                    Low
                </span>
            </Typography>
        },
        {
            id: 'moderateRisk',
						noTooltip:true,
            label: <Typography style={{ display: 'flex', alignItems: 'center'}}>
                <ErrorIcon style={{ color: '#FE974C' }} /> <span style={{ marginLeft: 5, fontSize: '0.75rem' }}>
                    Moderate
                </span>
            </Typography>
        },
        {
            id: 'highRisk',
						noTooltip:true,
            label: <Typography style={{ display: 'flex', alignItems: 'center'}}>
                <WarningIcon style={{ color: '#E8685E'}} /> <span style={{ marginLeft: 5, fontSize: '0.75rem' }}>
                    High
                </span>
            </Typography>
        }
        // {
        //     id: 'delete',
        //     label: '',
        //     minWidth: 85,
        //     align: 'left'
        // }
    ]}, []);

		useEffect(()=> {
			console.log("📢[officeList.js:144]:", "ue params", params);
		}, [params])
		useEffect(()=> {
			console.log("📢[officeList.js:144]:", "ue columns", columns);
		}, [columns])
		useEffect(()=> {
			console.log("📢[officeList.js:144]:", "ue locations", locations);
		}, [locations])

    function numberFormatter(num) {
        return (num * 100).toFixed(2)
    }

    const getPercentages = (counts) => {
        const reducer = (accumulator, currentValue) => accumulator + currentValue;
        const total = counts.reduce(reducer);

        if (total === 0) {
            return [];
        }

        return counts.map(count => numberFormatter(count / total));
    }

  useEffect(() => {
    //for polling of field worker positions

    setPollingWorkers(true);

    corporateLocationService.getFieldWorkerPositions(props.company)
        .then(data => {
            setFieldWorkerMetrics({
                ...fieldWorkerMetrics,
                total: data.count_within_proximity,
            });

            var count_risks = {
                low: 0,
                moderate: 0,
                high: 0,
            };

            const positions = []

            for (let i = 0; i < data.list.length; i++) {
                count_risks = {
                    low: 0,
                    moderate: 0,
                    high: 0,
                };

                const employee = data.list[i].employee
                const risk = data.list[i].risk
                const hazard = data.list[i].hazard;
                const isStale = data.list[i].is_stale

                const riskLevel =  risk && risk.risk ? risk.risk.name : "UNKNOWN"
                const latitude = data.list[i].latitude
                const longitude = data.list[i].longitude
                const isChecker = data.list[i].is_checker;

                let riskIcon = require('../assets/images/field-worker-stale.png');
                let riskColor = '#767676';
                let mapIcon = 'marker_grey';

                const temperature = data.list[i].temperature
                const covidScreening = data.list[i].covid_screen

                let feverStatus = '';

                if(temperature && temperature <= 37.5) {
                    feverStatus = '( No Fever )';
                } else if(temperature && temperature >= 37.6 && temperature <= 39.4) {
                    feverStatus = '( Moderate Fever )';
                } else if (temperature && temperature > 39.4) {
                    feverStatus = '( High Fever )';
                }

                // [
                //     'Low Risk: ' + fieldWorkerMetrics.counts[0],
                //     'Moderate Risk: ' + fieldWorkerMetrics.counts[1],
                //     'High Risk: ' + fieldWorkerMetrics.counts[2],
                // ]
                var lowCount = 0;
                var modCount = 0;
                var highCount = 0;
                if (hazard && hazard.label) {
                    switch(hazard.label) {
                        case 'LOW': 
                            // riskIcon = require('../assets/images/field-worker-low.png');
                            mapIcon = 'marker_green';
                            count_risks.low++;
                            lowCount++;
                            break;
                        case 'MODERATE':
                            // riskIcon = require('../assets/images/field-worker-moderate.png');
                            mapIcon = 'marker_yellow';
                            count_risks.moderate++;
                            modCount++;
                            break;
                        case 'HIGH':
                            // riskIcon = require('../assets/images/field-worker-high.png');
                            mapIcon = 'marker_red';
                            count_risks.high++;
                            highCount++;
                            break;
                        default:
                            break;
                    }

                    if(isStale) {
                        // riskIcon = require('../assets/images/field-worker-stale.png');
                        mapIcon = `${mapIcon}_stale`;
                    }

                    riskColor = hazard.color;
                }

                positions.push({
                    id: 'worker-' + employee.id,
                    lat: latitude,
                    lng: longitude,
                    popup: '<div style="width: 100%; margin-right: 150px; text-align: left; color: white">' +
                        '<div style="display: flex; align-items: center">' +
                            '<div style="position: relative; padding-top: 10px; display: inline-block; ">' +
                                '<img src="' + employee.profile_pic_url + '" alt="user_logo" style="width:25px; height:25px; object-fit:cover; border-radius:50%; border:3px solid ' + riskColor + '" />' + 
                                // + `<span> <span style="position: absolute; right:-10px; bottom:-10px; background-color: ${isStale ? 'gray' : 'green'}; text-align: center; border-radius: 50%; color:white; padding:10px 10px; font-size:20px;"></span> </span>` +
                                '<a target="_blank" href="/workers/' + employee.id + `" style="color: #3474B7; text-decoration: none; margin-right: 10px; padding-bottom: 30px;"><span style="font-weight: bold; font-size: 15px; margin-left: 10px;">${employee.name}</span></a>` +
                            '</div>' +
                        '</div>' +
                        `<span style="color: gray; font-size: 11px;">${ isChecker ? `Assigned as a <span style="color: #92B3D5; font-weight: 700;">Health Checker</span> <br/>` : '' }</span>` +
                        `<span style="color: white; font-size: 12px">SBU: ${employee.sbu && employee.sbu.label ? employee.sbu.label : 'N/A'}</span>` +
                        '<br />' +
                        `<span style="color: white; font-size: 12px">Risk Level: ${risk && risk.label ? risk.label : 'Unknown'}</span>` +
                        '<br />' +
                        `<span style="color: white; font-size: 12px">Temperature: ${temperature || 'N/A'} ${feverStatus}</span>` +
                        '<br />' +
                        `<span style="color: white; font-size: 12px">COVID Screening: ${covidScreening ? covidScreening.title : 'N/A'}</span>` +
                        `<span> <span style="height: 10px; width: 10px; background-color: ${covidScreening && covidScreening.color ? covidScreening.color : ''};border-radius: 50%; display:inline-block;"></span> </span>` +
                        '</div>',
                    // layer: 'layer' + riskLevel,
                    // source: 'point' + riskLevel,
                    icon: {
                        //id: 'image' + riskLevel,
                        // file: '',
                        // height: 0.75,
                        // width: 0.75,
                        img: mapIcon
                    },
                    properties: {
                        count: 1,
                        count_low: lowCount,
                        count_moderate: modCount,
                        count_high: highCount,
                    }
                })
            }
            if (pollCtr === 0 ) {
                setCompletedLoading(true);
            }
            setWorkerPositions(positions);
            if (mapRef.current) {
                mapRef.current.updateMarkers();
            }

            var countRisks = [ count_risks.low, count_risks.moderate, count_risks.high];

            if(!isEqual(countRisks, fieldWorkerMetrics.counts)) {
                setFieldWorkerMetrics({
                    ...fieldWorkerMetrics,
                    total: count_risks.low + count_risks.moderate + count_risks.high,
                    counts: [ count_risks.low, count_risks.moderate, count_risks.high]
                });
            }
        }).finally(() => {
            setPollingWorkers(false);

            //TODO: revert polling (insanity check)
            setPollTimeout(setTimeout(() => setPollCtr(ctr => ctr + 1), pollTimer))
        })

        /* Piggy-back polling with locations update */
        getMapLocations();

        return () => {
            if (pollTimeout) {
                clearTimeout(pollTimeout)
            }
        }
  }, [pollCtr]);

  useEffect(() => {
    //   if(!props.company_details.company) {
        getCompanyDetails();
    //   } else {
    //     setCompany(props.company_details.company);
    //     getPartnerLocations({ page_size: params.pageSize, page_offset: params.pageOffset});
    //   }
  }, []);


  const handleChange = name => event => {
    setState({
      ...state,
      [name]: event.target.value
    })

    if(name === 'search_text') {
        var p= {
            page_size: params.pageSize,
            page_offset: 0,
            search_text: event.target.value
        };

        setParams({
            ...params,
            pageOffset: 0
        });


        if (searchTimer) {
            clearTimeout(searchTimer)
        }

        setSearchTimer(
            setTimeout( () => {
                getPartnerLocations(p);
            }, 1000)
        );
    }
  }

  function getCompanyDetails() {
    setLoading(true);
    corporateHealthService.getPartnerDetails(props.company).then(
      response => {
          setLoading(false);
          setCompany(response);

          getPartnerLocations({ page_size: params.pageSize, page_offset: params.pageOffset});

          getMapLocations();
      }, error => {
          setLoading(false);
          setNotification({
            ...notification,
            message: { message: 'Unable to fetch company details' },
            show: true,
            error: true
        })
      }
    )
  }

  function getMapLocations(p) {
    console.log("📢[officeList.js:458]: getMapLocations");
    setLoadingLocations(true);
    corporateHealthService.listLocationCounts(props.company, {...p, exclude_types: 'FIELD'}).then(
        response => {
            var chartData = response.list.map((location) => {
                var total = location.count_risks.low + location.count_risks.moderate + location.count_risks.high;

                return {
                    label: location.location_label,
                    low: location.count_risks.low,
                    moderate: location.count_risks.moderate,
                    high: location.count_risks.high,
                    low_count: total > 0 ? (location.count_risks.low / total)* 100 : 0,
                    moderate_count: total > 0 ? (location.count_risks.moderate / total)* 100 : 0,
                    high_count: total > 0 ? (location.count_risks.high / total)*100 : 0,
                    total: total
                }
            });

            setMetricData(chartData);

              if(response.count >= 1) {
                  const newList = response.list.filter(loc =>  {
                      if(loc.zone && loc.zone.latitude !== 0 &&  loc.zone.longitude !== 0) return loc;
                  });

                  const featurePoints = newList.map(l => {
                      var mapIcon = 'marker_office'
                      switch(l.location_type_label) {
                          case 'Office':
                            mapIcon = 'marker_office'; break;
                          case 'Factory':
                            mapIcon = 'marker_factory'; break;
                          case 'Warehouse':
                            mapIcon = 'marker_warehouse'; break;
                          default:
                              break;
                      }

                      return {
                          id: 'location-' + l.location_id,
                          lat: l.zone.latitude,
                          lng: l.zone.longitude,
                          popup: `<div>
                                    <span style="text-transform: uppercase">
                                        ${l.location_type_label}
                                    </span>
                                    <h4 style="margin: 0 !important; padding: 0 !important;">
                                        ${l.location_label}
                                    </h4>
                                    <div style="
                                        opacity: 0.7;
                                        text-align: left;">
                                        <div><span style="border-radius: 25%;
                                            display: inline-block;
                                            height: 10px;
                                            margin-right: 5px;
                                            width: 10px;
                                            background-color: #4BAF8B;
                                            text-align: left;"></span>
                                                <span style="font-weight: 700">${l.count_risks.low || 0} Low Risk </span>
                                            </div>
                                        <div><span style="border-radius: 25%;
                                                display: inline-block;
                                                height: 10px;
                                                margin-right: 5px;
                                                width: 10px;
                                                background-color: #FE974C;
                                                text-align: left;
                                                "></span>
                                                <span style="font-weight: 700">${l.count_risks.moderate || 0} Moderate Risk </span>
                                            </div>
                                            <div><span style="border-radius: 25%;
                                            display: inline-block;
                                            height: 10px;
                                            margin-right: 5px;
                                            width: 10px;
                                            background-color: #DD5353;
                                            text-align: left;
                                            "></span>
                                            <span style="font-weight: 700">${l.count_risks.high || 0} High Risk </span>
                                            </div>
                                    </div>
                                </div>`,
                          icon: {
                              //id: 'image' + riskLevel,
                              // file: '',
                              // height: 0.75,
                              // width: 0.75,
                              img: mapIcon
                          },
                          properties: {
                                count: l.count_employee || 0,
                                count_low: l.count_risks.low || 0,
                                count_moderate: l.count_risks.moderate || 0,
                                count_high: l.count_risks.high || 0,
                         }
                      }
                  })

                  if (newList.length >= 1) {
                        setMapConfig({
                            ...mapConfig,
                            latitude: newList[0].zone.latitude,
                            longitude: newList[0].zone.longitude
                        });
                  }

                  setPoints(featurePoints);

                  if (mapRef.current) {
                      mapRef.current.updateMarkers();
                  }
              }
              setLoadingLocations(false);
        }, error => {
              setLoadingLocations(false);
              setNotification({
                  ...notification,
                  message: { message: 'Unable to fetch locations data' },
                  show: true,
                  error: true
              })
        }
    )
}

  const handleDeleteLocation = location => event => {
    setOpenConfirmDeletion(true);
    setLocationForDeletion(location);
  }

  const handleCloseConfirmDeletion = event => {
    setOpenConfirmDeletion(false);
    setLocationForDeletion(null);
  }

  function getPartnerLocations(p) {
      console.log("📢[officeList.js:596]: getPartnerLocations");
      setLoadingLocations(true);
      corporateHealthService.listLocationCounts(props.company, { ...p, exclude_types: 'FIELD'}).then(
          response => {

                setParams({
                    ...params,
                    count: response.count
                });
                var newList = response.list.map((location) => {
                    if (location.zone) {
                        setLocationMarkers(markers => markers.concat({
                            lat: location.zone.latitude,
                            lng: location.zone.longitude,
                            data: '<style>.H_ib_body{ background: rgba(0, 0, 0, .75); } .H_icon { fill: white !important }</style>' +
                                '<div style="width: 100%; margin-right: 150px; text-align: left; color: white">' +
                                `<span style="font-weight: bold; font-size: 15px;">${location.location_label}</span>` +
                                '<br />' +
                                `<span style="font-size: 12px;">${location.location_address}</span>` +
                                '</div>'

                        }))
                    }

                    return {
                        id:
                        <Link
                            component="button"
                            variant="body1"
                            className={clsx(classes.textLeft, classes.linkColor)}
                            onClick={() => {
                                history.push(`locations/${location.location_id}`)
                            }}
                        >
                            { location.location_label}
                        </Link>,
                        address:
                            location.location_address || '' ,
                        lowRisk: 
                            location.count_risks ? location.count_risks.low : '-' ,
                        moderateRisk: <React.Fragment>
                            {
                                location.count_risks && location.count_risks.moderate > 0 ? 
                                <span style={{ color: '#FE974C'}}>
                                    { location.count_risks.moderate }
                                </span> : <span>
                                    0
                                </span>
                            }
                        </React.Fragment>,
                        highRisk: <React.Fragment>
                             {
                                location.count_risks && location.count_risks.high > 0 ? 
                                <span style={{ color: '#D94135'}}>
                                    { location.count_risks.moderate }
                                </span> : <span>
                                    0
                                </span>
                            }
                        </React.Fragment>,
                        checkerCount: location.count_checker,
                        // delete: <IconButton onClick={handleDeleteLocation(location)}>
                        //         <DeleteIcon />
                        //     </IconButton>
                    }
                });

                setLocations(newList);

                console.log('newList: ', newList);
                setLoadingLocations(false);
          }, error => {
                setLoadingLocations(false);
								setNotification({
                    ...notification,
                    message: { message: 'Unable to fetch locations data' },
                    show: true,
                    error: true
                })
          }
      )
  }

  const handleOpenEditCompany = () => {
    setOpenEditCompany(true);
  }

  const handleCloseEditCompany = () => {
      setOpenEditCompany(false);
  }

  const handleOpenAddOffice = () => {
      if (isAdmin) {
          setOpenAddOffice(true);
      }
  }

  const handleCloseAddOffice = () => {
      getCompanyDetails();
      setOpenAddOffice(false);
  }

  const handleOpenUpdateOffice = (office) => {
      setOpenUpdateOffice(true);
      setSelectedOffice(office);
  }

  const handleCloseUpdateOffice = () => {
      setOpenUpdateOffice(false);
  }

  function handleChangePage(newPage) {
        if (newPage > page ) {
            var poffset = ((newPage + 1) - 1) * params.pageSize;
            setParams({
                ...params,
                pageOffset: poffset
            })
            getPartnerLocations({ page_size: params.pageSize, page_offset: poffset})
        } else if (newPage < page) {
            var poffset2 = newPage > 0 ? ((newPage - 1) - 1) * params.pageSize : 0;

            setParams({
                ...params,
                pageOffset: poffset2
                // pageOffset: ((newPage + 1) * params.pageSize) - params.pageSize
            })
            getPartnerLocations({ page_size: params.pageSize, page_offset: poffset2})
        }
        setPage(newPage);
  }

  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  function importLocations (file, setModal, setLoader) {
    setLoader(true)

    let params = new FormData()
    params.append('file', file)
    params.append('partner_id', props.company);

    corporateHealthService.importLocations(props.company, params)
        .then(() => {
            var p= {
                page_size: params.pageSize,
                page_offset: 0,
                search_text: ''
            };

            setState({
                ...state,
                search_text: ''
            });

            setParams({
                ...params,
                pageOffset: 0
            });

            getPartnerLocations(p);


            setNotification({
                ...notification,
                message: 'Batch Import Successful',
                show: true,
                success: true
            })
            setModal(false)
        })
        .catch((err) => {
            setNotification({
                ...notification,
                message: { message: 'Unable to import CSV' },
                show: true,
                error: true
            })
        })
        .finally(() => {
            setLoader(false)
        })
}


  return (
    <div className={classes.root}>
        { notification.error && <ErrorMessage open={notification.show} setOpen={setShowNotification} error={notification.message} /> }
        { notification.success && <SuccessMessage open={notification.show} setOpen={setShowNotification} message={notification.message} /> }
        { loading ? <LinearProgress color="primary" /> : undefined}
        { openAddOffice ?
            <AddOffice open={openAddOffice} handleClose={handleCloseAddOffice} selectedOffice={null} company={company} /> : undefined }
        { openConfirmDeletion && locationForDeletion ?
            <DeleteLocationPromptDialog location={locationForDeletion} handleClose={handleCloseConfirmDeletion} open={openConfirmDeletion} />
        : undefined}
        <Dashboard company={props.company} metricList={metricList} />
        <Grid container direction="column" alignItems="flex-start" justify="center" style={{ width: '100%'}}>
            <Grid item className={classes.spacer} xs={12}>
                <Typography variant="h5" className={classes.boldText}>
                   Locations Map
                </Typography>
            </Grid>
            {
                completedLoading && points && (
                    <Grid container item justify="flex-start" alignItems={"flex-start"} xs={12}>
                        <Grid item xs={7}>
                            <LocationsMap
                                ref={mapRef}
                                mapConfig={mapConfig}
                                points={points}
                                markers={workerPositions}
                                styles={{
                                    width: "100%",
                                    height: "100vh",
                                    maxHeight: 492
                                }}
                            />
                        </Grid>
                        {  (fieldWorkerMetrics && fieldWorkerMetrics.counts && fieldWorkerMetrics.counts.length >= 1 && getPercentages(fieldWorkerMetrics.counts).length >= 1) || metricData.length > 0 ? <Grid item container xs={5} direction="row" alignItems="flex-start" justify="flex-start">
                            { metricData.length > 0 ? <Paper style={{ width: '100%', marginBottom: 10, padding: 10, marginLeft: 10}} variant="outlined">
                                <Grid container style={{ width: '100%', maxHeight: 300, overflowY: 'auto', overflowX: 'hidden', minWidth: 404 }}>
                                    <Grid item container xs={11} alignItems="center" justify="center" style={{ marginLeft: 10}}>
                                        <Grid item xs={8} style={{ padding: 0, margin: 0}} align="center">
                                            <Typography variant="caption" style={{ fontWeight: 700, color: '#494949', marginLeft: 5 }}>
                                                Total Workers on All Locations
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4} style={{ padding: 0, margin: 0}} align="right">
                                            <Typography variant="h4" style={{ fontWeight: 700, color: '#494949' }}>
                                                { company.employeeCount || 0 }
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    {/* <Grid item xs={12} style={{ padding: 0, margin: 0}}>
                                        <Divider variant="middle" />
                                    </Grid> */}
                                    { metricData.length > 0 ? metricData.map((location, index) => {
                                                return ( location && location.total > 0 ? <React.Fragment key={index}>
                                                    <Grid item xs={4}  style={{ paddingTop: 18}} align="left">
                                                        <Typography variant="caption">
                                                            { location.label }
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={8} style={{ paddingTop: 10}} align="left">
                                                        <StackedBarChart data={location} />
                                                    </Grid>
                                                </React.Fragment> : undefined )
                                        }) : undefined 
                                    }
                                </Grid>
                            </Paper> : undefined }
                            {
                                (fieldWorkerMetrics && fieldWorkerMetrics.counts && fieldWorkerMetrics.counts.length >= 1 && getPercentages(fieldWorkerMetrics.counts).length >= 1) && (
                                    <Grid item xs={12} style={{ marginLeft: 10}}>
                                        <HealthRiskMetrics
                                            title={'Health Hazard Assessment'}
                                            // label={'Total Field-Based Workers in All Locations'}
                                            total={ fieldWorkerMetrics.total.toLocaleString() }
                                            backgroundColors={['#85CBBE', '#EEBE6A', '#E8685E']}
                                            // description={`Percentage of field-based\nworkers\nbased on hazard levels`}
                                            data={[
                                                { name: 'Low Hazard', value: fieldWorkerMetrics.counts[0]},
                                                { name: 'Moderate Hazard', value: fieldWorkerMetrics.counts[1]},
                                                { name: 'High Hazard', value: fieldWorkerMetrics.counts[2]}
                                            ]}
                                        />
                                    </Grid>
                                )
                            }
                        </Grid> : undefined }
                    </Grid>
                )
            }
            <Grid item container className={classes.spacer} xs={12} spacing={0}>
                {/* <Grid item container justify="flex-start" alignItems ="flex-start" xs={4} align="left">
                    <Typography variant="body1" style={{ paddingTop: 10, fontWeight: 700}}>
                        { company.label || '' } Locations
                    </Typography>
                </Grid> */}
                <Grid item container justify="flex-start" alignItems="flex-start" xs={6}>
                    <FormControl>
                        <TextField
                            onChange={handleChange('search_text')}
                            value={state.search_text || ''}
                            label="Search locations"
                            id="outlined-start-adornment"
                            style={{ minWidth: 300 }}
                            className={clsx(classes.margin, classes.textField)}
                            InputProps={{
                                startAdornment: <InputAdornment position="end">
                                    <SearchIcon />
                                </InputAdornment>,
                            }}
                            variant="outlined"
                        />
                        {/* <InputLabel htmlFor="input-with-icon-adornment">Search locations</InputLabel>
                        <Input
                        onChange={handleChange('search_text')}
                        value={state.search_text || ''}
                        variant="outlined"
                        id="input-with-icon-adornment"
                        startAdornment={
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        }
                        /> */}
                    </FormControl>
                </Grid>
                <Grid item container justify="flex-end" alignItems="flex-end" xs={3} align="right" style={{ marginBottom: 8}}>
                    <AdminsOnlyComponent>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={handleOpenAddOffice}
                            className={classes.buttonSpacer}
                            disabled={!isAdmin}
                        >
                            Add Location
                        </Button>
                    </AdminsOnlyComponent>
                </Grid>
                <Grid item container justify="flex-end" alignItems="flex-end" xs={3} align="right" style={{ marginBottom: 8}}>
                    <CsvImport
                        downloadUrl={'http://dnjqko642wsuu.cloudfront.net/public/corporate/location+import+template.csv'}
                        fileName={'Bulk Location Import Template.csv'}
                        title={"Bulk Import Locations"}
                        buttonText={"Bulk Import Locations"}
                        action={importLocations}
                    />
                </Grid>
            </Grid>
        </Grid>
        <Grid container direction="row" alignItems="center" justify="center">
            <Grid item xs={12}>
                { loadingLocations ? <LinearProgress color="primary" /> : undefined}
                <UserList 
									loader={loadingLocations}
									columns={columns || []}
									rows={locations}
									count={params.count || 0}
									rowsPerPage={params.pageSize}
									handlePage={handleChangePage}
								/>
            </Grid>
        </Grid>
    </div>
  );
};

export default Offices;
