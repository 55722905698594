import React, { useState } from "react";
import {
    Backdrop,
    Button,
    CircularProgress,
    Divider,
    Fade,
    Grid,
    Modal,
    Typography,
    Select,
    MenuItem
} from "@material-ui/core";
import clsx from "clsx";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    textLeft: {
        textAlign: 'left'
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    modalContent: {
        backgroundColor: theme.palette.background.paper,
        border: 0,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(3),
        width: '50vw',
    },
    selection: {
        [theme.breakpoints.only('xs')]: {
            width: '100%'
        },
        [theme.breakpoints.up('sm')]: {
            width: '50%'
        }
    }
}));

export default function (props) {
    const classes = useStyles();

    const employee = props.employee
    const modal = props.open
    const setModal = props.setOpen
    const [ isLoading, setLoading ] = useState(false)

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={modal}
            onClose={() =>  {
                setModal(false)
            }}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={modal}>
                <div className={classes.modalContent}>
                    <Grid container alignItems="center">
                        <Grid item xs={12}>
                            <Grid container justify="space-between" alignItems="center">
                                <Grid item>
                                    <Typography variant="h5" style={{ fontWeight: 'bold' }} className={clsx(classes.textLeft, classes.uppercase)}>
                                        Approve Account Linking
                                    </Typography>
                                    <Typography variant="subtitle1" className={classes.textLeft}>
                                        Please confirm the following for ID linking approval.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Divider style={{ width: '100%' }} />

                        <br /><br /><br />

                        <Grid container direction="column" spacing={3}>
                            <Grid item container direction="row">
                                <Grid item xs={12} sm={3}>
                                    <Typography variant="subtitle1" className={clsx(classes.textLeft, classes.uppercase)}>
                                        Employee Name:
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={9}>
                                    <Typography variant="subtitle1" className={clsx(classes.textLeft, classes.uppercase)} style={{ fontWeight: 'bold' }}>
                                        { employee.name }
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item container direction="row">
                                <Grid item xs={12} sm={3}>
                                    <Typography variant="subtitle1" className={clsx(classes.textLeft, classes.uppercase)}>
                                        ID Number:
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={9}>
                                    <Typography variant="subtitle1" className={clsx(classes.textLeft, classes.uppercase)} style={{ fontWeight: 'bold' }}>
                                        { employee.cardNumber }
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <br />

                    <Divider style={{ width: '100%' }} />

                    <br />


                    <Grid container>
                        <Grid item xs={12} sm={3}>
                            <Button onClick={() => props.actionCallback(employee.id)} disabled={isLoading} variant="contained" color="primary" style={{ width: '100%', color: 'white' }}>
                                { isLoading
                                    ? <CircularProgress color="primary" />
                                    : 'Approve'
                                }
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            </Fade>
        </Modal>
    )
}
