import React, {useState} from "react";
import {
    Backdrop,
    Button,
    CircularProgress,
    Divider,
    Fade,
    Grid,
    IconButton,
    Modal,
    Typography,
    Card,
    Paper
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import {
    Close as CloseIcon,
    CloudUpload as CloudUploadIcon,
    Description as DescriptionIcon,
    FiberManualRecord as DotIcon
} from "@material-ui/icons";
import DownloadTemplate from './downloadTemplate'

const useStyles = makeStyles(theme => ({
    textLeft: {
        textAlign: 'left'
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh'
    },
    modalContent: {
        backgroundColor: theme.palette.background.paper,
        border: 0,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(3),
        [theme.breakpoints.only('xs')]: {
            width: '50vw'
        },
        [theme.breakpoints.up('sm')]: {
            width: '45vw'
        }
    },
    title: {
        [theme.breakpoints.only('xs')]: {
            ...theme.typography.subtitle1
        },
        [theme.breakpoints.up('sm')]: {
            ...theme.typography.h5,
            fontWeight: 'bold'
        }
    },
    block: {
        width: '100%'
    },
    disableHover:  {
        backgroundColor: "transparent"
    }
}));

export default function (props) {
    const classes = useStyles()
    const [ modal, setModal ] = useState(false)
    const [ file, setFile ] = useState(null)
    const [ loader, setLoader ] = useState(false)
    const { downloadUrl, fileName, downloadParams } = props;

    const title = props.title || "Import CSV"
    const subtitle = props.subtitle
    const instructionMessage = props.instructionMessage
    const requiredFields = props.requiredFields
    const buttonText = props.buttonText || "Import CSV"
    const buttonUploadText = props.buttonUploadText || "Upload"
    const actionCallback = props.action

    function handleImageClick() {
        document.getElementById("selectImage").click()
    }

    function handleFileSelection(event) {
        setFile(event.target.files[0])

        // const reader  = new FileReader();

        // reader.addEventListener("load", function () {
        // }, false);

        // if (file) {
        //     reader.readAsDataURL(file);
        // }
    }

    return (
        <React.Fragment>
            <Button onClick={() => {
                setModal(true)
            }} color="primary" variant="outlined" 
            >
                { buttonText }
            </Button>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={modal}
                onClose={() =>  {
                    setModal(false)
                }}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{timeout: 500}}
            >
                <Fade in={modal}>
                    <div className={classes.modalContent}>
                        <Grid container alignItems="center" justify={"flex-start"}>
                            <Grid item xs={12}>
                                <Grid container justify="space-between" alignItems="center">
                                    <Grid item>
                                        <Typography className={clsx(classes.textLeft, classes.title)}>
                                            { title }
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <IconButton aria-label="delete" size="small" onClick={() => { setModal(false) }}>
                                            <CloseIcon color="error" />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {
                                subtitle && (
                                    <Grid item container xs={12} justify="space-between" alignItems="center">
                                        <Grid item>
                                            <Typography variant={"body2"} className={clsx(classes.textLeft)} style={{ color: "#5E5E5E" }}>
                                                { subtitle }
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                )
                            }

                            <Divider style={{ width: '100%' }} />
                            <br />
                            <br /><br />

                            {
                                instructionMessage && (
                                    <Grid item xs={12}>
                                        <Typography variant={"body2"} className={clsx(classes.textLeft)} style={{ color: "#757575" }}>
                                            { instructionMessage }
                                        </Typography>
                                    </Grid>
                                )
                            }

                            {
                                Array.isArray(requiredFields) && requiredFields.length > 0 && (
                                    <Grid item container xs={12} style={{ marginTop: "1%", marginBottom: "2%" }} alignItems={"center"} justify={"flex-start"}>
                                        {
                                            requiredFields.map(field => (
                                                <Grid key={field} item xs={12}>
                                                    <Typography variant={"body2"} className={clsx(classes.textLeft)} style={{ color: "#757575" }}>
                                                        <DotIcon fontSize={"inherit"} /> { field }
                                                    </Typography>
                                                </Grid>
                                            ))
                                        }
                                    </Grid>
                                )
                            }

                            <Grid item container alignItems={"flex-start"} justify={"flex-start"} xs={12} spacing={3}>
                                <Grid item xs={12}>
                                    <DownloadTemplate title={fileName} downloadUrl={downloadUrl} downloadParams={downloadParams}/>
                                </Grid>
                                <Grid item container xs={12} sm={2}>
                                    <Typography variant={"body2"} className={clsx(classes.textLeft)} style={{ fontWeight: "bold" }}>
                                        Upload File
                                    </Typography>
                                </Grid>
                                <Grid item container xs={12} sm={10}>
                                    <Paper variant={"outlined"} style={{ width: "100vw", height: "175px" }}>
                                        <Grid container direction={"column"} alignItems={"center"} justify={"center"} spacing={2}>
                                            <Grid item>
                                                <IconButton style={{ backgroundColor: 'transparent' }} color={file ? "primary" : "default"} aria-label="upload" onClick={handleImageClick}>
                                                    { file ? <DescriptionIcon style={{fontSize: 100}} /> : <CloudUploadIcon style={{fontSize: 100}} />}
                                                    <input id="selectImage" accept=".csv,.tsv" hidden type="file" onChange={handleFileSelection} />
                                                </IconButton>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="subtitle1">
                                                    { file ? file.name : "Choose a file" }
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>
                                <Divider style={{ width: '100%' }} />
                                <Grid item xs={12} sm={4}>
                                    <Button
                                        className={classes.block} variant={"contained"}
                                        color={"primary"} style={{ color: 'white' }}
                                        disabled={!file || loader} onClick={() => actionCallback(file, setModal, setLoader)}
                                    >
                                        { loader ? <CircularProgress /> : buttonUploadText }
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </Fade>
            </Modal>
        </React.Fragment>
    )
}
