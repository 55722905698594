import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import error_img from './../assets/svg/errorv2.svg';


const useStyles = makeStyles(theme => ({
    root: {
      paddingTop: theme.spacing(16),
      paddingBottom: theme.spacing(4),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      flexGrow: 1
    },
    officeInfoTitle: {
      padding: 10
    },
    officeInfoSection: {
      padding: 10
    },
    oopsStyle: {
        // color: theme.palette.error.main
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 200,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    textLeft: {
        textAlign: 'left'
    },
    marginSpacer: {
        marginRight: 10
    },
    margin: {
      margin: theme.spacing(2)
    },
    errorIcon: {
      color: theme.palette.error.main,
      backgroundColor: 'white',
      borderColor: theme.palette.error.main
    },
    linkColor: {
        color: theme.palette.link.main
    },
    mainImg: {
        [theme.breakpoints.down('sm')]: {
            width: '20em'
        },
        [theme.breakpoints.up('md')]: {
            width: '45em'
        },
    }
}));

const GenericError = (props) => {
    const classes = useStyles();

    return(
        <div className={classes.root}>
            <Grid container justify="center" alignItems="center">
                <Grid item xs={12} md={8} container justify="center" alignItems="center">
                    <img src={error_img} alt="Error Placeholder" className={classes.mainImg}/>
                </Grid>
                <Grid item xs={12} md={4} container justify="center" alignItems="center">
                    <Typography variant="h6" className={classes.oopsStyle} style={{ marginBottom: 20}}>
                        Page failed to load.
                    </Typography>
                    <Typography variant="body1">
                        This is really embarassing.
                        We've run into a problem. Please
                        refresh the page or contact Support if problem persists.
                    </Typography>
                </Grid>
            </Grid>
        </div>
    )
}

export default GenericError;