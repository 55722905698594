import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Dialog, DialogContentText, Typography, Grid, CircularProgress, TextField, useTheme, FormControl, InputLabel, Select, ExpansionPanelSummary } from '@material-ui/core';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import corporateHealthService from './../services/corporate-health.service';
import { ExpansionPanel, ExpansionPanelDetails } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import moment from 'moment';
import {
  CloudUpload as CloudUploadIcon,
  Description as DescriptionIcon
} from "@material-ui/icons";
import ErrorMessage from './../components/errorMessage';
import SuccessMessage from './../components/successMessage';

const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    fullLength: {
        width: '100%'
    }
  });

  const useStyles = makeStyles(theme => ({
    root: {
      padding: theme.spacing(4),
      flexGrow: 1,
      color: '#000'
    },
    officeInfoTitle: {
      padding: 10,
      color: '#000'
    },
    officeInfoSection: {
      padding: 10,
      color: '#000'
    },
    formControl: {
      marginRight: theme.spacing(1),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      minWidth: 300,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    fullHeight: {
      height: '100vh',
      width: '100vw'
    },
    headerPadding: {
      padding: 20,
      fontWeight: 700
    },
    textFieldSpacer: {
      margin: theme.spacing(1)
    },
    marginSpace: {
      margin: theme.spacing(3)
    },
    errorColor: {
      color: theme.palette.error.main,
      backgroundColor: 'white',
      borderColor: theme.palette.error.main
    },
    btnMargin: {
      marginTop: 10,
      marginLeft: 10
    }
  }));

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
});
  
const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);
  

const RemoveFromQuarantine = (props) => {
    const { open, handleClose, employee } = props;
    const classes = useStyles();
    const [ doctorClearanceFile, setDoctorClearanceFile ] = useState(null);
    const [ hrClearanceFile, setHrClearanceFile ] = useState(null);
    const [ openDocUpload, setOpenDocUpload ] = useState(false);
    const [ openHrUpload, setOpenHrUpload ] = useState(false);
    const [ message, setMessage ] = useState('');
    const [ error, setError ] = useState('');
    const [ openSuccess, setOpenSuccess ] = useState(false);
    const [ openError, setOpenError ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const [ state, setState ] = useState({
      note: ''
    });

      useEffect(() => {

      }, []);

    const handleChange = name => event => {
      setState({
        ...state,
        [name]: event.target.value
      });
    }

    const handleFileSelection = fileType => event => {
      if(fileType === 'doctor') {
        setDoctorClearanceFile(event.target.files[0]);
      }
      if(fileType === 'hr') {
        setHrClearanceFile(event.target.files[0]);
      }
    }

    const handleImageClick = fileType => event => {
      if(fileType === 'doctor') {
        setOpenDocUpload(true);
      } else {
        setOpenHrUpload(true);
      }
    }

    const handleDone = event => {
      let params = new FormData();
      params.append('hr_image', hrClearanceFile);
      params.append('doctor_image', doctorClearanceFile );
      params.append('employee_id', props.employee.employee.employee_id);
      params.append('quarantine_id', props.employee.id);
      params.append('note', state.note);

      setLoading(true);
      corporateHealthService.clearFromQuarantine(props.company, params).then(
        response => {
          setMessage('Successfully uploaded HR and Doctor clearances.');
          setOpenSuccess(true);

          setLoading(false);

          setTimeout(() => {
            handleClose();
          }, 1000);
        }, error => {
          setError({
            message: 'Unable to upload clearances.'
          });
          setOpenError(true);
          setLoading(false);
        }
      );

    }

    return (
        <div>
            <Dialog maxWidth={'sm'} fullWidth={true} open={open} onClose={handleClose}
                aria-labelledby="upload-clearances-title">
                 {error ?  <ErrorMessage error={error} open={openError} setOpen={setOpenError} /> : undefined}
                { message  ? <SuccessMessage open={openSuccess} setOpen={setOpenSuccess} message={message} /> : undefined}
                <DialogTitle id="upload-clearances-title" onClose={handleClose}>
                    <Typography component="span" variant="h6" style={{ fontWeight: 700}}>
                      Quarantined to Fit to Work Onsite <br />
                    </Typography>
                    <Typography component="span" variant="caption">
                        Removing an employee from the quarantine requires clearances from
                         a Doctor and a Company HR.
                    </Typography>
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={4}>
                      <Grid item xs={4} sm={4}>
                          <Typography variant="caption">
                            Employee Name
                          </Typography>
                      </Grid>
                      <Grid item xs={8} sm={8}>
                          <Typography variant="subtitle1" style={{ fontWeight: 700}}>
                              { employee.employee.last_name }, { employee.employee.first_name }
                          </Typography>
                              { employee.risk || '-' } { moment(employee.end_date).diff(moment(employee.start_date), 'days')} days in Quarantine
                      </Grid>
                      <Grid item xs={4} sm={4}>
                          <Typography variant="caption">
                              Upload Doctor Clearance
                          </Typography>
                      </Grid>
                      <Grid item xs={8} sm={8}>
                            <input accept="image/*" style={{ display: 'none'}} className={classes.input} id="icon-button-file" type="file" onChange={handleFileSelection('doctor')}/>
                            <label htmlFor="icon-button-file">
                              <IconButton color="primary" aria-label="upload picture" size="medium" component="span">
                                <CloudUploadIcon />
                              </IconButton>
                            </label>
                            <br />
                            <Typography variant="caption">
                              { doctorClearanceFile ? doctorClearanceFile.name : '' }
                            </Typography>
                            {/* <IconButton style={{ backgroundColor: 'transparent' }} color={ doctorClearanceFile ? "primary" : "default"} aria-label="upload" onClick={handleImageClick('doctor')}>
                                { doctorClearanceFile ? <DescriptionIcon style={{fontSize: 100}} /> : <CloudUploadIcon style={{fontSize: 100}} />}
                                { openDocUpload ? <input id="selectImageDoctor" accept=".csv,.tsv" hidden type="file" onChange={handleFileSelection('doctor')} /> : undefined }
                            </IconButton> */}
                      </Grid>
                      <Grid item xs={4} sm={4}>
                          <Typography variant="caption">
                            Upload HR Clearance
                          </Typography>
                      </Grid>
                      <Grid item xs={8} sm={8}>
                            <input accept="image/*" style={{ display: 'none'}} className={classes.input} id="icon-button-hr-file" type="file" onChange={handleFileSelection('hr')}/>
                            <label htmlFor="icon-button-hr-file">
                              <IconButton color="primary" aria-label="upload picture" size="medium" component="span">
                                <CloudUploadIcon />
                              </IconButton>
                            </label>
                            <br />
                            <Typography variant="caption">
                              { hrClearanceFile ? hrClearanceFile.name : '' }
                            </Typography>
                      </Grid>
                      <Grid item xs={4} sm={4}>
                          <Typography variant="caption">
                            Short note on why employee is fit to work
                          </Typography>
                      </Grid>
                      <Grid item xs={8} sm={8}>
                        <TextField
                          id="outlined-multiline-static"
                          label="Short note"
                          required
                          fullWidth
                          multiline
                          rows={4}
                          onChange={handleChange('note')}
                          value={state.note}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item container>
                        <Button variant="contained" style={{ color: 'white'}} disabled={loading} onClick={handleDone} color="primary">
                          Done { loading && <CircularProgress size="small" color="primary" />}
                        </Button>
                      </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </div>
    )

}

export default RemoveFromQuarantine;
  
