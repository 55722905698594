import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { ThemeProvider, Typography } from '@material-ui/core';
import TablePaginationActions from './../components/tablePaginationActions';
import ZennyaTheme from '../providers/ZennyaThemeProvider';
import DataGridProvider, { actions, DataGridContext } from '@zennya/web-component-library/src/components/DataGrid/DataGridContext';
import { DataGrid } from '@zennya/web-component-library/src/components/DataGrid';

const useStyles = makeStyles(theme => {
  return {
    root: {
      display: "flex, flexDirection: column, height: 70vh"
    },
    container: {
      maxHeight: '100vh',
    },
    columnHeader: {
      textTransform: 'uppercase',
      // fontWeight: 700
    },
    body1: {
      ...theme.typography.body1,
      fontSize: "15px"
    }
  }
})

function UserList(props) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  // const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const { columns, rows, handlePage, count, loader, pagination = true } = props;

  const [dataColumns, setDataColumns] = useState([]);
  const [dataRows, setDataRows] = useState([]);

  const [state, dispatch] = useContext(DataGridContext)

  useEffect(() => {
    const datagridColumns = columns.map(col => {
      const columnHeaderRenderer = (col.label) ? (cprops) => <col.label {...cprops}/> : null
      return {
        key: col.id,
        colId: col.id,
        name: col.label,
        width: col.minWidth??120,
        noTooltip: col.noTooltip,
        ...columnHeaderRenderer
      }
    })
    setDataColumns(datagridColumns)
  }, [columns])

  useEffect(()=> {
    dispatch({
      type: actions.LOAD_COLUMNS,
      payload: {columns: dataColumns}
    })
  }, [dataColumns, dispatch])

  useEffect(()=> {
    console.log("📢[userList.js:71]:", rows);
    dispatch({
      type: actions.LOAD_ROWS,
      payload: {rows: rows}
    })
  }, [rows, dispatch])


  useEffect(() => {
    setDataRows(rows)
  }, [rows])


  const handleChangePage = (event, newPage) => {
    handlePage(newPage);
    setPage(newPage);
  };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(+event.target.value);
  //   setPage(0);
  // };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      
      <DataGrid
        style={{ flex: '1 1 auto' }}
        containerStyle={{
            flex: '1 1 auto',
            display: 'flex',
            flexDirection: 'column'
        }}
        gridProps={{
          rowHeight: 50,
          defaultColumnOptions: {
            resizable: true,
            width: 80
          }
        }}
      />
      { pagination && <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={count}
          rowsPerPage={props.rowsPerPage || 20}
          page={page}
          onChangePage={handleChangePage}
          ActionsComponent={TablePaginationActions}
        // onChangeRowsPerPage={handleChangeRowsPerPage}
      /> }
      
    </div>
  );
}

export default function WrapUserList(props) {
  return (
    <ThemeProvider theme={ZennyaTheme}>
      <DataGridProvider>
        <UserList {...props} />
      </DataGridProvider>
    </ThemeProvider>
  );
}