import React, {useEffect, useRef, useState} from "react"
import {makeStyles} from "@material-ui/core/styles";
import {
    Paper,
    Grid,
    Typography,
    Divider
} from '@material-ui/core'
import LineChart from "../components/LineChart";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
    fullSize: {
        width: "100%"
    },
    chart: {
        width: "100%"
    },
    textLeft: {
        textAlign: "left"
    },
    textBold: {
        fontWeight: 'bold'
    },
    m1: {
        padding: "0.5rem"
    }
}));

export default function (props) {
    const classes = useStyles()

    return (
        <Paper className={clsx(classes.fullSize)} variant={"outlined"}>
            <Grid container>
                <Grid item container xs={12}>
                    <Typography className={clsx(classes.textLeft, classes.textBold, classes.m1)} variant={"subtitle1"}>
                        Corporate ID Linking Trend
                    </Typography>
                </Grid>

                <Divider style={{ width: "100%" }} />

                <Grid item container alignItems={"center"} justify={"center"} xs={12} style={{ margin: "0.5rem" }} spacing={3}>
                    <Grid item container alignItems={"center"} justify={"center"} xs={12}>
                        <LineChart
                            className={classes.chart}
                            labels={
                                [
                                    'May 1',
                                    'May 2',
                                    'May 3',
                                    'May 4',
                                    'May 5',
                                    'May 6'
                                ]
                            }
                            datasets={
                                [
                                    {
                                        label: 'Rejected ID Linking',
                                        backgroundColor: '#E7685E',
                                        borderColor: '#E7685E',
                                        data: [0, 200, 350, 450, 500, 600],
                                        fill: false
                                    },
                                    {
                                        label: 'Successful ID Linking',
                                        backgroundColor: '#85CBBE',
                                        borderColor: '#85CBBE',
                                        data: [0, 150, 250, 350, 650, 900],
                                        fill: false
                                    },
                                    {
                                        label: 'Workers who have not linked their accounts yet',
                                        backgroundColor: '#919EBB',
                                        borderColor: '#919EBB',
                                        data: [1000, 900, 750, 500, 400, 200],
                                        fill: false
                                    }
                                ]
                            }
                            options={
                                {
                                    responsive: true,
                                    maintainAspectRatio: false,
                                    scales: {
                                        xAxes: [{
                                            gridLines: {
                                                drawOnChartArea: false
                                            }
                                        }],
                                        yAxes: [{
                                            ticks: {
                                                min: 0,
                                                stepSize: 200
                                            },
                                            gridLines: {
                                                drawOnChartArea: false
                                            }
                                        }]
                                    },
                                }
                            }
                        />
                    </Grid>
                    <Grid item container alignItems={"center"} justify={"flex-start"} xs={12}>
                        <Typography variant={"body2"} style={{ color: "#494949" }}>
                            Total number of workers scanned vs. not scanned
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    )
}
