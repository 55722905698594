import instance from './helpers/instance';
import corporateHealthService from './corporate-health.service';

const API_URL = process.env.REACT_APP_WEB_ADMIN_URL;

export default {
    addEmployee: (partnerId, params) => {
        return instance.post(`${API_URL}/api/1/corporate/partners/${partnerId}/employees/references`, params).then(
            response => {
                return response.data;
            }
        )
    },
    updateEmployee: (employeeId, params) => {
        return instance.put(`${API_URL}/api/1/corporate/employees/${employeeId}`, params)
            .then(response => response.data)
            ;
    },
    deleteEmployee: (employeeId) => {
        return instance.delete(`${API_URL}/api/1/corporate/employees/${employeeId}`)
            .then();
    },
    unlinkEmployee: (employeeId) => {
        return instance.delete(`${API_URL}/api/1/corporate/employees/${employeeId}/link`)
            .then();
    },
    getEmployeeLocationRestrictions: (employeeId) => {
        return instance.get(`${API_URL}/api/1/corporate/employees/${employeeId}/restrictions/locations`)
            .then(response => response.data);
    },

    getEmployeeLocationTypeRestrictions: (employeeId) => {
        return instance.get(`${API_URL}/api/1/corporate/employees/${employeeId}/restrictions/location_types`)
            .then(response => response.data);
    },

    addEmployeeLocationRestrictions: (employeeId, locations) => {
        return instance.post(`${API_URL}/api/1/corporate/employees/${employeeId}/restrictions/locations`, locations, { cache: false})
            .then(response => response.data)
    },

    bulkUpdateEmployees: (params) => {
        return instance.post(`${API_URL}/api/1/corporate/employees/bulkUpdate`, params, { cache: false})
            .then(response => response.data)
    },

    addEmployeeLocationTypeRestrictions: (employeeId, location_types) => {
        return instance.post(`${API_URL}/api/1/corporate/employees/${employeeId}/restrictions/location_types`, location_types,  { cache: false})
            .then(response => response.data);
    },

    deleteEmployeeLocationRestrictions: (employeeId, locations) => {
        return instance.delete(`${API_URL}/api/1/corporate/employees/${employeeId}/restrictions/locations?${locations.toString()}`, {  cache: false})
            .then(response => response.data)
    },

    deleteEmployeeLocationTypeRestrictions: (employeeId, location_types) => {
        return instance.delete(`${API_URL}/api/1/corporate/employees/${employeeId}/restrictions/location_types?${location_types.toString()}`,{ cache: false})
            .then(response => response.data);
    },

    processQuarantinedEmployeeClearances: (params) => {
        return instance.post(`${API_URL}/api/1/corporate/quarantine/clearance/process`, params, { cache: false}).then(
            response => {
                return response.data;
            }
        )
    },
    getWorkerCOVIDResults: (id) => {
        return instance.get(`${API_URL}/api/1/corporate/employees/${id}/covid_test_results`, { cache: false}).then(
            response => {
                return response.data;
            }
        )
    },
    resendInvites: (idArray) => {
        return instance.post(`${API_URL}/api/1/corporate/employees/invite`, {references:[...idArray], cache: false}).then(
            response => {
                return response.data
            }
        )
    },
    fetchMetricsComorbidities: async (partnerId, peopleType) => {
        const comorNamesList = ((await corporateHealthService.getComorbidities())?.data?.list)
        let data = (await instance.get(`${API_URL}/api/1/corporate/partners/${partnerId}/medical/counts/comorbidities?type=${peopleType}`))?.data?.list
        const mappedData = data
            .filter(el=>el.value>0)
            .sort((a,b)=> b.value - a.value)
            .map(el=>{
            const comorName = comorNamesList.find(e=>e.name === el.name)?.label
            return {...el, name: comorName}
        })
        return {data:mappedData, tag:'comorbidities'}
    },
    fetchMetricsAllergies: async (partnerId, peopleType) => {
        const allerNamesList = ((await corporateHealthService.getAllergies())?.data?.list)
        let data = (await instance.get(`${API_URL}/api/1/corporate/partners/${partnerId}/medical/counts/allergies?type=${peopleType}`))?.data?.list
        const mappedData = data
            .filter(el=>el.value>0)
            .sort((a,b)=> b.value - a.value)
            .map(el=>{
            const comorName = allerNamesList.find(e=>e.name === el.name)?.label
            return {...el, name: comorName}
        })
        
        return {data:mappedData, tag:'allergies'}
    },
    fetchMetricsLinkages: async (partnerId, peopleType) => {
        let data = [{name:'one', value:1}, {name:'two', value:2}]
        data =  (await instance.get(`${API_URL}/api/1/corporate/partners/${partnerId}/medical/counts/linkages?type=${peopleType}`))?.data?.list
        return {data:data.sort((a,b)=> b.value - a.value), tag:'linkages'}
    },
    fetchMetricsGender: async (partnerId, peopleType) => {
        let data = [{name:'one', value:1}, {name:'two', value:2}]
        data = (await instance.get(`${API_URL}/api/1/corporate/partners/${partnerId}/medical/counts/gender?type=${peopleType}`))?.data?.list
        return {data:data.sort((a,b)=> b.value - a.value), tag:'gender'}
    },
    fetchMetricsAgeBrackets: async (partnerId, peopleType) => {
        let data = [{name:'one', value:1}, {name:'two', value:2}]
        data = (await instance.get(`${API_URL}/api/1/corporate/partners/${partnerId}/medical/counts/age_brackets?type=${peopleType}`))?.data?.list
        return {data:data.sort((a,b)=> b.value - a.value), tag:'age_brackets'}
    },
    fetchMetricsVaccinations: async (partnerId, peopleType) => {
        let data = [{name:'one', value:1}, {name:'two', value:2}]
        data = (await instance.get(`${API_URL}/api/1/corporate/partners/${partnerId}/medical/counts/vaccinations?type=${peopleType}`))?.data?.list
        return {data:data.map(x=>{return {...x, name: x.label}}).sort((a,b)=> b.value - a.value), tag:'vaccinations'}
    },
}
