import React, {useState, useEffect, useCallback} from 'react';
import clsx from 'clsx';
import {makeStyles, withStyles, ThemeProvider} from '@material-ui/core/styles';
import
{
    AppBar, Box, Chip,
    Grid,
    Typography,
    TextField,
    Link,
    Tab,
    Tabs,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    LinearProgress, InputAdornment, IconButton
} from '@material-ui/core';
import {Autocomplete, TabList, TabContext} from "@material-ui/lab";
import corporateHealthService from './../services/corporate-health.service';
import healthMetricsService from './../services/health-metrics.service';
import {useHistory} from "react-router-dom";
import ErrorMessage from "../components/errorMessage";
import SuccessMessage from "../components/successMessage";
import {Search as SearchIcon} from "@material-ui/icons";
import ZennyaTheme from "../providers/ZennyaThemeProvider";
import MuiBadge from '@material-ui/core/Badge';
import HealthRiskMetrics from './../healthRisk/healthRiskMetrics';
import HealthRiskBar from './../healthRisk/healthRiskBar';
import TabPanel from './../components/tabPanel';
import moment from 'moment';
import { GetLoggedInUser } from "../context/UserContext";
import UserList from "./userList";
import DataTable from './../components/DataTable';
import DataGrid from './grid'
import DataGridProvider, { actions,  DataGridContext } from '@zennya/web-component-library/src/components/DataGrid/DataGridContext';

const styles = (theme) => ({
    badge: {
        height: '20px',
        display: 'flex',
        padding: '0 6px',
        zIndex: 1,
        position: 'absolute',
        flexWrap: 'wrap',
        fontSize: '0.75rem',
        minWidth: 20,
        boxSizing: 'border-box',
        transition: 'transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        alignItems: 'center',
        fontWeight: 500,
        lineHeight: 1,
        alignContent: 'center',
        borderRadius: 10,
        flexDirection: 'row',
        justifyContent: 'center'
    },
    lowCount: {
        backgroundColor: '#85CBBE',
        color: 'white',
    },
    moderateCount: {
        backgroundColor: '#EEBE6A',
        color: 'white',
    },
    highCount: {
        backgroundColor: '#8F9EC3',
        color: 'white',
    },
    unknownCount: {
        backgroundColor: '#C4C4C4',
        color: 'white',
    }
})

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4),
        flexGrow: 1
    },
    officeInfoTitle: {
        padding: 10
    },
    officeInfoSection: {
        padding: 10
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    textLeft: {
        textAlign: 'left'
    },
    marginSpacer: {
        marginRight: 10
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    modalContent: {
        backgroundColor: theme.palette.background.paper,
        border: 0,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(3),
        width: '45%',
    },
    linkColor: {
        color: theme.palette.link.main
    },
    badge: {
        height: '20px',
        display: 'flex',
        padding: '0 6px',
        zIndex: 1,
        position: 'absolute',
        flexWrap: 'wrap',
        fontSize: '0.75rem',
        minWidth: 20,
        boxSizing: 'border-box',
        transition: 'transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        alignItems: 'center',
        fontWeight: 500,
        lineHeight: 1,
        alignContent: 'center',
        borderRadius: 10,
        flexDirection: 'row',
        justifyContent: 'center'
    },
    lowCount: {
        backgroundColor: '#C4EDC6',
        color: 'black',
    },
    moderateCount: {
        backgroundColor: '#FFE9C2',
        color: 'black',
    },
    highCount: {
        backgroundColor: '#FFDBD8',
        color: 'black',
    },
    unknownCount: {
        backgroundColor: '#C4C4C4',
        color: 'black',
    },
    meter: { 
        width: '100%',
        backgroundColor: '#ddd'
        // maxWidth: 80,
        // height: 10,  /* Can be anything */
        // position: 'relative',
        // background:' #E9E9E9',
        // '-moz-border-radius': 25,
        // '-webkit-border-radius': 25,
        // borderRadius: 25,
        // padding: 5,
        // boxShadow: 'inset 0 -1px 1px rgba(255,255,255,0.3)'
    },
    gauge: {
        width: '10%',
        height: 30,
        backgroundColor: '#4CAF50',
        textAlign: 'center',
        lineHeight: 30
        // display: 'block',
        // height: '100%',
        // borderTopRightRadius: 8,
        // borderBottomRightRadius: 8,
        // borderTopLeftRadius: 20,
        // borderBottomLeftRadius: 20,
        // boxShadow: 
        //     'inset 0 2px 9px  rgba(255,255,255,0.3), inset 0 -2px 6px rgba(0,0,0,0.4)',
        // position: 'relative',
        // overflow: 'hidden'
    }
}));


const HealthRisk = (props) => {
    const userData = GetLoggedInUser()

    const containerStyles = {
        height: 17,
        width: '80%',
        backgroundColor: "#e0e0de",
        borderRadius: 25,
        margin: 15,
        boxShadow: 'inset 0 -1px 1px rgba(255,255,255,0.3)'
      }
    
      const fillerStyles = {
        height: '100%',
        // width: `${completed}%`,
        // backgroundColor: bgcolor,
        // borderRadius: 50,
        borderTopRightRadius: 8,
        borderBottomRightRadius: 8,
        borderTopLeftRadius: 20,
        borderBottomLeftRadius: 20,
        textAlign: 'center'
      }
    
      const labelStyles = {
        padding: 5,
        color: '#767676',
        fontWeight: 'bold',
        fontSize: '0.9em',
        paddingBottom: 10,
        marginLeft: 15,
        verticalAlign: 'text-bottom'
      }
    

    const classes = useStyles();
    // const [ company, setCompany ] = useState();
    const [ isLoadingEmployees, setEmployeeLoader ] = useState(false)
    const [employees, setEmployees] = useState({
        list: [],
        count: 0
    })
    const [paginationParams, setPaginationParams] = useState({
        page_size: process.env.REACT_APP_DEFAULT_PAGE_SIZE,
        page_offset: 0
    });
    const genders = [
        {id: 0, label: "All", value: "all"},
        {id: 1, label: "Male", value: "male"},
        {id: 2, label: "Female", value: "female"},
    ];
    const ageRanges = [
        {id: 0, label: "All", value: -1},
        {id: 1, label: "<18", value: 0},
        {id: 2, label: "18-35", value: 18},
        {id: 3, label: "36-65", value: 36},
        {id: 4, label: ">65", value: 66}
    ];
    // const ageRanges = [
    //     { id: 0, label: "All Age Ranges", value: { ageStart: -1, ageEnd: -1 }},
    //     { id: 1, label: "<18", value: { ageStart: 0, ageEnd: 17 } },
    //     { id: 2, label: "18-24", value: { ageStart: 18, ageEnd: 24 } },
    //     { id: 3, label: "25-34", value: { ageStart: 25, ageEnd: 34 } },
    //     { id: 4, label: "35-44", value: { ageStart: 35, ageEnd: 44 } },
    //     { id: 5, label: "45-54", value: { ageStart: 45, ageEnd: 54 } },
    //     { id: 6, label: "55-64", value: { ageStart: 55, ageEnd: 64 } },
    //     { id: 7, label: "65-74", value: { ageStart: 65, ageEnd: 74 } },
    //     { id: 8, label: ">75", value: { ageStart: 75, ageEnd: 143 } }
    // ];
    const allLocations = {id: 0, label: "All Locations"}
    const allRoles = {id: 0, label: "All Roles"};
    const [locationSelection, setLocationSelection] = useState(allLocations)
    const [roleSelection, setRoleSelection] = useState(allRoles)
    const [ageRangeSelection, setAgeRangeSelection] = useState(ageRanges[0].value)
    const [genderSelection, setGenderSelection] = useState(genders[0].value)
    const [roles, setRoles] = useState([])
    const [search, setSearch] = useState()
    const [searchTimer, setSearchTimer] = useState(setTimeout(() => {
    }))
    const [searchCounter, setSearchCounter] = useState(0)
    const [isLoadingRoles, setRolesLoader] = useState(false)
    const [ validity, setValidity ] = useState(null)
    const [ expiry, setExpiry ] = useState(null)
    const [ testStatus, setTestStatus ] = useState(null)
    const [ testStatuses, setTestStatuses ] = useState([
        {
            label: 'Tested',
            value: 'TESTED'
        },
        {
            label: 'Re-test',
            value: 'RE_TEST'
        },
        {
            label: 'Untested',
            value: 'UNTESTED'
        }
    ]);

    const [notification, setNotification] = useState({
        message: '',
        show: false,
        success: false,
        error: false
    })
    const [counts, setCounts] = useState([]);
    const [counts2, setCounts2] = useState([]);
    const [counts3, setCounts3] = useState([]);
    const [metrics, setMetrics] = useState([]);
    const [ testSubTypes, setTestSubTypes ] = useState([]);
    const [ testSubType, setTestSubType ] = useState('');

    
    const labels = [ 'Low Risk', 'Moderate Risk', 'High Risk', 'Unknown'];
    const labels2 = [ 'Male', 'Female'];
    const labels3 = [ 'Below 18 yrs old', '18 - 35 yrs old', '36 - 65 yrs old', 'Above 65 yrs old']


    const [params, setParams] = useState({
        risk_factors: "LOW"
    });
    const [paramsHealthRisk, setParamsHealthRisk] = useState({
        risk_factors: "MODERATE"
    });
    const [paramsCovidRisk, setParamsCovidRisk] = useState({
        risk_factors: "HIGH"
    });
    const [page, setPage] = useState(0);

    const [tab, setTab] = useState(0);
    const tabs = [
        {
            label: "LOW",
            value: "HEALTHY"
        },
        {
            label: "MODERATE",
            value: "HEALTH_COMPROMISED"
        },
        {
            label: "HIGH",
            value: "COVID_COMPROMISED"
        },
        {
            label: "UNKNOWN",
            value: "UNKNOWN"
        }
    ];


    const history = useHistory();

    const setShowNotification = (value) => {
        setNotification({
            ...notification,
            show: value
        })
    }

    // function TabPanel(props) {
    //     const {children, value, ...other} = props;

    //     return (
    //         <div
    //             role="tabpanel"
    //             hidden={value !== tab}
    //             id={`simple-tabpanel-${tab}`}
    //             aria-labelledby={`simple-tab-${tab}`}
    //             {...other}
    //         >
    //             {value === tab && (
    //                 <div>{children}</div>
    //             )}
    //         </div>
    //     );
    // }

    function handleLocationSelection(event, value) {
        if (value && (value.id || value.id === 0)) {
            setLocationSelection(value)
        } else {
            setLocationSelection(allLocations)
        }
    }

    function handleRoleSelection(event, value) {
        if (value && (value.id || value.id === 0)) {
            setRoleSelection(value)
        } else {
            setRoleSelection(allRoles)
        }
    }

    function handleChangePage(evt, newPage) {
        setPaginationParams({
            ...paginationParams,
            page_offset: ((newPage + 1) * paginationParams.page_size) - paginationParams.page_size
        });
        setPage(newPage);
    }

    const [columns, setColumns] = useState([]);

    useEffect(() => {
        const newColumns = [{
            id: 'idNumber', 
            colId: 'idNumber',
            key: 'idNumber',
            name: 'Company ID',
            align: 'left',
            options: {
                filter: false,
                sort: false
            }
        },
        {
            id: 'name',
            colId: 'name',
            key: 'name',
            name: 'Name',
            align: 'left',
            options: {
                filter: false,
                sort: false
            },
            cellRenderer({column, row: employee}) {
                return employee.idNumber
                ? (
                    <Link
                        className={classes.linkColor}
                        component="button"
                        variant={"body1"}
                        onClick={() => {
                            history.push(`workers/${employee.id}`)
                        }}
                        color={"inherit"}
                    >
                        {employee.name}
                    </Link>
                )
                : `${employee.name}`
            }
        },
        {
            id: 'gender',
            colId: 'gender',
            key: 'gender',
            name: 'Gender',
            align: 'left',
            filter: {
                type: "option",
                default: "",
                // label: "Is type of",
                options: [
                    {
                        label: "Female",
                        value: "female",
                    },
                    {
                        label: "Male",
                        value: "male",
                    },
                ],
            },
            options: {
                filter: true,
                sort: false,
                filterOptions: {
                    names: ['Female', 'Male'],
                    fullWidth: true
                }
            }
        },
        {
            id: 'age',
            colId: 'age',
            key: 'age',
            name: 'Age',
            align: 'left',
            filter: {
                type: "option",
                default: "",
                label: "Range",
                options: [
                    {
                        label: "<18",
                        value: "<18",
                    },
                    {
                        label: "18-35",
                        value: "18-35",
                    },
                    {
                        label: "36-65",
                        value: "36-65",
                    },
                    {
                        label: ">65",
                        value: ">65",
                    },
                ],
            },
            options: {
                filter: true,
                sort: false,
                filterOptions: {
                    names: ['<18', '18-35', '36-65', '>65'],
                    fullWidth: true
                }
            }
        },
        {
            id: 'status',
            colId: 'status',
            key: 'status',
            name: 'Test Status',
            align: 'left',
            filter: {
                type: "option",
                default: "",
                // label: "Is type of",
                options: testStatuses.map((x, idx)=>{
                    return {label: x.label, value: x.label}
                })
            },
            options: {
                filter: true,
                sort: false,
                filterOptions: {
                    names: testStatuses.map(x => x.label),
                    fullWidth: true
                }
            },
        },
        {
            id: 'types',
            colId: 'types',
            key: 'types',
            name: 'Test Type',
            align: 'left',
            filter: {
                type: "option",
                default: "",
                // label: "Is type of",
                options: testSubTypes.map((x, idx)=>{
                    return {label: x.label, value: x.label}
                })
            },
            options: {
                filter: true,
                sort: false,
                filterOptions: {
                    names: testSubTypes.map(x => x.label),
                    fullWidth: true,
                },
            }
        },
        {
            id: 'validity',
            colId: 'validity',
            key: 'validity',
            name: 'Validity Period',
            align: 'left',
            minWidth: 45,
            filter: {
                type: "option",
                default: "",
                // label: "Is type of",
                options: [
                    {
                        label: "Valid",
                        value: "Valid",
                    },
                    {
                        label: "Invalid",
                        value: "Invalid",
                    },
                ],
            },
            options: {
                filter: false,
                sort: false,
                filterOptions: {
                    names: ['Valid', 'Invalid'],
                    fullWidth: true
                }
            }
        },
        {
            id: 'validity_date',
            colId: 'validity_date',
            key: 'validity_date',
            name: 'Valid Until',
            align: 'left',
            minWidth: 10,
            options: {
                filter: false,
                sort: false
            }
        }]
        setColumns(newColumns)
    }, [testStatuses, testSubTypes])


    const StyledBadge = withStyles(styles)((props) => {
        const {children, classes, count, styling, badgeStyling, label, ...other} = props;
        return (
            <MuiBadge badgeContent={
                <Typography component="span" className={clsx(badgeStyling, styling)}>
                    {count}
                </Typography>
            } showZero>
                <Typography variant="caption" style={{marginTop: 10}}>
                    {label}
                </Typography>
            </MuiBadge>
        )
    })


    function handleSearch() {
        clearTimeout(searchTimer)

        setSearchCounter(counter => counter + 1)
    }

    function numberFormatter(num) {
        return (num * 100).toFixed(2)
    }

    const getPercentages = (counts) => {
        const reducer = (accumulator, currentValue) => accumulator + currentValue;
        const total = counts.reduce(reducer);

        if (total === 0) {
            return [];
        }

        return counts.map(count => numberFormatter(count / total));
    }

    function getEndOfAgeRange(ageRange) {
        if (ageRange > -1) {
            switch (ageRange) {
                case 0:
                    return 17
                case 18:
                    return ageRange + 17
                case 36:
                    return ageRange + 29
                case 66:
                    return ageRange + 68
                default:
                    return ageRange + 9
            }
        }

        return 0
    }

    const getColors = (status) => {
        switch(status) {
            case 'TESTED':
                return {
                    bgColor: '#C4EDC6',
                    color: '#54A557'
                }
            case 'UNTESTED':
                return {
                    bgColor: '#FFDBD8',
                    color: '#E8685E'
                }
            case 'FOR RE-TEST':
                return {
                    bgColor: '#FE974C',
                    color: '#fff'
                }
            default:
                return {
                    bgColor: 'black',
                    color: '#fff'
                }
        }
    }
    useEffect(() => {
        // const filterParams = {
        //     partner: props.company
        // }
        //
        // if (genderSelection) {
        //     filterParams.gender = genderSelection
        // }
        //
        // if (search) {
        //     filterParams.search_text = search
        // }

        // const ageRangeStart = ageRangeSelection
        // let ageRangeEnd = getEndOfAgeRange(ageRangeStart)
        //
        // filterParams.age_range_start = ageRangeStart
        // filterParams.age_range_end = ageRangeEnd

        const promises = [
            healthMetricsService.getEmployeeHealthRiskCount(props.company, { risk_factors: ['LOW'] }),
            healthMetricsService.getEmployeeHealthRiskCount(props.company, { risk_factors: ['MODERATE'] }),
            healthMetricsService.getEmployeeHealthRiskCount(props.company, { risk_factors: ['HIGH'] }),
            healthMetricsService.getEmployeeHealthRiskUnknownCount(props.company, {})
        ];

        healthMetricsService.getTestSubTypes(props.company).then(
            response => {
                setTestSubTypes(response.list);
            }
        )
        

        const p = Promise.all(promises).then(responses => {
            const newList = responses.map((response, index) => {
                return { name: labels[index], value: response.count || 0 }
            });

            console.log(p);

            setCounts(newList);
        })


        const promises2 = [
            healthMetricsService.getEmployeeHealthRiskCount(props.company, { risk_factors: ['HIGH', 'MODERATE', 'LOW', 'UNKNOWN'], gender: 'MALE' }),
            healthMetricsService.getEmployeeHealthRiskCount(props.company, { risk_factors: ['HIGH', 'MODERATE', 'LOW', 'UNKNOWN'], gender: 'FEMALE' }),
        ];

        const p2 = Promise.all(promises2).then(responses => {
            const newList = responses.map((response, index) => {
                return { name: labels2[index], value: response.count || 0 }
            });

            setCounts2(newList);
        })

        //age ranges
        const promises3 = [
            // corporateHealthService.getEmployeeHealthRiskV2({ ...params, age_range_start: 0, age_range_end: 11 }),
            healthMetricsService.getEmployeeHealthRiskCount(props.company, {
                risk_factors: ['HIGH', 'MODERATE', 'LOW', 'UNKNOWN'],
                age_range_start: 0,
                age_range_end: 17
            }),
            healthMetricsService.getEmployeeHealthRiskCount(props.company, {
                risk_factors: ['HIGH', 'MODERATE', 'LOW', 'UNKNOWN'],
                age_range_start: 18,
                age_range_end: 35
            }),
            healthMetricsService.getEmployeeHealthRiskCount(props.company, {
                risk_factors: ['HIGH', 'MODERATE', 'LOW', 'UNKNOWN'],
                age_range_start: 36,
                age_range_end: 65
            }),
            healthMetricsService.getEmployeeHealthRiskCount(props.company, {
                risk_factors: ['HIGH', 'MODERATE', 'LOW', 'UNKNOWN'],
                age_range_start: 65,
                age_range_end: 150
            }),
        ];

        const p3 = Promise.all(promises3).then(responses => {
            const newList = responses.map((response, index) => {
                return { name: labels[index], value: response.count || 0 }
            });

            setCounts3(newList);
        })

        const getRoles = () => {
            return new Promise(function (resolve) {
                setRolesLoader(true);
                corporateHealthService.getCompanyRoles(props.company)
                    .then(
                        (response) => {
                            setRoles(response.list);
                        },
                        (fail) => {
                        }
                    )
                    .finally(() => {
                        setRolesLoader(false);
                        resolve(true);
                    })
            })

        }

        const getCovidTestedMetrics = () => {
            return new Promise(function (resolve) {
                healthMetricsService.getEmployeeCovidTestedCount(props.company)
                    .then(data =>
                    {
                        const metrics = []
                        metrics.covidTested = {
                            tested: data.valid_tests_count,
                            notTested: data.health_workers_count - data.valid_tests_count
                        }
                        setMetrics(metrics)
                    })
                    .finally(() => {
                        resolve(true);
                    })
            })
        }
        Promise.all([p, p2, p3, getCovidTestedMetrics()]);


        // const promises4 = [
        //     p,
        //     p2,
        //     p3,
        //     getRoles(),
        //     getCovidTestedMetrics()
        // ];

        // Promise.all(promises4).then(responses => {
        //     handleFireEffect();
        // });
    }, [props.company]);

    useEffect(() => {
        setEmployeeLoader(true)

        var filterParams = {}


        switch (tab) {
            case 0:
                filterParams.risk_factors = "LOW"
                break
            case 1:
                filterParams.risk_factors = "MODERATE"
                break
            case 2:
            case 3:
            default:
                filterParams.risk_factors = "HIGH"
        }

        // if (roleSelection && roleSelection.id) {
        //     filterParams.role = roleSelection.id
        // }

        filterParams.page_size = paginationParams.page_size
        filterParams.page_offset = paginationParams.page_offset

        if(testSubType) {
            filterParams.test_type = testSubType
        }

        if (genderSelection) {
            filterParams.gender = genderSelection
        }

        if (ageRangeSelection) {
            filterParams.gender = genderSelection
        }

        if(testStatus) {
            filterParams.status = testStatus;
        }

        filterParams.age_range_start = ageRangeSelection
        filterParams.age_range_end = getEndOfAgeRange(ageRangeSelection)

        if (search) {
            filterParams.search_text = search
        }

        let promise = async () => corporateHealthService.getEmployeeHealthRisk(props.company, filterParams)

        if (tab === 3) {
            promise = async () => corporateHealthService.getEmployeeHealthRiskUnknown(props.company, filterParams)
        }
        promise()
            .then(response => {         
                var newList = response.list.map((employee, index) => {
                    var statusColor = getColors(employee.result_status);

                    var dtValidity = employee.result_date_added ? moment(employee.result_date_added).add(14, 'days').format('MM/DD/YYYY') : ''

                    var progress = dtValidity !== '' ? moment(dtValidity).diff(moment(), 'hours') : 0;

                    var progressPercent = ((progress / 336) * 100) >= 10 ? (( progress / 336 ) * 100) : 10;

                    var duration = employee.result_date_added ? moment(dtValidity).toNow(true) : null;

                    var durationText = duration ? duration : '';

                    var progressColor = '';

                    if(progressPercent) {
                        if(progressPercent > 50 && progressPercent <= 100) {
                            progressColor = '#73C2A6'
                        } else if(progressPercent > 10 && progressPercent <= 50) {
                            progressColor = '#FE974C'
                        } else {
                            progressColor = '#E8685E'
                        }
                    }

                    console.log("📢[healthRisk.js:826]:", employee);
                    return {
                        id: employee.employee_id || 0,
                        name: `${employee.last_name + ", " + employee.first_name}`,
                        idNumber: employee.company_id || "-",
                        role: employee.role || "-",
                        gender: employee.gender ? employee.gender : '-',
                        age: employee.birthdate ? employee.age : "-",
                        status: employee.result_status
                            ? (
                                <React.Fragment>
                                    <Chip size="small" style={{ backgroundColor: statusColor.bgColor, color: statusColor.color , border: `1px solid ${statusColor.bgColor}`, borderRadius: 5}} label={
                                        <Typography variant="caption" style={{ fontWeight: 700}}>
                                            { employee.result_status }
                                        </Typography>
                                    } />
                                </React.Fragment>
                            )
                            : '-',
                        types: employee.lab_tests ? employee.lab_tests.join(", ") : "-",
                        validity: <React.Fragment>
                            <span>
                                { progress > 0 ? 
                                        <div style={containerStyles}>
                                            <div style={{ ...fillerStyles, width: `${progressPercent}%`, backgroundColor: progressColor, textAlign: 'center' }}>
                                            <span style={{ ...labelStyles, 'white-space': 'nowrap'}}>
                                                <span>
                                                { durationText}
                                                </span>
                                            </span>
                                            </div>
                                        </div>
                                    : (employee.result_date_added ? `Expired ${moment(dtValidity).fromNow()}` : '-')
                                }
                            </span>
                        </React.Fragment>,
                        validity_date: employee.result_status && employee.result_status !== 'FOR RE-TEST' && dtValidity && moment(dtValidity).isAfter(moment()) ? dtValidity : '-'

                    }
                });

                setEmployees({
                    list: newList,
                    count: response.count || 0
                });

                // set new counts

                const promises = [
                    healthMetricsService.getEmployeeHealthRiskCount(props.company, { ...filterParams, risk_factors: ['LOW'] }),
                    healthMetricsService.getEmployeeHealthRiskCount(props.company, { ...filterParams, risk_factors: ['MODERATE'] }),
                    healthMetricsService.getEmployeeHealthRiskCount(props.company, { ...filterParams, risk_factors: ['HIGH'] }),
                    healthMetricsService.getEmployeeHealthRiskUnknownCount(props.company, { ...filterParams, risk_factors: []})
                ];
        
                const p = Promise.all(promises).then(responses => {
                    const newList = responses.map((response, index) => {
                        return { name: labels[index], value: response.count || 0 }
                    });
        
                    // console.log('counts');
                    // console.log(counts);
        
                    setCounts(newList);
                    // setCounts([
                    //     { name: 'Low Risk', newList[0]},
                    //     { name: }
                    // ])
                    // setCounts([20, 15, 30, 10]);
                });


                //set new counts for the male/female workers

                const promises2 = [
                    healthMetricsService.getEmployeeHealthRiskCount(props.company, { ...filterParams, risk_factors: ['HIGH', 'MODERATE', 'LOW', 'UNKNOWN'], gender: 'MALE' }),
                    healthMetricsService.getEmployeeHealthRiskCount(props.company, { ...filterParams, risk_factors: ['HIGH', 'MODERATE', 'LOW', 'UNKNOWN'], gender: 'FEMALE' }),
                ];
        

                const p2 = Promise.all(promises2).then(responses => {
                    const newList = responses.map((response, index) => {
                        return { name: labels2[index], value: response.count || 0 }
                    });
        
                    setCounts2(newList);
                })

                //set new counts for the age range charts
                const promises3 = [
                    // corporateHealthService.getEmployeeHealthRiskV2({ ...params, age_range_start: 0, age_range_end: 11 }),
                    healthMetricsService.getEmployeeHealthRiskCount(props.company, {
                        ...filterParams,
                        risk_factors: ['HIGH', 'MODERATE', 'LOW', 'UNKNOWN'],
                        age_range_start: 0,
                        age_range_end: 17
                    }),
                    healthMetricsService.getEmployeeHealthRiskCount(props.company, {
                        ...filterParams,
                        risk_factors: ['HIGH', 'MODERATE', 'LOW', 'UNKNOWN'],
                        age_range_start: 18,
                        age_range_end: 35
                    }),
                    healthMetricsService.getEmployeeHealthRiskCount(props.company, {
                        ...filterParams,
                        risk_factors: ['HIGH', 'MODERATE', 'LOW', 'UNKNOWN'],
                        age_range_start: 36,
                        age_range_end: 65
                    }),
                    healthMetricsService.getEmployeeHealthRiskCount(props.company, {
                        ...filterParams,
                        risk_factors: ['HIGH', 'MODERATE', 'LOW', 'UNKNOWN'],
                        age_range_start: 65,
                        age_range_end: 150
                    }),
                ];

                const p3 = Promise.all(promises3).then(responses => {
                    const newList = responses.map((response, index) => {
                        return { name: labels3[index], value: response.count || 0 }
                    });

                    setCounts3(newList);
                    // setCounts3([9, 20, 43, 30]);
                })
        
            })
            .catch((e)=> {
                console.log("📢[healthRisk.js:951]:", e);
            })
            .finally(() => {
                setEmployeeLoader(false)
            })
    }, [props.company, tab, paginationParams, roleSelection, genderSelection, ageRangeSelection, search, testSubType, testStatus])

    const labels4 = [
        {
            label: <StyledBadge label={`Low`} styling={classes.lowCount} badgeStyling={classes.badge} count={counts.length > 0 ? counts[0].value : 0}/>
        },
        {
            label: <StyledBadge label={`Moderate`} styling={classes.moderateCount} badgeStyling={classes.badge}
                                count={counts.length > 1 ? counts[1].value : 0}/>
        },
        {
            label: <StyledBadge label={`High`} styling={classes.highCount} badgeStyling={classes.badge}
                count={counts.length > 1 ? counts[2].value : 0}/>
        },
        {
            label: <StyledBadge label={`Unknown`} styling={classes.unknownCount} badgeStyling={classes.badge}
            count={counts.length > 1 ? counts[3].value : 0}/>
        }
    ];

    const statuses = [
        "HEALTHY",
        "HEALTH_COMPROMISED",
        "COVID_COMPROMISED",
        "UNKNOWN"
    ]

    const [comorbidities, setComorbidities] = useState([])

    const handleFilterChange = (filters) => {
        if(filters[2].length === 1) {
            var gender = genders.find(x => x.label === filters[2][0]);
            if(gender) {
                setGenderSelection(gender.value);
            }
        } else {
            setGenderSelection('all');
        }

        if(filters[3].length === 1) {
            var ageRange = ageRanges.find(x => x.label === filters[3][0]);
            if(ageRange) {
                setAgeRangeSelection(ageRange.value);
            }
        } else {
            setAgeRangeSelection(-1);
        }

        if(filters[4].length === 1) {
            var s = testStatuses.find(x => x.label === filters[4][0]);
            if(s) {
                setTestStatus(s.value);
            }
        } else {
            setTestStatus(null);
        }

        if(filters[5].length === 1) {
            var subtype = testSubTypes.find(x => x.label === filters[5][0]);
            if(subtype) {
                setTestSubType(subtype.name);
            }
        } else {
            setTestSubType('all');
        }
    }
    const onFilterChange = (filters) => {
        console.log("📢[healthRisk.js:1090]:", filters);
        setGenderSelection(filters?.gender??'all')
        
        const ageSelect = filters?.age
        const ageRange = ageRanges.find(x=>x.label === ageSelect)
        setAgeRangeSelection(ageRange?.value??-1)

        const statusSelect = filters?.status
        const statusRange = testStatuses.find(x=>x.label === statusSelect)
        setTestStatus(statusRange?.value??null)

        const typeSelect = filters?.types
        const subtype = testSubTypes.find(x=>x.label === typeSelect)
        console.log("📢[healthRisk.js:1036]:", subtype?.name??'all');
        setTestSubType(subtype?.name??'all')

    }

    
    useEffect(() => {
        const params = {}

        if (!userData.user.isAdmin) {
            const user = userData.user

            if (user.corporate && user.corporate.strategic_business_unit) {
                params.sbu = user.corporate.strategic_business_unit.id
            }
        }

        healthMetricsService.getComorbidityCounts(props.company, params)
            .then(data => {
                let tempComorbidities = []
                let colorMappingIndex = 0
                const colors = [
                    '#EA857D',
                    '#EEBE6A',
                    '#85CBBE',
                    '#F59D6B',
                    '#929EBC',
                    '#D49F41',
                    '#7380A7',
                    '#74BACD',
                    '#FE974C',
                    '#D1F9F7'
                ]

                data.list.forEach(item => {
                    item.counts.forEach(comorbidity => {
                        if (comorbidity.count === 0) {
                            return
                        }

                        const index = tempComorbidities.findIndex(temp => temp.name === comorbidity.consideration_name)

                        if (index !== -1) {
                            tempComorbidities[index].count = tempComorbidities[index].count + comorbidity.count
                        } else {
                            let color = '#'+(Math.random() * 0xFFFFFF << 0).toString(16).padStart(6, '0')

                            if (colorMappingIndex < (colors.length - 1)) {
                                color = colors[colorMappingIndex]
                                colorMappingIndex++
                            }

                            tempComorbidities.push({
                                name: comorbidity.consideration_name,
                                label: comorbidity.consideration_label,
                                count: comorbidity.count,
                                color
                            })
                        }
                    })
                })

                setComorbidities(tempComorbidities)
            })
    }, [props.company])

    const handleAfterSuccess = event => {
        setPaginationParams({
            ...paginationParams,
            page_offset: 0
        });
        setGenderSelection('all');
        setAgeRangeSelection(-1);
    }

    useEffect(()=> {
        //formatcols
    }, [columns])

    return (
        <ThemeProvider theme={ZennyaTheme}>
            <div className={classes.root}>
                {notification.error &&
                    <ErrorMessage open={notification.show} setOpen={setShowNotification} error={notification.message}/>}
                {notification.success &&
                    <SuccessMessage open={notification.show} setOpen={setShowNotification} message={notification.message}/>}
                <Grid container>
                    <Grid item xs={5}>
                        <Typography variant="h5" className={classes.textLeft} style={{fontWeight: 'bold'}}>
                            Worker Health Risk
                        </Typography>
                    </Grid>
                </Grid>
                <br/>
                <Grid container spacing={2} >
                    {
                        (counts.length >= 1 && getPercentages(counts).length >= 1) && (
                            <Grid item xs={12} sm={6} md={4}>
                                <HealthRiskMetrics
                                    title={'Health Risk Assessment'}
                                    description={`Percentage of workers' health risk according to assessment`}
                                    backgroundColors= {['#85CBBE', '#EEBE6A', '#E8685E', '#E9E9E9']}
                                    labels={
                                        [
                                            'Low Risk: ' + counts[0],
                                            'Moderate Risk: ' + counts[1],
                                            'High Risk: ' + counts[2],
                                            'Unknown: ' + counts[3]
                                        ]
                                    }
                                    data={counts}
                                />
                            </Grid>
                        )
                    }
                    {

                        metrics.covidTested && (
                                <Grid item xs={12} sm={6} md={4}>
                                    <HealthRiskMetrics
                                        title={'Covid-19 Tested Workers'}
                                        data={[
                                            { name: 'Tested', value: metrics.covidTested.tested},
                                            { name: 'Not Tested', value: metrics.covidTested.notTested }
                                        ]}
                                        backgroundColors={['#8F9EC3', '#EEBE6A']}
                                    />
                                </Grid>
                            )
                    }
                    {
                        (counts2.length >= 1 && getPercentages(counts2).length >= 1) && (
                            <Grid item xs={12} sm={6} md={4}>
                                <HealthRiskMetrics
                                    title={'Workers Gender'}
                                    description={`Percentage of female and male workers`}
                                    labels={
                                        [
                                            'Male: ' +counts2[0],
                                            'Female: ' +counts2[1],
                                        ]
                                    }
                                    backgroundColors={['#87AFD9', '#F38181']}
                                    data={counts2}
                                    // datasets={
                                    //     [
                                    //         {
                                    //             backgroundColor: ['#87AFD9', '#F38181'],
                                    //             data: counts2
                                    //         }
                                    //     ]
                                    // }
                                />
                            </Grid>
                        )
                    }
                    {
                        (counts3.length >= 1 && getPercentages(counts3).length >= 1) && (
                            <Grid item xs={12} sm={6} md={4}>
                                <HealthRiskMetrics
                                    title={'Workers Age Range'}
                                    description={`Percentage of workers by age range`}
                                    labels={
                                        [
                                            // 'Under 12 years old',
                                            '[< 18]: ' + counts3[0],
                                            '[18 - 35]: ' + counts3[1],
                                            '[36 - 65]: ' + counts3[2],
                                            '[65 <]: ' + counts3[3]
                                        ]
                                    }
                                    backgroundColors={[
                                        // '#ffbcb5',
                                        '#EEBE6A',
                                        '#F59D6B', '#929EBC', '#5FBBD3']}
                                    data={counts3}
                                    // datasets={
                                    //     [
                                    //         {
                                    //             backgroundColor: [
                                    //                 // '#ffbcb5',
                                    //                 '#EEBE6A',
                                    //                 '#F59D6B', '#929EBC', '#5FBBD3'],
                                    //             data: counts3
                                    //         }
                                    //     ]
                                    // }
                                />
                            </Grid>
                        )
                        }
                                            {
                            genderSelection === 'all' && ageRangeSelection === -1 && (
                                <Grid item xs={12} md={8}>
                                    <HealthRiskBar
                                        title={'Number of Workers with Health Conditions'}
                                        // description={'Percentage of workers with health conditions'}
                                        // labels={comorbidities.map(comorbidity => comorbidity.label + ": " + comorbidity.count)}
                                        // counts={comorbidities.map(comorbidity => comorbidity.count)}
                                        legendAlignment={{ align: 'right', verticalAlign: 'bottom'}}
                                        data={comorbidities.map((comorbidity) =>  {
                                            return {
                                                name: comorbidity.label,
                                                count: comorbidity.count
                                            }
                                        } )}
                                        backgroundColors={comorbidities.length > 0 ? comorbidities.map(comorbidity => comorbidity.color) : ['#767676', '#DED9D9']}
                                    />
                                </Grid>
                            )
                        }
                    </Grid>
                    <br/>
                <Grid container direction="row" >
                    <Grid item xs={12}>
                        <TextField
                            label="Search Name" variant="outlined"
                            fullWidth
                            onBlur={(ev) => {
                                setSearch(ev.target.value);
                                handleSearch();
                            }}
                            // TODO: on change a different way to handle debounce
                            // onChange={(event) =>
                            // {
                            //     setSearch(event.target.value)

                            //     if (searchTimer)
                            //     {
                            //         clearTimeout(searchTimer)
                            //     }

                            //     setSearchTimer(
                            //         setTimeout(() =>
                            //         {
                            //             handleSearch()
                            //         }, 1000)
                            //     )
                            // }}
                            onKeyPress={(ev) => {
                                if (ev.key === "Enter") {
                                    setSearch(ev.target.value)
                                    handleSearch()
                                }
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <SearchIcon/>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                
                    <Grid item xs={12}>
                        <TabContext value={tab}>
                            <AppBar position="static" elevation={0}>
                                <TabList 
                                    aria-label="Risk Tabs"
                                    style={{ backgroundColor: 'white'}}
                                    onChange={(e, t)=>setTab(t)}
                                >
                                    <Tab label={labels4[0].label} />
                                    <Tab label={labels4[1].label} />
                                    <Tab label={labels4[2].label} />
                                    <Tab label={labels4[3].label} />
                                </TabList>
                            </AppBar>
                        </TabContext>
                        <Grid container>
                            <Grid item xs={12}>
                                { isLoadingEmployees && <LinearProgress color="primary" /> }
                                <DataGrid
                                    columns={columns}
                                    company={props.company} 
                                    listCall={handleAfterSuccess}
                                    onChangePage={handleChangePage}
                                    handleFilterChange={onFilterChange}
                                    handleSort={()=>{}}
                                    onChangeRowsPerPage={()=>{}}
                                    rows={employees.list || [] }
                                    params={paginationParams}
                                    page={page}
                                    count={employees.count}
                                />
                            </Grid>
                        </Grid>
                        
                        {/* {
                            statuses.map((status, index) => (
                                <TabPanel key={index} index={index} value={status}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            { isLoadingEmployees && <LinearProgress color="primary" /> }
                                            <UserList
                                                loaders={isLoadingEmployees}
                                                columns={columns}
                                                rows={employees.list || []}
                                                count={100}
                                                handlePage={handleChangePage} />
                                        </Grid>
                                    </Grid>
                                </TabPanel>
                            ))
                        } */}
                    </Grid>
                </Grid>
            </div>
        </ThemeProvider>
    );
}

export default HealthRisk
