import { Grid, makeStyles, TextField, Typography } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { debounce } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { searchAddress } from "../../services/address/fetchAddressComponents";

export const useStyles = makeStyles((theme) => ({
  default:{
    [theme.breakpoints.down("xs")]: {
      backgroundColor: theme.palette.common.white,
    }
  },
}))

const AutocompleteAddress = ({ onSelectAddress = () => {} }) => {
  const classes = useStyles()

  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [selectedLocation, setSelectedLocation] = useState();
  const loading = open && options.length === 0;

  async function fetchAddress(search) {
    let active = true;
    if (search.length < 3) {
      setOptions(value ? [value] : []);
      return undefined;
    }

    const results = await searchAddress(search);

    if (active) {
      let newOptions = [];
      if (value) {
        newOptions = [value];
      }
      if (results) {
        newOptions = [...newOptions, ...results];
      }
      setOptions(newOptions);
    }

    return () => {
      active = false;
    };
  }

  useEffect(() => {
    if (inputValue !== "") {
      setSearch(inputValue);
    }
  }, [inputValue]);

  const setSearch = useCallback(
    debounce((search) => {
      fetchAddress(search);
    }, 500),
    []
  );

  return (
    <Autocomplete
    className={classes.default}
      onChange={(evt, value, reason) => {
        onSelectAddress(value)
      }}
      onInputChange={(evt, newInputValue) => {
        setInputValue(newInputValue);
      }}
      options={options}
      style={{ verticalAlign: "bottom" }}
      renderInput={(iParams) => (
        <TextField
          {...iParams}
          InputLabelProps={{
            shrink: true,
          }}
          label={"Address Helper"}
          placeholder="search for barangay name to fill the fields below"
          variant={"outlined"}
          fullWidth
        />
      )}
      getOptionLabel={(option) => option.label}
      renderOption={(option) => {
        return (
          <Grid container alignItems="center">
            <Grid item xs>
              <Typography variant="body1" color="textPrimary">
                {option.label}
              </Typography>
              <Typography variant="body1" color="textSecondary">
                {option?.province} {option?.region}
              </Typography>
            </Grid>
          </Grid>
        );
      }}
    />
  );
};

export { AutocompleteAddress };
