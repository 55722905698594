import axios from 'axios';
import moment from 'moment';
import { isEmpty } from 'voca';
import { PEOPLE_TYPE } from '../employees/employeeList';
import instance from './helpers/instance';
import v from 'voca'
const corporateHealthService = {
    getTemperatureMetrics,
    getCovidMetrics,
    listQuarantinedEmployees,
    getHealthPass,
    getPartnerDetails,
    getPartnerEmployees,
    getPartnerEmployeesAndReferences,
    getPeopleListCSV,
    listGates,
    assignAsCheckerOrRep,
    updatePartnerCompany,
    getTrafficHistory,
    listIndustries,
    addEmployee,
    getLocation,
    getPartnerLocations,
    getPartnerDepartments,
    getEmployeesByRoleType,
    getEmployeesV2,
    getEmployeeHealthRisk,
    getEmployeeHealthRiskV2,
    getEmployeeHealthRiskUnknown,
    getLinkingRequests,
    getNoMatchLinkingRequests,
    updateLinkingRequest,
    getEmployeeDetails,
    getEmployeeLogs,
    importEmployeeReferences,
    listLocationTypes,
    importHealthCheckers,
    listFloors,
    addLocation,
    updateLocation,
    removeFromQuarantine,
    importLocations,
    clearFromQuarantine,
    unassignHealthChecker,
    listLocationCounts,
    listEligibleEmployeesAsHealthCheckers,
    moveQuarantineCategory,
    getRejectionReasons,
    getCompletedQuarantineEmployees,
    getCompanyRoles,
    getCompanyLogos,
    getCompanyMappings,
    getAllergies,
    getComorbidities,
    getClientHealthPass,
    getDependents,
    getThirdPartyEmployees,
    importDependentReferences,
    importThirdPartyReferences,
    getRelationOptions,
    getEmployeeTypes,
    getVaccines    
};


const API_URL = process.env.REACT_APP_WEB_ADMIN_URL;

async function getRelationOptions() {
    return await instance.get(`${API_URL}/api/1/corporate/employees/relationships/types`);
}

async function getEmployeeTypes() {
    return await instance.get(`${API_URL}/api/1/corporate/employees/employees/types`);
}

function getTemperatureMetrics(companyId) {
    return instance.get(`${API_URL}/api/1/corporate/${companyId}/dashboard/metrics/temperature`, { cache: false}).then(
        response => {
            return response.data;
        }
    );
}

function getCovidMetrics(companyId) {
    return instance.get(`${API_URL}/api/1/corporate/${companyId}/dashboard/metrics/covid`, { cache: false}).then(
        response => {
            return response.data;
        }
    );
}

function listQuarantinedEmployees(companyId, params) {
    return instance.get(`${API_URL}/api/1/corporate/${companyId}/quarantine`, { params: params, cache: false}).then(
        response => {
             return response.data;
        }
    )
}

function moveQuarantineCategory(companyId, params) {
    return instance.put(`${API_URL}/api/1/corporate/${companyId}/quarantine/moveCategory`, params, {cache: false}).then(
        response => {
            return response.data;
        }
    )
}

function getPartnerDetails(partnerId) {
    return instance.get(`${API_URL}/api/1/corporate/partners/${partnerId}/detailed`, { cache: false}).then(
        response => {
            return response.data
        }
    );
}

function getPartnerEmployees(partnerId, params) {
    return instance.get(`${API_URL}/api/1/corporate/partners/${partnerId}/employees`, { params: params, cache: false}).then(
        response => {
            return response.data;
        }
    )
}
async function getPeopleListCSV(partnerId, peopleType, params) {
    let reference_types = ''
    switch (peopleType) {
        case PEOPLE_TYPE.WORKER:
            reference_types = 'EMPLOYEE'
            break
        case PEOPLE_TYPE.DEPENDENT:
            reference_types = 'DEPENDENT'
            break
        case PEOPLE_TYPE.THIRDPARTY:
            reference_types = 'EXTERNAL'
            break
        default:
            break;
    }
    let hasMore = true
    let currentPage = 0
    const page_size = 100;
    let data = []
    let hasError = false

    
    while (hasMore) {
        try {
            const response = await instance.get(`${API_URL}/api/2/corporate/partners/${partnerId}/employees`, 
                { params: {...params, page_offset: currentPage, page_size, reference_types}, cache: false}
            )
            data = [...data, ...response?.data?.list??[]]
            currentPage+=page_size
            if ((data.length >= response?.data?.count) || isEmpty(response?.data?.list)) {
                hasMore = false
            }
        } catch (error) {
            hasMore = false
            hasError = true
            return `Problem downloading csv : ${error?.message}`
        }
    }

    

    let addtlColHeader = []
    switch (peopleType) {
        case PEOPLE_TYPE.DEPENDENT:
            addtlColHeader = ['Primary Employee ID', 'Primary Employee Name']
            break
        case PEOPLE_TYPE.THIRDPARTY:
            addtlColHeader = ['Third Party Employer']
            break
        case PEOPLE_TYPE.WORKER:
        default:
            break;
    }

    const rowHeader = [
        'Employee ID',
        'First Name',
        'Middle Name',
        'Last Name',
        'Email',
        'Mobile Number',
        ...addtlColHeader,
        'Vaccine First Dose',
        'Vaccine Second Dose',
        'Vaccine Brand',
        'Vaccine Status',
        'Linking Status',
        'Address',
        'Gender',
        'Civil Status',
        'Allergies',
        'Comorbidities'
    ].map(i=>`"${i}"`).join(',')

    const rows = data.map(item => {
        let addtlData = []
        switch (peopleType) {
            case PEOPLE_TYPE.DEPENDENT:
                addtlData = [item?.primary_employee_id, `${item?.primary_employee?.lastName} ${item?.primary_employee?.firstName}`]
                break
            case PEOPLE_TYPE.THIRDPARTY:
                addtlData = [item?.third_party_employer]
                break
            case PEOPLE_TYPE.WORKER:
            default:
                break;
        }
        const row = [
            item.employee_id,
            item.first_name,
            item.middle_name,
            item.last_name,
            item.email,
            (item.mobile_number??'').toString(),
            ...addtlData,
            item?.vaccine?.first_dose_date&&moment.utc(item?.vaccine?.first_dose_date).format('MM-DD-YYYY'),
            item?.vaccine?.second_dose_date&&moment.utc(item?.vaccine?.second_dose_date).format('MM-DD-YYYY'),
            item?.vaccine?.brand,
            item?.vaccine?.first_dose_date!==null && item?.vaccine?.second_dose_date !== null ? "VACCINATED" :  item?.vaccine?.first_dose_date===null && item.vaccine?.second_dose_date === null? "NOT VACCINATED": "FIRST DOSE",
            item.status,
            item.location,
            v.capitalize(item?.gender, true),
            item.civil_status,
            item.allergies,
            item.comorbidities,
        ]
        return row.map(i=>`"${i??''}"`).join(',')
    }).join('\n')

    const csvData = `${rowHeader}\n${rows}`

    var downloadFile = `${v.snakeCase(peopleType)}-List-Report-${moment().format('MM-DD-YYYY-HH:mm:ss')}.csv`;
    var file = new Blob([...csvData], {
        type: 'application/octet-stream',
    });
    var saving = document.createElement('a');
    saving.href = URL.createObjectURL(file);
    saving.download = (
        downloadFile
    );
    saving.click();
    
}

function getPartnerEmployeesAndReferences(partnerId, params) {
    return instance.get(`${API_URL}/api/2/corporate/partners/${partnerId}/employees`, { params: {...params, internal: null, reference_types: 'EMPLOYEE'}}).then(
        response => {
            return response.data;
        }
    )
}

function getDependents(partnerId, params) {
    return instance.get(`${API_URL}/api/2/corporate/partners/${partnerId}/employees`, { params: {...params, reference_types: 'DEPENDENT'}}).then(
        response => {
            return response.data;
        }
    )
}

function getThirdPartyEmployees(partnerId, params) {
    return instance.get(`${API_URL}/api/2/corporate/partners/${partnerId}/employees`, { params: {...params, internal: null, reference_types: 'EXTERNAL'}}).then(
        response => {
            return response.data;
        }
    )
}



function getPartnerLocations(partnerId, params) {
    return instance.get(`${API_URL}/api/1/corporate/partners/${partnerId}/locations`, { params: params, cache: false}).then(
        response => {
            return response.data;
        }
    )
}

function getPartnerDepartments(partnerId) {
    return instance.get(`${API_URL}/api/1/corporate/partners/${partnerId}/departments`).then(
        response => {
            return response.data;
        }
    )
}

function listLocationTypes() {
    return instance.get(`${API_URL}/api/1/corporate/location/types`).then(response => {
        return response.data
    });
}

function listGates(partnerId, locationId) {
    return instance.get(`${API_URL}/api/1/corporate/partners/${partnerId}/locations/${locationId}/gates`).then(
        response => {
            return response.data;
        }
    )
}


function listFloors(partnerId, locationId) {
    return instance.get(`${API_URL}/api/1/corporate/partners/${partnerId}/locations/${locationId}/floors`).then(
        response => {
            return response.data;
        }
    )
}

function updatePartnerCompany(p, partnerId) {
    return instance.put(`${API_URL}/api/1/corporate/partners/${partnerId}/update`, p, { cache: false}).then(
        response => {
            return response.data;
        }
    )
}

function assignAsCheckerOrRep(p) {
    return instance.post(`${API_URL}/api/1/corporate/partners/${p.partner}/employee/locations/${p.location}/setRepsChecker`, p, { cache: false}).then(
        response => {
            return response.data;
        }
    )
}

function getTrafficHistory(locationId, p) {
    p.temp_logic_operator = '>=';
    p.risk_level = true;
    p.recorded_temperature = 38;

    return instance.get(`${API_URL}/api/1/corporate/traffic/location/${locationId}/getTraffic`, { params: p, cache: false}).then(
        response => {
            return response.data;
        }
    )
}

function getEmployeesByRoleType(p) {
    return instance.get(`${API_URL}/api/1/corporate/partners/${p.partner}/employeesV2`, {params: p, cache: false}).then(response => {
        return response.data;
    })
}

function getCompanyRoles(p) {
    return instance.get(`${API_URL}/api/1/corporate/partners/${p}/roles`, {cache: false}).then(response => {
        return response.data;
    })
}

function getEmployeesV2(usp) {
    return instance.get(`${API_URL}/api/1/corporate/partners/${usp.get("partner")}/employeesV2?`+usp.toString(), {cache: false }).then(response => {
        return response.data;
    })
}

function getEmployeeHealthRiskV2(params) {
    return instance.get(`${API_URL}/api/1/corporate/partners/${params.partner}/employees/risks/levels`, {params: params, cache: false}).then(
        response => {
            return response.data
        }
    );
}

function getEmployeeHealthRisk(partnerId, params) {
    return instance.get(`${API_URL}/api/1/corporate/partners/${partnerId}/employees/risks/levels`, { params, cache: false}).then(
        response => {
            return response.data
    })
}

function getEmployeeHealthRiskUnknown(partnerId, params) {
    return instance.get(`${API_URL}/api/1/corporate/partners/${partnerId}/employees/risks/unknown`, { params, cache: false}).then(
        response => {
            return response.data
        }
    );
}

function listIndustries() {
    return instance.get(`${API_URL}/api/1/corporate/industries`, { cache: false}).then(
        response => {
            return response.data;
        }
    )
}

function getLocation(locationId, params) {
    return instance.get(`${API_URL}/api/1/corporate/partners/locations/${locationId}`, { params: params, cache: false}).then(
        response => {
            return response.data;
        }
    )
}

function removeFromQuarantine(partnerId, p) {
    return instance.delete(`${API_URL}/api/1/corporate/${partnerId}/quarantine`, { params: p}).then( response => {
        return response.data;
    })
}

function clearFromQuarantine(partnerId, p) {
    return instance.post(`${API_URL}/api/1/corporate/${partnerId}/quarantine/clearance/upload`, p).then( response => {
        return response.data;
    })
}

function listEligibleEmployeesAsHealthCheckers(partnerId, p) {
    return instance.get(`${API_URL}/api/1/corporate/partners/${partnerId}/checkers/eligible`, { params: p}).then(response => {
        return response.data;
    })
}

function getRejectionReasons(params) {
    return instance.get(`${API_URL}/api/1/corporate/quarantine/clearance/rejectReasons`, { params: params, cache: false}).then(response => {
        return response.data;
    })
}

function getHealthPass(hashId, timeout) {
    let p = {
        hash_id: hashId
    };

    return axios.create({
        baseUrl: API_URL,
        headers: {
            // "Access-Control-Allow-Origin": "*",
            // "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, PATCH, OPTIONS",
            // "Access-Control-Allow-Credentials": "true",
            // "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers"
        }
    }).get(`${API_URL}/api/1/corporate/passes`, { params: p, cache: false, timeout: timeout || null }).then( response => {
        return response.data
    })
}

async function getClientHealthPass(clientId) {
    return (await instance.get(`${API_URL}/api/1/clients/${clientId}/corporate/passes/settings`))?.data
}


function addEmployee(partnerId, params) {
    return instance.post(`${API_URL}/api/1/corporate/partners/${partnerId}/employees/references`, params).then(
        response => {
            return response.data;
        }
    )
}

function addLocation(companyId, params) {
    return instance.post(`${API_URL}/api/1/corporate/partners/${companyId}/locations`, params).then(
        response => {
            return response.data;
        }
    )
}

function unassignHealthChecker(companyId, params) {
    return instance.put(`${API_URL}/api/1/corporate/partners/${companyId}/employee/${params.employee_id}/unassignAsChecker`, params).then(
        response => {
            return response.data
        }
    )
}

function listLocationCounts(companyId, params) {
    return instance.get(`${API_URL}/api/1/corporate/partners/${companyId}/locations/counts`, { params: params}).then(
        response => {
            return response.data;
        }
    )
}


function importLocations(companyId, params) {
    return instance.post(`${API_URL}/api/1/corporate/locations/import`, params,
    {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    }).then(
        response => {
            return response.data;
        }
    )
}

function importHealthCheckers(companyId, params) {
    return instance.post(`${API_URL}/api/1/corporate/checkers/import`, params,
     {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    }).then(
        response => {
            return response.data;
        }
    )
}

function updateLocation(params) {
    return instance.put(`${API_URL}/api/1/corporate/partners/${params.pid}/locations/${params.id}/update`, params).then(
        response => {
            return response.data;
        }
    )
}

function importEmployeeReferences(partnerId, params) {
    return instance.post(`${API_URL}/api/1/corporate/partners/${partnerId}/employees/csv?type=EMPLOYEE`, params, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    }).then(
        response => {
            return response.data;
        }
    )
}

function importDependentReferences(partnerId, params) {
    return instance.post(`${API_URL}/api/1/corporate/partners/${partnerId}/employees/csv?type=DEPENDENT`, params, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    }).then(
        response => {
            return response.data;
        }
    )
}

function importThirdPartyReferences(partnerId, params) {
    return instance.post(`${API_URL}/api/1/corporate/partners/${partnerId}/employees/csv?type=EXTERNAL`, params, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    }).then(
        response => {
            return response.data;
        }
    )
}

function getEmployeeDetails(id) {
    return instance.get(`${API_URL}/api/1/corporate/employees/${id}`).then(
        response => {
            return response.data
        }
    );
}

function getEmployeeLogs(partnerId, params) {
    return instance.get(`${API_URL}/api/1/corporate/${partnerId}/logs/employees`, { params }).then(
        response => {
            return response.data
        }
    );
}

function getLinkingRequests(params) {
    return instance.get(`${API_URL}/api/1/corporate/partners/link-requests`, { params, cache: false })
        .then(response => response.data)
}

function getNoMatchLinkingRequests(params) {
    return instance.get(`${API_URL}/api/1/corporate/partners/link-requests/noMatch`, { params, cache: false })
        .then(response => response.data)
}

function updateLinkingRequest(requestId, params) {
    return instance.put(`${API_URL}/api/1/corporate/partners/link-requests/${requestId}`, params)
}

function reassignHealthChecker(partnerId, checkerId) {

}

function getCompletedQuarantineEmployees(companyId, p) {
    return instance.get(`${API_URL}/api/1/corporate/${companyId}/quarantine/completedDuration`, { params: p, cache: false}).then(
        response => response.data
    )
}

function getCompanyLogos(companyId) {
    return instance.get(`${API_URL}/api/1/corporate/partners/${companyId}/corporateMedia`, { cache: false}).then(
        response => response.data
    )
}

function getCompanyMappings(companyId) {
    return instance.get(`${API_URL}/api/1/corporate/partners/${companyId}/mappings`, { cache: false}).then(response => {
        return response.data;
    })
}

async function getAllergies() {
    return await instance.get(`${API_URL}/api/1/corporate/medical/allergies`)
}

async function getComorbidities() {
    return await instance.get(`${API_URL}/api/1/corporate/medical/comorbidities`)
}

async function getVaccines() {
	return await instance.get(`${API_URL}/api/1/corporate/medical/vaccines`);
}


export default corporateHealthService;
