import { Box, Grid, Typography, withStyles } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { JsonParam } from 'serialize-query-params';
import { useQueryParam } from "use-query-params";
import corporateEventsService from '../../services/corporate-events.service';

const styles = (theme) => ({
  cancel: {
    color: theme.palette.error.main,
  },
  cohortContainer: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
});

function LocationInfoBox({ classes }) {
  const [location, setlocation] = useState();
  const [locationId, setLocationId]= useQueryParam("location", JsonParam);

  useEffect(() => {
    async function fetchLocationDetails() {
      const result = await corporateEventsService.getEventLocationDetails(locationId)
      setlocation(result)
    }
    fetchLocationDetails()
  }, [locationId]);

  if (isEmpty(location)) {
    return <Alert severity="info">Select a location</Alert>
  }
  return (
    <Box textAlign="left">
      <Grid container alignItems="center">
        <Grid item xs>
          <Typography gutterBottom variant="h4">
            {location?.label}
          </Typography>
        </Grid>
        <Grid item>
          <Typography gutterBottom variant="h6">
            [{location.id}]
          </Typography>
        </Grid>
      </Grid>
      <Typography color="textSecondary" variant="body2">
        {location.address}
      </Typography>
    </Box>
  );
}

export default withStyles(styles)(LocationInfoBox);
