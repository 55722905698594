import React from "react"
import { Tooltip } from "@material-ui/core";

import { GetLoggedInUser } from "../context/UserContext";

export default function AdminsOnlyComponent(props) {
    const { children } = props

    const userData = GetLoggedInUser()

    return (
        userData.user.isAdmin
            ? children
            : (
                <Tooltip title="Only admins have access to this" aria-label="Delete">
                    <div>
                        { children }
                    </div>
                </Tooltip>
            )
    )
}
