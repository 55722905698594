import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { Button, Divider, Typography, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Dialog, Grid } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouseUser, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

const useStyles = makeStyles(theme => ({

}));

const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    fullLength: {
        width: '100%'
    }
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });
    
  const DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiDialogContent);

  const DeleteLocationPromptDialog = (props) => {
    const classes = useStyles();
    const { open, handleClose, location } = props;

    useEffect(() => {
      console.log(props);
    }, []);

    return (
      <div>
        <Dialog maxWidth="md" fullWidth={true} open={open} onClose={handleClose} aria-labelledby='delete-location-prompt-dialog'>
            <DialogTitle id="delete-location-prompt" onClose={handleClose}>
              Delete Location
            </DialogTitle>
            <DialogContent>
              <Grid container direction="column" alignItems="center" justify="center">
                <Grid item xs={12} align="center">
                  <Typography variant="body1">
                      You are about to delete { `"${location.location_label}"`}
                  </Typography>
                  <Typography variant="caption" style={{ color: 'red'}}>
                    This process is irreversible!
                  </Typography>
                </Grid>
              </Grid>
              <Grid container alignItems="flex-start" justify="flex-start">
                <Grid item xs={12} style={{ paddingLeft: 15, paddingRight: 15}}>
                  <Typography variant="body1">
                    Deleting a location will have the following consequences:
                  </Typography>
                </Grid>
                <Grid item xs={12} style={{ paddingLeft: 15, paddingRight: 15}}>
                  <ul>
                    <li>
                      <Typography variant="body1">
                        { location.count_checker === 0 ? 'No' : location.count_checker } Health Checker/s will be unassigned. 
                        { location.count_checker >= 1 ? 'This may affect the security of your office.' : '' }
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="body1">
                        All historical logs will be deleted and no longer be accessible.
                      </Typography>
                    </li>
                  </ul>
                </Grid>
              </Grid>
              <Divider />
              <Grid container alignItems="flex-start" justify="flex-start">
                <Grid item xs={12} align="center" style={{ paddingTop: 15, paddingBottom: 15}}>
                  Are you sure you want to proceed?
                </Grid>
                <Grid item xs={6} align="right" style={{ paddingRight: 10}}>
                  <Button variant="outlined" color="primary">
                      Cancel
                  </Button>
                </Grid>
                <Grid item xs={6} align="left" style={{ paddingLeft: 10 }}>
                  <Button variant="contained" color="primary" style={{ color: 'white'}}>
                    Delete Location
                  </Button> 
                </Grid>
              </Grid>
            </DialogContent>
        </Dialog>
      </div>
    )
  }

  export default DeleteLocationPromptDialog;