import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Divider, FormControl, Grid, InputLabel, Paper, Select, Typography } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      height: '100%',
      padding: theme.spacing(1),
      backgroundColor: theme.palette.background.paper,
      color: '#000',
      textAlign: 'left'
    },
    secondaryListText: {
        color: '#8d918e'
    },
    textLeft: {
        textAlign: 'left'
    },
    gridPadding: {
        padding: theme.spacing(1)
    },
    title: {
        fontWeight: 500
    },
    divider: {
        color: '#C4C4C4'
    }
}));

const GraphCard = (props) => {
    const classes = useStyles();
    const { title, children } = props;

    return(
        <div className={classes.root}>
            <Grid container spacing={4}>
                <Grid item xs={8} sm={8} container alignItems="flex-start" justify="flex-start">
                    <Typography variant="body1" gutterBottom className={clsx(classes.textLeft, classes.title)}>
                        { title }
                    </Typography>
                    </Grid>
                    <Grid item xs={4} sm={4} container alignItems="center" justify="flex-end" className={classes.textLeft}>
                        
                        {/* <Button variant="outlined" size="small" onClick={addFloor}>
                            Add Floor
                        </Button> */}
                    </Grid>
                <Divider  variant="middle" className={classes.divider} />
                <Grid item xs={12}>
                    { children }
                </Grid>
            </Grid>
        </div>
    )
}

export default GraphCard