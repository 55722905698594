import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { useMediaQuery } from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';
import ZennyaTheme from './../providers/ZennyaThemeProvider';

import Sidebar from './Sidebar';
import Topbar from './Topbar';
import Footer from './Footer';

import axios from "../services/helpers/instance";
import logger, { snackbar } from "../services/logger.service";

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    paddingTop: 56,
    height: '90%',
    [theme.breakpoints.up('sm')]: {
      paddingTop: 64
    }
  },
  shiftContent: {
    paddingLeft: 240
  },
  shiftContentSmall: {
    paddingLeft: 30
  },
  content: {
    height: '100%',
    // display: 'flex',
    width: '100%'
  }
}));

const Main = props => {
  const { children } = props;

  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true
  });

  const [openSidebar, setOpenSidebar] = useState(true);

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  const shouldOpenSidebar = isDesktop;

  useEffect(() => {
    console.log('isDesktop: ' + isDesktop);
    setOpenSidebar(isDesktop);
  }, [isDesktop])

  // const { enqueueSnackbar } = snackbar()

  // axios.interceptors.response.use(config => config, err => {
  //   let response = err.response;

  //   if (response && response.data) {
  //     const data = response.data

  //     const message = data.message ? data.message.en : ''
  //     const details = data.details
  //     const code = data.code

  //     const errMessage = details
  //         ? `${code}: ${details}`
  //         : message
  //             ? `${code}: ${message}`
  //             : code

  //     logger.err(enqueueSnackbar, errMessage)
  //   }

  //   return Promise.reject(err)
  // })

  return (
    <ThemeProvider theme={ZennyaTheme}>
      <div
          className={clsx({
            [classes.root]: true,
            // [classes.shiftContent]: isDesktop,
            [classes.shiftContentSmall]: true
          })}
      >
        {/* <Topbar onSidebarOpen={handleSidebarOpen} /> */}
        <Sidebar
            isDesktop={isDesktop}
            onClose={handleSidebarClose}
            open={shouldOpenSidebar}
            variant={isDesktop ? 'persistent' : 'temporary'}
        />
        <main className={classes.content}>
          {children}
        </main>
      </div>
    </ThemeProvider>
  );
};

Main.propTypes = {
  children: PropTypes.node
};

export default Main;
