import React, {useEffect, useRef, useState} from "react"
import {makeStyles} from "@material-ui/core/styles";
import {
    Paper,
    Grid,
    Typography,
} from '@material-ui/core'
import clsx from "clsx";
import { ResponsiveContainer,PieChart, Pie, Sector, Cell, Legend } from 'recharts';
import { Divider } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    fullSize: {
        width: "100%",
        
    },
    chart: {
        width: "100%"
    },
    textLeft: {
        textAlign: "left"
    },
    textCenter: {
        textAlign: "center"
    },
    textBold: {
        fontWeight: 'bold'
    },
    graphContainer: {
        '-webkit-box-sizing': 'border-box',
        '-moz-box-sizing': 'border-box',
        borderSizing: 'border-box',
        padding: 10,
        width: 500,
        mineight: 500,
        backgroundColor: '#fff'
    },
    legend: {
        borderRadius: '3px',
        padding: '5px',
        zIndex: 1,
        opacity: 0.7,
        textAlign: 'left',
        
    },
    legendEntry: {
        display: 'inline-block',
        height: '10px',
        marginRight: '5px',
        width: '2.5px',
        textAlign: 'right',
        opacity: 1
    },
    legendText: {
        textAlign: 'right',
        fontWeight: 700
    }
}));

const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,
      fill, payload, percent, value } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';
  
    return (
      <g>
        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
            { payload.name !== 'No Data' ? 
                <React.Fragment>
                    <tspan style={{ fontSize: '2.5rem', fontWeight: 700}}>
                        {`${(percent * 100).toFixed(0)}%`}
                    </tspan>
                    <tspan x={cx} y={cy + 13} dy={10} style={{ fontSize: '0.75rem'}}>
                        {payload.name}
                    </tspan>
                </React.Fragment> :
                <tspan fill={'#494949'} style={{ fontSize: '0.75rem', fontWeight: 700}}>
                    { payload.noDataDisplay || 'No data is available'}
                </tspan>
            }
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        { payload.name !== 'No Data' ? <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />: undefined}
        {/* <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        /> */}
        {/* <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none"/>
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none"/> */}
        {/* <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`PV ${value}`}</text> */}
        {/* <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
          {`(${(percent * 100).toFixed(2)}%)`}
        </text> */}
      </g>
    );
  };

const HealthRiskMetrics = ({renderKey, ...props}) => {
    const classes = useStyles()
    const [ activeIndex, setActiveIndex ] = useState(0); 
    const [ totalCount, setTotalCount ] = useState(0);

    const {
        labels = [],
        datasets = [],
        description,
        backgroundColors = [],
        counts = [],
        data = []
    } = props;

    const [ percentages, setPercentages ] = useState([]);

    const onPieEnter = (data, index) => {
        setActiveIndex(index);
    }

    const onPieClick = (index) => {
        setActiveIndex(index);
    }

    useEffect(() => {
        if (Array.isArray(data)) {
            setTotalCount(data.reduce((acc, currentVal) => acc + currentVal.value, 0));
        }
    }, [data])

    // useEffect(() => {
    //     const reducer = (accumulator, currentValue) => accumulator + currentValue;

    //     if (counts.length > 0) {
    //         const total = counts.reduce(reducer);
    //         setTotalCount(total);

    //         if (total !== 0) {
    //             const data = counts.map(count => ((count / total) * 100).toFixed(2));

    //             setPercentages(data);
    //         }
    //     }
    // }, [counts, labels])

    const renderLegend = (props) => {
        const { payload } = props;
      
        return (
          <div className={classes.legend}>
            {
              payload.map((entry, index) => (
                <div key={index} style={{ fontSize: '0.75rem'}}>
                    <b>{totalCount > 0 ? data[index].value: 'NA'}</b> 
                <span className={classes.legendEntry} style={{ marginLeft: 3, backgroundColor: backgroundColors[index] }}></span><span className={classes.legendText}>
                    {entry.value}
                </span></div>
              ))
            }
            <div style={{ fontSize: '0.75rem'}}>
                    <b>{totalCount}</b> 
                <span className={classes.legendEntry} style={{ marginLeft: 3,}}></span><span className={classes.legendText}>
                    Total
                </span></div>
          </div>
        );
      }


    return (
        <Paper className={clsx(classes.fullSize)} variant={"outlined"}>
            <Grid container 
            // alignItems={"center"} 
            // justify={"center"} 
            style={{ margin: "0.5rem" }}
             spacing={1}
             >
                <Grid item xs={12} style={{ padding: 0, margin: 0}} align="left">
                    <Typography variant="caption" style={{ color: '#494949', marginLeft: 5, fontWeight: 700 }}>
                        { props.title }
                    </Typography>
                </Grid>
                <Grid item  xs={12}>
                            <ResponsiveContainer width="100%" height={250}>
                                <PieChart key={renderKey}>
                                    <Legend wrapperStyle={{pointerEvents: 'none', top:0}} content={renderLegend} verticalAlign="middle" layout="horizontal" />
                                    <Pie
                                        animationBegin={0}
                                        
                                        isAnimationActive={renderKey!==0}
                                        activeIndex={activeIndex}
                                        activeShape={renderActiveShape}
                                        data={totalCount > 0 ? data: [
                                            { name: 'No Data', value: 25, noDataDisplay: props.noDataDisplay },
                                            { name: 'No Data', value: 75, noDataDisplay: props.noDataDisplay }
                                        ]}
                                        
                                        dataKey={`value`}
                                        innerRadius={70}
                                        outerRadius={90}
                                        fill="#D0CECE"
                                        onMouseOver={onPieEnter}
                                        >
                                        {
                                            data.map((entry, index) => <Cell onClick={(e) => onPieClick(index) } key={`cell-${index}`} fill={ backgroundColors[index]} />)
                                        }
                                    </Pie>
                                </PieChart>
                            </ResponsiveContainer>
                    
                    {/* {
                        labels.length > 0 && (
                            // <DoughnutChart
                            //     labels={labels}
                            //     datasets={
                            //         datasets.length > 0
                            //             ? datasets
                            //             : [
                            //                 {
                            //                     backgroundColor: backgroundColors,
                            //                     data: percentages
                            //                 }
                            //             ]
                            //     }
                            //     options={
                            //         {
                            //             tooltips: {
                            //                 callbacks: {
                            //                     title: function(tooltipItem, data) {
                            //                         return data['labels'][tooltipItem[0]['index']];
                            //                     },
                            //                     label: function(tooltipItem, data) {
                            //                         return `${data['datasets'][0]['data'][tooltipItem['index']]}%`;
                            //                     }
                            //                 }
                            //             },
                            //             legend: {
                            //                 position: 'right',
                            //                 labels: {
                            //                     fontSize: 10
                            //                 }
                            //             }
                            //         }
                            //     }
                            // />
                        )
                    } */}
                    <br />
                </Grid>
            </Grid>
        </Paper>
    )
}

export default HealthRiskMetrics;
