import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { CircularProgress, Dialog, MenuItem, DialogContentText, Typography, Grid, TextField, useTheme, FormControl, InputLabel, Select } from '@material-ui/core';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import corporateHealthService from './../services/corporate-health.service';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ErrorMessage from './../components/errorMessage';
import SuccessMessage from './../components/successMessage';

const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    fullLength: {
        width: '100%'
    }
  });

  const useStyles = makeStyles(theme => ({
    root: {
      padding: theme.spacing(4),
      flexGrow: 1,
      color: '#000'
    },
    officeInfoTitle: {
      padding: 10,
      color: '#000'
    },
    officeInfoSection: {
      padding: 10,
      color: '#000'
    },
    formControl: {
      marginRight: theme.spacing(1),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      minWidth: 300,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    fullHeight: {
      height: '100vh',
      width: '100vw'
    },
    headerPadding: {
      padding: 20,
      fontWeight: 500
    },
    textFieldSpacer: {
      margin: theme.spacing(1)
    },
    boldText: {
      fontWeight: 600
    },
    btn: {
      color: 'white'
    }
  }));
  

  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <div>{children}</div>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });
  
  const DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiDialogContent);
  

  const AssignAsChecker = (props) => {
    const classes = useStyles();
    const { company, open, handleClose, checker } = props;
    const [ state, setState ] = useState({
      location: '',
      gate: '',
      employee: {
        display_name: undefined
      },
      floor: '',
      checker_type: 'ROVING'
    });
    const [ gates, setGates ] = useState([]);
    const [ loading, setLoading ] = useState(false);
    const [ error, setError ] = useState('');
    const [ message, setMessage ] = useState('');
    const [ openError, setOpenError ] = useState(false);
    const [ openSuccess, setOpenSuccess ] = useState(false); 
    const [ floors, setFloors ] = useState([]);
    const [ selectedFloor, setSelectedFloor ] = useState({ name: '', gates: []});
    const [ openAutocomplete, setOpenAutocomplete ] = useState(false);
    const [ openEmployeeAutocomplete, setOpenEmployeeAutocomplete ] = useState(false);
    const [ options, setOptions ] = useState([]);
    const [ value, setValue ] = useState(undefined);
    const [ inputValue, setInputValue ] = useState('');
    const [ employees, setEmployees ] = useState([]);
    const [ loadingEmployees, setLoadingEmployees ] = useState(false);
    const checkerTypes = [
      { value: 'ROVING', label: 'Roving'},
      { value: 'GATE', label: 'Gate-based'}
    ];

    const getPartnerLocations = useCallback((companyId, params) => {
      setLoading(true);
      corporateHealthService.listLocationCounts(companyId, params).then(
        response => {
          setOptions(options.concat(response.list));
          setLoading(false);
        }, error => {
          setLoading(false);
        }
      )
    }, []);

    const getEligibleEmployeesAsHealthCheckers = useCallback((companyId, params) => {
      setLoadingEmployees(true);
      return corporateHealthService.listEligibleEmployeesAsHealthCheckers(companyId, params).then(
        response => {
          setLoadingEmployees(false);
          var newList = response.list.map((emp, index) => {
            return {
              id: emp.id,
              display_name: emp.first_name + ' ' + emp.last_name
            }
          });
          console.log(newList);
          setEmployees(newList);
        }, error => {
          setLoadingEmployees(false);
        }
      );
    }, []);

    useEffect(() => {
      if(openAutocomplete) {
        getPartnerLocations(company.id, { page_size: 20, page_offset: 0});
        // getEligibleEmployeesAsHealthCheckers(company.id, { partner: company.id, page_size: 20, page_offset: 0});
      }

      if(openEmployeeAutocomplete) {
        getEligibleEmployeesAsHealthCheckers(company.id, { partner: company.id, page_size: process.env.REACT_APP_DEFAULT_PAGE_SIZE, page_offset: 0});
      }

    }, [company, openAutocomplete, openEmployeeAutocomplete, getPartnerLocations, getEligibleEmployeesAsHealthCheckers]);

    const handleChange = (name, value = '') => event => {
      setState({
        ...state,
        [name]: event.target.value
      });

      if(name === 'location') {
        listFloors(event.target.value);
      }
      if( name === 'floor') {
        setSelectedFloor(floors.find(({id}) => id === Number(event.target.value)));
      }
    }

    const handleChangeEmployee = (event, value) => {
      console.log(value);

      setState({
        ...state,
        employee: value
      })
    }

    const handleChangeLocation = (event, value) => {
      console.log(value);

      listFloors(value);
    }
  

    function listFloors(location) {
      var params = {
        partner: company.id,
        location: location.location_id
      };

      setLoading(true);
      corporateHealthService.listFloors(params.partner, params.location).then(
        response => {
          console.log(response.list[0]);
          setFloors(response.list);
          setState({
            ...state,
            location: location,
            floor: response.list[0].id,
            gate: response.list[0].gates.list[0].id
          })
          setSelectedFloor(response.list[0])
          setLoading(false);
        }, error => {
          setLoading(false);
          setError(error.response.data);
        }
      );
    }

    const handleAssign = (event) => {
      var params = {
        partner: company.id,
        location: Number(state.location.location_id),
        checkers: [ 
          { 
            type: state.checker_type,
            employee: state.employee.id,
            gate: state.checker_type === 'GATE' ? Number(state.gate) : null,
            isChecker: true,
        } ],
        representatives: []
      };

      setLoading(true);
      corporateHealthService.assignAsCheckerOrRep(params).then(
       response => {
        setMessage('Successfully assigned employee as health checker.');
        setOpenSuccess(true);
        setLoading(false);
        handleClose();
       },error => {
        setLoading(false);
        console.log(error.response);
        setError('Unable to assign employee as health checker');
        setOpenError(true);
       }
      )
    }

    return (
        <div>
            <Dialog maxWidth={'sm'} fullWidth={true} open={open}
            onClose={handleClose} aria-labelledby="assign-as-checker-title">
                {error ?  <ErrorMessage error={error} open={openError} setOpen={setOpenError} /> : ''}
                { message  ? <SuccessMessage open={openSuccess} setOpen={setOpenSuccess} message={message} /> : undefined}
                <DialogTitle id="assign-as-checker-title" onClose={handleClose}>
                    <Typography variant="h6" className={classes.boldText}>
                      Assign a Health Checker
                    </Typography>
                    <Typography variant="caption">
                        Assign a health checker by searching the name from the employee list per office location.
                    </Typography>
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={4}>
                        <Grid item xs={4} sm={4}>
                          <Typography variant="body1" className={classes.headerPadding}>
                            Health Checker Type
                          </Typography>
                        </Grid>
                        <Grid item xs={8} sm={8}>
                          <FormControl variant="outlined" className={classes.formControl}>
                              <InputLabel htmlFor="outlined-gates-native-simple">Health Checker Type</InputLabel>
                                  <Select
                                      native
                                      size="small"
                                      value={state.checker_type}
                                      disabled={loading}
                                      onChange={handleChange('checker_type')}
                                      label="Health Checker Type"
                                      inputProps={{
                                      name: 'checker-type',
                                      id: 'outlined-checker-types-native-simple',
                                      }}
                                  >
                                      ({
                                    checkerTypes &&  checkerTypes.length >= 1 ? checkerTypes.map((option, index) => {
                                          return (
                                              option ? 
                                              <option value={option.value} key={index}>
                                              { option.label }
                                              </option>
                                          : undefined)
                                      })
                                      : undefined
                                      })
                                  </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={4} sm={4}>
                          <Typography variant="body1" className={classes.headerPadding}>
                            Location
                          </Typography>
                        </Grid>
                        <Grid item xs={8} sm={8}>
                              <Autocomplete
                                  id="asynchronous-demo"
                                  style={{ width: 300 }}
                                  open={openAutocomplete}
                                  onOpen={() => {
                                    setOpenAutocomplete(true);
                                  }}
                                  onClose={() => {
                                    setOpenAutocomplete(false);
                                  }}
                                  disableClearable
                                  getOptionSelected={(option, value) => option.location_label === value.location_label}
                                  getOptionLabel={(option) => (option.location_label || '')}
                                  options={options}
                                  loading={loading}
                                  value={state.location.location_label}
                                  onChange={handleChangeLocation}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      onChange={({ target }) => { 
                                          setInputValue(target.value)
                                          setTimeout(() => getPartnerLocations(company.id, { search_text: target.value, page_offset: 0, page_size: 10}), 500);
                                        }
                                      }
                                      label="Search location"
                                      variant="outlined"
                                      InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                          <React.Fragment>
                                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                          </React.Fragment>
                                        ),
                                      }}
                                    />
                                  )}
                                />
                        </Grid>
                        { state.checker_type === 'GATE' ? 
                          <React.Fragment>
                            <Grid item xs={4} sm={4}>
                              <Typography variant="body1" className={classes.headerPadding}>
                                Floor Name
                              </Typography>
                            </Grid>
                            <Grid item container justify="flex-start" alignItems="flex-start" xs={8} sm={8}>
                                  { floors ? <FormControl variant="outlined" className={classes.formControl}>
                                        <InputLabel htmlFor="outlined-floors-native-simple">Floor Name</InputLabel>
                                            <Select
                                                native
                                                size="small"
                                                value={state.floor}
                                                disabled={loading}
                                                onChange={handleChange('floor')}
                                                label="Floor Name"
                                                inputProps={{
                                                name: 'floor',
                                                id: 'outlined-floors-native-simple',
                                                }}
                                            >
                                                <option aria-label="None" value="" />
                                                ({
                                                floors && floors.length >= 1 ? floors.map((option, index) => {
                                                    return (
                                                        option ? 
                                                        <option value={option.id} key={index}>
                                                        { option.name }
                                                        </option>
                                                    : undefined)
                                                })
                                                : undefined
                                                })
                                            </Select>
                                    </FormControl> : undefined}
                                    { (state.location !== '' && floors.length === 0 && !loading)? <Typography variant="body1" className={classes.headerPadding}>
                                        There are no floors in this location.
                                    </Typography> : undefined }
                                    {/* {
                                      state.location === '' ? <Typography variant="body1" className={classes.headerPadding}>
                                        Please choose a location first.
                                      </Typography> : undefined
                                    } */}
                            </Grid>
                          </React.Fragment>
                        : undefined }
                        { state.checker_type === 'GATE' ? <React.Fragment>
                          <Grid item xs={4} sm={4}>
                            <Typography variant="body1" className={classes.headerPadding}>
                              Entrance Name
                            </Typography>
                          </Grid>
                          <Grid item container justify="flex-start" alignItems="flex-start" xs={8} sm={8}>
                                { selectedFloor ? <FormControl variant="outlined" className={classes.formControl}>
                                      <InputLabel htmlFor="outlined-gates-native-simple">Entrance Name</InputLabel>
                                          <Select
                                              native
                                              size="small"
                                              value={state.gate}
                                              disabled={loading || !selectedFloor}
                                              onChange={handleChange('gate')}
                                              label="Entrance Name"
                                              inputProps={{
                                              name: 'gate',
                                              id: 'outlined-gates-native-simple',
                                              }}
                                          >
                                              <option aria-label="None" value="" />
                                              ({
                                            selectedFloor &&  selectedFloor.gates.count >= 1 ? selectedFloor.gates.list.map((option, index) => {
                                                  return (
                                                      option ? 
                                                      <option value={option.id} key={index}>
                                                      { option.description }
                                                      </option>
                                                  : undefined)
                                              })
                                              : undefined
                                              })
                                          </Select>
                                  </FormControl> : undefined}
                          </Grid>
                        </React.Fragment>: undefined }
                        <Grid item xs={4} sm={4}>
                          <Typography variant="body1" className={classes.headerPadding}>
                            Worker Name
                          </Typography>
                        </Grid>
                        <Grid item xs={8} sm={8}>
                            <Autocomplete
                                  id="asynchronous-employees"
                                  style={{ width: 300 }}
                                  open={openEmployeeAutocomplete}
                                  onOpen={() => {
                                    setOpenEmployeeAutocomplete(true);
                                  }}
                                  onClose={() => {
                                    setOpenEmployeeAutocomplete(false);
                                  }}
                                  disableClearable
                                  getOptionSelected={(option, value) => option.display_name === value.display_name}
                                  getOptionLabel={option => option.display_name}
                                  options={employees || []}
                                  loading={loadingEmployees}
                                  value={state.employee.display_name}
                                  onChange={handleChangeEmployee}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      onChange={({ target }) => { 
                                          // setInputValue(target.value)
                                          setTimeout(() => getEligibleEmployeesAsHealthCheckers(company.id, { search_text: target.value, page_offset: 0, page_size: 10, partner: company.id}), 500);
                                        }
                                      }
                                      label="Search worker name"
                                      variant="outlined"
                                      InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                          <React.Fragment>
                                            {loadingEmployees ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                          </React.Fragment>
                                        ),
                                      }}
                                    />
                                  )}
                                />
                          {/* <Autocomplete
                              id="combo-box-demo"
                              options={employees}
                              getOptionLabel={option => option.employee.display_name}
                              style={{ width: 300 }}
                              onChange={handleChangeEmployee}
                              renderInput={params => (
                                <TextField {...params} label="Name" variant="outlined" fullWidth />
                              )}
                            /> */}
                        </Grid>
                        <Grid item xs={6} sm={6}>
                          <Button onClick={handleAssign} variant="contained" disabled={loading} color="primary" className={classes.btn}>
                                Assign As Health Checker
                          </Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default AssignAsChecker;