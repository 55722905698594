import { ButtonGroup, TableHead } from "@material-ui/core";
import { Table } from "@material-ui/core";
import { TableBody } from "@material-ui/core";
import { TableCell } from "@material-ui/core";
import { TableRow } from "@material-ui/core";
import {
  Avatar,
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import corporateEventsService from "../../services/corporate-events.service";
import { StyledEventStatus, StyledEventType } from "../eventCell";
import { getCalendarDateFormat } from "../eventsData";
import { SlotFormDialog } from "../eventslots/SlotForm";
import { countEventEntrants, countServiceEntrants } from "../utils/event";
import { AddCohortDialog } from "./AddCohortDialog";
import { ResourceAgenda, ResourceAgendaDialog } from "./ResourceAgenda";

const useStyles = makeStyles((theme) => ({
  cancel: {
    color: theme.palette.error.main,
  },
  count: {
    backgroundColor: `${theme.palette.common.white} !important`,
  },
  cohortContainer: {
    paddingTop: theme.spacing(1),
  },
  root: {
    textAlign: "start",
    padding: theme.spacing(4),
    flexGrow: 1,
    maxWidth: "1440px",
  },
  serviceAction: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "flex-end",
    alignItems: "center",
    "&>*" :{
      marginLeft: theme.spacing(2),
    }
  },
}));

const EventHeaderDetail = ({ company, event, reloadEvent = () => {} }) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [cohorts, setCohorts] = useState([]);
  const [services, setServices] = useState([]);
  const openCohort = (cohort) => {
    history.push({
      pathname: `/cohorts/${cohort.id}`,
      search: location.search,
    });
  };

  useEffect(() => {
    async function getCohorts() {
      let response = await corporateEventsService.getCohortsList(company);
      setCohorts(response.list ?? []);
      response = await corporateEventsService.getServiceTypes(company);
      setServices(response?.list ?? []);
    }
    getCohorts();
  }, [company]);

  return (
    <>
      <EventHeaderDetailContent event={event} company={company}/>
      <Box className={classes.serviceAction}>
        <div></div>
        {/* <AddCohortDialog cohorts={cohorts} event={event} onComplete={()=>{reloadEvent()}}/> */}
        
          <ResourceAgendaDialog event={event} company={company}/>
          <SlotFormDialog
            cohorts={cohorts}
            services={services}
            event={event}
            onComplete={() => {
              reloadEvent();
            }}
          />
        
        
      </Box>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell width="30%">Service</TableCell>
            <TableCell width="10%">Call Time</TableCell>
            <TableCell width="50%">Cohorts</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(event.services ?? []).map((service, idx) => (
            <React.Fragment key={`slots-${idx}`}>
              {(service.slots ?? []).map((slot, sid) => (
                <TableRow key={`${idx}-${sid}`}>
                  {sid === 0 && (
                    <TableCell rowSpan={(service.slots ?? []).length}>
                      {service?.type?.label}
                    </TableCell>
                  )}
                  <TableCell>{slot.start_date ? moment(slot.start_date).format("LT"):'-'}</TableCell>
                  <TableCell>
                    {(slot.cohorts ?? []).map((cohort) => {
                      return (
                        <Chip
                          key={`cohort-${cohort.id}-${idx}-${sid}`}
                          onClick={() => openCohort(cohort)}
                          classes={{ avatar: classes.count }}
                          color="primary"
                          label={cohort.label}
                          avatar={<Avatar>{cohort.entry_count}</Avatar>}
                        />
                      );
                    })}
                  </TableCell>
                </TableRow>
              ))}
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </>
  );
};

const EventHeaderDetailContent = ({ event }) => {
  return (
    <Box>
      <Grid container>
        <Grid item xs={3}>
          <StyledEventType variant="body2">Status: </StyledEventType>
        </Grid>
        <Grid item xs={9}>
          <StyledEventStatus>{event?.status}</StyledEventStatus>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={3}>
          <StyledEventType variant="body2">Location: </StyledEventType>
        </Grid>
        <Grid item xs={9}>
          <StyledEventStatus>{event?.location?.label} - {event?.location?.address}</StyledEventStatus>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={3}>
          <StyledEventType variant="body2">Event Date: </StyledEventType>
        </Grid>
        <Grid item xs={9}>
          <StyledEventStatus>
            {moment(event?.start_date).format('LL')}
          </StyledEventStatus>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={3}>
          <StyledEventType variant="body2">Start Time: </StyledEventType>
        </Grid>
        <Grid item xs={9}>
          <StyledEventStatus>
            {moment(event?.start_date).format('LT')}
          </StyledEventStatus>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={3}>
          <StyledEventType variant="body2">End Time: </StyledEventType>
        </Grid>
        <Grid item xs={9}>
          <StyledEventStatus>
            {moment(event?.end_date).format('LT')}
          </StyledEventStatus>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={3}>
          <StyledEventType variant="body2">Total participants: </StyledEventType>
        </Grid>
        <Grid item xs={9}>
          <StyledEventStatus>{countEventEntrants(event)}</StyledEventStatus>
        </Grid>
      </Grid>
    </Box>
  );
};
export { EventHeaderDetail, EventHeaderDetailContent };
