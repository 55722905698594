import React from 'react';
import marker_icon from './../assets/svg/marker.svg';

export default class AddLocationMapClass extends React.Component {
    constructor(props) {
        super(props);

        this.platform = new  window.H.service.Platform({
            apikey: `${process.env.REACT_APP_HERE_MAPS_API_KEY}`
        });

        this.mapRef = React.createRef();
    
        this.state = {
            map: null,
            marker: props.marker,
            behavior: null,
            ui: null
          };

        this.changeMarker = this.changeMarker.bind(this);
        this.handleGetCoordinates = props.handleGetCoordinates;
        this.addCircleToMap = this.addCircleToMap.bind(this);
    }

    addCircleToMap(map, coords, radius){
      map.addObject(new window.H.map.Circle(
        // The central point of the circle
       coords,
        // The radius of the circle in meters
        radius,
        {
          style: {
            strokeColor: 'rgba(23, 175, 186, 0.7)', // Color of the perimeter
            lineWidth: 2,
            fillColor: 'rgba(11, 137, 146, 0.4)'  // Color of the circle
          }
        }
      ));
    }


    changeMarker(marker, radius = 100) {
      var currentCenter = this.state.map.getCenter()

      if(currentCenter.lat !== marker.lat || currentCenter.lng !== marker.lng) {
        this.state.map.removeObjects(this.state.map.getObjects());
      }

        var svgIcon = new window.H.map.Icon(marker_icon);


        var icon = new window.H.map.Icon(marker_icon, { size: { w:50, h:50}}),
                coords = { lat: marker.lat, lng: marker.lng },
                mapMarker = new window.H.map.Marker(coords, { volatility: true, icon: icon});
          
        mapMarker.draggable = true;


        mapMarker.setData('<p>' + marker.label  + '</p>');

        var bubble;

        // mapMarker.addEventListener('tap', event => {
        //   bubble = new window.H.ui.InfoBubble(
        //     coords,
        //     {
        //       content: event.target.getData()
        //     }
        //   );

        //   this.state.ui.addBubble(bubble);
        // }, false);

      // this.state.map.addEventListener('tap', function(evt) {
      //     const objects = this.state.map.getObjects()

      //     if(objects.length >= 1) this.state.map.removeObjects(objects)

      //     var coord = this.state.map.screenToGeo(evt.currentPointer.viewportX,
      //         evt.currentPointer.viewportY);

      //         var icon = new window.H.map.Icon(marker_icon, { size: { w:50, h:50}}),
      //         coords = { lat: coord.lat, lng: coord.lng },
      //         mapMarker2 = new window.H.map.Marker(coords, { volatility: true, icon: icon});

      //         this.state.map.addObject(mapMarker2);
      //         this.state.map.setCenter(coords)
      
      //         this.addCircleToMap(this.state.map, coords, 100);
      // });

        mapMarker.addEventListener('pointerenter', event => {
          bubble = new window.H.ui.InfoBubble(
            coords,
            {
              content: event.target.getData()
            }
          );

          this.state.ui.addBubble(bubble);
        }, false);

        mapMarker.addEventListener('pointerleave', event => {
          bubble.close();
        }, false);

        // this.handleGetCoordinates(coords);

        this.state.map.addObject(mapMarker);
        this.state.map.setCenter(coords)

        this.addCircleToMap(this.state.map, coords, radius);
    }

    shouldComponentUpdate(props, state) {
        if(props.marker) this.changeMarker(props.marker)
        if(props.radius && props.marker) {
          this.changeMarker(props.marker, props.radius)
        }

        return false;
    }
  
    componentDidMount() {
      const defaultLayers = this.platform.createDefaultLayers();

      const nmap = new window.H.Map(
        this.mapRef.current,
        defaultLayers.vector.normal.map,
        {
            center: {
                lat: 14.560307, lng: 121.024956
            },
            zoom: 15,
            pixelRatio: window.devicePixelRatio || 1
        }
      );
  
      const nbehavior = new  window.H.mapevents.Behavior(new window.H.mapevents.MapEvents(nmap));
  
      const nui = new window.H.ui.UI.createDefault(nmap, defaultLayers);
      

      nmap.addEventListener('tap', function(evt) {
        const objects = nmap.getObjects()

        if(objects.length >= 1) nmap.removeObjects(nmap.getObjects())

        var coords = nmap.screenToGeo(evt.currentPointer.viewportX,
            evt.currentPointer.viewportY);

        
      });
  
      this.setState({ map: nmap, behavior: nbehavior, ui: nui });
    }
  
    componentWillUnmount() {
      this.state.map.dispose();
    }
  
    render() {
      return <div ref={this.mapRef} style={{ height: 300 }} />;
    }
  }
  