import instance from './helpers/instance';

const API_URL = process.env.REACT_APP_WEB_ADMIN_URL;

const corporatePackagesService = {
    getPkgs: () => {
        return [
            {
                id: 1,
                name: 'COVID Antibody Lab Test',
                price: 1500,
                discount: '20% OFF',
                discounted_price: 1500 * 0.8,
                start_date: '01-01-2020',
                end_date: '06-01-2020',
                status: 'EXPIRED'
            },
            {
                id: 2,
                name: 'Telemedicine (15 mins)',
                price: 500,
                discount: '20% OFF',
                discounted_price: 500 * 0.8,
                start_date: '01-01-2020',
                end_date: '12-01-2020',
                status: 'ACTIVE'
            },
            {
                id: 3,
                name: 'Fight Flu',
                price: 1500,
                discount: '50% OFF',
                discounted_price: 1500 * 0.5,
                start_date: '01-01-2020',
                end_date: '12-01-2020',
                status: 'ACTIVE'
            },
            {
                id: 4,
                name: 'Glutathione',
                price: 1700,
                discount: '50% OFF',
                discounted_price: 1700 * 0.8,
                start_date: '01-01-2020',
                end_date: '12-01-2020',
                status: 'ACTIVE'
            },
            {
                id: 5,
                name: 'Standard Check',
                price: 795,
                discount: '20% OFF',
                discounted_price: 795 * 0.8,
                start_date: '01-01-2020',
                end_date: '12-01-2020',
                status: 'ACTIVE'
            },
            {
                id: 6,
                name: 'Routine Check',
                price: 1035,
                discount: '20% OFF',
                discounted_price: 1035 * 0.8,
                start_date: '01-01-2020',
                end_date: '12-01-2020',
                status: 'ACTIVE'
            },
            {
                id: 7,
                name: 'Basic Check',
                price: 1275,
                discount: '20% OFF',
                discounted_price: 1275 * 0.8,
                start_date: '01-01-2020',
                end_date: '12-01-2020',
                status: 'ACTIVE'
            }, {
                id: 8,
                name: 'General Check',
                price: 1560,
                discount: '20% OFF',
                discounted_price: 1560 * 0.8,
                start_date: '01-01-2020',
                end_date: '12-01-2020',
                status: 'ACTIVE'
            }, {
                id: 9,
                name: 'Advanced Check',
                price: 2025,
                discount: '20% OFF',
                discounted_price: 2025 * 0.8,
                start_date: '01-01-2020',
                end_date: '12-01-2020',
                status: 'ACTIVE'
            }, {
                id: 10,
                name: 'Diabetic Panel Check',
                price: 1790,
                discount: '20% OFF',
                discounted_price: 1790 * 0.8,
                start_date: '01-01-2020',
                end_date: '12-01-2020',
                status: 'ACTIVE'
            }
        ]
    },
    getPkg: (pkgId) => {
        console.log(pkgId);
        switch(pkgId) {
            case 1:
                console.log('here');
                return {
                    id: 1,
                    name: 'COVID Antibody Lab Test',
                    price: 1500,
                    discount: '20% OFF',
                    discounted_price: 1500 * 0.8,
                    start_date: '01-01-2020',
                    end_date: '06-01-2020',
                    status: 'EXPIRED',
                    tests: [],
                    description:
                    "The most accurate COVID antibody test available. Your blood specimen is processed in a laboratory and results are delivered within 48 to 72 hours."
                }
            case 2:
                return {
                    id: 2,
                    name: 'Telemedicine (15 mins)',
                    price: 500,
                    discount: '20% OFF',
                    discounted_price: 500 * 0.8,
                    start_date: '01-01-2020',
                    end_date: '12-01-2020',
                    tests: [],
                    status: 'ACTIVE',
                    description: 
                    "Discuss medical concerns with a registered doctor from a safe distance via a video or voice call, on-demand."
                }
            case 3:
                return {
                    id: 3,
                    name: 'Fight Flu',
                    price: 1500,
                    discount: '50% OFF',
                    discounted_price: 1500 * 0.5,
                    start_date: '01-01-2020',
                    end_date: '12-01-2020',
                    status: 'ACTIVE',
                    description:
                    "Reduce the changes of catching flu by more than 60% through simple vaccination that takes 10 mins in the comfort of our home."
                }
            case 4:
                return {
                    id: 4,
                    name: 'Glutathione',
                    price: 1700,
                    discount: '50% OFF',
                    discounted_price: 1700 * 0.8,
                    start_date: '01-01-2020',
                    end_date: '12-01-2020',
                    status: 'ACTIVE',
                    tests: [],
                    description:
                    "Glutathione acts as an important antioxidant in your body. That means it helps combat free radicals that can damage your body cells."
                }
            case 5:
                return {
                    id: 5,
                    name: 'Standard Check',
                    price: 795,
                    discount: '20% OFF',
                    discounted_price: 795 * 0.8,
                    start_date: '01-01-2020',
                    end_date: '12-01-2020',
                    status: 'ACTIVE',
                    description:
                    "A group of tests that would give you a picture of how your body is currently doing. A quick check of your sugar, kidney health, and liver health to know your current status.",
                    tests: [
                        { id: 1, label: 'Blood Urea Nitrogen'},
                        { id: 2, label: 'Blood Uric Acid'},
                        { id: 3, label: 'Cholesterol'},
                        { id: 4, label: 'Creatinine'},
                        { id: 5, label: 'Fasting Blood Sugar'},
                        { id: 6, label: 'High Density Lipoprotein'},
                        { id: 7, label: 'Lipid Profile'},
                        { id: 8, label: 'SGOT/AST'},
                        { id: 9, label: 'SGPT/ALT'},
                        { id: 10, label: 'Triglycerides'}
                    ]
                }
            case 6: 
                return {
                    id: 6,
                    name: 'Routine Check',
                    price: 1035,
                    discount: '20% OFF',
                    discounted_price: 1035 * 0.8,
                    start_date: '01-01-2020',
                    end_date: '12-01-2020',
                    status: 'ACTIVE',
                    description:
                    "A quick peek on how your body is doing with just a quick procedure. A test to give you a glimpse of your blood level, sugar level, kidney health and liver health",
                    tests: [
                        { id: 11, label: 'Alkaline Phospatas/ALP'},
                        { id: 1, label: 'Blood Urea Nitrogen'},
                        { id: 2, label: 'Blood Uric Acid'},
                        { id: 3, label: 'Cholesterol'},
                        { id: 12, label: 'Complete Blood Count'},
                        { id: 13, label: 'Complete Urinalysis'},
                        { id: 4, label: 'Creatinine'},
                        { id: 5, label: 'Fasting Blood Sugar'},
                        { id: 6, label: 'High Density Lipoprotein'},
                        { id: 7, label: 'Lipid Profile'},
                        { id: 8, label: 'SGOT/AST'},
                        { id: 9, label: 'SGPT/ALT'},
                        { id: 10, label: 'Triglycerides'}
                    ]
                }
            case 7: 
                return {
                    id: 7,
                    name: 'Basic Check',
                    price: 1275,
                    discount: '20% OFF',
                    discounted_price: 1275 * 0.8,
                    start_date: '01-01-2020',
                    end_date: '12-01-2020',
                    status: 'ACTIVE',
                    description:
                    "Have a snapshot of your overall health with just a quick test. This test can give you an idea of your blood level, sugar level, kidney health, liver health and electrolyte level in your body.",
                    tests: [
                        { id: 11, label: 'Alkaline Phospatas/ALP'},
                        { id: 1, label: 'Blood Urea Nitrogen'},
                        { id: 2, label: 'Blood Uric Acid'},
                        { id: 3, label: 'Cholesterol'},
                        { id: 12, label: 'Complete Blood Count'},
                        { id: 13, label: 'Complete Urinalysis'},
                        { id: 4, label: 'Creatinine'},
                        { id: 5, label: 'Fasting Blood Sugar'},
                        { id: 6, label: 'High Density Lipoprotein'},
                        { id: 7, label: 'Lipid Profile'},
                        { id: 14, label: 'Potassium (K)'},
                        { id: 8, label: 'SGOT/AST'},
                        { id: 9, label: 'SGPT/ALT'},
                        { id: 15, label: 'Sodium'},
                        { id: 10, label: 'Triglycerides'}
                    ]
                }
            case 8: 
                return {
                    id: 8,
                    name: 'General Check',
                    price: 1560,
                    discount: '20% OFF',
                    discounted_price: 1560 * 0.8,
                    start_date: '01-01-2020',
                    end_date: '12-01-2020',
                    status: 'ACTIVE',
                    description:
                    `A group of tests that would give you an overview of your general health. With just a quick test you would have an idea of your blood level, 
                    sugar level, kidney health, liver health, electrolyte level and pancreas health.`,
                    tests: [
                        { id: 11, label: 'Alkaline Phospatas/ALP'},
                        { id: 16, label: 'Amylase'},
                        { id: 1, label: 'Blood Urea Nitrogen'},
                        { id: 2, label: 'Blood Uric Acid'},
                        { id: 3, label: 'Cholesterol'},
                        { id: 12, label: 'Complete Blood Count'},
                        { id: 13, label: 'Complete Urinalysis'},
                        { id: 4, label: 'Creatinine'},
                        { id: 5, label: 'Fasting Blood Sugar'},
                        { id: 6, label: 'High Density Lipoprotein'},
                        { id: 7, label: 'Lipid Profile'},
                        { id: 8, label: 'SGOT/AST'},
                        { id: 9, label: 'SGPT/ALT'},
                        { id: 17, label: 'Total Bilirubin'},
                        { id: 18, label: 'Total Protein'},
                        { id: 10, label: 'Triglycerides'}
                    ]
                }
            case 9:
                return {
                    id: 9,
                    name: 'Advanced Check',
                    price: 2025,
                    discount: '20% OFF',
                    discounted_price: 2025 * 0.8,
                    start_date: '01-01-2020',
                    end_date: '12-01-2020',
                    status: 'ACTIVE',
                    description:
                    `Dive deeper into your health markers with this group of tests. With this group of tests you can have a peek
                    of your current blood level, sugar level, kidney health, liver health, pancreas health, and electrolyte level.`,
                    tests: [
                        { id: 11, label: 'Alkaline Phospatas/ALP'},
                        { id: 16, label: 'Amylase'},
                        { id: 1, label: 'Blood Urea Nitrogen'},
                        { id: 2, label: 'Blood Uric Acid'},
                        { id: 19, label: 'Calcium (Ca)'},
                        { id: 3, label: 'Cholesterol'},
                        { id: 12, label: 'Complete Blood Count'},
                        { id: 13, label: 'Complete Urinalysis'},
                        { id: 4, label: 'Creatinine'},
                        { id: 5, label: 'Fasting Blood Sugar'},
                        { id: 6, label: 'High Density Lipoprotein'},
                        { id: 7, label: 'Lipid Profile'},
                        { id: 14, label: 'Potassium (K)'},
                        { id: 8, label: 'SGOT/AST'},
                        { id: 9, label: 'SGPT/ALT'},
                        { id: 15, label: 'Sodium'},
                        { id: 17, label: 'Total Bilirubin'},
                        { id: 18, label: 'Total Protein'},
                        { id: 10, label: 'Triglycerides'}
                    ]
                }
            case 10:
                return {
                    id: 10,
                    name: 'Diabetic Panel Check',
                    price: 1790,
                    discount: '20% OFF',
                    discounted_price: 1790 * 0.8,
                    start_date: '01-01-2020',
                    end_date: '12-01-2020',
                    status: 'ACTIVE',
                    description:
                    `
                        A group of tests that would give you a background of your risk of developing diabetes as it gives you an idea of your
                        blood sugar level not just today but for the past 3 months and current status of your kidney and liver health and also your
                        current electrolyte level. 
                    `,
                    tests: [
                        { id: 1, label: 'Blood Urea Nitrogen'},
                        { id: 2, label: 'Blood Uric Acid'},
                        { id: 3, label: 'Cholesterol'},
                        { id: 12, label: 'Complete Blood Count'},
                        { id: 13, label: 'Complete Urinalysis'},
                        { id: 4, label: 'Creatinine'},
                        { id: 5, label: 'Fasting Blood Sugar'},
                        { id: 20, label: 'HBA1c'},
                        { id: 6, label: 'High Density Lipoprotein'},
                        { id: 7, label: 'Lipid Profile'},
                        { id: 14, label: 'Potassium (K)'},
                        { id: 9, label: 'SGPT/ALT'},
                        { id: 15, label: 'Sodium'},
                        { id: 10, label: 'Triglycerides'}
                    ]
                }
            default: return {}
        }
    }
}

export default corporatePackagesService;