import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, Grid, Typography, Avatar, Tooltip, IconButton } from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import AcUnitIcon from '@material-ui/icons/AcUnit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAsterisk } from '@fortawesome/free-solid-svg-icons'


const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: 2.5,
    width: '100%'
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    color: 'white',
    fontWeight: 700
  },
  avatar: {
    backgroundColor: theme.palette.error.main,
  },
  difference: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  differenceIcon: {
    color: theme.palette.error.dark
  },
  differenceValue: {
    color: theme.palette.error.dark
  },
  cardContent: {
    padding: 0
  },
  container: {
    height: '80%'
  },
  iconContainer: {
    backgroundColor: '#78BDBA',
    borderBottomLeftRadius: 10,
    borderTopLeftRadius: 10,
    textAlign: 'left',
    color: 'white',
  },
  macroContainer: {
    backgroundColor: '#97D2D3',
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    textAlign: 'center',
    color: 'white'
  },
  icon: {
    color: 'white',
    height: 40,
    width: 40
  },
  buttonEl: {
    display: 'inline-block',
    color: '#000',
    boxShadow: '0px 15px 20px rgba(0, 0, 0, 0.1)',
    transition: 'all 0.3s ease 0s',
    outline: 'none',
    cursor: 'pointer',
    verticalAlign: 'middle',
  },
  activeBg: {
    // borderBottomLeftRadius: 10,
    // borderTopLeftRadius: 10,
    // borderTopRightRadius: 10,
    // borderBottomRightRadius: 10,
    // background: `radial-gradient(circle, rgba(105,171,189,1) 0%, rgba(139,174,246,1) 48%)`
    background: `radial-gradient(circle, rgba(140,194,194,1) 4%, rgba(100,148,146,0.9640231092436975) 98%)`
  },
  activeBg2: {
    // background: `radial-gradient(circle, rgba(105,171,189,1) 0%, rgba(139,174,246,1) 95%)`
    background: `radial-gradient(circle, rgba(140,194,194,1) 4%, rgba(93,138,136,0.9640231092436975) 98%)`
  }
}));

const HealthCheckerCard = props => {
  const { className, count, label,  ...rest } = props;

  const [ state, setState ] = useState({
    hovering: false,
  });

  const classes = useStyles();

  const handleHover = event => {
    setState({
      ...state,
      hovering: true
    })
  }

  const handleHoverLeave = event => {
    setState({
      ...state,
      hovering: false
    })
  }


  return (
    <div className={clsx(classes.root, classes.buttonEl)} onClick={props.clickEvent} onMouseOver={handleHover} onMouseLeave={handleHoverLeave}>
       <Grid direction="row" container className={clsx({
        [classes.container]: true, 
        [classes.buttonEl]: true,
        [classes.activeBg]: state.hovering
      })}>
         {/* <Paper className={clsx(classes.paper, classes.container)} elevation={3}> */}
           <Grid item container direction="row" className={classes.container} spacing={3}>
             <Grid item container xs={4} className={clsx({
              [classes.iconContainer]: true,
              [classes.activeBg2]: state.hovering
            })} style={{ paddingLeft: 18, paddingTop: 15}}>
               {/* <AcUnitIcon className={classes.icon}/> */}
               <Tooltip title="View Details">
                <IconButton onClick={props.clickEvent}>
                  <FontAwesomeIcon icon={faAsterisk} size="lg" style={{ color: 'white' }} />
                </IconButton>
              </Tooltip>
             </Grid>
             <Grid item container direction="column" xs={8} className={clsx({
              [classes.macroContainer]: true,
              [classes.activeBg]: state.hovering
            })}>
               <Typography variant="h4">
                 { count }
               </Typography>
               <Typography
                   className={classes.title}
                   color="textSecondary"
                   variant="caption"
                   >
                     { label ? label : 'Total Health Checkers'}
                   </Typography>
             </Grid>
           </Grid>
         {/* </Paper> */}
       </Grid>
     </div>
   );
};

HealthCheckerCard.propTypes = {
  className: PropTypes.string
};

export default HealthCheckerCard;
