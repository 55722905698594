import React, {useContext, useState} from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {AppBar, Grid, Toolbar, Badge, Hidden, IconButton, Avatar, Typography} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import InputIcon from '@material-ui/icons/Input';
import zennya_logo from './../assets/images/zennya-icon-text.png';
import authenticationService, { getLabelFromRoles } from './../services/authentication.service';
import { useHistory } from 'react-router-dom';
import { UserContext } from './../context/UserContext';
import {
  Person as PersonIcon
} from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none'
  },
  flexGrow: {
    flexGrow: 1
  },
  signOutButton: {
    marginLeft: theme.spacing(1)
  },
  logo: {
    width: '30%',
  },
  textLeft: {
    textAlign: 'left'
  }
}));

const Topbar = props => {
  const { className, onSidebarOpen, ...rest } = props;
  const history = useHistory();
  const classes = useStyles();

  const logoutUser = event => {
    authenticationService.logout();
    history.push('/login');
  }

  return (
    <UserContext.Consumer>
      {
        value => (
          <React.Fragment>
            <div className={classes.flexGrow} />
            <Hidden mdDown>
              <Grid container direction="row" spacing={1} alignItems="flex-end" justify="flex-end" style={{ color: 'white'}}>
                <Grid item align="right">
                  <Avatar
                    alt="Person"
                    className={classes.avatar}
                    src={value.user.admin.profile_pic_url}
                  >
                  <PersonIcon />
                </Avatar>
                </Grid>
                <Grid item align="left">
                  <Typography variant="caption" style={{ fontWeight: "bold" }}>
                    {value.user.admin.first_name} {value.user.admin.last_name}
                  </Typography> <br />
                  <Typography variant="caption" style={{ textTransform: "capitalize", fontWeight: 500 }}>
                    { getLabelFromRoles(value.user.admin.roles) }
                  </Typography>
                </Grid>
              </Grid>
              {/* <span className={classes.textLeft} style={{ marginLeft: "1%", color: 'white' }}>
                <Typography variant="caption" style={{ fontWeight: "bold" }}>
                  {user.admin.first_name } { user.admin.last_name}
                </Typography>
                <Typography variant="caption">
                  <span style={{ textTransform: "capitalize", fontWeight: 500 }}>
                    {user.admin.type }
                  </span>
                </Typography>
              </span> */}
            </Hidden>
            <div className={classes.flexGrow} />
            <Hidden mdDown>
              <IconButton
                onClick={logoutUser}
                className={classes.signOutButton}
                style={{ color: 'white' }}
              >
                <InputIcon />
              </IconButton>
            </Hidden>
            {/* <Hidden lgUp>
              <IconButton
                color="inherit"
                onClick={onSidebarOpen}
              >
                <MenuIcon />
              </IconButton>
            </Hidden> */}
        </React.Fragment>
        )
      }
    </UserContext.Consumer>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func
};

export default Topbar;
