import { matchSorter } from "match-sorter";

async function getRegions() {
  const regions = require("./philippine-addresses-main/region.json");
  return regions;
}

async function getProvince(regionCode) {
  if (!!regionCode) {
    return import("./philippine-addresses-main/province.json").then(
      (provinces) => {
        const result = provinces.default.filter(
          (p) => p.region_code === regionCode
        );
        return result;
      }
    );
  }
  return [];
}

async function getCities(provinceCode) {
  if (!!provinceCode) {
    return import("./philippine-addresses-main/city.json").then((cities) => {
      const result = cities.default.filter(
        (p) => p.province_code === provinceCode
      );
      return result;
    });
  }
  return [];
}

async function getBarangays(cityCode) {
  if (!!cityCode) {
    return import("./philippine-addresses-main/barangay.json").then(
      (barangays) => {
        const result = barangays.default.filter(
          (p) => p.city_code === cityCode
        );
        return result;
      }
    );
  }
  return [];
}

async function searchAddress(search) {
  const { default: barangays } = await import(
    "./philippine-addresses-main/barangay.json"
  );
  const { default: cities } = await import(
    "./philippine-addresses-main/city.json"
  );
  const { default: regions } = await import(
    "./philippine-addresses-main/region.json"
  );
  const { default: provinces } = await import(
    "./philippine-addresses-main/province.json"
  );

  const words = search.split(" ");

  const matchBarangay = words
    .map((word, idx) => {
      const match = matchSorter(barangays, word, { keys: ["brgy_name"] });
      return match;
    })
    .flat()
    .map((e) => {
      return {
        ...e,
        city_name: cities.find((c) => c.city_code === e.city_code).city_name,
        province_name: provinces.find(
          (c) => c.province_code === e.province_code
        ).province_name,
        region_name: regions.find((c) => c.region_code === e.region_code)
          .region_name,
        label: e.brgy_name,
      };
    });
  const matchCities = words
    .map((word, idx) => matchSorter(cities, word, { keys: ["city_name"] }))
    .flat()
    .map((e) => {
      return {
        ...e,
        province_name: provinces.find(
          (c) => c.province_code === e.province_code
        ).province_name,
        region_name: regions.find((c) => c.region_code === e.region_desc)
          .region_name,
        label: e.city_name,
      };
    });
  const matchProvinces = words
    .map((word, idx) =>
      matchSorter(provinces, word, { keys: ["province_name"] })
    )
    .flat()
    .map((e) => {
      return {
        ...e,
        region_name: regions.find((c) => c.region_code === e.region_code)
          .region_name,
        label: e.province_name,
      };
    });
  const matchRegions = words
    .map((word, idx) => matchSorter(regions, word, { keys: ["region_name"] }))
    .flat()
    .map((e) => {
      return { ...e, label: e.region_name };
    });

  const mergedResults = [
    ...matchRegions,
    ...matchProvinces,
    ...matchCities,
    ...matchBarangay,
  ];

  const matchsort = words.reduceRight((items, word) => {
    const match = matchSorter(items, word, {
      keys: ["label", "region_name", "province_name", "city_name", "brgy_name"],
    });
    return match;
  }, mergedResults);

  const mappedItems = matchsort.map((item) => {
    const label = [
      item?.brgy_name ? "Brgy " + item?.brgy_name : null,
      item.city_name,
    ]
      .filter((e) => e != null)
      .join(', ');
    return {
      label: label,
      region: item.region_name,
      province: item.province_name,
      city: item.city_name,
      barangay: item?.brgy_name,
    };
  });
  return mappedItems;
}

async function mapRegionNameToCode(regionName) {
  const { default: regions } = await import(
    "./philippine-addresses-main/region.json"
  );
  const result = regions.find(r=>r.region_name === regionName)
  return result
}

export { getRegions, getProvince, getCities, getBarangays, searchAddress, mapRegionNameToCode };
