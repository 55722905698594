import React, { useState, useEffect } from 'react';
import {
    Grid, Typography, 
	FormControl,
    TextField
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import EventAgenda from './eventsAgenda';
import { Alert, Autocomplete } from '@material-ui/lab';
import corporateEventsService from '../services/corporate-events.service';
import isEmpty from 'lodash.isempty';
import LocationInfoBox from "./location/info";
import { NumberParam, JsonParam, StringParam } from 'serialize-query-params';
import { useQueryParam } from 'use-query-params';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4),
        flexGrow: 1,
        maxWidth: '1440px'
    },
    officeInfoTitle: {
        padding: 10
    },
    officeInfoSection: {
        padding: 10
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    textLeft: {
        textAlign: 'left'
    },
    marginSpacer: {
        marginRight: 10
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    modalContent: {
        backgroundColor: theme.palette.background.paper,
        border: 0,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(3),
        width: '45%',
    },
    linkColor: {
        color: theme.palette.link.main
    },
    btnTextSize: {
        fontSize: '1.25rem'
    },
    rectangularChip: {
        padding: 5,
        borderRadius: 5,
        fontWeight: 700,
        fontSize: '0.75rem'
    }
}));

const EventCalendar = (props) => {
    const classes = useStyles();
    const [locations, setLocations] = useState([]);
    const [locationId, setLocationId]= useQueryParam("location", JsonParam);
    const [location, setLocation] = useState();

    useEffect(() => {
		async function loadEvents() {
			const response = await corporateEventsService.getEventLocations(
				props.company
			);
            // console.log("🚀 ~ file: events.js ~ line 93 ~ loadEvents ~ response", response)
			setLocations(response.list)
		}
		loadEvents();
	}, [])
    useEffect(() => {
        const findLocation = locations.find(loc=>loc.id === locationId)
        setLocation(findLocation)
    }, [locations, locationId]);

    return (
        <div className={classes.root}>
            <Grid container spacing={3}>
                <Grid item xs={6} container justify="flex-start" alignItems="flex-start">
                    <LocationInfoBox location={locationId}/>
                </Grid>
                <Grid item xs={6} container justify="flex-end" alignItems="flex-start">
                    <FormControl>
                        <Autocomplete
                            options={locations}
							getOptionLabel={item=>item.label}
							onChange={(evt, val)=> {
                                // console.log("🚀 ~ file: calendar.js ~ line 99 ~ EventCalendar ~ evt, val", evt, val)
								setLocation(val)
                                setLocationId(val.id, 'replaceIn')
							}}
                            renderInput={(iParams) => (
								<TextField {...iParams} label='Search locations:'
									variant="outlined"
									fullWidth
									style={{ minWidth: 300 }}
								/>
							)}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    {/* {!isEmpty(location)?( */}
                        <EventAgenda {...props} location={location}/>
                    {/* ):(<></>)} */}
                </Grid>
            </Grid>
        </div>
    )
}

export default EventCalendar;