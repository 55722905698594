import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import corporateEventsService from "../../services/corporate-events.service";
import { Metrics, PieMetric } from "./metrics";

const EventMetrics = ({ eventId, consolidatedCohortData }) => {
  const [metricsComorbidities, setMetricsComorbidities] = useState([]);
  const [metricsAllergies, setMetricsAllergies] = useState([]);
  const [metricsLinkages, setMetricsLinkages] = useState([]);
  const [metricsGenders, setMetricsGenders] = useState([]);
  const [metricsAgeBrackets, setMetricsAgeBrackets] = useState([]);
  const [metricsVaccinations, setMetricsVaccinations] = useState([]);

  useEffect(() => {
    async function fetchCounts() {
      const requests = await Promise.allSettled(
        [
          corporateEventsService.fetchMetricsComorbidities,
          corporateEventsService.fetchMetricsAllergies,
          corporateEventsService.fetchMetricsLinkages,
          corporateEventsService.fetchMetricsVaccinations,
          // corporateEventsService.fetchMetricsAgeBrackets,
        ].map(async (fn) => {
          const response = await fn(eventId);
          return response;
        })
      );
      requests.forEach((response) => {
        switch (response?.value?.tag) {
          case "comorbidities":
            setMetricsComorbidities(response?.value?.data ?? []);
            break;
          case "allergies":
            setMetricsAllergies(response?.value?.data ?? []);
            break;
          case "linkages":
            setMetricsLinkages(response?.value?.data ?? []);
            break;
          case "gender":
            setMetricsGenders(response?.value?.data ?? []);
            break;
          case "age_brackets":
            setMetricsAgeBrackets(response?.value?.data ?? []);
            break;
          case "vaccinations":
            setMetricsVaccinations(response?.value?.data ?? [])
            break;
          default:
            break;
        }
      });
    }
    fetchCounts();
  }, [eventId]);
  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <PieMetric
          metric={Metrics.INVITED}
          title="Invite Status"
          consolidatedCohortData={consolidatedCohortData}
          colors={["#7b8bb0", "#C2C2C2"]}
        />
      </Grid>
      <Grid item xs={4}>
        <PieMetric
          metric={Metrics.GENDER}
          title="Gender"
          consolidatedCohortData={consolidatedCohortData}
          colors={["#6A99CA", "#EA857D", "#EEBE6A"]}
        />
      </Grid>
      <Grid item xs={4}>
        <PieMetric
          metric={Metrics.AGE}
          title="Worker Age Range"
          consolidatedCohortData={consolidatedCohortData}
          colors={["#6A99CA", "#EA857D", "#EEBE6A"]}
        />
      </Grid>
      <Grid item xs={4}>
        <PieMetric
          metric={Metrics.RAWDATA}
          title="Comorbidities"
          consolidatedCohortData={metricsComorbidities}
        />
      </Grid>
      <Grid item xs={4}>
        <PieMetric
          metric={Metrics.RAWDATA}
          title="Allergies"
          consolidatedCohortData={metricsAllergies}
        />
      </Grid>
      <Grid item xs={4}>
        <PieMetric
          metric={Metrics.RAWDATA}
          title="Vaccinations"
          consolidatedCohortData={metricsVaccinations}
        />
      </Grid>
    </Grid>
  );
};

export { EventMetrics };
