import { createMuiTheme } from '@material-ui/core/styles';

const ZennyaTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#92B3D5'
        },
        secondary: {
            main: '#85CBC8'
        },
        error: {
            main: '#F59B94'
        },
        link: {
            main: '#3474B7'
        },
        default: {
            main: '#C4C4C4'
        }
    }
})

export default ZennyaTheme;
