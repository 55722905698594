import {
  Avatar,
  Box,
  Chip,
  Link,
  ListItem,
  ListItemText,
  makeStyles,
  NativeSelect,
  TablePagination,
  ThemeProvider,
} from "@material-ui/core";
import {
  DataGrid,
  GridBox,
} from "@zennya/web-component-library/src/components/DataGrid";
import DataGridProvider, {
  actions as gridActions,
  DataGridContext,
} from "@zennya/web-component-library/src/components/DataGrid/DataGridContext";
import isEmpty from "lodash.isempty";
import React, { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import ZennyaTheme from "../providers/ZennyaThemeProvider";
import corporateHealthService from "./../services/corporate-health.service";

const useStyles = makeStyles((theme) => ({
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    marginRight: theme.spacing(1),
  },
  nameRoot: {
    paddingLeft: 0,
    paddingRight: 0,
    cursor: "pointer",
    "&:hover": {
      textDecoration: "none",
    },
  },
  linked: {
    backgroundColor: "#C4EDC6",
    color: "#54A557",
    border: "1px solid #C4EDC6",
    borderRadius: 5,
    fontWeight: 700,
  },
  unlinked: {
    backgroundColor: "#FFDBD8",
    color: "#E8685E",
    border: "1px solid #FFDBD8",
    borderRadius: 5,
    fontWeight: 700,
  },
}));

function ViewCohort(props) {
  const classes = useStyles();
  const history = useHistory();
  const [state, dispatch] = React.useContext(DataGridContext);
  const [columns, setColumns] = React.useState([]);
  const [rows, setRows] = React.useState([]);

  const [locationSelection, setLocationSelection] = useState({
    id: 0,
    label: "All Locations",
  });
  const [departmentSelection, setDepartmentSelection] = useState({
    id: 0,
    label: "All Departments",
  });
  const [isInternal, setIsInternal] = useState(false);
  const [isLinked, setIsLinked] = useState(false);
  const [searchCounter, setSearchCounter] = useState(0);
  const [params, setParams] = useState({
    page_size: process.env.REACT_APP_DEFAULT_PAGE_SIZE,
    page_offset: 0,
  });

  const [queryParams, setQueryParams] = useState({});

  const [search, setSearch] = useState();

  const [pages, setPages] = useState({ count: 0, pages: 0 });
  const [page, setPage] = useState(0);

  const baseColumns = [
    // { id: 'id', label: 'ID' },
    {
      colId: "company_id",
      key: "company_id",
      name: "Company ID",
      align: "flex-start",
      sortable: true,
      frozen: true,
    },
    {
      colId: "last_name",
      key: "last_name",
      name: "Name",
      align: "flex-start",
      sortable: true,
      filter: "text",
      width: 200,
      resizable: true,
      cellRenderer({ column, row }) {
        return row.id ? (
          <Link
            component={ListItem}
            className={classes.nameRoot}
            onClick={() => {
              history.push(`workers/${row.id}`);
            }}
          >
            <Avatar
              src={row.profile_picture_url}
              alt={"user_logo"}
              className={classes.small}
            />
            <ListItemText primary={`${row.last_name}, ${row.first_name}`} />
          </Link>
        ) : (
          <>{`${row.last_name}, ${row.first_name}`}</>
        );
      },
    },
    {
      colId: "role",
      key: "role",
      name: "Role",
      align: "flex-start",
      sortable: true,
    },
    {
      colId: "business_unit",
      key: "business_uint",
      name: "Business Unit",
      align: "flex-start",
      sortable: true,
    },
    {
      colId: "department",
      key: "department",
      name: "Department",
      align: "flex-start",
      sortable: true,
      options: {
        filter: false,
        filterOptions: {
          names: ["All Departments"],
        },
        sort: true,
      },
    },
    {
      colId: "worker_type",
      key: "worker_type",
      name: "Worker Type",
      align: "flex-start",
      filterRenderer: (p) => (
        <NativeSelect
          value={p.value ?? 2}
          onChange={(e) => p.onChange(e.target.value)}
        >
          <option value={2}>All</option>
          <option value={1}>Internal</option>
          <option value={0}>Third-Party</option>
        </NativeSelect>
      ),
    },
    {
      colId: "work_nature",
      key: "work_nature",
      name: "Work Nature",
      align: "flex-start",
      sortable: true,
      options: {
        filter: false,
        filterOptions: {
          names: ["Office", "Field"],
        },
        sort: true,
      },
    },
    {
      colId: "status",
      key: "status",
      name: "Status",
      align: "flex-start",
      sortable: true,
      cellRenderer({ column, row }) {
        return (
          <Chip
            size="small"
            className={
              row.status === "LINKED" ? classes.linked : classes.unlinked
            }
            label={row.status === "LINKED" ? "LINKED" : "NOT LINKED"}
          />
        );
      },
      filterRenderer: (p) => (
        <NativeSelect
          value={p.value ?? 2}
          onChange={(e) => p.onChange(e.target.value)}
        >
          <option value={2}>All</option>
          <option value={1}>Linked</option>
          <option value={0}>Not Linked</option>
        </NativeSelect>
      ),
    },
  ];

  const getCompanyEmployees = useCallback(
    async (param) => {
      console.log("🚀 ~ file: ViewCohort.js ~ line 292 ~ param", param);
      const {
        locationId,
        departmentId,
        internal,
        status,
        query,
        sortParams,
      } = param;
      const requestParams = {
        ...params,
        location_ids: locationId,
        departments: departmentId,
        internal,
        linked: status,
        query,
        sort_by: sortParams?.column,
        sort_order: sortParams?.value,
      };

      const response = await corporateHealthService.getPartnerEmployeesAndReferences(
        props.company,
        requestParams
      );
      const result = response.list.map((item) => {
        const {
          id,
          employee_id: company_id,
          role,
          department,
          business_unit,
          work_type: work_nature,
          worker_type: third_party_employer,
          status,
        } = item;

        return {
          ...item,
          id,
          company_id,
          role,
          department,
          business_unit,
          work_nature,
          worker_type: third_party_employer ? "Third-Party" : "Internal",
          status,
        };
      });

      setRows(result);
      setPages({
        count: response.count,
        pages: Math.ceil(response.count / params.page_size),
      });
    },
    [params, props.company]
  );

  React.useEffect(() => {
    async function loadCompanyEmployees() {
      console.log(
        "🚀 ~ file: ViewCohort.js ~ line 315 ~ loadCompanyEmployees ~ queryParams",
        queryParams
      );
      await getCompanyEmployees({
        ...queryParams,
        sortParams: {
          column: state.sortColumn,
          value: state.sortDirection,
        },
      });
    }
    dispatch({
      payload: { columns: baseColumns },
      type: gridActions.LOAD_COLUMNS,
    });
    loadCompanyEmployees();
  }, [params, state.sortColumn, state.sortDirection, queryParams]);

  React.useEffect(() => {
    dispatch({
      payload: {
        rows,
      },
      type: gridActions.LOAD_ROWS,
    });
  }, [rows]);

  React.useEffect(() => {
    if (isEmpty(state.filterColumn)) {
      console.log("ue filter init state");
      return;
    }
    const searchKeys = Object.keys(state.filterColumn);

    let queryFilter = {};

    searchKeys.map((searchKey) => {
      switch (searchKey) {
        case "status":
          queryFilter.status =
            state.filterColumn[searchKey] === "2"
              ? null
              : !!parseInt(state.filterColumn[searchKey]);
          break;
        case "worker_type":
          queryFilter.internal =
            state.filterColumn[searchKey] === "2"
              ? null
              : !!parseInt(state.filterColumn[searchKey]);
          break;
        case "last_name":
          queryFilter.query = state.filterColumn[searchKey];
          break;
        default:
          break;
      }
    });

    setQueryParams(queryFilter);
  }, [state.filterColumn]);

  const onChangePage = useCallback((evt, newPage) => {
    setParams({
      ...params,
      page_offset: (newPage + 1) * params.page_size - params.page_size,
    });
    setPage(newPage);
  }, []);

  const onChangeRowsPerPage = useCallback((evt) => {
    setParams({
      ...params,
      page_size: parseInt(evt.target.value, 10),
    });
  }, []);

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "70vh" }}>
      <DataGrid
        style={{ height: '100%' }}
        containerStyle={{
            flex: '1 1 0',
        }}
        showSelector
        filterable
        columns={columns}
        rows={rows}
        gridProps={{
          rowHeight: 45,
        }}
      />
      <TablePagination
        component="div"
        count={pages.count}
        page={page}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        rowsPerPage={params.page_size}
      />
    </div>
  );
}

export default function WrapViewCohort(props) {
  return (
    <ThemeProvider theme={ZennyaTheme}>
      <DataGridProvider>
        <ViewCohort {...props} />
      </DataGridProvider>
    </ThemeProvider>
  );
}
