import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { createStyles, Theme } from '@material-ui/core/styles';
import { Dialog, DialogContentText, Typography, Grid, TextField, InputBase, NativeSelect, useTheme, FormControl, InputLabel, Select } from '@material-ui/core';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import corporateHealthService from './../services/corporate-health.service';
import ErrorMessage from './../components/errorMessage';
import SuccessMessage from './../components/successMessage';

const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      width: 250,
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #ced4da',
      fontSize: 16,
      padding: '10px 26px 10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        borderRadius: 4,
        borderColor: '#85CBC8',
        // boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
    },
  }),
)(InputBase);


const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    fullLength: {
        width: '100%'
    }
  });

  const useStyles = makeStyles(theme => ({
    root: {
      padding: theme.spacing(4),
      flexGrow: 1,
      color: '#000'
    },
    officeInfoTitle: {
      padding: 10,
      color: '#000'
    },
    officeInfoSection: {
      padding: 10,
      color: '#000'
    },
    formControl: {
      marginRight: theme.spacing(1),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      minWidth: 300,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    fullHeight: {
      height: '100vh',
      width: '100vw'
    },
    headerPadding: {
        padding: '30px 20px 20px 20px',
        fontWeight: 500
    },
    noPaddingTop: {
        paddingTop: '0 !important'
    }
  }));
  

  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });
  
  const DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiDialogContent);
  
  

const EditCompany = (props) => {
    const classes = useStyles();
    const [ industries, setIndustries ] = useState([]);
    const { open, handleClose, company } = props;
    const [ loading, setLoading ] = useState(false);
    const [ error, setError ] = useState('');
    const [ message, setMessage ] = useState('');
    const [ openError, setOpenError ] = useState(false);
    const [ openSuccess, setOpenSuccess ] = useState(false);
    const [ state, setState ] = useState({
        brandName: props.company.brandName || '',
        description: props.company.description || '',
        industry: props.company.industry ? props.company.industry.id : '',
        administrator: {
            firstName: '',
            middleName: '',
            lastName: '',
            emailAddress: '',
            mobileNumber: ''
        }
    });  

    useEffect(() => {
        listIndustries();
    }, []);
    
    function listIndustries() {
        setLoading(true);
        corporateHealthService.listIndustries().then(
            response => {
                setIndustries(response.list);
                setLoading(false);
            }, error => {
                setError(error.response.data);
                setOpenError(true);
                setLoading(false);
            }
        );
    }


    const handleChange = name => event => {
        setState({
            ...state,
            [name]: event.target.value
        });
    }

    const handleChangeAdmin = name => event => {
        setState({
            ...state,
            administrator: {
                [name]: event.target.value
            }
        });
    }

    const handleUpdateCompany = event => {
        var params = {
          corporatePartner: company.id,
          label: company.label,
          brandName: state.brandName,
          description: state.description,
          email: company.email,
          mainRepresentative: company.mainRepresentative,
          phoneNumber: company.phoneNumber,
          industry: state.industry,
        }
  
        setLoading(true);
        corporateHealthService.updatePartnerCompany(params, company.id).then(
          response => {
            setMessage('Successfully updated company details.');
            setOpenSuccess(true);
            setLoading(false);
            const timed = setTimeout(() => {
              handleClose();
            }, 500);
            return () => clearTimeout(timed);
            // handleClose();
          }, error => {
            if(error.response.status === 403 ) {
                setError({
                    message: 'You have no permission to update company details.'
                });
                setOpenError(true);
            } else {
                setError(error.response.data);
                setOpenError(true);
            }
            setLoading(false);
          }
        );
      }

    return (
        <div>
            <Dialog maxWidth={'sm'} fullWidth={true} open={open} onClose={handleClose} aria-labelledby="edit-company-title">
                <DialogTitle id="edit-company-title" onClose={handleClose}>
                    Edit Main Office Details <br />
                    <Typography variant="caption">
                        Details here are displayed in zennya App
                    </Typography>
                </DialogTitle>
                <DialogContent dividers>
                    {error ?  <ErrorMessage error={error} open={openError} setOpen={setOpenError} /> : ''}
                    { message  ? <SuccessMessage open={openSuccess} setOpen={setOpenSuccess} message={message} /> : undefined}
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                required
                                variant="outlined"
                                id="brandName"
                                label="Brand Name"
                                fullWidth
                                size="small"
                                value={state.brandName}
                                onChange={handleChange('brandName')}
                                />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                variant="outlined"
                                id="description"
                                label="Description"
                                fullWidth
                                multiline={true}
                                size="small"
                                rows={3}
                                rowsMax={3}
                                value={state.description}
                                onChange={handleChange('description')}
                                />
                        </Grid>
                        <Grid item container sm={12} xs={12} className={classes.noPaddingTop}>
                            <Grid item xs={4} sm={4}>
                                <Typography variant="body1" className={classes.headerPadding}>
                                    Industry
                                </Typography>
                            </Grid>
                            <Grid item xs={8} sm={8}>
                              <FormControl className={classes.margin}>
                                  <InputLabel htmlFor="demo-customized-select-native">Industry</InputLabel>
                                  <NativeSelect
                                  id="demo-customized-select-native"
                                  value={state.industry}
                                  onChange={handleChange}
                                  input={<BootstrapInput />}
                                  >
                                    <option value="" aria-label="None" />
                                      ({
                                      industries && industries.length >= 1 ? industries.map((option, index) => {
                                          return (
                                              option ? 
                                              <option value={option.id} aria-label={option.label} key={index}>
                                              { option.label }
                                              </option>
                                          : undefined)
                                      })
                                      : undefined
                                      })
                                  </NativeSelect>
                              </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* <Typography variant="h6" gutterBottom>
                        Administrator
                    </Typography> */}
                    <Grid container spacing={4}>
                        {/* <Grid item xs={12} sm={4}>
                            <TextField
                                required
                                size="small"
                                variant="outlined"
                                id="adminFirstName"
                                label="First Name"
                                fullWidth
                                value={state.administrator.firstName || ''}
                                onChange={handleChangeAdmin('firstName')}
                                />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                required
                                variant="outlined"
                                size="small"
                                id="adminMiddleName"
                                label="Middle Name"
                                fullWidth
                                value={state.administrator.middleName || ''}
                                onChange={handleChangeAdmin('middleName')}
                                />  
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                required
                                size="small"
                                variant="outlined"
                                id="adminLastName"
                                label="Last Name"
                                fullWidth
                                value={state.administrator.lastName || ''}
                                onChange={handleChangeAdmin('lastName')}
                                />  
                        </Grid> */}
                    </Grid>
                    <Grid container spacing={4}>
                        {/* <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                size="small"
                                variant="outlined"
                                id="adminEmail"
                                label="Email Address"
                                fullWidth
                                value={state.administrator.email || ''}
                                onChange={handleChangeAdmin('email')}
                            />          
                        </Grid> */}
                        {/* <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                size="small"
                                variant="outlined"
                                id="adminMobileNumber"
                                label="Mobile Number"
                                fullWidth
                                value={state.administrator.mobileNumber || ''}
                                onChange={handleChangeAdmin('mobileNumber')}
                            />  
                        </Grid> */}
                        <Grid item xs={12} sm={12}>
                            <Button onClick={handleUpdateCompany} disabled={loading} color="primary" variant="contained">
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default EditCompany;