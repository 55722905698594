import React, { useState } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import authenticationService from './../services/authentication.service';
import ErrorMessage from '../components/errorMessage';
import {
  Link as RouterLink,
  useHistory
} from 'react-router-dom';
import {
  Grid,
  Link,
  Typography,
  Container,
  TextField,
  Button,
  LinearProgress
} from '@material-ui/core';
import zennya_health_logo from './../assets/images/zennya_health_horizontal.png';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    maxWidth: '85%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  multilineColor: {
      color: '#45C0BE'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  content: {
    flexGrow: 1,
    width: '100%'
  },
  marginTop: {
    [theme.breakpoints.up('md')]: {
      marginTop: '8em'
    }
  },
  textRight: {
    textAlign: 'right'
  }
}));

const Login = (props) => {
  const classes = useStyles();
  const [ email, setEmail ] = useState('');
  const [ password, setPassword ] = useState('');
  const [ error, setError ] = useState('');
  const [open, setOpen] = React.useState(true);
  const history = useHistory();
  const [ loading, setLoading ] = useState(false);
  const [ pathways, setPathways ] = useState([]);
  const [ loadedPaths, setloadedPaths ] = useState(false);
  const [ state, setState ] = useState({
    selectedPathway: ''
  })

  const validateForm = ( ) => {
      return email.length > 0 && password.length > 0;
  }

  const handleChange = name => event => {
    setState({
      ...state,
      [name]: event.target.value
  });
  }

  const handleSubmit = event => {
      event.preventDefault();
      setLoading(true);
      authenticationService.login(email)
      .then(
        response => {
          setLoading(false);
          // history.push('/');
          setPathways(response.pathways);

          const staffAcc = response.pathways.find(function(el) {
              return el.type == 'Staff'
          });
          if(staffAcc) {
            setState({
              ...state,
              selectedPathway: staffAcc
            });
            setloadedPaths(true);

          } else {
            setError({
              message: 'You do not have a corporate staff account.'
            });
            setOpen(true);
          }

        }, error => {

          setLoading(false);
          if(error.response.status === 401) {
            setError({
              message: 'You are unauthorized.'
            });
            setOpen(true);
          }

          if(error.response.status === 403) {
            // return history.push('/forbidden');
            setError({
              message: 'You are forbidden to access.'
            });
            setOpen(true);
          }

          if(error.response.status === 404) {
            setError({
              message: 'Invalid or non-existing user.'
            });
            setOpen(true);
          }
        })
  }

  const handleLogin = event => {
    var params = {
      email: email,
      password: password,
      // device_id: 'web',
      // notification_id: 'notifid'
    }

    event.preventDefault();
    setLoading(true);
    authenticationService.corporateLogin(params)
    .then(
      response => {
        setLoading(false);
        history.push('/locations');
      }, error => {
        setLoading(false);
        // setError(error.response.data);
        setOpen(true);

        if (error.response) {
          if(error.response.status === 401) {
            setError({
              message: 'You are unauthorized.'
            });
            setOpen(true);
          }

          if(error.response.status === 404) {
            setError({
              message: 'Invalid / Non-existing user'
            });
            setOpen(true);
          }

          if(error.response.status === 403) {
            setError({
              message: 'You are forbidden.'
            })
            setOpen(true);
          }
        }
      })
  }

  const emailChange = (event) => {
    event.preventDefault();
    setEmail(event.target.value);
  }

  const passwordChange = (event) => {
    event.preventDefault();
    setPassword(event.target.value);
  }

  return (
    <div className={classes.content}>
      { loading ? <LinearProgress variant="query"/> : undefined }
      <Container component="main" className={classes.marginTop}>
        {error ?  <ErrorMessage error={error} open={open} setOpen={setOpen} /> : ''}
        <CssBaseline />
        <div className={classes.paper}>
            <img
            alt="Company Logo"
            className={classes.avatar}
            src={zennya_health_logo}
            to="/login"
          />
          <Typography variant="h6">
            Login to zennya Corporate Health
          </Typography>
          <form className={classes.form} noValidate>
              <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  value={email}
                  autoFocus
                  onChange={emailChange}
              />
              <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  value={password}
                  onChange={passwordChange}
              />
              <Grid container>
                <Grid
                    item
                    xs={12}
                    className={classes.textRight}
                >
                  <Link
                      component={RouterLink}
                      to={"/forgot-password"}
                      style={{ color: '#C4C4C4' }}
                  >
                    Forgot password?
                  </Link>
                </Grid>
              </Grid>
              {/* { loadedPaths ? <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  value={password}
                  onChange={passwordChange}
              /> : undefined } */}
            {/* { !loadedPaths ? <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={loading}
              className={classes.submit}
              onClick={handleSubmit}
            >
              Login
            </Button> : undefined } */}
            {/* { loadedPaths ? <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={loading}
              className={classes.submit}
              onClick={handleLogin}
            >
              Login
            </Button> : undefined } */}
            <Button
              type="submit"
              fullWidth
              style={{ color: 'white' }}
              variant="contained"
              color="primary"
              disabled={loading}
              className={classes.submit}
              onClick={handleLogin}
            >
              Login
            </Button>
          </form>
        </div>
      </Container>
    </div>
  );
}

export default Login;
