import React, { useState, useEffect } from 'react';
import Avatar from '@material-ui/core/Avatar';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Login from './login';
import ZennyaTheme from './../providers/ZennyaThemeProvider';
import { ThemeProvider } from '@material-ui/styles';
import laptop_with_app from './../assets/svg/laptop_with_app.svg';
import health_pass from './../assets/svg/health_pass.svg';
import quarantine_management from './../assets/svg/quarantine_management_preview.svg';
import telemed from './../assets/svg/telemed.svg';
import corporate_events from './../assets/svg/corporate_events.svg';
import './dots.css';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundRepeat: 'no-repeat',
    backgroundColor: '#87B0D9',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    textAlign: 'center',
    minHeight: '20em',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    marginTop: '7em',
    maxHeight: '20em',
    // width: '100%',
    height: 'auto',
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      maxWidth: '17em'
    },
    // backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  page1: {
      color: 'white',
      margin: theme.spacing(2)
  },
  hidden: {
    display: 'none'
  },
  show: {
    display: 'flex',
    '-webkit-animation-name': 'fade',
    '-webkit-animation-duration': '1.5s',
    'animation-name': 'fade',
    'animation-duration': '1.5s'
  },
  smallerAvatar: {
    [theme.breakpoints.down('sm')]: {
      width: '40%',
      height: '50%'
    }
  },
  footer: {
    // clear: 'both',
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      marginTop: '40vh'
    }
    // marginTop: '0vh'
    // position: 'fixed',
    // left: 0,
    // bottom: 0,
    // width: '100%'
    // marginTop: 'calc(5% + 20px)',
    // bottom: 20,
    // position: 'fixed'
  }
}));

export default function LoginLanding() {
  const classes = useStyles();
  const [ page, setPage ] = useState(2);
  const [ showLinkBtns, setShowLinkBtns ] = useState(false);

  const navigatePage = newPage => event => {
    setPage(newPage);
  }

  useEffect(() => {
    if(page >= 1 && !showLinkBtns) {
      setShowLinkBtns(true);
    }

    if(page >= 1) {
      const interval = setInterval(() => {
        if(page >= 1 && page < 5) {
          setPage(page + 1);
        } else if(page === 5) {
          setPage(1)
        }
      }, 7000);
      return () => clearInterval(interval)
    }

  }, [page, showLinkBtns])

  return (
    <ThemeProvider theme={ZennyaTheme}>
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={12} sm={false} md={6} component={Paper} elevation={4} square style={{ height: '100vh'}}>
        <Login />
      </Grid>
      <Grid container item xs={12} sm={false} md={6} justify="center" alignItems="center" style={{ height: '100vh'}} className={classes.image}>
            { laptop_with_app ? <Grid container className={clsx({
              [classes.show] : page === 1,
              [classes.hidden] : page !== 1
            })} direction="column" alignItems="center" justify="center" spacing={0}>
                <img
                    onLoad={navigatePage(1)}
                    alt="Laptop with corp health app"
                    className={classes.avatar}
                    src={laptop_with_app}
                    to="/login"
                />
                <Typography variant="h6" style={{ fontWeight: 700}} className={classes.page1}>
                    Intuitive Workplace Command Health Center
                </Typography>
                <Typography variant="body1" className={classes.page1}>
                    Instantly access, manage, and take action on vital employee health data
                    from one centralized dashboard.
                </Typography>
            </Grid> : undefined }
            { health_pass ? <Grid container className={clsx({
              [classes.show] : page === 2,
              [classes.hidden] : page !== 2
            })} direction="column" alignItems="center" justify="center" spacing={0}>
                <img
                    alt="Health pass preview"
                    className={clsx(classes.avatar, classes.smallerAvatar)}
                    src={health_pass}
                    to="/login"
                />
                <Typography variant="h6" style={{ fontWeight: 700}} className={classes.page1}>
                    Automated Workplace Entry Control
                </Typography>
                <Typography variant="body1" className={classes.page1}>
                   Enforce through workplace entry protocols through the digital health pass,
                   activated by daily COVID symptoms screening and at-gate temperature checks 
                </Typography>
            </Grid> : undefined }
            { quarantine_management ? <Grid container className={clsx({
              [classes.show] : page === 3,
              [classes.hidden] : page !== 3
            })} direction="column" alignItems="center" justify="center" spacing={0}>
                <img
                    alt="Quarantine management preview"
                    className={classes.avatar}
                    src={quarantine_management}
                    to="/login"
                />
                <Typography variant="h6" style={{ fontWeight: 700}} className={classes.page1}>
                    Quarantine Management 
                </Typography>
                <Typography variant="body1" className={classes.page1}>
                    Keep updated with the health status of quarantined workers, tracking 
                    pending quarantine leaves and key medical test results.
                </Typography>
            </Grid> : undefined }
            { telemed ? <Grid className={clsx({
              [classes.show] : page === 4,
              [classes.hidden] : page !== 4
            })} container direction="column" alignItems="center" justify="center" spacing={0}>
                <img
                    alt="Quarantine management preview"
                    className={classes.avatar}
                    src={telemed}
                    to="/login"
                />
                <Typography variant="h6" style={{ fontWeight: 700}} className={classes.page1}>
                  Telemedicine E-Clearance               
                </Typography>
                <Typography variant="body1" className={classes.page1}>
                Opt for a telemedicine consultation to clear quarantined workers for 
                work or verify employee illness.
                </Typography>
            </Grid> : undefined }
           { corporate_events ? <Grid className={clsx({
              [classes.show] : page === 5,
              [classes.hidden] : page !== 5
            })} container direction="column" alignItems="center" justify="center" spacing={0}>
                <img
                    alt="Quarantine management preview"
                    className={classes.avatar}
                    src={corporate_events}
                    to="/login"
                />
                <Typography variant="h6" style={{ fontWeight: 700}} className={classes.page1}>
                    On-site Medical Events               
                </Typography>
                <Typography variant="body1" className={classes.page1}>
                    Book corporate medical events with certified healthcare 
                    providers to professionally manage the medical event with 
                    minimal interruption to ongoing work
                </Typography>
            </Grid> : undefined }
            { showLinkBtns ? <Grid container direction="row" alignItems="center" justify="center" spacing={0} className={classes.footer}>
                <span className={`dot ${page === 1 ? `active`: ``}`} onClick={navigatePage(1)}></span>
                <span className={`dot ${page === 2 ? `active`: ``}`} onClick={navigatePage(2)}></span>
                <span className={`dot ${page === 3 ? `active`: ``}`} onClick={navigatePage(3)}></span>
                <span className={`dot ${page === 4 ? `active`: ``}`} onClick={navigatePage(4)}></span>
                <span className={`dot ${page === 5 ? `active`: ``}`} onClick={navigatePage(5)}></span>
            </Grid> : undefined }
      </Grid>
    </Grid>
    </ThemeProvider>
  );
}