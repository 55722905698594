import { Avatar } from "@material-ui/core";
import {
  Button,
  ButtonGroup,
  Checkbox,
  Divider,
  Grid,
  TextField,
  Typography,
  withStyles,
  Tooltip,
  Chip
} from "@material-ui/core";
import { Cancel } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import isEmpty from "lodash.isempty";
import React from "react";

const styles = (theme) => ({
  cancel: {
    color: theme.palette.error.main,
  },
  cohortContainer: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  wrap: {
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  option: {
    borderBottom: '1px solid #ccc'
  },
  count: {
    backgroundColor: `${theme.palette.common.white} !important`,
  },
});

function CohortAutoComplete({
  cohorts,
  classes,
  label,
  onSelectedCohorts,
  selectedCohorts,
  openCohort,
  frozenCohorts=[],
  ...props
}) {
  return (
    <Autocomplete
      disableCloseOnSelect
      multiple
      options={cohorts}
      getOptionLabel={(option) => option.label}
      renderInput={(iParams) => (
        <TextField
          {...iParams}
          error={isEmpty(selectedCohorts)}
          label={`Cohorts: ${label}`}
          variant="outlined"
          fullWidth
        />
      )}
      getOptionSelected={(option, value) => {
        return option.id === value.id;
      }}
      value={selectedCohorts}
      defaultValue={[]}
      onChange={(evt, value) => {
        onSelectedCohorts(value);
      }}
      disableClearable={!isEmpty(frozenCohorts)}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => {
          return (
            <Chip
              {...getTagProps({ index })}
              key={option.id}
              onClick={() => openCohort(option)}
              classes={{ avatar: classes.count }}
              color="primary"
              label={option.label}
              onDelete={frozenCohorts.includes(option) ? undefined : ()=>{
                getTagProps({ index }).onDelete()
              }}
              avatar={<Avatar>{option.entry_count}</Avatar>}
            />
            
          );
        })
      }
      renderOption={(item, status) => {
        return (
            <Tooltip title={item.description}>
              <Grid className={classes.option}alignItems="center" justify="space-between" container>
                <Grid item xs={1}>
                  <Checkbox checked={status.selected} />
                </Grid>
                <Grid item xs={9}>
                  {item.label}
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="caption">
                    Entries: {item.entry_count}
                  </Typography>
                </Grid>
              </Grid>
            </Tooltip>
          
        );
      }}
      size="small"
      ChipProps={{
        variant: "outlined",
        color: "primary",
      }}
    />
  );
}

export default withStyles(styles)(CohortAutoComplete);
