import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Divider, Grid, List, ListItem, ListItemText, Link,
     ListItemAvatar, Avatar, Typography } from '@material-ui/core';
import clsx from 'clsx';
import {
    useHistory
  } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      maxWidth: 540,
      backgroundColor: '#211f1f',
      color: '#000',
      height: '70vh'
    },
    container: {
        maxHeight: 275,
        overflow: 'auto'
    },
    secondaryListText: {
        color: '#8d918e'
    },
    divider: {
        backgroundColor: 'white'
    },
    pointerHand: {
        cursor: 'pointer'
    }
}));

const CircleStatus = (props) => {
    return (
        <span> 
            <span style={{ 
                marginLeft: 5,
                height: 10,
                width:10,
                backgroundColor: props.color, 
                borderRadius: '50%', 
                display: 'inline-block' }}>
            </span>
        </span>
    )
}
  

const UserReportList = (props) => {
    const classes = useStyles();
    const classes2 = props.classes;
    const { list } = props;
    const history = useHistory();
    
    useEffect(() => {
        console.log(list);
    }, []);

    const getFeverStatus = (temperature) => {
        if(temperature && temperature <= 37.5) {
            return '( No Fever )';
        } else if(temperature && temperature >= 37.6 && temperature <= 39.4) {
            return '( Moderate Fever )';
        } else if (temperature && temperature > 39.4) {
            return'( High Fever )';
        } else {
            return ''
        }
    }

    // const getFeverColor = temperature => {
    //     if(temperature && temperature <= 37.5) {
    //         return '#78BDBA';
    //     } else if(temperature && temperature >= 37.6 && temperature <= 39.4) {
    //         return '#FE974C';
    //     } else if (temperature && temperature > 39.4) {
    //         return'#E8685E';
    //     } else {
    //         return '#FE974C'
    //     }
    // }


    return (
        <Grid container spacing={0} alignItems="center" justify="center">
              <List className={clsx(classes.root, classes.container)}>
                { list.length !== 0 ? list.map((item, index) => {
                    return (
                        item ?
                        <React.Fragment key={index}>
                            <ListItem alignItems="flex-start"
                                className={classes.pointerHand}
                            >
                                <ListItemAvatar>
                                    <Avatar src={item.employee.profile_pic_url} alt={"user_logo"} style={{ width: 40, height: 40, border: 
                                    item.hazard && item.hazard.color ? `4px solid ${item.hazard.color}` : '#FE974C' }} />
                                </ListItemAvatar>
                                <ListItemText
                                    // primary={`${item.employee.name}`}
                                    primary={item.employee.id ? (
                                        <React.Fragment>
                                            <Link
                                                color="primary"
                                                className={classes.linkColor}
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    textDecoration: 'none',
                                                    fontWeight: 700
                                                }}
                                                component={"button"}
                                                target="_blank"
                                                rel="noopener"
                                                onClick={() => {
                                                    history.push(`workers/${item.employee.id}`)
                                                }}>
                                                <Typography variant="body2" style={{ fontWeight: 700}}>
                                                    {item.employee.name}
                                                </Typography>
                                            </Link>
                                    </React.Fragment>)
                                    : ''}
                                    secondary={
                                        <React.Fragment>
                                            <Typography
                                                component="span"
                                                variant="caption"
                                                style={{ color: 'white'}}
                                                className={classes.inline}
                                                color="textPrimary"
                                                >
                                                COVID Screening: <span>
                                                    { item.covid_screen && item.covid_screen.title ? item.covid_screen.title : 'No COVID screening done yet'}
                                                    <CircleStatus color={item.covid_screen && item.covid_screen.color ? item.covid_screen.color: '#FE974C'} />
                                                    </span><br />
                                                Temperature: <span>
                                                    { item.temperature ? `${item.temperature} Celsius` : 'No temperature recorded'} { getFeverStatus(item.temperature)}
                                                    </span> <br />
                                                { item.is_checker && item.is_online ? <span>
                                                    Online Health Checker <br/> </span> : '' }
                                            </Typography>
                                            {/* <Divider className={classes.divider}/> */}
                                            {/* <Typography  variant="caption">
                                                { item.department || '-'}
                                            </Typography> */}
                                        </React.Fragment>
                                    }
                                />
                                </ListItem>
                            <Divider variant="inset" component="li" />
                        </React.Fragment> : undefined
                    )
                }) : <Typography variant="body1" style={{ color: 'white'}}>
                    There are no workers in the list.
                </Typography> }
            </List>
        </Grid>
    )
}

export default UserReportList;