import React from "react"
import {
    Backdrop,
    Button,
    Fade,
    Grid,
    Modal,
    Typography,
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
    List,
    ListItemText,
    ListItem,
    Divider
} from "@material-ui/core";
import clsx from "clsx";
import {
    CheckCircle as CheckCircleIcon,
    Cancel as CancelIcon,
    Warning as WarningIcon,
    ExpandMore as ExpandMoreIcon,
    Info as InfoIcon
} from "@material-ui/icons";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    textLeft: {
        textAlign: 'left'
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh'
    },
    modalContent: {
        backgroundColor: theme.palette.background.paper,
        border: 0,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            width: '50vw'
        },
        [theme.breakpoints.up('md')]: {
            width: '25vw'
        }
    },
    title: {
        [theme.breakpoints.only('xs')]: {
            ...theme.typography.subtitle1,
            fontWeight: 'bold'
        },
        [theme.breakpoints.up('sm')]: {
            ...theme.typography.h5,
            fontWeight: 'bold'
        },
        textAlign: "center"
    },
    textCenter: {
        textAlign: "center"
    },
    fontBold: {
        fontWeight: "bold"
    },
    block: {
        width: '100%'
    }
}));

export default function (props) {

    const classes = useStyles()

    const { modal, setModal, successCount, errors, warnings } = props

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={modal}
            onClose={() =>  {
                setModal(false)
            }}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{timeout: 500}}
        >
            <Fade in={modal}>
                <div className={classes.modalContent}>
                    <Grid container alignItems="center" justify={"flex-start"}>
                        <Grid item xs={12}>
                            <Typography className={classes.title} variant={"body1"}>
                                Your file was uploaded
                            </Typography>
                        </Grid>

                        <Grid item xs={12} style={{ marginTop: "3vh" }}>
                            Results:
                        </Grid>

                        <Grid item container xs={12} style={{ marginTop: "1vh" }} alignItems={"center"} justify={"center"}>
                            <ExpansionPanel square style={{ width: "100%" }} expanded={false}>
                                <ExpansionPanelSummary style={{ cursor: "default" }}>
                                    <Grid item container alignItems={"center"} justify={"center"}>
                                        <Grid item container alignItems={"center"} justify={"center"} sm={6} md={2}>
                                            {
                                                successCount > 0
                                                    ? (<CheckCircleIcon style={{ color: "#4CAF50" }} fontSize={"large"} />)
                                                    : (<InfoIcon style={{ color: "grey" }} fontSize={"large"} />)
                                            }
                                        </Grid>
                                        <Grid item container alignItems={"center"} justify={"flex-start"} sm={6} md={10}>
                                            <Typography className={clsx(classes.fontBold)} variant={"subtitle1"}>
                                                {
                                                    successCount > 0
                                                        ? successCount > 1 ? successCount + " rows were" : "1 row was"
                                                        : "No rows were"
                                                } imported
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </ExpansionPanelSummary>
                            </ExpansionPanel>
                        </Grid>

                        {
                            Array.isArray(errors) && errors.length > 0 && (
                                <Grid item container xs={12} style={{ marginTop: "3vh" }} alignItems={"center"} justify={"center"}>
                                    <ExpansionPanel className={clsx(classes.block)} square>
                                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                            <Grid item container alignItems={"center"} justify={"center"}>
                                                <Grid item container alignItems={"center"} justify={"center"} sm={6} md={2}>
                                                    <CancelIcon color={"error"} fontSize={"large"} />
                                                </Grid>
                                                <Grid item container alignItems={"center"} justify={"flex-start"} sm={6} md={10}>
                                                    <Typography className={clsx(classes.fontBold)} variant={"subtitle1"}>
                                                        {
                                                            errors.length > 1
                                                                ? errors.length + " rows were"
                                                                : errors.length + " row was"
                                                        } skipped
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>
                                            <List className={clsx(classes.block)}>
                                                {
                                                    (errors??[]).map((error, index) => (
                                                        <React.Fragment>
                                                            <ListItem key={`item-${index}`}>
                                                                <ListItemText primary={error} />
                                                            </ListItem>
                                                            <Divider key={`divider-${index}`} className={clsx(classes.block)} />
                                                        </React.Fragment>
                                                    ))
                                                }
                                            </List>
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                </Grid>
                            )
                        }

                        {
                            Array.isArray(warnings) &&  warnings.length > 0 && (
                                <Grid item container xs={12} style={{ marginTop: "3vh" }} alignItems={"center"} justify={"center"}>
                                    <ExpansionPanel className={clsx(classes.block)} square>
                                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                            <Grid item container alignItems={"center"} justify={"center"}>
                                                <Grid item container alignItems={"center"} justify={"center"} sm={6} md={2}>
                                                    <WarningIcon style={{ color: "#FC8C00" }} fontSize={"large"} />
                                                </Grid>
                                                <Grid item alignItems={"center"} justify={"flex-start"} sm={6} md={10}>
                                                    <Typography className={clsx(classes.fontBold)} variant={"subtitle1"}>
                                                        { warnings.length } imported { warnings.length > 1 ? "rows" : "row" } had warnings
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>
                                            <List className={clsx(classes.block)}>
                                                {
                                                    (warnings??[]).map((warning, index) => {
                                                        console.log("📢[CsvImportResponseDialog.js:186]:", warning);
                                                        const message = `Row ${warning?.row}: ${warning?.message?.join()}`
                                                        return (
                                                            <React.Fragment>
                                                                <ListItem key={`item-${index}`}>
                                                                    <ListItemText primary={message} />
                                                                </ListItem>
                                                                <Divider key={`divider-${index}`} className={clsx(classes.block)} />
                                                            </React.Fragment>
                                                        )
                                                    })
                                                }
                                            </List>
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                </Grid>
                            )
                        }

                        <Grid item container xs={12} style={{ marginTop: "3vh" }} alignItems={"center"} justify={"center"}>
                            <Button
                                className={clsx(classes.block, classes.fontBold)}
                                variant="contained"
                                onClick={() => setModal(false)}
                            >
                                Close
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            </Fade>
        </Modal>
    )
}
