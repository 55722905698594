import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import corporatePackagesService from './../services/corporate-packages.service';
import {
    AppBar, Box, Chip, Card, CardContent,
    Grid,
    IconButton, Button, Paper,
    Typography,
    Link,
    Avatar, Tabs, Tab,
    LinearProgress,
    Menu,
    MenuItem
} from "@material-ui/core"
import BackButton from '../components/backButton';
import CheckIcon from '@material-ui/icons/Check';
import pkgImg from '../assets/svg/corp-packages/package-10.svg';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4),
        flexGrow: 1,
        color: '#000',
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        width: '100%'
    },
    circleImage: {
        width: theme.spacing(20),
        height: theme.spacing(20),
        borderRadius: '50%',
        display: 'inline-block'
    },
    margin: {
        margin: theme.spacing(1)
    },
    sectionMargin: {
        margin: theme.spacing(4)
    },
    cardMargin: {
        marginLeft: theme.spacing(10),
        marginRight: theme.spacing(10)
    }
}))

const CorpPackageDetails = (props) => {
    const classes = useStyles();
    const [ pkg, setPkg ] = useState({});
    const pkgId = props.match.params.id
    const [ img, setImg ] = useState('');

    const colors = [
        '#E5B6AF',
        '#ABB9E0',
        '#B0CAE6',
        '#E9CBB5',
        '#A7C6B7',
        '#CFD0C0',
        '#E9C5A3',
        '#BECBDC',
        '#E1C4BF',
        '#D8C39E'
    ];

    const [ pkgColor, setPkgColor ] = useState(colors[pkgId - 1]);


    useEffect(() => {
        setPkg(corporatePackagesService.getPkg(Number(pkgId)));
        setImg(require(`../assets/svg/corp-packages/package-${pkgId}.svg`) || '');

        console.log(require(`../assets/svg/corp-packages/package-${pkgId}.svg`) || '');
    }, []);

    return (
       <Grid container className={classes.root} justify="flex-start" alignItems="flex-start" spacing={3}>
             { pkg.id && pkg.name && img? 
             <React.Fragment>
                <Grid item>
                    <BackButton />
                </Grid>
                <Grid item xs={12} container justify="flex-start" alignItems="flex-start" style={{ paddingLeft: 0}}>
                    <Typography variant="h4" className={classes.boldText}>
                        { pkg.name }
                    </Typography>
                </Grid>
                <Grid container justify="center" alignItems="center" spacing={2}>
                    <Grid item container xs={6} style={{ width: '80%', minHeight: '80vh'}}>
                        <Paper className={classes.paper}>
                            <Grid item xs={12} align="center">
                                <Avatar alt={pkg.name} className={classes.circleImage} src={require(`../assets/svg/corp-packages/package-${pkgId}.svg`) || ''} 
                                style={{ backgroundColor: pkgColor}} />
                            </Grid>
                            <Grid item xs={12} align="center" className={classes.margin}>
                                <Typography variant="body1">
                                    { pkg.description }
                                </Typography>
                            </Grid>
                            <Grid item xs={12} align="center" className={classes.sectionMargin}>
                                {
                                    pkg.tests && pkg.tests.length >= 1 ?
                                    <Typography className={classes.sectionMargin} style={{ fontSize: '1rem', fontWeight: 700, color: '#494949'}}>
                                        HERE'S WHAT YOU GET
                                    </Typography>
                                :   undefined}
                                <Grid item xs={12} align="center" style={{ marginLeft: '7.5rem'}}>
                                    { pkg.tests && pkg.tests.length >= 1 ? pkg.tests.map((test, index) => {
                                    return test ?
                                    <Typography variant="body1" key={index} style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}>
                                        <CheckIcon style={{ color: '#92B3D5', marginRight: 5 }}/> { test.label }
                                    </Typography> : undefined
                                    })  :   undefined}
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item container xs={6} style={{ width: '80%', minHeight: '80vh'}}>
                        <Paper className={classes.paper}>
                            <Grid item xs={12} align="left" className={classes.cardMargin}>
                                <Card style={{ backgroundImage: `url(${img})`, 
                                    backgroundSize: 'cover',
                                    backgroundAttachment: 'fixed',
                                    backgroundRepat: 'no-repeat',
                                    color: '#fff', 
                                    textAlign: 'left'}}>
                                    <CardContent>
                                        <Typography style={{ fontSize: '2rem'}} gutterBottom>
                                            { pkg.name }
                                        </Typography>
                                        <Typography style={{ fontSize: '1.75rem'}}>
                                            Lorem ipsum dolor sit amet, consectetur adipiscing
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </React.Fragment> : undefined }
        </Grid>
    )
}

export default CorpPackageDetails;