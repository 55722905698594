import moment from "moment";

const healthRiskReportColumns = [
    {
        id: 'worker_id',
        name: 'worker_id',
        label: 'Worker ID'
    },
    {
        id: 'worker_name',
        name: 'worker_name',
        label: 'Worker Name'
    },
    {
        id: 'worker_role',
        name: 'worker_role',
        label: 'Role'
    },
    {
        id: 'risk_status',
        name: 'risk_status',
        label: 'Health Risk Status'
    },
    {
        id: 'last_entry',
        name: 'last_entry',
        label: 'Last entry to office'
    }
];

const quarantinedReportColumns = [
   {
        id: 'worker_id',
        name: 'worker_id',
        label: 'Worker ID'
   },
   {
       id: 'worker_name',
       name: 'worker_name',
       label: 'Worker Name'
   },
   {
       id: 'worker_role',
       name: 'worker_role',
       label: 'Role'
   },
   {
        id: 'worker_department',
        name: 'worker_department',
        label: 'Department'
    },
   {
       id: 'date_quarantined',
       name: 'date_quarantined',
       label: 'Date Entered Quarantine'
   },
   {
       id: 'quarantine_reason',
       name: 'quarantine_reason',
       label: 'Quarantine Reason'
   },
   {
       id: 'quarantine_category',
       name: 'quarantined_category',
       label: 'Quarantine Category'
   }
];

const covidTestReportColumns = [
    {
        id: 'worker_id',
        name: 'worker_id',
        label: 'Worker ID'
    },
    {
        id: 'worker_name',
        name: 'worker_name',
        label: 'Worker Name'
    },
    {
         id: 'worker_department',
         name: 'worker_department',
         label: 'Department'
    },
    {
        id: 'worker_role',
        name: 'worker_role',
        label: 'Role'
    },
    {
        id: 'worker_type',
        name: 'worker_type',
        label: 'Worker Type'
    },
    {
        id: 'work_nature',
        name: 'work_nature',
        label: 'Work Nature'
    },
    {
        id: 'lab_result_id',
        name: 'lab_result_id',
        label: 'Lab Result ID'
    },
    {
        id: 'test_date',
        name: 'test_date',
        label: 'Test Date'
    },
    {
        id: 'valid_until',
        name: 'valid_until',
        label: 'Valid Until'
    },
    {
        id: 'sub_type',
        name: 'sub_type',
        label: 'Type'
    },
    {
        id: 'result',
        name: 'result',
        label: 'Result'
    }
]
const dailyTempReadingColumns = [
    {
        id: 'employee_id',
        name: 'employee_id',
        label: 'Employee ID'
    },
    {
        id: 'employee_first_name',
        name: 'employee_first_name',
        label: 'Employee First Name'
    },
    {
        id: 'employee_last_name',
        name: 'employee_last_name',
        label: 'Employee Last Name'
    },
    {
        id: 'employee_role',
        name: 'employee_role',
        label: 'Employee Role'
    },
    {
        id: 'employee_risk_level',
        name: 'employee_risk_level',
        label: 'Employee Risk Level'
    },
    {
        id: 'employee_temperature',
        name: 'employee_temperature',
        label: 'Employee Temperature'
    },
    {
        id: 'employee_entrance_date_as_string',
        name: 'employee_entrance_date_as_string',
        label: 'Employee Entrance Date'
    },
    {
        id: 'checker_id',
        name: 'checker_id',
        label: 'Checker ID'
    },
    {
        id: 'checker_first_name',
        name: 'checker_first_name',
        label: 'Checker First Name'
    },
    {
        id: 'checker_last_name',
        name: 'checker_last_name',
        label: 'Checker Last Name'
    },
    {
        id: 'checker_type',
        name: 'checker_type',
        label: 'Checker Type'
    },
    {
        id: 'location_label',
        name: 'location_label',
        label: 'Location'
    },
    {
        id: 'gate_label',
        name: 'gate_label',
        label: 'Gate'
    },
    {
        id: 'floor_label',
        name: 'floor_label',
        label: 'Floor'
    }
]

const covidScreeningColumns = [
    {
        id: 'worker_id',
        name: 'worker_id',
        label: 'Worker ID'
    },
    {
        id: 'worker_name',
        name: 'worker_name',
        label: 'Worker Name'
    },
    {
        id: 'worker_department',
        name: 'worker_department',
        label: 'Department'
   },
   {
       id: 'worker_role',
       name: 'worker_role',
       label: 'Role'
   },
   {
       id: 'temperature',
       name: 'temperature',
       label: 'Temperature'
   },
   {
       id: 'worker_type',
       name: 'worker_type',
       label: 'Worker Type'
   },
   {
        id: 'work_nature',
        name: 'work_nature',
        label: 'Work Nature'
    },
    // {
    //     id: 'assessment_id',
    //     name: 'assessment_id',
    //     label: 'Assessment ID'
    // },
    {
        id: 'assessment_date',
        name: 'assessment_date',
        label: 'Assessment Date'
    },
    {
        id: 'risk_level',
        name: 'risk_level',
        label: 'Risk Level'
    },
    {
        id: 'body_aches',
        name: 'body_aches',
        label: 'Body Aches'
    },
    {
        id: 'cough_and_colds',
        name: 'cough_and_colds',
        label: 'Cough and Colds'
    },
    {
        id: 'chills_or_sweating',
        name: 'chills_or_sweating',
        label: 'Chills or Sweating'
    },
    {
        id: 'repeated_shaking_with_chills',
        name: 'repeated_shaking_with_chills',
        label: 'Repeated Shaking with Chills'
    },
    {
        id: 'sore_throat',
        name: 'sore_throat',
        label: 'Sore Throat'
    },
    {
        id: 'shortness_of_breath',
        name: 'shortness_of_breath',
        label: 'Shortness of Breath'
    },
    {
        id: 'muscle_pain',
        name: 'muscle_pain',
        label: 'Muscle Pain'
    },
    {
        id: 'vomiting',
        name: 'vomiting',
        label: 'Vomiting'
    },
    {
        id: 'diarrhea',
        name: 'diarrhea',
        label: 'Diarrhea'
    },
    {
        id: 'fever',
        name: 'fever',
        label: 'Fever'
    },
    {
        id: 'headache',
        name: 'headache',
        label: 'Headache'
    },
    {
        id: 'fatigue',
        name: 'fatigue',
        label: 'Fatigue'
    },
    {
        id: 'loss_of_taste_and_smell',
        name: 'loss_of_taste_and_smell',
        label: 'Loss of Taste and Smell'
    }
];
const vaccinationReportsColumns=[
    {
        id: 'employee_id',
        name:'employee_id',
        label:'Company ID'
    },
    {
        id: 'last_name',
        name:'last_name',
        label:'Last Name',
    },
    {
        id: 'first_name',
        name:'first_name',
        label:'First Name',
    },
    {
        id: 'type',
        name:'type',
        label:'Type'
    },
    {
        id: 'relationship',
        name:'relationship',
        label:'Relationship',
        options:{
            customBodyRender:(value)=>{
                return <span>{value||'-'}</span>
            }
        }
    },
    {
        id: 'primary_employee_id',
        name:'primary_employee_id',
        label:'Primary Employee Company ID',
        options:{
            customBodyRender:(value)=>{
                if(value!==null||value!==''){
                    return <span>{value}</span>
                }
                return <span>-</span>
            }
        }
    },
    {
        id: 'primary_employee',
        name:'primary_employee',
        label:'Primary Employee Name',
        options:{
            customBodyRender:(value)=>{
                if(value!==null){
                    return <span>{value.display_name}</span>
                }
                return <span>-</span>
            }
        }
    },
    {
        id: 'first_dose_date',
        name:'vaccine',
        label:'Covid vaccine first dose',
        options:{
            customBodyRender:(value,meta)=>{
                return <span>{value.first_dose_date!==null? moment(value.first_dose_date).format('YYYY/MM/DD'):'-'}</span>
            }
        }
    },
    {
        id: 'days_after_first_dose',
        name:'vaccine',
        label:'Days since first dose',
        options:{
            customBodyRender:(meta)=>{
                if(meta.first_dose_date!==null && meta.second_dose_date!==null){
                    return <span>Completed</span>
                }
                if(meta.first_dose_date!==null && meta.second_dose_date===null){
                    var given = moment(meta.first_dose_date);
                    var current = moment().startOf('day');
                    var dayDiff = Math.abs(Math.floor(moment.duration(given.diff(current)).asDays()))
                    var weeks = Math.floor(dayDiff/7)
                    return <span>{`${dayDiff} ${dayDiff>1?'days':'day'} or ${weeks} ${weeks>1?'weeks':'week'}`}</span>
                }
            }
        }
    },
    {
        id: 'second_dose_date',
        name:'vaccine',
        label:'Covid vaccine second dose',
        options:{
            customBodyRender:(meta)=>{
                return <span>{meta.second_dose_date!==null? moment(meta.second_dose_date).format('YYYY/MM/DD'):'-'}</span>
            }
        }

    },
    {
        id: 'vaccination_status',
        name:'vaccine',
        label:'Vaccination status',
        options:{
            customBodyRender:(meta)=>{
                if(meta.first_dose_date===null && meta.second_dose_date===null){
                    return <span>NOT VACCINATED</span>
                }
                if(meta.first_dose_date!==null && meta.second_dose_date!==null){
                    return <span>VACCINATED</span>
                }
                return <span>First Dose</span>
            }
        }
    },
    {
        id: 'status',
        name:'status',
        label:'Link status'
    },
    {
        id: 'location',
        name:'location',
        label:'Address'
    },
    {
        id: 'gender',
        name:'gender',
        label:'Gender'
    },
    {
        id: 'civil_status',
        name:'civil_status',
        label:'Civil status'
    },
    {
        id: 'allergies',
        name:'allergies',
        label:'Allergies'
    },
    {
        id: 'commorbidities',
        name:'commorbidities',
        label:'Comorbidities'
    }
]

export { healthRiskReportColumns, quarantinedReportColumns, covidTestReportColumns, covidScreeningColumns, dailyTempReadingColumns, vaccinationReportsColumns }