import React from "react"
import {
    Card,
    CardContent,
    Grid,
    Typography,
    Paper
} from "@material-ui/core"
import {makeStyles} from "@material-ui/core/styles";
import NumberUtils from '../utils/numberUtils'

const useStyles = makeStyles(theme => ({
    root: {
    },
    title: {
        [theme.breakpoints.up('sm')]: {
            fontSize: 8
        },
        [theme.breakpoints.up('md')]: {
            fontSize: 10
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: 12
        },
        fontWeight: 'bold'
    },
    count: {
        [theme.breakpoints.up('sm')]: {
            fontSize: 14
        },
        [theme.breakpoints.up('md')]: {
            fontSize: 16
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: 28
        },
        fontWeight: 'bold'
    },
    cardContainer: {
        flexGrow: 1,
        paddingTop: 5,
        paddingBottom: 5,
        paddingLeft: 15,
        paddingRight: 15,
        minHeight: '15vh'
    }
}));

export default function CountCard(props) {
    const classes = useStyles()

    const {
        title,
        count = 0,
        backgroundColor = '#fff',
        titleColor = '#000',
        countColor = '#000'
    } = props

    return (
        <Card className={classes.root} style={{ backgroundColor }}>
            <Grid container justify={"center"} alignItems={"center"} className={classes.cardContainer}>
                <Grid item xs={12}>
                    <Typography className={classes.title} style={{ color: titleColor }}>
                        { title }
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography className={classes.count} style={{ color: countColor }}>
                        { (count && count !== 0) ? NumberUtils.friendlyFormat(count, 2) : '-' }
                    </Typography>
                </Grid>
            </Grid>
        </Card>
    )
}
