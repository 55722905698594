import React, {useEffect, useRef, useState} from "react"
import {makeStyles} from "@material-ui/core/styles";
import {
    Paper,
    Grid,
    Typography,
    Divider
} from '@material-ui/core'
import DoughnutChart from "../components/DoughnutChart";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
    fullSize: {
        width: "100%"
    },
    chart: {
        width: "100%"
    },
    textLeft: {
        textAlign: "left"
    },
    textBold: {
        fontWeight: 'bold'
    },
    m1: {
        padding: "0.5rem"
    }
}));

export default function (props) {
    const { counts, company } = props;
    const [ percentages, setPercentages ] = useState([]);
    const classes = useStyles()

    function numberFormatter(num) {
        return (num * 100).toFixed(2)
    }

    useEffect(() => {
        const reducer = (accumulator, currentValue) => accumulator + currentValue;
        const total = company.employeeCount >= 1 ? company.employeeCount : 1;

        setPercentages(
            [
                numberFormatter(counts[0] / total),
                numberFormatter((total - counts[0] - counts[2]) / total),
                numberFormatter(counts[2] / total),
                numberFormatter(counts[1] / total)
            ]
        )
        
    }, [])


    return (
        <Paper className={clsx(classes.fullSize)} variant={"outlined"}>
            <Grid container>
                <Grid item container xs={12}>
                    <Typography className={clsx(classes.textLeft, classes.textBold, classes.m1)} variant={"subtitle1"}>
                        Workers On-Boarded
                    </Typography>
                </Grid>

                <Divider style={{ width: "100%" }} />

                <Grid item container alignItems={"center"} justify={"center"} xs={12} style={{ margin: "0.5rem" }} spacing={3}>
                    <Grid item container alignItems={"center"} justify={"center"} xs={12}>
                        { percentages.length >= 1 ? <DoughnutChart
                            className={classes.chart}
                            labels={
                                [
                                    'Workers with linked accounts',
                                    'Workers with not linked accounts',
                                    'Workers with pending approval status',
                                    'Workers with rejected ID linking'
                                ]
                            }
                            datasets={
                                [
                                    {
                                        backgroundColor: ['#8F9EC3', '#EEBE6A', '#85CBBE', '#FFDBD8'],
                                        data: percentages
                                    }
                                ]
                            }
                            options={
                                {
                                    tooltips: {
                                        callbacks: {
                                          title: function(tooltipItem, data) {
                                            return data['labels'][tooltipItem[0]['index']];
                                          },
                                          label: function(tooltipItem, data) {
                                            return `${data['datasets'][0]['data'][tooltipItem['index']]}%`;
                                          }
                                        }
                                    },
                                    legend: {
                                        position: 'left',
                                        labels: {
                                            fontSize: 10
                                        }
                                    }
                                }
                            }
                        /> : undefined }
                    </Grid>
                    <Grid item container alignItems={"center"} justify={"flex-start"} xs={12}>
                        <Typography variant={"body2"} style={{ color: "#494949" }}>
                            Percentage of on-boarded workers
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    )
}
