import {
  CardContent,
  Tooltip,
  makeStyles,
  Typography,
  withTheme,
} from "@material-ui/core";
import React, { useState } from "react";
import styled from "styled-components";
import { countEventEntrants } from "./utils/event";
import v from "voca";
import moment from "moment";
import { Divider } from "@material-ui/core";

const StyledInfoCard = styled(CardContent)`
  padding: ${({ theme }) => `${theme.spacing(1)}px`};
  position: relative;
  overflow: hidden;
  cursor: pointer;
`;

export const StyledEventStatus = styled(Typography)`
  font-weight: bold;
  font-size: 0.8rem;
`;

export const StyledEventTitle = styled(Typography)`
  font-weight: bold;
`;

export const StyledEventType = styled(Typography)`
  font-weight: inherit;
`;

const useStyles = makeStyles((theme) => ({
  stats: { ...theme.typography.caption },

  evtContainer: {
    position: "relative",
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    color: theme.palette.text.primary,
  },
  tooltip: {
    position: "fixed",
    borderRadius: theme.spacing(1),
    backgroundColor: "#555",
    color: "#fff",
    padding: theme.spacing(1),
    zIndex: 10,
    opacity: 0.95,
    pointerEvents: "none",
    width: 300,
  },
  divider: {
    backgroundColor: theme.palette.grey[500],
  },
  date: {
    display: "block",
  },
}));

function EventCell({ agendaCell, theme, truncate, event, expanded }) {
  const classes = useStyles();
  return (
    <StyledInfoCard theme={theme}>
      {expanded && (
        <>
          <Typography variant="caption" className={classes.date}>
            {moment(event.start).format("LL")}
          </Typography>
          <Typography variant="caption" className={classes.date}>
            {moment(event.start).format("LT")} -{" "}
            {moment(event.end).format("LT")}
          </Typography>
          <Divider className={classes.divider} />
          <Divider className={classes.divider} />
        </>
      )}

      {!agendaCell && (
        <Typography variant="subtitle2">
          {truncate ? v.truncate(event.title, 20) : event.title}
        </Typography>
      )}
      <div className={classes.stats}>
        <StyledEventStatus variant="subtitle1">
          {event.status}
        </StyledEventStatus>
        {(event.services ?? []).map((service, servid) => (
          <React.Fragment key={servid}>
            <div>{service.type?.label}</div>
            {(service.slots ?? []).map((slot, sid) => (
              <div key={sid}><b>{`Slot ${sid+1}: ${moment(slot.start_date).format(
                "LT"
              )}`}</b> : {expanded && (slot.cohorts??[]).map(cohort=>cohort.label).join(', ')}</div>
            ))}
            <Divider className={classes.divider} />
          </React.Fragment>
        ))}
        {["REQUESTED", "CANCELLED"].includes(event.status) ? (
          <div>Count: {event.client_requested_count ?? 0}</div>
        ) : (
          <>
            <div>Invited: {event.client_invited_count ?? 0}</div>
            <div>Accepted: {event.client_accepted_count ?? 0}</div>
          </>
        )}
      </div>
    </StyledInfoCard>
  );
}

function TooltipCell({ event, ...props }) {
  const classes = useStyles();
  const [visible, setVisible] = useState(false);
  const [clientY, setClientY] = useState({ top: 0 });
  return (
    <div
      className={classes.evtContainer}
      onMouseLeave={(evt) => {
        setVisible(false);
      }}
      onMouseEnter={(evt) => {
        let tb = {}
        let rl = {}
        if (evt.clientY > window.innerHeight / 2) {
          tb = {
            top: "unset",
            bottom: window.innerHeight - evt.clientY,
          }
        } else {
          tb = {
            bottom: "unset",
            top: evt.clientY,
          }
        }
        if (evt.clientX > window.innerWidth / 2) {
          rl = {
            left: "unset",
            right: window.innerWidth - evt.clientX - 50,
          }
        } else {
          rl = {
            left: evt.clientX - 50,
            right: "unset",
          }
        }
        setClientY({...tb, ...rl});
        if (!props.agendaCell) {
          setVisible(true);
        }
      }}
      onClick={(evt) => {
        if (props.agendaCell) {
          props.onEventClick(event);
        }
      }}
    >
      {visible && (
        <div className={classes.tooltip} style={{ ...clientY }}>
          <EventCell event={event} {...props} expanded/>
        </div>
      )}
      <EventCell truncate event={event} {...props} />
    </div>
  );
}
export default withTheme(TooltipCell);
