import corporateEventsService from "./../services/corporate-events.service";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import React from "react";
import { Link, Typography, Chip, Avatar} from '@material-ui/core';
import Moment from "react-moment"
import { GridBox } from "@zennya/web-component-library/src/components/DataGrid";
import { SelectColumn, SelectCellFormatter } from 'react-data-grid';
import {Link as RouterLink} from 'react-router-dom'
import { IconButton } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import { SeverityProgress } from "../components/SeverityProgress";

function formatCohortData(cohort) {
    return {
        ...cohort,
        id: cohort.id,
        label: cohort.label,
        description: cohort.description,
        date_created: cohort.date_created,
        last_updated: cohort.last_updated,
        entry_count: cohort.entry_count,
    }
};

export const prepEntryLink = (entry, classes, history) => {
    return ((entry.employee_id||entry.id) && entry.full_name) ? (
        <React.Fragment>
            <Link
                className={classes.linkColor}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    textDecoration: 'none',
                    marginTop: '5px',
                }}
                component={"button"}
                onClick={() => { history.push(`/workers/${entry.employee_id||entry.id}`) }}>
                <Avatar src={entry.profile_pic_url} alt={"user_logo"} style={{ width: 30, height: 30 }} />
                <Typography variant="body2" style={{ marginLeft: 5 }}>
                    {entry.full_name}
                </Typography>
            </Link>
        </React.Fragment>
    ) : "--"
}



function formatCohortEntriesData(entry, classes, history) {
    return {
        ...entry,
        age: entry.birthdate ? moment.utc().diff(moment.utc(entry.birthdate), 'years') : '-',
        id: entry.reference_id,
        company_id: (entry.company_id) ? entry.company_id : "-",
        name: (entry.full_name) ? entry.full_name : "-",
        role: (entry.role) ? entry.role : "-",
        work_type: null, // no discernable data point for this
        work_nature: (entry.work_nature) ? entry.work_nature : "-",
    }
};

export function getCalendarDateFormat() {
    return 'MMMM DD, YYYY hh:mm a';
};

export const VACCINE_STATUS = {
    VACCINATED: "Vaccinated",
    FIRST_DOSE: "First_Dose",
    UNVACCINATED: "Unvaccinated",
}
export const EVENT_INVITE_STATUS = {
    ACCEPTED: "Accepted",
    DECLINED: "Declined",
    SENT: "Sent",
    UNSENT: "Unsent",
    PENDING: "PENDING"
}

const cohortsData = {
    getProcessedCohortsList(companyId, params, cohortClickCallback, cohortDeleteCallback) {
        return corporateEventsService.getCohortsList(companyId, params)
            .then(data => {
                var tableData = {
                    draggable: true,
                    count: data.count,
                };
                /* Construct the columns */
                tableData.columns = [
                    SelectColumn,
                    {
                        "key": "id",
                        "colId": "col1",
                        "name": "ID",
                        "type": "text",
                        "sortable": true,
                        "align": "flex-start",
                        "width": 60
                    },
                    {
                        "key": "label",
                        "colId": "col2",
                        "name": "Label",
                        "sortable": true,
                        "align": "flex-start",
                        "resizable": true,
                        cellRenderer({row: cohort}) {
                            return (cohort.id) ? (
                                <GridBox align="flex-start">
                                    <Link
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            textDecoration: 'none',
                                        }}
                                        component={"button"}
                                        onClick={() => { cohortClickCallback(cohort)}}>
                                        <Typography variant="body2" style={{ marginLeft: 5 }}>
                                            {cohort.label}
                                        </Typography>
                                    </Link>
                                </GridBox>
                            ) : cohort.label
                        }
                    },
                    {
                        "key": "description",
                        "colId": "col3",
                        "name": "Description",
                        "type": "text",
                        "sortable": true,
                        "align": "flex-start",
                        "resizable": true,
                    },
                    {
                        "key": "date_created",
                        "colId": "col4",
                        "name": "Date Created",
                        "type": "date",
                        "sortable": true,
                        "resizable": true,
                        cellRenderer(props) {
                            return <Moment format="lll">{props.row['date_created']}</Moment>
                        },
                    },
                    {
                        "key": "last_updated",
                        "colId": "col5",
                        "name": "Last Updated",
                        "type": "date",
                        "sortable": true,
                        "resizable": true,
                        cellRenderer(props) {
                            return <Moment format="lll">{props.row['last_updated']}</Moment>
                        },
                    },
                    {
                        "key": "entry_count",
                        "colId": "col6",
                        "name": "Employee Count",
                        "type": "number",
                        "sortable": true,
                        "resizable": true,
                    },
                    {
                        colId: 'action',
                        key: 'action',
                        name: '',
                        width: 50,
                        noTooltip: true,
                        cellRenderer({ column, row }) {
                            return (
                                <IconButton
                                    onClick={() => {
                                        cohortDeleteCallback(row);
                                    }}>
                                    <Delete color="secondary" />
                                </IconButton>
                                )
                            }
                        },
                ]

                /* Construct the rows */
                tableData.rows = data.list.map(function (event) {
                    const row = formatCohortData(event);
                    return row;
                });

                return tableData;
            });
    }


};

const cohortsEntriesData = {
    getColumns({allRowsSelected, onAllRowsSelectionChange, isEvent}){
        return [
            {...SelectColumn,
            columnHeaderRenderer: (props) => {
                return (
                <SelectCellFormatter
                    aria-label="Select All"
                    value={allRowsSelected}
                    onChange={onAllRowsSelectionChange}
                />
                );
            }},
            {
                key: "id",
                colId: "id",
                name: "ID",
                hidden: true,
                width: -1,
            },
            {
                "key": "company_id",
                "colId": "col1",
                "name": "ID",
                "type": "text",
                frozen: true,
                "sortable": true,
                "align": "flex-start",
                "width": 60,
            },
            {
                "key": "name",
                "colId": "col2",
                "name": "Name",
                "type": "text",
                frozen: true,
                "sortable": true,
                "align": "flex-start",
                "resizable": true,
                "width": 200,
                noTooltip: true,
                // filter: {
                //     type: 'text'
                // },
                cellRenderer({row}) {
                    return (
                        <div className="doubleRow">
                            <Link 
                                component={RouterLink}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    textDecoration: 'none',
                                    marginTop: '5px',
                                }}
                                to={`/workers/${row.employeeId||row.id}`}>
                                <Avatar src={row.profile_pic_url} alt={"user_logo"} style={{ width: 30, height: 30 }} />
                                <Typography variant="body2" style={{ marginLeft: 5 }}>
                                    {row.name}
                                </Typography>
                            </Link>
                        </div>
                    )
                }
            },  
            {
                "key": "first_dose_date",
                "colId": "first_dose_date",
                "name": "COVID Vaccine 1st Dose",
                "minWidth": 200,
                "resizable": true,
                "sortable": true,
                "frozen": false,
                cellRenderer({ row }) {
                if(row.vaccine===undefined) return null
                return row.vaccine?.first_dose_date===null? <Typography variant="caption" component="span" color="textSecondary">No vaccination record</Typography>:<span>{`${moment(row.vaccine?.first_dose_date).format("MMM DD, YYYY")} - ${row.vaccine?.brand}`}</span>
                },
            },
            {
                key: 'vaccine_countdown',
                colId: 'vaccine_countdown',
                name: 'Second Dose Eligibility',
                minWidth: 150,
                resizable: true,
                cellRenderer({ row }) {
                    if( ( row.vaccine?.first_dose_date ? 1 : 0 ) ^ ( row.vaccine?.second_dose_date ? 1 : 0 ) ) {
                        const end = moment(row.vaccine?.first_dose_date); // another date
                        const days = moment().diff(end, 'days')
                        return (
                            <SeverityProgress 
                            value={days}
                            maxValue={31} 
                            unit="days" />
                        )
                    }
                    return <Typography variant="caption" component="span" color="textSecondary">NA</Typography>
                }
            },
            {
                "key": "days_since_first_dose",
                "colId": "days_since_first_dose",
                "name": "Days Since First Dose",
                "minWidth": 200,
                "resizable": true,
                "sortable": true,
                "frozen": false,
                cellRenderer({ row }) {
                const end = moment(row.vaccine?.first_dose_date); // another date
                const days = moment().diff(end, 'days')
                if(row.vaccine?.second_dose_date===null && row.vaccine?.first_dose_date===null){
                    return <Typography variant="caption" component="span" color="textSecondary">None</Typography >
                }
        
                if(row.vaccine?.first_dose_date!==null && row.vaccine?.first_dose_date===null){
                    return <span>{days}</span>
                }
        
                if(row.vaccine?.second_dose_date!==null && row.vaccine?.first_dose_date!==null){
                    return <span>Done</span>
                }
                return row.vaccine?.second_dose_date===null && row.vaccine?.first_dose_date===null? <span>'NA'</span>: row.vaccine?.first_dose_date===null? <span>NA</span>:<span>{`${days}`}</span>
                },
            },
            {
                "key": "second_dose_date",
                "colId": "second_dose_date",
                "name": "COVID Vaccine 2nd Dose",
                "minWidth":200,
                "resizable": true,
                "sortable": true,
                "frozen": false,
                cellRenderer({ row }) {
                if(row.vaccine===undefined) return null
                return row.vaccine?.second_dose_date===null? <Typography variant="caption" component="span" color="textSecondary">No vaccination record</Typography>:<span>{`${moment(row.vaccine?.second_dose_date).format("MMM DD, YYYY")} - ${row.vaccine?.brand}`}</span>
                },
            },
            {
                "key": "vaccination_status",
                "colId": "vaccination_status",
                "name": "Vaccination Status",
                "minWidth": 150,
                "resizable": true,
                "sortable": true,
                "frozen": false,
                filter: {
                    type: "option",
                    default: "",
                    label: "",
                    options: [
                        {
                            label: "Vaccinated",
                            value: VACCINE_STATUS.VACCINATED,
                        },
                        {
                            label: "First Dose",
                            value: VACCINE_STATUS.FIRST_DOSE,
                        },
                        {
                            label: "Unvaccinated",
                            value: VACCINE_STATUS.UNVACCINATED,
                        },
                    ],
                },
                cellRenderer({row}) {
                if(row.vaccine===undefined) return null
                const style =
                    row.vaccine?.first_dose_date!==null && row.vaccine?.second_dose_date !== null
                    ? {
                        backgroundColor: "#C4EDC6",
                        color: "#54A557",
                        border: "1px solid #C4EDC6",
                        borderRadius: 5,
                        }
                    : row.vaccine?.first_dose_date!==null && row.vaccine?.second_dose_date === null? {
                        backgroundColor: "#DBEDFF",
                        color: "#6A99CA",
                        border: "1px solid #DBEDFF",
                        borderRadius: 5,
                        }:{
                        backgroundColor: "#FFDBD8",
                        color: "#E8685E",
                        border: "1px solid #FFDBD8",
                        borderRadius: 5,
                        };
                const label =  row.vaccine?.first_dose_date!==null && row.vaccine?.second_dose_date !== null ? "VACCINATED" :  row.vaccine?.first_dose_date===null && row.vaccine?.second_dose_date === null? "NOT VACCINATED": "FIRST DOSE";
                return (
                    <div>
                    <Chip
                    size="small"
                    style={style}
                    label={
                        <Typography variant="caption" style={{ fontWeight: 700 }}>
                        {label}
                        </Typography>
                    }
                    /></div>
                );
                }
            },
            {
                "key": "role",
                "colId": "col3",
                "name": "Role",
                "type": "text",
                "sortable": true,
                hidden:isEvent,
                "resizable": true,
            },
            {
                "key": "work_type",
                "colId": "col4",
                "name": "Work Type",
                "type": "text",
                "sortable": true,
                hidden:isEvent,
                "resizable": true,
            },
            {
                "key": "work_nature",
                "colId": "col5",
                "name": "Work Nature",
                "type": "text",
                "sortable": true,
                hidden:isEvent,
                "resizable": true,
            },
            {
            key: "age",
            colId: "age",
            name: "Age",
            sortable: true,
            resizable: true,
            },
            {...isEvent?{
                key:'invite',
                colId: 'invite',
                name: "Invite Status",
                width: 125,
                noTooltip:true,
                cellRenderer(props) {
                    let style
                    switch ((props?.row?.invite?.status??"").toUpperCase()) {
                        case "DECLINED":
                            style = {
                                backgroundColor: "#FFDBD8",
                                color: "#E8685E",
                                border: "1px solid #FFDBD8",
                                borderRadius: 5,
                            }
                            break;
                        case "ACCEPTED":
                            style= {
                                backgroundColor: "#C4EDC6",
                                color: "#54A557",
                                border: "1px solid #C4EDC6",
                                borderRadius: 5,
                                }
                            break;
                        default:
                            style = {
                                borderRadius: 5
                            }
                            break;
                    }
                    return (
                        <Chip
                        size="small"
                        style={style}
                        label={
                            <Typography color="textSecondary" variant="caption" style={{ fontWeight: 700 }}>
                            {props?.row?.invite?.status??"UNSENT"}
                            </Typography>
                        }
                        />
                    );
                },
                filter: {
                    type: "option",
                    default: "",
                    label: "",
                    options: [
                        {
                            label: "Accepted",
                            value: EVENT_INVITE_STATUS.ACCEPTED,
                        },
                        {
                            label: "Declined",
                            value: EVENT_INVITE_STATUS.DECLINED,
                        },
                        {
                            label: "Sent",
                            value: EVENT_INVITE_STATUS.SENT,
                        },
                        {
                            label: "Unsent",
                            value: EVENT_INVITE_STATUS.UNSENT,
                        },
                    ],
                },
            }:
            {
                key: "status",
                colId: "status",
                name: "Status",
                sortable: false,
                resizable: true,
                width: 125,
                cellRenderer(props) {
                    const style =
                        props?.row.status === "LINKED"
                        ? {
                            backgroundColor: "#C4EDC6",
                            color: "#54A557",
                            border: "1px solid #C4EDC6",
                            borderRadius: 5,
                            }
                        : {
                            backgroundColor: "#FFDBD8",
                            color: "#E8685E",
                            border: "1px solid #FFDBD8",
                            borderRadius: 5,
                            };
                    const label = props?.row.status === "LINKED" ? "LINKED" : "NOT LINKED";
                    return (
                        <Chip
                        size="small"
                        style={style}
                        label={
                            <Typography variant="caption" style={{ fontWeight: 700 }}>
                            {label}
                            </Typography>
                        }
                        />
                    );
                },
                noTooltip: true,
                filter: {
                    type: "option",
                    label: "Type",
                    options: [
                        {
                        label: "Linked",
                        value: "Linked",
                        },
                        {
                        label: "Not Linked",
                        value: "Not Linked",
                        },
                    ],
                },
            },
            },

        ]
    },
    getProcessedCohortEntriesList({cohortId, classes, history}) {
        return corporateEventsService.getCohortEntries(cohortId)
            .then(data => {
                var tableData = {
                    draggable: true,
                    count: data.count,
                };

                /* Construct the rows */
                tableData.rows = data.list.map(function (event) {
                    const row = formatCohortEntriesData(event, classes, history);
                    return row;
                });

                return tableData;
            });
    }


};

export { cohortsData, cohortsEntriesData };