import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Divider, Grid,  IconButton, TextField, Stepper, Step, InputLabel, FormControl, Select, StepLabel, Button, Typography } from '@material-ui/core';
import AddLocationMapClass from './../maps/addLocationMapClass';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import clsx from 'clsx';
import DebounceTextField from './../components/DebounceTextField';
import locationsService from './../services/locations.service';
import MapsAutoSuggest from './../components/mapsAutoSuggest';
import PerimeterSlider from './../maps/perimeterSlider';
import { PinLocationMap } from './../maps/pinLocationMap';
// import { LocationsMap } from '../maps/locationsMap';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%'
    },
    button: {
        marginRight: theme.spacing(1)
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    formControl: {
      marginRight: theme.spacing(1),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      minWidth: 300,
    },
    errorColor: {
      color: theme.palette.error.main,
      backgroundColor: 'white',
      borderColor: theme.palette.error.main
    },
    btnMargin: {
      marginTop: 10,
      marginLeft: 10
    },
    prevBtn: {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
      borderColor: theme.palette.secondary.main
    },
    textFieldSpacer: {
      margin: theme.spacing(1)
    },
    show: {
      display: 'block'
    },
    hidden: {
      display: 'none'
    }
}));

function getSteps() {
    return [
        'Location Details',
        'Add Location Perimeter',
        'Add Telephone Number and Location Type',
        'Add Floors and Floor Names',
        'Add Entrances and Entrances Names'
    ]
}

const NewAddOffice = (props) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const steps = getSteps();
  // const [ mapMarker, setMapMarker ] = useState(null);
  // const [ searchText, setSearchText ] = useState('');
  const [ value, setValue ] = useState(null);
  // const [ radius, setRadius ] = useState(100);
  const { state, addFloor, officeTypes, handleChange,
    mapConfig,
    handleChangeFloor, mapMarker, setMapMarker, searchText, setSearchText, radius, setRadius,
    deleteFloor, filteredGates, deleteGate, handleAddOffice,
    inputValue, setInputValue,
    handleChangePhoneNumber, addGate, handleChangeGate } = props;

  // const isStepOptional = (step) => {
  //   return step === 1;
  // };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);

    if(activeStep === 4) {
      handleAddOffice();
      setActiveStep(4);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    // if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    // }

    // setActiveStep((prevActiveStep) => prevActiveStep + 1);
    // setSkipped((prevSkipped) => {
    //   const newSkipped = new Set(prevSkipped.values());
    //   newSkipped.add(activeStep);
    //   return newSkipped;
    // });
  };

  const handleGetCoordinates = (coords) => {
    setMapMarker({
      ...mapMarker,
      geometry: {
        type: 'Point',
        coordinates: [ coords.lng, coords.lat ]
      }
    });
  }

  // const handleLocateAddress = (event) => {
  //   if(state.address !== '') {
  //     locationsService.geocoder(state.address).then(
  //       response => {
  //         if(response.list.length >= 1) {
  //           setMapMarker({
  //             lat: response.list[0].latitude,
  //             lng: response.list[0].longitude
  //           })
  //         }
  //       }
  //     ).catch(error => {
  //       console.log('error in auto-suggest locations');
  //     })
  //   }
  // }


  const labels = [
    {
      main: 'Location Details',
      subtitle: 'Add the location name, complete address, and telephone number.'
    },
    {
      main: 'Set Location Perimeter',
      subtitle: 'Set a circular perimeter around the location of the workplace by setting the radius on the map'
    },
    {
      main: 'Add Telephone Number and Location Type',
      subtitle: "Select the location type whether it's an Office, Factory, or Warehouse."
    },
    {
      main: 'Add Floors and Floor Names',
      subtitle: 'Add location floors/levels and their names.'
    },
    {
      main: 'Add Entrances and Entrances Names',
      subtitle: 'Add location entrances/gates and their names.'
    }
  ]

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <div className={classes.root}>
    <Stepper activeStep={activeStep}>
      {steps.map((label, index) => {
        const stepProps = {};
        const labelProps = {};
        // if (isStepOptional(index)) {
        //   labelProps.optional = <Typography variant="caption">Optional</Typography>;
        // }
        if (isStepSkipped(index)) {
          stepProps.completed = false;
        }
        return (
          <Step key={label} {...stepProps} style={{ color: 'white'}}>
            <StepLabel style={{ color: 'white'}} {...labelProps}></StepLabel>
          </Step>
        );
      })}
    </Stepper>
    <div>
        <div>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} style={{ paddingTop: 0, paddingBottom: 0}} container justify="center" alignItems="center">
                <Typography variant="body1" component="span" style={{ fontWeight: 700}}>
                  { labels[activeStep].main }
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} container justify="center" alignItems="center">
                <Typography variant="caption" component="span">
                  { labels[activeStep].subtitle}
                </Typography>
              </Grid>
          </Grid>
          {/* <Typography className={classes.instructions}></Typography> */}
          { activeStep === 0 ?
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <TextField
                      required
                      variant="outlined"
                      id="officeName"
                      label="Location Name"
                      fullWidth
                      size="small"
                      value={state.label}
                      onChange={handleChange('label')}
                      />
              </Grid>
              {/* <Grid item xs={12} sm={12}>
                <TextField
                    variant="outlined"
                    id="officeAddress"
                    label="Location Address"
                    fullWidth
                    required
                    multiline={true}
                    size="small"
                    rows={3}
                    rowsMax={3}
                    value={state.address}
                    onBlur={handleLocateAddress}
                    onChange={handleChange('address')}
                    />
              </Grid> */}
              <Grid item xs={12} sm={12}>
                  <MapsAutoSuggest mapMarker={mapMarker} setMapMarker={setMapMarker} 
                    value={value} setValue={setValue} label={'Complete Address'}
                    helperText={'Type an address to see suggested results.'}
                    inputValue={inputValue} setInputValue={setInputValue}
                  />
              </Grid>
            </Grid> : undefined }
            {
              activeStep === 2 ? 
              <Grid container spacing={3}>
                  <Grid item xs={12} sm={12}>
                      <TextField
                        required
                        variant="outlined"
                        id="telephoneNumber"
                        label="Telephone Number"
                        fullWidth
                        size="small"
                        value={state.phoneNumbers[0]}
                        onChange={handleChangePhoneNumber('phoneNumber', 0)}
                      />
                  </Grid>
                  <Grid item container sm={12} xs={12}>
                      <Grid item xs={4} sm={4} container justify="center" alignItems="center">
                          <Typography variant="body1" className={classes.headerPadding}>
                              Location Type
                          </Typography>
                      </Grid>
                      <Grid item xs={8} sm={8}>
                          <FormControl variant="outlined" color="primary" className={classes.formControl}>
                              <InputLabel htmlFor="outlined-industries-native-simple">Location Type</InputLabel>
                                  <Select
                                      native
                                      size="small"
                                      value={state.officeType}
                                      onChange={handleChange('officeType')}
                                      label="Location Type"
                                      inputProps={{
                                      name: 'office',
                                      id: 'outlined-industries-native-simple',
                                      }}
                                  >
                                      <option aria-label="None" value="" />
                                      ({
                                      officeTypes && officeTypes.length >= 1 ? officeTypes.map((option, index) => {
                                          return (
                                              option ? 
                                              <option value={option.id} key={index}>
                                              { option.label }
                                              </option>
                                          : undefined)
                                      })
                                      : undefined
                                      })
                                  </Select>
                          </FormControl>
                      </Grid>
                  </Grid>
              </Grid>
            : undefined}
              <Grid className={clsx({
                    [classes.show] : activeStep <= 1,
                    [classes.hidden] : activeStep > 1
                })} item xs={12} sm={12} style={{ margin: 10}}>
                   <PinLocationMap mapConfig={mapConfig} mapMarker={mapMarker}  radius={radius}
                   handleChange={handleGetCoordinates}
                    styles={{ width: "80vh", height: "calc(50vh - 80px)"}} />
                  {/* { mapMarker ? <PinLocationMap marker={mapMarker} /> : undefined } */}
                {/* <AddLocationMapClass marker={mapMarker} radius={radius} handleGetCoordinates={handleGetCoordinates}/> */}
              </Grid>
            {
              activeStep === 1 ?
              <Grid item xs={12} style={{ margin: 15 }}>
                <PerimeterSlider radius={radius} setRadius={setRadius} />
              </Grid> : undefined
            }
            {
              activeStep === 3 ?
                <Grid container spacing={4} alignItems="center" justify="center">
                  <Grid item xs={12} sm={12} style={{ display: 'block', maxWidth: '80%'}}>
                  {
                    state.floors ? state.floors.map((floor, index) => {
                          return (
                            floor ?
                            <Grid item container spacing={2} key={`floor-${index}`}> 
                                  <Grid item xs={10}>
                                    <TextField key={index}
                                      required
                                      className={classes.textFieldSpacer}
                                      variant="outlined"
                                      id={`floorName-${index}`}
                                      label={`Floor #${index + 1} Name`}
                                      fullWidth
                                      size="small"
                                      value={state.floors[index].name}
                                      onChange={handleChangeFloor('name', index)}
                                      />
                                  </Grid>
                                  <Grid item xs={2} align="left">
                                      <IconButton aria-label="delete" size="small" onClick={deleteFloor(index)} className={clsx(classes.errorColor, classes.btnMargin)}>
                                          <CancelRoundedIcon />
                                      </IconButton>
                                      {/* <Button variant="outlined" size="small" className={classes.errorColor} onClick={deleteFloor(index)}>
                                        Delete Floor
                                      </Button> */}
                                  </Grid>
                            </Grid> : undefined)})
                  : undefined  
                  }
                      <Grid item container xs={12} sm={12} style={{ display: 'block', maxWidth: '80%', marginLeft: 8}}>
                        <Button variant="outlined" size="small" color="primary" onClick={addFloor}>
                          Add Floor
                        </Button>
                      </Grid>
                  </Grid>
                  {/* <Grid item container xs={12} sm={12} style={{ display: 'block', maxWidth: '80%'}}>
                      <Button variant="outlined" size="small" color="primary" onClick={addFloor}>
                        Add Floor
                      </Button>
                  </Grid> */}
                </Grid>
           : undefined}
           {
             activeStep === 4 ?
                <Grid container spacing={4} alignItems="center" justify="center">
                   <Grid item xs={12} sm={12} style={{ display: 'block', maxWidth: '80%'}}>
                    {
                      state.floors ? state.floors.map((floor, index) => {
                            return (
                              floor ?
                              <Grid item container spacing={2} key={`floor-${index}`}> 
                                  <Grid item xs={12}>
                                    <Typography variant="body1" style={{ fontWeight: 700}}>
                                      { floor.name !== '' ? floor.name : `Floor #${index + 1}` }
                                    </Typography>
                                  </Grid>
                                  <Grid item container xs={12} sm={12} spacing={0}>
                                      {
                                        state.gates ? filteredGates(index).map((gate, index2) => {
                                          return (
                                            gate ?
                                            <Grid container key={index2} spacing={2}>
                                              <Grid item key={`gate-${index}-${index2}-description`} xs={10}>
                                                  <TextField
                                                    required
                                                    className={classes.textFieldSpacer}
                                                    variant="outlined"
                                                    id={`gateDescription-${index}-${index2}`}
                                                    label={`Entrance #${index2 + 1} Name`}
                                                    fullWidth
                                                    size="small"
                                                    value={gate.description || ''}
                                                    onChange={handleChangeGate('description', index, gate.mockId)}
                                                    />
                                              </Grid>
                                              <Grid item key={`action-${index}-${index2}`} xs={2}>
                                                  <IconButton aria-label="delete" size="small" onClick={deleteGate(index2)} className={clsx(classes.errorColor, classes.btnMargin)}>
                                                      <CancelRoundedIcon />
                                                  </IconButton>
                                              </Grid>
                                            </Grid>
                                            : undefined
                                            )
                                        })
                                      : undefined}
                                      <Grid item xs={12} sm={12} container alignItems="flex-start" justify="flex-start" style={{ marginLeft: 8}}>
                                        <Button variant="outlined" size="small" color="primary" onClick={addGate(index)}>
                                          Add Entrance
                                        </Button>
                                      </Grid>
                                  </Grid>
                              </Grid> : undefined)})
                    : undefined }
                    </Grid>
                </Grid>
             : undefined
           }
          <div>
            <Grid container spacing={4}>
              <Divider />
              <Grid item xs={6} container alignItems="flex-start" justify="flex-start">
                { activeStep >= 1 ? <Button disabled={activeStep === 0} onClick={handleBack} variant="outlined" color="primary">
                  Previous
                </Button> : undefined }
              </Grid>
              <Grid item xs={6} container alignItems="flex-end" justify="flex-end">
                <Button
                  variant="contained"
                  color="primary"
                  style={{ color: 'white'}}
                  onClick={handleNext}
                  className={classes.button}
                >
                  {activeStep === steps.length - 1 ? 'Add Location' : 'Continue'}
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
    </div>
  </div>
  )
}

export default NewAddOffice;