import React, { useState, useLayoutEffect, useRef, useEffect } from 'react';
import mapboxgl from 'mapbox-gl';
import marker_icon from './../assets/images/marker.png';
import './maps.css';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

const PinLocationMap = (props) => {
    const [ state, setState ] = useState(props.mapConfig)
    const { points, styles, mapMarker, handleChange, radius = 100, mode = 'create' } = props;
    const [ internalMarker, setInternalMarker ] = useState(null);

    const [ map, setMap ] = useState(null);

    const mapContainer = useRef(null);

    var geojson = {
      type: 'FeatureCollection',
      features: points
    };


    useLayoutEffect(() => {
        const initMap = ({ setMap, mapContainer }) => {
            const map = new mapboxgl.Map({
                container: mapContainer.current,
                style: 'mapbox://styles/mapbox/light-v10?optimize=true',
                center: [state.lng, state.lat],
                zoom: state.zoom
            })

            map.on('load', () => {
                setMap(map);
                map.resize();

                const img1 = new Image();
                img1.src = marker_icon;
                img1.alt = 'marker icon';

                map.addSource('circleSource', {
                  type: 'geojson',
                  data: {
                      type: 'FeatureCollection',
                      features: [
                          {
                              type: 'Feature',
                              geometry: {
                                  type: 'Point',
                                  coordinates: mapMarker.geometry.coordinates
                              }
                          }
                      ],
                  },
              })

                if(map && mapMarker && !internalMarker && mode === 'create') {        
                  if(mapMarker.geometry.coordinates.length === 2) {
                    var marker = new mapboxgl.Marker({ draggable: true })
                    .setLngLat(mapMarker.geometry.coordinates)
        
                    setInternalMarker(marker)
        
                    marker.addTo(map);
                    
                    marker.on('dragend', function(e) {
                      handleChange(marker.getLngLat());
                    });
        
                    map.flyTo({ center: mapMarker.geometry.coordinates });
                  } 
                }
        
                if(map && radius && mapMarker.geometry.coordinates.length === 2) {
                  map.addLayer({
                    id: 'radius',
                    type: 'circle',
                    source: 'circleSource',
                    paint: {
                        'circle-color': '#85CBBE',
                        'circle-opacity': 0.25,
                        'circle-radius': {
                            stops: [
                                [0, 0],
                                [20, radius / 0.075 / Math.cos(mapMarker.geometry.coordinates[1] * Math.PI / 180)]
                            ],
                            base: 2
                        },
                        'circle-stroke-width': 1,
                        'circle-stroke-color': '#85CBBE',
                    }
                  });
                }

                if(points) {
                  map.addSource('points', { type: 'geojson', data: geojson });

                  if(mapMarker) {                    
                    new mapboxgl.Marker()
                    .setLngLat(mapMarker.geometry.coordinates)
                    .addTo(map);
                  }

                  map.loadImage(marker_icon, function(error, image ) {
                      if(error) throw error;
                      map.addImage('marker-icon', img1);

                      map.addLayer({
                          id: 'points',
                          type: 'symbol',
                          source: 'points',
                          'layout': {
                              // get the icon name from the source's "icon" property
                              // concatenate the name to get an icon from the style's sprite sheet
                              // 'icon-image': ['concat', ['get', 'icon'], '-15'],
                              // 'icon-image': [ 'get', 'icon' ],
                              // get the title name from the source's "title" property
                              'icon-image': 'marker-icon',
                              'icon-size': 0.1,
                              'text-field': ['get', 'title'],
                              'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
                              'text-offset': [0, 1.2],
                              'text-anchor': 'top'
                          }
                      });
                  })
                }
            });

            
            var popup = new mapboxgl.Popup({
              closeButton: false,
              closeOnClick: false
            });

            map.on('mouseenter', 'points', function(e) {
               
              var coordinates = e.features[0].geometry.coordinates.slice();
              var description = e.features[0].properties.description;
               
              while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
              coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
              }
              
                
              // Populate the popup and set its coordinates
              // based on the feature found.
              popup
              .setLngLat(coordinates)
              .setHTML(`<div>
                ${description}
              </div>`)
              .addTo(map);
            });
               
            map.on('mouseleave', 'points', function() {
              // map.getCanvas().style.cursor = '';
              popup.remove();
            });

            map.addControl(new mapboxgl.NavigationControl());
        };

        if(!map) initMap({ setMap, mapContainer});

        if(map && mapMarker && internalMarker) {
          if(mapMarker.geometry.coordinates.length === 2) {
             internalMarker.setLngLat(mapMarker.geometry.coordinates);
            
            internalMarker.on('dragend', function(e) {
              handleChange(internalMarker.getLngLat());
            });

            map.flyTo({ center: mapMarker.geometry.coordinates });
          } 
        }


        if(map && mapMarker && !internalMarker) {        
          if(mapMarker.geometry.coordinates.length === 2) {
            var marker = new mapboxgl.Marker({ draggable: true })
            .setLngLat(mapMarker.geometry.coordinates)

            setInternalMarker(marker)

            marker.addTo(map);
            
            marker.on('dragend', function(e) {
              handleChange(marker.getLngLat());
            });

            map.flyTo({ center: mapMarker.geometry.coordinates });
          } 
        }

        if(map && radius && mapMarker.geometry.coordinates.length === 2) {
          if(map.getSource('circleSource')) {
            if(map.getLayer('radius')) {
              map.removeLayer('radius');
            }

            map.getSource('circleSource').setData(
              {
                type: 'FeatureCollection',
                features: [
                    {
                        type: 'Feature',
                        geometry: {
                            type: 'Point',
                            coordinates: mapMarker.geometry.coordinates
                        }
                    }
                ]
              });

              map.addLayer({
                id: 'radius',
                type: 'circle',
                source: 'circleSource',
                paint: {
                    'circle-color': '#85CBBE',
                    'circle-opacity': 0.25,
                    'circle-radius': {
                        stops: [
                            [0, 0],
                            [20, radius / 0.075 / Math.cos(mapMarker.geometry.coordinates[1] * Math.PI / 180)]
                        ],
                        base: 2
                    },
                    'circle-stroke-width': 1,
                    'circle-stroke-color': '#85CBBE',
                }
            });
        }
      }
    

    }, [map, mapMarker, radius]);

    return (
        <div ref={el => (mapContainer.current = el)} style={styles}/>
    )
}

export { PinLocationMap }