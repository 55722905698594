import React, {useEffect, useState} from "react"
import {
    Avatar,
    Badge, Button, CircularProgress, Dialog,
    DialogContent,
    DialogTitle, Divider,
    FormControl, FormControlLabel,
    Grid, Radio,
    RadioGroup,
    TextField,
    Typography,
    Stepper, Step, StepLabel, IconButton
} from "@material-ui/core";
import clsx from "clsx";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import {
    makeStyles
} from "@material-ui/core/styles";
import {
    Close as CloseIcon,
    Error as ErrorIcon
} from "@material-ui/icons"
import CorporatePartnerService from "../services/corporate-employee.service"
import {useHistory} from "react-router-dom";

import logger, { snackbar } from "../services/logger.service";

const useStyles = makeStyles(theme => ({
    grow: {
        flexGrow: 1,
    },
    bold: {
        fontWeight: "bold"
    },
    textErrorColor: {
        color: "#EA857D"
    },
    textDefaultColor: {
        color: "#494949"
    },
    textSuccessColor: {
        color: "#70BE5C"
    },
    textCenter: {
        textAlign: "center"
    },
    subtitle1: {
        fontSize: "1rem"
    },
    fullWidth: {
        width: "100%"
    },
    largeAvatar: {
        width: theme.spacing(12),
        height: theme.spacing(12),
        margin: theme.spacing(1)
    },
    employeeDataName: {
        fontWeight: "bold",
        color: "#455362"
    },
    employeeDataContainer: {
        [theme.breakpoints.only('xs')]: {
            textAlign: "center"
        },
        [theme.breakpoints.up('sm')]: {
            textAlign: "left"
        }
    }
}));

export default function DeleteEmployeeDialog(props) {
    const history = useHistory()
    const classes = useStyles()

    const { enqueueSnackbar } = snackbar()

    const {
        modal, setModal,
        employee
    } =  props

    const [ isDeleting, setIsDeleting ] = useState(false)
    const [ isUnlinking, setIsUnlinking ] = useState(false)

    const steps = [
        'Unlink Account',
        'Remove Account'
    ]

    const [ activeStep, setActiveStep ] = useState(1)
    const [ isEmployeeLinked, setIsEmployeeLinked ] = useState(false)
    const [ isEmployeeNewlyUnlinked, setIsEmployeeNewlyUnlinked ] = useState(false)

    const [ employeeData, setEmployeeData ] = useState([
        {
            label: "Name",
            value: "-"
        },
        {
            label: "Role",
            value: "-"
        },
        {
            label: "Work Nature",
            value: "-"
        },
        {
            label: "Seniority",
            value: "-"
        },
        {
            label: "Department",
            value: "-"
        },
        {
            label: "Entity",
            value: "-"
        },
        {
            label: "Strategic Business Unit",
            value: "-"
        },
        {
            label: "Business Unit",
            value: "-"
        }
    ])

    function unlinkEmployee() {
        setIsUnlinking(true)

        CorporatePartnerService.unlinkEmployee(employee.id)
            .then(() => {
                setIsEmployeeNewlyUnlinked(true)
                logger.success(enqueueSnackbar, "Employee has been unlinked")
            })
            .finally(() => {
                setIsUnlinking(false)
            })
    }

    function deleteEmployee() {
        setIsDeleting(true)

        CorporatePartnerService.deleteEmployee(employee.id)
            .then(() => {
                logger.success(enqueueSnackbar, "Employee has been removed")

                history.push('/workers')
            })
            .finally(() => {
                setIsDeleting(false)
            })
    }

    function isEmployeeObjectEmpty() {
        return !employee || employee.constructor !== Object || Object.keys(employee).length === 0
    }

    useEffect(() => {
        if (!isEmployeeObjectEmpty()) {
            setIsEmployeeLinked(employee.status === "LINKED")

            setEmployeeData([
                {
                    label: "Name",
                    value: `${employee.last_name}, ${employee.first_name}` || "-"
                },
                {
                    label: "Role",
                    value: employee.role || "-"
                },
                {
                    label: "Work Nature",
                    value: employee.work_nature || "-"
                },
                {
                    label: "Seniority",
                    value: employee.seniority || "-"
                },
                {
                    label: "Department",
                    value: employee.department || "-"
                },
                {
                    label: "Entity",
                    value: employee.entity || "-"
                },
                {
                    label: "Strategic Business Unit",
                    value: employee.strategic_business_unit || "-"
                },
                {
                    label: "Business Unit",
                    value: employee.business_unit || "-"

                }
            ])
        }
    }, [employee])

    function renderStepContent() {
        switch (activeStep) {
            case 1:
                return renderUnlinkAccountStep()
            case 2:
            default:
                return renderRemoveAccountStep()
        }
    }

    function renderEmployeeData() {
        return (
            <React.Fragment>
                <Grid item container alignItems={"center"} justify={"center"} xs={12}>
                    <Avatar src={employee.profile_pic_url} className={classes.largeAvatar} />
                </Grid>
                <Grid item container alignItems={"center"} justify={"center"} xs={12}>
                    {
                        employeeData.map(data => (
                            <React.Fragment key={data.label}>
                                <Grid item container className={classes.employeeDataContainer} justify={"flex-end"} xs={12} sm={6}>
                                    <Grid item xs={12} sm={6}>
                                        <Typography className={clsx(classes.employeeDataName)}>
                                            { data.label }
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item container className={classes.employeeDataContainer} justify={"center"} xs={12} sm={6}>
                                    <Grid item xs={12} sm={6}>
                                        <Typography>
                                            { data.value }
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </React.Fragment>
                        ))
                    }
                </Grid>
            </React.Fragment>
        )
    }

    function renderRemoveAccountStep() {
        return (
            <Grid container alignItems={"center"} justify={"center"} spacing={2}>
                <Grid item xs={12}>
                    <Typography className={clsx(classes.bold, classes.textCenter)} variant={"h6"}>
                        Remove Account
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography className={clsx(classes.textCenter)} style={{ color: "red" }}>
                        This process is irreversible
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography>
                        Removing the worker's account from the company will delete the worker's profile and references
                        in the company's system. This will not delete any data from the worker's zennya account.
                    </Typography>
                </Grid>
                <Grid item container alignItems={"center"} justify={"center"} spacing={2}  xs={12}>
                    { renderEmployeeData() }
                </Grid>
                <Grid item xs={12}>
                    <Divider className={classes.fullWidth} />
                </Grid>
                <Grid item container alignItems={"center"} justify={isEmployeeLinked ? "space-between" : "center"} xs={12}>
                    {
                        isEmployeeLinked && (
                            <Grid item xs={12} sm={4}>
                                <Button
                                    color="primary"
                                    variant={"outlined"}
                                    disabled={isDeleting}
                                    onClick={() => setActiveStep(1)}
                                    fullWidth
                                >
                                    Previous
                                </Button>
                            </Grid>
                        )
                    }
                    <Grid item xs={12} sm={4}>
                        <Button
                            color="primary"
                            variant={"contained"}
                            disabled={isDeleting}
                            onClick={deleteEmployee}
                            fullWidth style={{ color: "white" }}
                        >
                            {
                                isDeleting
                                    ? <CircularProgress color="primary" size={25} />
                                    : "Remove Profile"
                            }
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    function renderUnlinkAccountStep () {
        return (
            <Grid item container alignItems={"center"} justify={"center"} spacing={2} xs={12}>
                <Grid item xs={12}>
                    <Typography className={clsx(classes.bold, classes.textCenter)} variant={"h6"}>
                        Unlink Account
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography>
                        Unlinking an account will unlink the zennya account of the worker with this specific worker
                        reference data. The worker will no longer be able to access the Corporate Health Pass in the
                        zennya mobile application but the worker reference data will still be viewable here in the
                        Corporate Web App.
                    </Typography>
                </Grid>
                <Grid item container alignItems={"center"} justify={"center"} spacing={2}  xs={12}>
                    { renderEmployeeData() }
                </Grid>
                <Grid item xs={12}>
                    <Divider className={classes.fullWidth} />
                </Grid>
                <Grid item container alignItems={"center"} justify={"space-between"} xs={12}>
                    <Grid item xs={12} sm={4}>
                        <Button
                            color="primary"
                            variant={"contained"}
                            disabled={isUnlinking || isEmployeeNewlyUnlinked}
                            onClick={unlinkEmployee}
                            fullWidth style={{ color: "white" }}
                        >
                            {
                                isUnlinking
                                    ? <CircularProgress color="primary" size={25} />
                                    : (
                                        isEmployeeNewlyUnlinked
                                            ? "Unlinked"
                                            : "Unlink Account"
                                    )
                            }
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Button
                            color="primary"
                            variant={isEmployeeNewlyUnlinked ? "contained" : "outlined"}
                            disabled={isUnlinking}
                            onClick={() => setActiveStep(2)}
                            fullWidth
                            style={isEmployeeNewlyUnlinked ? { color: "white" } : {}}
                        >
                            Next
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    function renderSteps() {
        return (
            <React.Fragment>
                <Stepper activeStep={activeStep} alternativeLabel>
                    {
                        steps.map(
                            (label, index) => (
                                <Step key={index}>
                                    <StepLabel
                                        style={{ color: "white" }}
                                    >
                                        {/*<span className={classes.bold}>*/}
                                        {/*    { label }*/}
                                        {/*</span>*/}
                                    </StepLabel>
                                </Step>
                            )
                        )
                    }
                </Stepper>
                {
                    renderStepContent()
                }
            </React.Fragment>
        )
    }

    return (
        <Dialog
            maxWidth={"md"}
            fullWidth
            open={modal}
            onClose={() => setModal(false)}
            aria-labelledby="add-employee-dialog"
        >
            <DialogTitle
                id="add-employee-dialog-title"
                onClose={ () => setModal(false)}
            >
                {/*<Grid container justify={"space-between"}>*/}
                {/*    <Grid item xs={6}>*/}
                {/*        <Typography className={clsx(classes.bold)} variant={"h6"}>*/}
                {/*            { title }*/}
                {/*        </Typography>*/}
                {/*        <span className={clsx(classes.textDefaultColor, classes.subtitle1)}>*/}
                {/*                { subtitle }*/}
                {/*        </span>*/}
                {/*    </Grid>*/}
                {/*    <Grid item xs={6} className={classes.textRight}>*/}
                {/*        <IconButton onClick={ () => setModal(false)}>*/}
                {/*            <CloseIcon />*/}
                {/*        </IconButton>*/}
                {/*    </Grid>*/}
                {/*</Grid>*/}
                <span className={clsx(classes.bold)} style={{
                    display: 'flex',
                    alignItems: 'center'
                }}>
                    <ErrorIcon
                        className={clsx(classes.textErrorColor)}
                    />
                    &nbsp;&nbsp;Remove Worker from the Company
                </span>
            </DialogTitle>
            <DialogContent className={clsx(classes.grow)} dividers>
                {
                    isEmployeeLinked
                        ? renderSteps()
                        : renderRemoveAccountStep()
                }
            </DialogContent>
        </Dialog>
    )
}
