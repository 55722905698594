import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Box, Divider, FormControl, Grid, InputLabel, Paper, Select, Typography, Button } from '@material-ui/core';
import PeopleIcon from '@material-ui/icons/People';
import AcUnitIcon from '@material-ui/icons/AcUnit';
import AddOffice from './addOffice';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
    flexGrow: 1
  },
  officeInfoTitle: {
    padding: 10
  },
  officeInfoSection: {
    padding: 10
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    width: '100%'
  },
  cardSpacing: {
    margin: 5
  },
  boldText: {
        fontWeight: 700
  },
  textLeft: {
      textAlign: 'left'
  },
  avatar: {
    backgroundColor: '#228705',
    height: 46,
    width: 46
  },
  avatar2: {
    backgroundColor: '#f7c14a',
    height: 46,
    width: 46
  },
  contentSpacer: {
      margin: 10,
  }
}));


const OfficeCard = (props) => {
  const classes = useStyles();
  const { office, company, handleOpenUpdateOffice } = props;
  const [ state, setState ] = useState(office || {});
//   const [ selectedOffice, setSelectedOffice ] = useState(false);
//   const [ openUpdateOffice, setOpenUpdateOffice ] = useState(false);
  
  
//   useEffect(() => {
//   }, []);   


  const handleChange = name => event => {
    setState({
      ...state,
      [name]: event.target.value
    })
  }

    // const handleOpenUpdateOffice = () => {
    //     setOpenUpdateOffice(true);
    // }

    // const handleCloseUpdateOffice = () => {
    //     setOpenUpdateOffice(false);
    // }

    // const handleOpenUpdate = (office) => {
    //     handleOpenUpdateOffice(office);
    // }

  return (
    <React.Fragment>
         <Paper className={classes.paper}>
            <Grid container spacing={2} className={classes.cardContainer}>
                <Grid item xs={5} sm={5} lg={5} xl={5} container alignItems="flex-start" justify="flex-start">
                    <Grid item xs container direction="column" spacing={2}>
                        <Grid item xs>
                            <Typography variant="body1" className={clsx(classes.cardSpacing, classes.boldText, classes.textLeft)}>
                            { office.label || '-' }
                            </Typography>
                            <Typography variant="body2" className={clsx(classes.cardSpacing, classes.boldText, classes.textLeft)}>
                                { office.address || '-' }
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={5} sm={5} lg={5} xl={5} container>
                    <Grid item xs container direction="column" justify="center" alignItems="center" spacing={0}> 
                        <Avatar
                            alt="Person"
                            className={classes.avatar}
                        >
                            <PeopleIcon />
                        </Avatar>
                        <Typography
                            className={classes.name}
                            variant="h6"
                        >
                            {office.employeeCount || 0 }
                        </Typography>
                        <Typography variant="body2">Workers</Typography>
                    </Grid>
                    <Grid item xs container direction="column" justify="center" alignItems="center" spacing={0}>
                        <Avatar
                            alt="AcUnit"
                            className={classes.avatar2}
                        >
                            <AcUnitIcon className={classes.icon} />
                        </Avatar> 
                        <Typography
                            className={classes.name}
                            variant="h6"
                        >
                            {office.healthCheckerCount || 0 }
                        </Typography>
                        <Typography variant="body2"> Health Checkers</Typography>
                    </Grid>
                </Grid>
                <Grid item className={classes.cardContainer}>
                    <Button variant="contained" size="medium" color="primary" onClick={() => { handleOpenUpdateOffice(state)} }>
                        EDIT
                    </Button>
                </Grid>
            </Grid>
            <Divider className={classes.contentSpacer} />
            <Grid container spacing={2} className={classes.cardContainer}>
                <Grid item xs={5} sm={5} lg={5} xl={5} container alignItems="flex-start" justify="flex-start">
                    <Grid item xs container direction="column" spacing={2}>
                        <Grid item xs>
                            <Typography variant="body1" className={clsx(classes.cardSpacing, classes.boldText, classes.textLeft)}>
                                Representatives
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={5} sm={5} lg={5} xl={5} container>
                    <Grid item xs container direction="column" justify="center" alignItems="center" spacing={0}> 
                        <Typography variant="body1" className={clsx(classes.boldText, classes.textLeft)}>
                          Checkers
                        </Typography>
                    </Grid>
                    <Grid item xs container direction="column" justify="center" alignItems="center" spacing={0}>
                    </Grid>
                </Grid>
                <Grid item className={classes.cardContainer}>
                </Grid>
            </Grid>
        </Paper>
    </React.Fragment>
  );
};

export default OfficeCard;