import instance from './helpers/instance';

const API_URL = process.env.REACT_APP_WEB_ADMIN_URL;

export default {
    getTestTypes: (id) => {
        return instance.get(`${API_URL}/api/1/corporate/partners/${id}/reports/testTypes`).then(
            response => {
                return response.data;
            }
        )
    },
    getHealthRiskReport: (params) => {
        return instance.get(`${API_URL}/api/1/corporate/partners/${params.id}/reports/healthRisk`, { params: params }).then(
            response => {
                return response.data;
            }
        )
    },
    downloadHealthRiskReport: (params) => {
        return instance.get(`${API_URL}/api/1/corporate/partners/${params.id}/reports/healthRisk/download`, { params: params }).then(
            response => {
                return response;
            }
        )
    },
    getQuarantinedWorkerReport: (params) => {
        return instance.get(`${API_URL}/api/1/corporate/partners/${params.id}/reports/quarantinedWorkers`, { params: params }).then(
            response => {
                return response.data;
            }
        )
    },
    downloadQuarantinedWorkerReport: (params) => {
        return instance.get(`${API_URL}/api/1/corporate/partners/${params.id}/reports/quarantinedWorkers/download`, { params: params }).then(
            response => {
                return response;
            }
        )
    },
    getCovidTestResults: (params) => {
        return instance.get(`${API_URL}/api/1/corporate/partners/${params.id}/reports/covidTestResults`, { params: params }).then(
            response => {
                return response.data;
            }
        )
    },
    downloadCovidTestResultReport: (params) => {
        return instance.get(`${API_URL}/api/1/corporate/partners/${params.id}/reports/covidTestResults/download`, { params: params }).then(
            response => {
                return response;
            }
        )
    },
    getCovidScreening: (params) => {
        return instance.get(`${API_URL}/api/1/corporate/partners/${params.id}/reports/covidScreeningResults`, { params: params }).then(
            response => {
                return response.data;
            }
        )
    },
    downloadCovidScreeningReport: (params) => {
        return instance.get(`${API_URL}/api/1/corporate/partners/${params.id}/reports/covidScreeningResults/download`, { params: params }).then(
            response => {
                return response;
            }
        )
    },
    getDailyTempReading: (params) => {
        return instance.get(`${API_URL}/api/1/corporate/partners/${params.id}/reports/dailyTemperatureReading`, { params: params }).then(
            response => {
                return response.data;
            }
        )
    },
    downloadDailyTempReadingReport: (params) => {
        return instance.get(`${API_URL}/api/1/corporate/partners/${params.id}/reports/dailyTemperatureReading/download`, { params: params }).then(
            response => {
                return response;
            }
        )
    },
    getVaccinationReport: (params)=>{
        return instance.get(`${API_URL}/api/1/corporate/partners/${params.id}/reports/vaccinations`, {params:params}).then(
            response=>{
                return response.data
            }
        )
    },
    downloadVaccinationReport:(params)=>{
        return instance.get(`${API_URL}/api/1/corporate/partners/${params.id}/reports/vaccinations/download`).then(
            reponse=>{
                return reponse;
            }
        )
    }

}