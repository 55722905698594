import React, { useState, useEffect } from 'react';
import { Button, FormControl, Grid, TextField, InputAdornment, LinearProgress,
     Typography, Backdrop, CircularProgress, Divider, Fade, IconButton, Modal,
     Card, Paper
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ErrorMessage from './../components/errorMessage';
import SuccessMessage from './../components/successMessage';
import clsx from "clsx";
import CsvImport from './../components/csvImport';
import {
        Close as CloseIcon,
        CloudUpload as CloudUploadIcon,
        Description as DescriptionIcon,
        FiberManualRecord as DotIcon
} from "@material-ui/icons";
import corporateEventsService from '../services/corporate-events.service';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4),
        flexGrow: 1,
        maxWidth: '1440px'
    },
    button: {
        marginRight: theme.spacing(1)
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    formControl: {
      marginRight: theme.spacing(1),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      minWidth: 300,
    },
    errorColor: {
      color: theme.palette.error.main,
      backgroundColor: 'white',
      borderColor: theme.palette.error.main
    },
    btnMargin: {
      marginTop: 10,
      marginLeft: 10
    },
    prevBtn: {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
      borderColor: theme.palette.secondary.main
    },
    textFieldSpacer: {
      margin: theme.spacing(1)
    },
    show: {
      display: 'block'
    },
    hidden: {
      display: 'none'
    },
    titleHeader: {
        marginTop: '50',
        fontWeight: 700
    }
    
}));

const CreateCohorts = (props) => {
    const classes = useStyles();

    const [error, setError] = useState('');

    const [ state, setState ] = useState({
    });


    const handleChange = name => event => {
        setState({
            ...state,
            [name]: event.target.value
        })
    }
    const partnerId = props.company;

    const callback = props.callback;
    const [file, setFile] = useState(null);
    const [notification, setNotification] = useState({
        message: '',
        show: false,
        isError: false
    });

    function handleFileSelection(event) {
        setFile(event.target.files[0])
    }

    function handleImageClick() {
        document.getElementById("selectImage").click()
    }

    function setShowNotification(value) {
        setNotification({
            ...notification,
            show: value
        })
    }
    function createNotification(message, isError) {
      setNotification({
          message: message,
          show: true,
          isError: isError
      })
    }

    const addCohort = () => {
        let params = new FormData();
        params.append('label', state.label);
        params.append('description', state.description);
        params.append('file', file);
        params.append('types', props.peopleType);

        corporateEventsService.createCohort(props.company, params).then(
            (response) => {
                createNotification("Successfully created Cohort");
                callback(true);
            }
        ).catch(
            (error) => {
                createNotification("Error: "+ error.message, true)
            }
        )

    }
  

    return (
        
        <div className={classes.root}>
            { notification.show && notification.isError && <ErrorMessage open={notification.show} setOpen={setShowNotification} error={{ message: notification.message }} />}
            { notification.show && !notification.isError && <SuccessMessage open={notification.show} setOpen={setShowNotification} message={notification.message} />}
            <Grid container spacing={3}>
                <Grid item xs={6} container justify="flex-start" alignItems="flex-start">
                    <Typography className={classes.titleHeader} variant="h5" >
                        Create Cohort
                    </Typography>
                </Grid>
            </Grid>

            <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <TextField
                        required
                        variant="outlined"
                        id="cohortLabel"
                        label="Cohort Name"
                        fullWidth
                        size="small"
                        value={state.label || ''}
                        style={{ minWidth: 300, maxWidth: 500 }}
                        onChange={handleChange('label')}
                        />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <TextField
                        required
                        variant="outlined"
                        id="cohortDescription"
                        label="Cohort Description"
                        fullWidth
                        size="small"
                        value={state.description || ''}
                        style={{ minWidth: 300, maxWidth: 500 }}
                        onChange={handleChange('description')}
                    />
                </Grid>

                <Grid item xs={12} sm={12} container justify="flex-end" alignItems="flex-end" xs={3} align="right" style={{ marginBottom: 8 }}>
                    <Grid item container xs={2} sm={2}>
                        <Typography variant={"body2"} className={clsx(classes.textLeft)} style={{ fontWeight: "bold" }}>
                            Upload File
                        </Typography>
                    </Grid>
                    <Grid item container xs={10} sm={10} style={{ bottomMargin: "20px" }}>
                        <Paper variant={"outlined"} style={{ width: "25vw", height: "50px", bottomMargin: "20px" }}>
                            <Grid container direction={"column"} alignItems={"center"} justify={"center"} spacing={2}>
                                <Grid item>
                                    <IconButton style={{ backgroundColor: 'transparent' }} color={file ? "primary" : "default"} aria-label="upload" onClick={handleImageClick}>
                                        {file ? <DescriptionIcon style={{ fontSize: 25 }} /> : <CloudUploadIcon style={{ fontSize: 25 }} />}
                                        <input id="selectImage" accept=".csv,.tsv" hidden type="file" onChange={handleFileSelection} />
                                    </IconButton>
                                </Grid>
                                <Grid item>
                                    <Typography variant="subtitle1">
                                        {file ? file.name : "Choose a file"}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>

                <Button variant="outlined" size="small" color="primary" onClick={addCohort} style={{ topMargin: "20px" }}
                    inputprops={{
                        startAdornment: <InputAdornment position="end">
                            <AddCircleOutlineIcon />
                        </InputAdornment>,
                    }}>
                    Create Cohort
                </Button>
            </Grid>
        </div>
    )
}

export default CreateCohorts;