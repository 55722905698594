import { IconButton } from "@material-ui/core";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormGroup,
  Grid,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import { isEmpty } from "lodash";
import { useConfirm } from "material-ui-confirm";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import Moment from "react-moment";
import { JsonParam, useQueryParam } from "use-query-params";
import corporateEventsService from "../services/corporate-events.service";
import CohortAutoComplete from "./autocomplete/cohort";
import ServiceAutoComplete from "./autocomplete/service";
import { StyledEventStatus, StyledEventType } from "./eventCell";
import { SlotForm } from "./eventslots/SlotForm";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const styles = (theme) => ({
  bold: {
    fontWeight: 600,
  },
  cancel: {
    color: theme.palette.warning.main,
  },
  cohortContainer: {
    paddingLeft: theme.spacing(2),
  },
  cohortTitle: {
    paddingTop: theme.spacing(2),
  },

  checkbox: {
    paddingRight: theme.spacing(1),
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
});

function EventCreate({
  classes,
  start,
  end,
  onSubmit,
  onClose,
  cohorts,
  services,
  open,
  openCohort,
  loading,
}) {
  const confirm = useConfirm();

  const [eventTitle, setEventTitle] = useState("");
  const [cohortId, setCohortId] = useState("");

  const [formValues, setFormValues] = useState();
  const [serviceSet, setServiceSet] = useState([]);
  const [selectedServiceCohorts, setSelectedServiceCohorts] = useState([]);

  const [triggerReset, setTriggerReset] = useState(false);

  const [locationId, setLocationId] = useQueryParam("location", JsonParam);
  const [location, setLocation] = useState(null);

  useEffect(() => {
    async function fetchLocationDetails() {
      const result = await corporateEventsService.getEventLocationDetails(
        locationId
      );
      setLocation(result);
    }
    fetchLocationDetails();
  }, [locationId]);

  useEffect(() => {
    if (open) {
      setEventTitle("");
      setServiceSet([]);
    }
  }, [open]);

  function onSetService(services, reason, detail) {
    setServiceSet(
      services.map((service) => {
        return { ...service };
      })
    );
  }

  function onSelectedCohorts(service, selectedCohorts) {
    const updServices = serviceSet.map((servItem) => {
      if (servItem.id === service.id) {
        servItem.cohorts = selectedCohorts;
      }
      return servItem;
    });
    setSelectedServiceCohorts(updServices);
  }
  function renderServiceBoxes() {
    return Object.entries(serviceSet).map(([k, v]) => {
      return (
        <CohortAutoComplete
          key={k}
          label={v.label}
          selectedCohorts={v.cohorts ?? []}
          onSelectedCohorts={(selectedCohorts) =>
            onSelectedCohorts(v, selectedCohorts)
          }
          cohorts={cohorts}
          openCohort={openCohort}
        />
      );
    });
  }

  function isFormValid() {
    const res = serviceSet.reduce((acc, service) => {
      return acc || isEmpty(service.cohorts);
    }, false);
    return !isEmpty(eventTitle) && !isEmpty(serviceSet) && !res;
  }

  function prepareBundle(values) {
    const buildEvent = {
      label: values?.label,
      start_date: values.start,
      end_date: values.end,
      services: (values.services ?? []).map((service) => {
        return {
          type: service?.service,
          slots: (service?.timeslots ?? []).map((slot) => {
            return {
              cohorts: slot?.cohorts?.map((cohort) => cohort.id),
              start_date: slot?.callTime,
            };
          }),
        };
      }),
    };
    onSubmit({
      event: buildEvent,
    });
  }

  const onConfirmClose = useCallback(() => {
    if (!isEmpty(formValues?.label) || !isEmpty(formValues?.services)) {
      confirm({
        title: "Unsaved Changes",
        description: "Are you sure you want to close and discard your changes?",
        cancellationButtonProps: { autoFocus: true },
      })
        .then(() => {
          setTriggerReset(true);
          onClose();
        })
        .catch(() => {});
    } else {
      setTriggerReset(true);
      onClose();
    }
  }, [confirm, formValues, onClose]);

  const slots = true;
  return slots ? (
    <Dialog
      maxWidth="md"
      open={open}
      fullWidth
      disableEnforceFocus
      onClose={() => onConfirmClose()}
    >
      <DialogTitle
        id="allocations"
        onClose={() => onConfirmClose()}
        disableTypography
        className={classes.header}
      >
        <Typography variant="h6">New event</Typography>
        <IconButton className={classes.close} onClick={() => onConfirmClose()}>
          <Close />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers>
        <Grid container>
          <Grid item xs={3}>
            <StyledEventType variant="body2">Date: </StyledEventType>
          </Grid>
          <Grid item xs={9}>
            <StyledEventStatus>{moment(start).format("LL")}</StyledEventStatus>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={3}>
            <StyledEventType variant="body2">Location: </StyledEventType>
          </Grid>
          <Grid item xs={9}>
            <StyledEventStatus>{location?.address}</StyledEventStatus>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogContent>
        <SlotForm
          triggerReset={triggerReset}
          start={start}
          end={end}
          cohorts={cohorts}
          services={services}
          setFormValues={setFormValues}
          prepareBundle={prepareBundle}
          onClose={() => onConfirmClose()}
        />
      </DialogContent>
    </Dialog>
  ) : (
    <Dialog
      maxWidth="md"
      open={open}
      fullWidth
      disableEnforceFocus
      onClose={() => onConfirmClose()}
    >
      <DialogTitle id="alert-dialog-slide-title">Create Event</DialogTitle>
      <DialogContent>
        <Typography gutterBottom>
          <Moment format={"MMMM DD, YYYY"}>{start}</Moment>
        </Typography>
        <Typography display="inline" className={classes.bold}>
          <Moment format={"hh:mm a"}>{start}</Moment>
        </Typography>{" "}
        to{" "}
        <Typography display="inline" className={classes.bold}>
          <Moment format={"hh:mm a"}>{end}</Moment>
        </Typography>
        <FormGroup>
          <FormControl>
            <TextField
              error={isEmpty(eventTitle)}
              variant="outlined"
              helperText="required"
              autoFocus
              margin="dense"
              id="eventTitle"
              label="Event Name"
              value={eventTitle ? eventTitle : ""}
              onChange={(evt) => setEventTitle(evt.target.value)}
            />
          </FormControl>
          <FormControl>
            <ServiceAutoComplete
              services={services}
              onSetService={onSetService}
              selectedServices={serviceSet}
            />
          </FormControl>
          {!isEmpty(serviceSet) ? (
            <FormControl className={classes.cohortContainer}>
              <StyledEventType className={classes.cohortTitle} variant="body2">
                Assign Cohorts:{" "}
              </StyledEventType>
              {renderServiceBoxes()}
            </FormControl>
          ) : (
            <></>
          )}
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()}>Cancel</Button>
        <Button
          disabled={!isFormValid() || loading}
          onClick={() => prepareBundle()}
          color="primary"
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default withStyles(styles)(EventCreate);
