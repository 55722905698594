import {
    Button,
    TablePagination,
} from "@material-ui/core";
import React, { useCallback, useState } from "react";

import {
    DataGrid,
} from "@zennya/web-component-library/src/components/DataGrid";
import DataGridProvider, {
    actions as gridActions,
    DataGridContext,
} from "@zennya/web-component-library/src/components/DataGrid/DataGridContext";
import { cohortsEntriesData } from "./cohortsData";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import { Typography } from '@material-ui/core';
import corporateHealthService from "../services/corporate-health.service";
import corporateEmployeeService from "../services/corporate-employee.service";
import { useConfirm } from 'material-ui-confirm';
import EmployeeGrid from '../employees/grid';

const styles = (theme) => ({
    linkColor: {
        color: theme.palette.link.main
    }
  });

function CohortEntriesList(props) {
    const {classes} = props;
    const [state, dispatch] = React.useContext(DataGridContext);
    const [columns, setColumns] = React.useState([]);
    const [rows, setRows] = React.useState([]);
    const cohortId = props.cohortId;
    const [params, setParams] = useState({
        page_size: 10,
        page_offset: 0,
    });
    const [pages, setPages] = useState({ count: 0, pages: 0 });
    const [page, setPage] = useState(0);

    const history = useHistory();
    const confirm = useConfirm() 

    const [isAllrowsSelected, setIsAllrowsSelected] = useState(false);
    const [selectedRows, setSelectedRows] = useState(() => new Set());

    const onAllRowsSelectionChange = useCallback(
        (rowset) => {
            setIsAllrowsSelected(rowset)
            const empls = new Set()
            if(rowset) {
                (rows).forEach(element => {
                    empls.add(element.id)
                });
            }
            setSelectedRows(empls)
        },
        [rows],
    );

    React.useEffect(() => {
        dispatch({
            type: gridActions.SET_LOADING
        })
    }, [])
    
    React.useEffect(() => {
        let cols = cohortsEntriesData.getColumns({allRowsSelected:isAllrowsSelected, onAllRowsSelectionChange})
        cols.shift()
        setColumns(cols)
    }, [isAllrowsSelected, onAllRowsSelectionChange])
    
    React.useEffect(() => {
        async function loadCohortEntries() {
            const response = await cohortsEntriesData.getProcessedCohortEntriesList({cohortId, classes, history});
            setRows(response.rows);
            setPages({
                count: response.count,
                pages: Math.ceil(response.count / params.page_size),
            });
            dispatch({
                type: gridActions.SET_DONE_LOADING
            })
        };
        loadCohortEntries();
    }, [cohortId, dispatch, history, params]);

    React.useEffect(() => {
        dispatch({
            payload: {
                columns,
            },
            type: gridActions.LOAD_COLUMNS,
        });
    }, [columns, dispatch]);

    React.useEffect(() => {
        dispatch({
            payload: {
                rows,
            },
            type: gridActions.LOAD_ROWS,
        });
    }, [dispatch, rows]);

    const onChangePage = useCallback((evt, newPage) => {
        setParams({
            ...params,
            page_offset: (newPage + 1) * params.page_size - params.page_size,
        });
        setPage(newPage);
    }, []);

    const onChangeRowsPerPage = useCallback((evt) => {
        setParams({
            ...params,
            page_size: parseInt(evt.target.value, 10),
        });
    }, []);

    const sendInvitesAll = async () => {
        const unlinkedrefs = await corporateHealthService.getPartnerEmployeesAndReferences(props.company, {linked:false})
        console.log("📢[employeeList.js:306]:", unlinkedrefs);
    }
    const sendInvites = async (selRowIds) => {
        const result = await corporateEmployeeService.resendInvites(Array.from(selRowIds))
        console.log("📢[employeeList.js:331]:", result);
    }

    const renderRightAccessory = useCallback(
        () => {
            if (selectedRows.size>0) {
                return (<Button variant="contained" onClick={() => {
                    confirm({
                        title: 'Send invites',
                        description: 'Send email invites to selected employees?',
                        cancellationButtonProps: {autoFocus: true}
                    })
                    .then(()=>sendInvites(selectedRows))
                    .catch(() => {})
                }} color="primary">Resend Invites to Selected ({selectedRows.size})</Button>)
            }
            return <b>Employee Entries</b>
        },
        [confirm, selectedRows],
    );
    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <EmployeeGrid
                columns={columns}
                company={props.company} 
                // listCall={handleAfterSuccess}
                loading={state.loading}
                onChangePage={onChangePage}
                onChangeRowsPerPage={onChangeRowsPerPage}
                rows={rows}
                params={params}
                page={page}
                count={pages.count}
                leftAccessory={()=> {
                    return renderRightAccessory()
                    
                }}
                onSelectedRows={(selRows)=>{
                    setSelectedRows(selRows)
                }}
                selectedRows={selectedRows}
                filterable={false}
                disablePagination
                totalCount={pages.count}
            />
            {/* <DataGrid
                style={{ minHeight: "50vh" }}
                showSelector
                filterable={false}
                columns={columns}
                rows={rows}
                gridProps={{
                    rowHeight: 45,
                }}
                leftAccessory={()=><b>Employee Entries</b>}
                rightAccessory={()=> {
                    return renderRightAccessory()
                    
                }}
                gridProps={{
                    onSelectedRows={(selRows)=>{
                        setSelectedRows(selRows)
                    }}
                    selectedRows={selectedRows}
                }}
            />
            <TablePagination
                component="div"
                count={pages.count}
                page={page}
                onChangePage={onChangePage}
                onChangeRowsPerPage={onChangeRowsPerPage}
                rowsPerPage={params.page_size}
            /> */}
        </div>
    );
}

function WrapCohortEntriesList(props) {
    return (
        <DataGridProvider>
            <CohortEntriesList {...props} />
        </DataGridProvider>
    );
}

export default withStyles(styles)(WrapCohortEntriesList);
