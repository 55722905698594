import React from "react"
import {
    Typography
} from "@material-ui/core"

export default function Title(props) {

    const { text } = props

    return  (
        <Typography variant={"h5"} style={{ fontWeight: "bold" }}>
            { text || "" }
        </Typography>
    )
}
